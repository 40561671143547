import { defineStore } from "pinia";
import { api } from "@/api";

interface State {
  leaveTypes: Array<any>;
}

export const useLeaveTypesStore = defineStore("leaveTypes", {
  state: (): State => ({
    leaveTypes: [],
  }),
  getters: {
    getLeaveTypes: state => {
      return state.leaveTypes;
    },
  },
  actions: {
    fetchLeaveTypes() {
      return api.get("/leave-types/")
        .then((response) => {
          this.leaveTypes = response.data;
        });
    },
  },
});
