<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Numéro, nom, email, téléphone..."
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Montant
          </div>
          <div class="input-group">
            <input
              v-model="amount"
              type="number"
              class="form-control">
            <div class="input-group-append">
              €
            </div>
          </div>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Status du paiement
          </div>
          <select
            v-model="paymentState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="not_paid">
              Impayé
            </option>
            <option value="partial">
              Partiellement payé
            </option>
            <option value="not_paid_or_partially_paid">
              Impayé ou partiellement payé
            </option>
            <option value="paid">
              Payé complètement
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Status Odoo
          </div>
          <select
            v-model="odooPaymentState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="not_pushed">
              Pas encore sur Odoo
            </option>
            <option value="unpaid_or_partially_paid">
              Impayé ou partiellement payé
            </option>
            <option value="paid">
              Payé
            </option>
            <option value="error">
              En erreur
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Rappels de paiements
          </div>
          <select
            v-model="paymentRequestState"
            class="form-select">
            <option value="all">
              Tout
            </option>
            <option value="no_payment_request_sent">
              Aucun rappel envoyé
            </option>
            <option value="first_payment_request_sent">
              Premier rappel envoyé
            </option>
            <option value="second_payment_request_sent">
              Second rappel envoyé
            </option>
            <option value="third_payment_request_sent">
              Troisième rappel envoyé
            </option>
            <option value="transfered_to_collection_company">
              Transféré à la société de recouvrement
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Méthode de paiement
          </div>
          <select
            v-model="paymentMethod"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option value="wire">
              Virement
            </option>
            <option value="cash">
              Liquide
            </option>
            <option value="bancontact">
              Bancontact
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
          </select>
        </div>


        <div class="filter-item">
          <div class="filter-item-title">
            Auteur
          </div>
          <select v-model="authorId" class="form-select">
            <option
              :value="null">
              Tous
            </option>
            <option
              v-for="(technicianChoice, index) in technicians"
              :key="index"
              :value="technicianChoice.id">
              {{ technicianChoice.first_name }} {{ technicianChoice.last_name }}
            </option>
          </select>
        </div>


        <div class="filter-item">
          <ResetFiltersButton :store="paymentRequestListStore" />
        </div>
      </div>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link
        :to="{ name: 'payment-request-list' }"
        class="cat-list-item"
        :class="{ 'active': !$route.query.journalId }">
        Tous
        <span
          v-if="paymentRequestListStore.getTotal('all') != null">
          ({{ paymentRequestListStore.getTotal('all') }})
        </span>
      </router-link>

      <router-link
        v-for="(journal, index) in journals"
        :key="index"
        :to="{ name: 'payment-request-list', query: { journalId: journal.id } }"
        class="cat-list-item"
        :class="{ 'active': $route.query.journalId == journal.id }">
        {{ journal.origin.code }} {{ journal.invoice_code_prefix }}
        <span
          v-if="paymentRequestListStore.getTotal(journal.id) != null">
          ({{ paymentRequestListStore.getTotal(journal.id) }})
        </span>
      </router-link>
    </div>

    <router-view style="min-height: 100%;" />
  </div>
</template>

<script>

import { usePaymentRequestListStore } from "@/stores/paymentRequestList";
import { useJournalsStore } from "@/stores/journals";
import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import { useTechniciansStore } from "@/stores/technicians";

export default {
  name: "InvoiceListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
  },
  data() {
    return {
      paymentRequestListStore: usePaymentRequestListStore(),
      journalsStore: useJournalsStore(),
      techniciansStore: useTechniciansStore(),
    };
  },
  computed: {
    journals() {
      return this.journalsStore.getJournals;
    },
    query: {
      get() {
        return this.paymentRequestListStore.getFilter("q");
      },
      set(val) {
        this.paymentRequestListStore.addFilter("q", val);
        this.paymentRequestListStore.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.paymentRequestListStore.getFilter("created_at_after"),
          this.paymentRequestListStore.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.paymentRequestListStore.addFilter("created_at_after", val[0]);
        this.paymentRequestListStore.addFilter("created_at_before", val[1]);
        this.paymentRequestListStore.refresh();
      },
    },
    amount: {
      get() {
        return [
          this.paymentRequestListStore.getFilter("amount"),
        ];
      },
      set(val) {
        this.paymentRequestListStore.addFilter("amount", val);
        this.paymentRequestListStore.refresh();
      },
    },
    paymentState: {
      get() {
        const res = this.paymentRequestListStore.getFilter("payment_state");
        if (res == null) {
          return "all";
        } else {
          return res;
        }
      },
      set(val) {
        if (val == "all") {
          val = null;
        }
        this.paymentRequestListStore.addFilter("payment_state", val);
        this.paymentRequestListStore.refresh();
      },
    },
    paymentRequestState: {
      get() {
        const res = this.paymentRequestListStore.getFilter("payment_request_state");
        if (res == null) {
          return "all";
        } else {
          return res;
        }
      },
      set(val) {
        if (val == "all") {
          val = null;
        }
        this.paymentRequestListStore.addFilter("payment_request_state", val);
        this.paymentRequestListStore.refresh();
      },
    },
    odooPaymentState: {
      get() {
        const res = this.paymentRequestListStore.getFilter("odoo_payment_state");
        if (res == null) {
          return "all";
        } else {
          return res;
        }
      },
      set(val) {
        if (val == "all") {
          val = null;
        }
        this.paymentRequestListStore.addFilter("odoo_payment_state", val);
        this.paymentRequestListStore.refresh();
      },
    },
    paymentMethod: {
      get() {
        return this.paymentRequestListStore.getFilter("payment_method");
      },
      set(val) {
        this.paymentRequestListStore.addFilter("payment_method", val);
        this.paymentRequestListStore.refresh();
      },
    },
    sortBy: {
      get() {
        return this.paymentRequestListStore.getFilter("sort_by");
      },
      set(val) {
        this.paymentRequestListStore.addFilter("sort_by", val);
        this.paymentRequestListStore.refresh();
      },
    },
    authorId: {
      get() {
        return this.paymentRequestListStore.getFilter("author_id");
      },
      set(val) {
        this.paymentRequestListStore.addFilter("author_id", val);
        this.paymentRequestListStore.refresh();
      },
    },
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    
  },
};
</script>

<style lang="scss" scoped>


</style>
