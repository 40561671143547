<template>
  <div :class="{ 'visible': backgroundVisible }" class="my-modal" @click.self="if (!preventHideOnBackgroundClick) hide();">
    <div
      :class="{'visible': visible, 'full': width == 'full', 'big': width == 'big', 'medium': width == 'medium', 'small': width == 'small', 'auto': width == 'auto', 'no-padding': padding == 'none', 'transparent': noBackground }" 
      class="my-modal-content"
      :style="{ 'background-color': backgroundColor }">
      <h2
        v-if="title"
        style="text-align: center; margin-bottom: 20px; color: black;">
        {{ title }}
      </h2>
      <div class="my-modal-body">
        <slot />
      </div>
      <div v-if="$slots.buttons" class="my-modal-buttons">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    width: {
      type: String,
      required: false,
      default: "auto",
      validator: (width) => {
        return ["full", "big", "medium", "small", "auto"].includes(width);
      },
    },
    padding: {
      type: String,
      required: false,
      default: "default",
      validator: (padding) => {
        return ["default", "none"].includes(padding);
      },
    },
    preventHideOnBackgroundClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fefefe",
    },
    noBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["visible", "hidden"],
  data() {
    return {
      backgroundVisible: false,
      visible: false,
    };
  },
  methods: {
    show() {
      this.backgroundVisible = true,
      this.visible = true;
      this.$emit("visible");
    },
    hide() {
      this.visible = false;
      setTimeout(
        () => {
          this.backgroundVisible = false;
          this.$emit("hidden");
        }, 200,
      );
    },
    toggle() {
      if (this.backgroundVisible) {
        this.hide();
      } else {
        this.show();
      }
    },
  },
};
</script>

<style lang="scss" scroped>

   /* The Modal (background) */
  .my-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 998; /* Sit on top */ /* The Google Autocomplete component is 1000 and it must be over the modale */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    margin: 0px !important;

    &.visible {
      display: block;
    }

    /* Modal Content/Box */
    .my-modal-content {
      background-color: #fefefe;
      border: 1px solid #888;
      border-radius: 4px;
      white-space: normal;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      overflow: auto;

      &.transparent {
        background-color: transparent !important;
        border: none;
        box-shadow: none;
      }

      padding: 20px;

      &.no-padding {
        padding: 0px;
      }

      // Display
      display: flex;
      flex-direction: column;

      // Position

      position: relative;
      margin: 10% auto;

      // Height

      max-height: 80%;

      // Width

      &.full {
        width: auto;
        max-width: none;
        margin: 0;
        max-height: 100%;
        height: 100%;
      }

      &.big {
        max-width: 900px;
      }

      &.medium {
        max-width: 500px;
      }

      &.small {
        max-width: 300px;
      }

      &.auto {
        width: fit-content;
      }

      // Animation on visible

      animation-name: appear;
      animation-duration: 0.2s;
      animation-timing-function: ease-out;
      -o-animation-timing-function: ease-out;
      -moz-animation-timing-function: ease-out;
      -webkit-animation-timing-function: ease-out;

      @keyframes appear {
        from {top: -100px; opacity: 0}
        to {top: 0; opacity: 1}
      }

      @-webkit-keyframes appear {
        from {top: -100px; opacity: 0}
        to {top: 0; opacity: 1}
      }
           
      // Animation on hidden

      &:not(.visible) {
        animation-name: disappear;
        animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        top: -100px;
        opacity: 0;

        @keyframes disappear {
          to {top: -100px; opacity: 0}
          from {top: 0; opacity: 1}
        }

        @-webkit-keyframes disappear {
          to {top: -100px; opacity: 0}
          from {top: 0; opacity: 1}
        } 
      }

      .my-modal-body {
        flex-grow: 1;
      }

      .my-modal-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>