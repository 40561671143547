<template>
  <div class="form-group">
    <label v-if="!hideTitle" class="form-label">
      <span v-if="title">{{ title }}</span> <span v-else-if="schema">{{ $filters.capitalize(schema.verbose_name) }}</span> <span v-if="markAsRequired" style="font-size: 0.8rem;"><i>Requis</i></span>
      <span v-if="schema && schema.help_text" class="tooltip" style="cursor: help;">
        &#9432;
        <div class="tooltip-text" style="min-width: 200px;">{{ schema.help_text }}</div>
      </span>
    </label>
    <slot />
    <small
      v-if="errorsStr"
      class="form-text text-red">
      {{ errorsStr }}
    </small>
  </div>
</template>



<script>


export default {
  name: "FormField",
  props: {
    schema: {
      type: Object,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    errors: {
      type: Array,
      required: false,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: null, // If specified, this will overwrite the schema.null value
    },

  },
  computed: {
    markAsRequired() {
      if (this.required != null) {
        return this.required;
      } else {
        return this.schema != null && !this.schema.null;
      }
    },
    errorsStr() {
      const errors = [];

      if (this.error) {
        errors.push(this.error);
      }

      if (this.errors) {
        return [...this.errors, ...errors].join(" | ");
      } else {
        return errors.join(" | ");
      }
    },
  },
};

</script>
