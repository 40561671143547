<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <button
        v-if="meIsAdminOrManager"
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-storage' })">
        <i class="bi bi-boxes" />
        <div class="tooltip-text left">
          Nouveau stockage
        </div>
      </button>

      <button
        class="tool-btn tooltip"
        style="margin-left: 5px;"
        @click="$router.push({ name: 'scan-product' })">
        <i class="bi-upc-scan" />
        <div class="tooltip-text left">
          Scanner
        </div>
      </button>
    </div>

    <LocalList
      :rowComponent="storageRow"
      :load="loadStorages" />
  </div>
</template>

<script>

import StorageRow from "@/components/rows/storage/StorageRow.vue";
import { shallowRef } from "vue";
import LocalList from "@/components/listV2/LocalList.vue";
import { useStoragesStore } from "@/stores/storages";
import { useMeStore } from "@/stores/me";

export default {
  name: "StorageListLayout",
  components: {
    LocalList,
  },
  data() {
    return {
      storageRow: shallowRef(StorageRow),
      storagesStore: useStoragesStore(),
      meStore: useMeStore(),
    };
  },
  computed: {
    meIsAdminOrManager() {
      return this.meStore.meIsAdmin || this.meStore.meIsManager;
    },
  },
  methods: {
    loadStorages() {
      return this.$services.storage.search();
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
