<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Contrat</span>
    </div>

    <div class="card-body">
      <div v-if="editing">
        <div v-if="contract" class="form-group">
          Contrat actuellement sélectionné : {{ contract.ref }}
        </div>

        <div class="form-group">
          <label class="form-label">
            Nouveau contrat à assigner
          </label>

          <div class="horizontal-spaced-container align-middle">
            <SelectObject
              type="contract"
              placeholder="Sélectionnez un contrat"
              style="width: 100%; max-width: 400px;"
              @select="onContractSelected" />
            <Loader v-if="saving" />
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end;">
          <button
            class="btn-primary"
            :disabled="saving"
            @click="editing = false">
            Annuler
          </button>
        </div>
      </div>

      <div v-else-if="contract" class="flow-spaced-container">
        <router-link
          class="btn-link"
          :to="{ name: 'contract', params: { 'id': contract.id } }"
          @click.stop>
          {{ contract.ref }}
        </router-link>
          
        <button
          class="btn-link"
          @click="editing = true">
          Changer
        </button>
      </div>

      <div v-else class="vertical-spaced-container">
        <div><i>Aucun</i></div>
        <button
          class="btn-link"
          @click="editing = true">
          Sélectionner un contrat
        </button>
        <button
          class="btn-link"
          @click="createContract">
          Créer un contrat
        </button>
      </div>
    </div>

    <CreateContractModal
      ref="createContractModal"
      @created="onContractSelected" />
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import Loader from "@/components/Loader.vue";
import CreateContractModal from "@/components/modals/CreateContractModal.vue";

export default {
  name: "InvoiceContractCard",
  components: {
    SelectObject,
    Loader,
    CreateContractModal,
  },
  props: {
    modelValue: { // The invoice
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],
  data() {
    return {
      editing: false,
      saving: false,
    };
  },
  computed: {
    contractIteration() {
      return this.modelValue.contract;
    },
    contract() {
      if (this.contractIteration) {
        return this.contractIteration.contract;
      } else {
        return null;
      }
    },
  },
  methods: {
    createContract() {
      this.$refs.createContractModal.show(this.modelValue.customer);
    },
    onContractSelected(contract) {

      if (contract.iterations == null || contract.iterations.length <= 0) {
        throw new Error("No contract iteration found");
      }

      this.saving = true;

      const body = {
        contract_id: contract.iterations[0].id,
      };

      this.$services.invoice.patch(
        this.modelValue.id,
        body,

      ).then((response) => {
        this.$emit("update:modelValue", {
          ...this.modelValue,
          contract: response.data.contract,
        });

        this.editing = false;

      
      }).finally(() => {
        this.saving = false;
      });
    },
  },
  
};
</script>
