import { RouteConfig } from "vue-router";

import { useProductListStore } from "@/stores/productList";

import ProductGenericRow from "@/components/rows/product/ProductGenericRow.vue";
import ProductListLayout from "@/views/products/ProductListLayout.vue";
import ProductObjectLayout from "@/views/products/ProductObjectLayout.vue";
import ProductObjectView from "@/views/products/ProductObjectView.vue";
import ItemObjectCreationView from "@/views/products/ItemObjectCreationView.vue";
import ProductScannerView from "@/views/products/ProductScannerView.vue";
import ProductObjectStock from "@/views/products/ProductObjectStock.vue";
import StorageObjectCreationView from "@/views/storages/StorageObjectCreationView.vue";
import StorageContentLayout from "@/views/storages/StorageContentLayout.vue";
import StorageListLayout from "@/views/storages/StorageListLayout.vue";
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import ItemGrid from "@/views/products/items/ItemGrid.vue";
import ItemList from "@/views/products/items/ItemList.vue";
import ProductGrid from "@/views/products/ProductGrid.vue";


const stockRoutes: RouteConfig[] = [

  //// Stock

  {
    path: "/stock",
    redirect: "/stock/produits",
    name: "stock",
  },

  // Products
  
  {
    path: "/stock/produits",
    component: ProductListLayout,
    redirect: to => ({
      name: "item-list",
    }),
    name: "product-list",
    children: [
      {
        path: "grille-de-produits",
        component: ProductGrid,
        name: "product-grid",
      },
      {
        path: "articles",
        component: PaginatedStoreList,
        name: "item-list",
        props: () => {
          return {
            rowComponent: ProductGenericRow,
            store: useProductListStore(),
            noResultMessage: "Aucun article",
            subList: "item",
          };
        },
      },
      {
        path: "equipements",
        component: PaginatedStoreList,
        name: "product-list-equipment",
        props: () => {
          return {
            rowComponent: ProductGenericRow,
            store: useProductListStore(),
            noResultMessage: "Aucun équipement",
            subList: "equipment",
          };
        },
      },
      {
        path: "services",
        component: PaginatedStoreList,
        name: "product-list-service",
        props: () => {
          return {
            rowComponent: ProductGenericRow,
            store: useProductListStore(),
            noResultMessage: "Aucun service",
            subList: "service",
          };
        },
      },
      {
        path: "forfaits",
        component: PaginatedStoreList,
        name: "product-list-bundle",
        props: () => {
          return {
            rowComponent: ProductGenericRow,
            store: useProductListStore(),
            noResultMessage: "Aucun forfait",
            subList: "bundle",
          };
        },
      },
    ],
  },
  {
    path: "/stock/produits/:id",
    component: ProductObjectLayout,
    name: "product",
    redirect: to => ({
      name: "product-view",
      params: { id: to.params.id },
    }),
    children: [
      {
        path: "voir",
        component: ProductObjectView,
        name: "product-view",
      },
      {
        path: "stock",
        component: ProductObjectStock,
        name: "product-stock",
      },
    ],
  },
  {
    path: "/stock/produits/nouveau",
    component: ItemObjectCreationView,
    name: "create-product",
  },
  {
    path: "/stock/produits/scanner",
    component: ProductScannerView,
    name: "scan-product",
  },

  // Storage

  {
    path: "/stock/stockages",
    component: StorageListLayout,
    name: "storage-list",
  },
  {
    path: "/stock/stockages/nouveau",
    component: StorageObjectCreationView,
    name: "create-storage",
  },
  {
    path: "/stock/stockages/:id/",
    component: StorageContentLayout,
    name: "storage",
  },
];

export default stockRoutes;
