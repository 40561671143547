<template>
  <tr class="my-list-row selectable">
    <td>
      <span class="text-bigger mr-2">{{ $filters.printCustomer(object) }}</span><span class="text-smaller">{{ object.billing_street }} {{ object.billing_city }}</span>
    </td>
  </tr>
</template>

<script>

export default {
  name: "CustomerResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
