<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;"><i class="bi bi-person-vcard" /> Contacts</span>
    </div>
    
    <div class="card-body">
      <div class="vertical-spaced-container">
        <div
          v-for="(person, index) of modelValue"
          :key="person.id">
          <div style="display: flex;">
            <strong style="margin-right: 5px;">
              #{{ index + 1 }} {{ $filters.fullname(person) }}
            </strong>

            <button class="small-tool-btn" @click="$refs.personModal.edit(person)">
              <i class="bi bi-pencil-square" />
            </button>
          </div>
          <p><span v-if="person.email">{{ person.email }}</span><i v-else>Pas d'email</i></p>
          <p><span v-if="person.phone_number">{{ person.phone_number }}</span><i v-else>Pas de numéro de téléphone</i></p>
          <p>Parle {{ person.language }}</p>
        </div>

        <div>
          <button
            class="btn-link"
            @click="$refs.personModal.new()">
            <i class="bi bi-plus-circle" /> Ajouter un contact
          </button>
        </div>
      </div>

      <PersonModal
        ref="personModal"
        @onPersonCreated="onPersonCreated"
        @onPersonChanged="onPersonChanged" />
    </div>
  </div>
</template>

<script>

import PersonModal from "@/components/modals/PersonModal.vue";

export default {
  name: "PersonsCard",
  components: {
    PersonModal,
  },
  props: {
    modelValue: { // The person list
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onPersonCreated(newPerson) {
      this.$emit(
        "update:modelValue", 
        [...this.modelValue, newPerson],
      );
    },
    onPersonChanged(newPerson) {
      this.$emit(
        "update:modelValue", 
        this.modelValue.map((person) => {
          if (person.id === newPerson.id) {
            return newPerson;
          }
          return person;
        }),
      );
    },
  },
};

</script>

<style lang="scss" scoped>


</style>