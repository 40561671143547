<template>
  <ObjectViewLayout
    title="Nouveau stockage"
    withBackButton
    @back="$router.push({ name: 'storage-list' })">
    <template #default>
      <FormSection>
        <template #default>
          <table class="table-form">
            <tr>
              <td><strong>Nom :</strong></td>
              <td>
                <input v-model="object.name" type="text">
                <span
                  v-if=" validationError?.name"
                  class="validation-error">{{ validationError.name }}</span>
              </td>
            </tr>
          </table>
        </template>
      </FormSection>
    
      <button class="btn-outline-secondary" style="margin-right: 10px;" @click="cancel">
        Annuler
      </button>
      <button class="btn-secondary" @click="submit">
        <Loader v-if="submitting" />
        Valider
      </button>
    </template>
  </ObjectViewLayout>
</template>

<script>

import { api, uri } from "@/api";
import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormSection from "@/components/layout/FormSection.vue";
import Loader from "@/components/Loader.vue";
import { notifySuccess, notifyError } from "@/notifications";

export default {
  name: "StorageObjectCreationView",
  components: {
    ObjectViewLayout,
    FormSection,
    Loader,
  },
  data() {
    return {
      submitting: false,
      object: {},
      validationError: null,
    };
  },
  methods: {
    submit() {
      this.submitting = true;
      this.validationError = null;

      let submittedObject = JSON.parse(JSON.stringify(this.object));

      api.post(uri.batches(), submittedObject)
        .then((response) => {
          notifySuccess(`Stockage ${response.data.name} créé`);
          this.$router.push({ name: "storage", params: { id: response.data.id } });

        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationError = error.response.data;
          } else {
            notifyError("Erreur lors de la création du stockage");
            throw error;
          }

        }).finally(() => {
          this.submitting = false;
        });
    },
    cancel() {
      this.$router.push({ name: "storage-list" });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>