<template>
  <div style="text-align: center; display: flex; flex-direction: column; min-width: 300px;">
    <div class="form-group" style="font-weight: bold; font-size: 1.2rem;">
      Connectez vous à<br>l'espace prestataire EDN
    </div>
    
    <div class="form-group">
      <input
        id="email"
        v-model="credentials.email"
        type="text"
        placeholder="Email"
        class="form-control">
    </div>

    <div class="form-group">
      <input
        id="password"
        v-model="credentials.password"
        type="password"
        placeholder="Mot de passe"
        class="form-control">
    </div>

    <button
      type="submit"
      class="btn-primary"
      :class="{ 'loading': loading }"
      @click.prevent="login">
      Se connecter
    </button>
  </div>
</template>

<script>

import { notifyError } from "@/notifications";
import { useMeStore } from "@/stores/me";

export default {
  name: "Login",
  emits: ["loginSucceed"],
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      loading: false,
      meStore: useMeStore(),
    };
  },
  methods: {
    login() {
      if (this.loading) {
        return;
      }

      if (!this.credentials.email) {
        notifyError("Veuillez renseigner votre email");
        return;
      }

      if (!this.credentials.password) {
        notifyError("Veuillez renseigner votre mot de passe");
        return;
      }

      this.loading = true;

      this.meStore.login(this.credentials)
        .then(() => {
          this.$emit("loginSucceed");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) { // Received unautorised response
            notifyError("Email ou mot de passe invalide");
          } else {
            notifyError("Une erreur est survenue lors de l'autentification.");
            throw error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
