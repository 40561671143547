<template>
  <div style="margin: 10px;">
    <MonthSelector @change="onSelectedMonthChanged" />

    <div style="overflow-x: auto;">
      <table>
        <tr>
          <th class="header">
            <LoaderView v-if="loading" />
          </th>

          <th
            v-for="(date, index) in days"
            :key="index"
            class="header">
            {{ date }}
          </th>

          <th class="header" rowspan="2">
            Nombres total de demi-journée travaillée
          </th>
        </tr>

        <tr>
          <td class="header">
            Jours fériés
          </td>

          <td
            v-for="(date, index2) in days"
            :key="index2"
            class="header"
            :class="{ 'holiday': holidayAtDate(date) != null }">
            <div v-if="holidayAtDate(date)">
              {{ holidayAtDate(date) }}
            </div>
          </td>
        </tr>
        
        <tr
          v-for="(technician, index) in techniciansData"
          :key="index"
          style="height: 50px;">
          <td class="header">
            {{ technician.first_name }} {{ technician.last_name }}
          </td>

          <StaffCalendarCase
            v-for="(date, index2) in days"
            :key="index2"
            :holidays="holidays"
            :technician="technician"
            :date="date"
            :selectedTechnician="selectedTechnician"
            :selectionFromDate="selectionFromDate"
            :selectionFromMorning="selectionFromMorning"
            :selectionToDate="selectionToDate"
            :selectionToMorning="selectionToMorning"
            @openEditionModal="openEditionModal"
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            @mouseenter="onMouseEnter" />

          <td class="header">
            {{ technician.nb_working_half_days_during_month }}
          </td>
        </tr>
      </table>
    </div>

    <div style="display: flex; margin-top: 20px; margin-left: -5px; margin-right: -5px;">
      <div
        v-for="(leaveType, index) in leaveTypes"
        :key="index"
        style="width: 60px; height: 60px; display: flex; justify-content: center; align-items: center; padding: 5px; text-align: center; margin: 0 5px;"
        :style="{ 'background-color': leaveType.color }">
        {{ leaveType.label ? leaveType.label : leaveType.code }}
      </div>
    </div>

    <EditStaffCalendarDateModal ref="edition-modal" @onLeavesCreated="onLeavesCreated" />
  </div>
</template>

<script>

import { api, uri } from "@/api";
import LoaderView from "@/components/LoaderView.vue";
import EditStaffCalendarDateModal from "@/components/modals/EditStaffCalendarDateModal.vue";
import { GDate } from "g-date";
import StaffCalendarCase from "@/components/StaffCalendarCase.vue";
import MonthSelector from "@/components/MonthSelector.vue";
import { useLeaveTypesStore } from "@/stores/leaveTypes";

export default {
  name: "StaffCalendarView",
  components: {
    LoaderView,
    EditStaffCalendarDateModal,
    StaffCalendarCase,
    MonthSelector,
  },
  data() {
    return {
      loading: false,
      techniciansData: null,
      holidays: null,
      year: GDate.today().year,
      month: GDate.today().month,
      selectedTechnician: null,
      selectionFromDate: null,
      selectionFromMorning: null,
      selectionToDate: null,
      selectionToMorning: null,
      leaveTypesStore: useLeaveTypesStore(),
    };
  },
  computed: {
    beginingOfRequestedMonth() {
      return new GDate(this.year, this.month, 1);
    },
    days() {
      var date = this.beginingOfRequestedMonth;
      var days = [];
      while (date.month == this.month) {
        days.push(date);
        date = date.addDays(1);
      }
      return days;
    },
    leaveTypes() {
      return this.leaveTypesStore.getLeaveTypes;
    },
  },
  mounted() {  
    this.fetchData();
    if (this.leaveTypes.length == 0) {
      this.leaveTypesStore.fetchLeaveTypes();
    }
  },
  methods: {
    fetchData() {
      console.log("Fetching planning staff data");
      this.loading = true;

      api.get(uri.planningStaff(this.year, this.month))
        .then((response) => {
          this.techniciansData = response.data.technicians_data;
          this.holidays = response.data.holidays;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // eslint-disable-next-line no-unused-vars
    onLeavesCreated(_newLeaves) {
      this.fetchData();
    },
    holidayAtDate(date) {
      if (this.holidays == null) {
        return null;
      }

      const isoDate = date.toISOString();
      
      if (isoDate in this.holidays) {
        return this.holidays[isoDate];
      } else {
        return null;
      }
    },

    onSelectedMonthChanged(data) {
      this.year = data.year;
      this.month = data.month;
      this.fetchData();
    },
    onMouseDown(technician, date, morning) {
      console.log("onMouseDown");
      this.selectedTechnician = technician;
      this.selectionFromDate = date;
      this.selectionFromMorning = morning;
    },
    onMouseUp(_technician, date, morning) {
      this.$refs["edition-modal"].show(this.selectedTechnician, this.selectionFromDate, this.selectionFromMorning, date, morning);
      this.selectedTechnician = null;
      this.selectionFromDate = null;
      this.selectionFromMorning = null;
      this.selectionToDate = null;
      this.selectionToMorning = null;
    },
    onMouseEnter(_technician, date, morning) {
      if (this.selectedTechnician != null) {
        this.selectionToDate = date;
        this.selectionToMorning = morning;
      }
    },
    openEditionModal(technician, date, morning, afternoon) {
      this.$refs["edition-modal"].show(technician, date, morning, date, !afternoon);
    },
  },
};
</script>

<style scoped lang="scss">

  .header {
    background: lightsteelblue; padding: 5px; border: 1px solid #e6e6e6;

    &.holiday {
      background: lightgray;
    }
  }

</style>
