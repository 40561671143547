<template>
  <button class="tool-btn tooltip" @click.stop="fetchPdf">
    <Loader v-if="fetchingPdf" />
    <i v-else class="bi bi-filetype-pdf" />
    <div class="tooltip-text left">
      Télécharger en PDF
    </div>
  </button>

  <button class="tool-btn tooltip" :disabled="modelValue.rdv_date == null" @click.stop="sendToCustomerByEmail">
    <Loader v-if="sendingToCustomerByEmail" />
    <i v-else class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer au client par email
      </div>
      <div v-if="modelValue.rdv_date == null" class="tooltip-text-red">
        Le rendez-vous n'a pas encore été fixé
      </div>
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="edit">
    <i class="bi bi-pencil-square" />
    <div class="tooltip-text left">
      Editer
    </div>
  </button>
</template>

<script>

import Loader from "@/components/Loader.vue";

export default {
  name: "InterventionReportToolButtons",
  components: {
    Loader,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingPdf: false,
      sendingToCustomerByEmail: false,
    };
  },
  methods: {
    fetchPdf() {
      this.fetchingPdf = true;

      this.$services.intervention.fetchReportPdf(this.modelValue.id)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          var filename = "rapport.pdf";

          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          
        }).finally(() => {
          this.fetchingPdf = false;
        });
    },
    sendToCustomerByEmail() {
      this.sendingToCustomerByEmail = true;

      this.$services.intervention.sendReportToCustomerByEmail(this.modelValue.id)
        .finally(() => {
          this.sendingToCustomerByEmail = false;
        });
    },
    edit() {
      this.$router.push({ name: "edit-intervention-report", params: { id: this.modelValue.id } });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>