import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useQuoteListStore = buildPaginatedMultiListStore(
  "quoteList",
  "/quotes/",
  "Aucun devis trouvé",
  true,
  (filters: object, subList: string) => {
    if (subList != "all") {
      filters.journal = subList;
    }

    return filters;
  },
);
