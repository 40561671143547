<template>
  <div>
    <p v-if="errorFetchingSlots">
      Aïe ! Il y a eu une erreur lors du chargement des créneaux disponibles... Essayez de recharger la page ou signalez le problème au support technique.
    </p>

    <LoaderView v-else-if="slots == null" text="Chargement des créneaux disponibles" />
      
    <i v-else-if="slots.length == 0">
      Aucun créneau disponible
    </i>

    <div v-else class="vertical-spaced-container">
      <SlotCard 
        v-for="(slot, index) in slots"
        :key="index"
        :extendable="extendable"
        :extended="slot.extended == true"
        :slotProp="slot"
        :intervention="intervention"
        @extend="extendSlot(slot)"
        @collapse="slot.extended = false" />
    </div>
  </div>
</template>

<script>

import SlotCard from "@/views/interventions/SlotCard.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "FreeSlots",
  components: {
    SlotCard,
    LoaderView,
  },
  props: {
    intervention: {
      type: Object,
      required: false,
      default: null,
    },
    technicianIds: {
      type: Array,
      required: false,
      default: null,
    },
    activityIds: {
      type: Array,
      required: false,
      default: null,
    },
    zipcode: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
    dateFrom: {
      type: String,
      required: false,
      default: () => null,
    },
    dateTo: {
      type: String,
      required: false,
      default: () => null,
    },
    extendable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["extend"],
  data() {
    return {
      errorFetchingSlots: false,
      slots: null,
    };
  },
  mounted() {
    this.fetchSlots();
  },
  methods: {
    fetchSlots() {
      this.slots = null;
      this.errorFetchingSlots = false;
      
      this.$services.planning.fetchFreeSlots(
        this.intervention ? this.intervention.id : null,
        this.zipcode,
        this.countryCode,
        this.technicianIds != null && this.technicianIds.length > 0 ? this.technicianIds : null,
        this.activityIds != null && this.activityIds.length > 0 ? this.activityIds : null,
        this.dateFrom,
        this.dateTo,
      )
        .then((response) => {
          const slots = response.data;

          // Fonction de comparaison personnalisée pour trier les objets en fonction de 'additional_distance'
          function compareSlots(a, b) {
            // Gérer les cas où 'additional_distance' est null ou undefined ou not a number
            if (a.additional_distance === null || a.additional_distance === undefined || isNaN(a.additional_distance)) {
              return 1; // 'a' est considéré plus grand, donc il sera placé après 'b'
            } else if (b.additional_distance === null || b.additional_distance === undefined || isNaN(b.additional_distance)) {
              return -1; // 'b' est considéré plus grand, donc il sera placé après 'a'
            } else {
              // Comparaison basée sur la valeur de 'additional_distance'
              return a.additional_distance - b.additional_distance;
            }
          }

          // Tri de la liste 'slots' en utilisant le comparateur personnalisé
          slots.sort(compareSlots);
          
          this.slots = slots;
        })
        .catch((err) => {
          this.errorFetchingSlots = true;
          throw err;
        });
    },
    extendSlot(slot) {
      slot.extended = true;
      this.$emit("extend", slot);
    },
  },
  
};
</script>

<style lang="scss" scoped>


</style>
