<template>
  <Modal ref="modal" width="big">
    <template #default>
      <strong>Encoder un appel client</strong>
      <div>
        <textarea v-model="notes" style="width: 100%; height: 100%; min-height: 50vh; resize: none;" class="form-control" />
      </div>
    </template>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="cancel">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="!canSubmit || submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "AddCallModal",
  components: {
    Modal,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  emits: ["newCall"],
  data() {
    return {
      submitting: false,
      notes: null,
    };
  },
  computed: {
    canSubmit() {
      return this.notes != null && this.notes.length > 0;
    },
  },
  methods: {
    clear()  {
      this.notes = null;
      this.submitting = false;
    },
    show() {
      this.clear();
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    submit() {
      this.submitting = true;
      this.$services.customerCall.create(
        {
          customer_id: this.customerId,
          notes: this.notes,
        },
      ).then((response) => {
        this.$emit("newCall", response.data);
        this.hide();
      }).finally(() => {
        this.submitting = false;
      });
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>