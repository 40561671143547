<template>
  <ManyToManyForm
    v-model="internalValue"
    :elements="actions"
    fieldToDisplay="description" />
</template>

<script setup lang="ts">

import ManyToManyForm from "@/components/forms/ManyToManyForm.vue";
import { useInterventionReportOptionsStore } from "@/stores/interventionReportOptions";
import { computed } from "vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: Array,
    required: false,
    default: () => {return [];},
  },
});

// Emits

// eslint-disable-next-line no-undef
const emits = defineEmits(["update:modelValue"]);

// Data

const interventionReportOptionsStore = useInterventionReportOptionsStore();

// Computed

const actions = computed(() => interventionReportOptionsStore.getActions); // Utilisez le type State ici
const internalValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits("update:modelValue", value);
  },
});

</script>
