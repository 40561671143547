<template>
  <Modal
    ref="modal"
    :backgroundColor="backgroundColor">
    <div v-if="modelValue" class="vertical-spaced-container">
      <div>
        <div class="flow-spaced-container" style="align-items: center;">
          <strong>
            <span v-if="modelValue.status == 'cancelled'">Annulé</span>
            <span v-else>Echéance: {{ $filters.dateStringToHumanizedDateTime(modelValue.deadline) }}</span>
          </strong>
      
          <button
            v-if="modelValue.status != 'cancelled'"
            class="tool-btn tooltip"
            @click="toggleStatus">
            <Loader v-if="togglingStatus" />
            <i v-else-if="modelValue.status == 'done'" class="bi bi-check2-circle" />
            <i v-else class="bi bi-check-lg" />
            <div class="tooltip-text over">
              Marquer comme {{ modelValue.status == 'done' ? 'non ' : '' }}fait
            </div>
          </button>

          <button
            v-if="modelValue.status != 'done'"
            class="tool-btn tooltip"
            @click="toggleCancel">
            <Loader v-if="cancelling" />
            <i v-else-if="modelValue.status == 'cancelled'" class="bi bi-circle" />
            <i v-else class="bi bi-x-circle" />
            <div class="tooltip-text over">
              <span v-if="modelValue.status == 'cancelled'">Désannuler</span>
              <span v-else>Annuler</span>
            </div>
          </button>

          <button v-if="modelValue.status == 'todo'" class="tool-btn tooltip" @click="delay">
            <i class="bi bi-arrow-counterclockwise" />
            <div class="tooltip-text over">
              Reporter l'échéance
            </div>
          </button>
        </div>
      </div>

      <div>
        <select
          class="form-select"
          :value="modelValue.action"
          @change="onActionChange">
          <option value="call">
            Appeler le client
          </option>
          <option value="sms">
            Envoyer un SMS
          </option>
          <option value="email">
            Envoyer un email au client
          </option>
          <option value="rdv">
            Prendre rendez-vous avec le client
          </option>
          <option value="close">
            Clôturer l'intervention
          </option>
          <option value="cancel">
            Annuler l'intervention
          </option>
        </select>
      </div>

      <div>
        <SelectObject
          type="user"
          :selectedValue="modelValue.assigned ? $filters.fullname(modelValue.assigned) : null"
          @select="onNewAssignedAt"
          @unselect="onNewAssignedAt()" />
      </div>

      <div>
        <HistoryCard
          objectName="la tache"
          :history="modelValue.history"
          :postComment="postComment" />
      </div>
    </div>
      
    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          @click="hide">
          Fermer
        </button>
      </div>
    </template>
  </Modal>

  <DelayTaskModal v-if="modelValue" ref="delayModal" :modelValue="modelValue" />
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";
import Loader from "@/components/Loader.vue";
import DelayTaskModal from "@/components/modals/DelayTaskModal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "TaskModal",
  components: {
    Modal,
    HistoryCard,
    Loader,
    DelayTaskModal,
    SelectObject,
  },
  emits: ["change"],
  data() {
    return {
      togglingStatus: false,
      delaying: false,
      cancelling: false,
      modelValue: null,
    };
  },
  computed: {
    isDueToday() {
      if (this.modelValue == null) {
        return false;
      }

      if (this.isDone || this.isCancelled) {
        return false;
      }
      const deadline = new Date(this.modelValue.deadline);
      const today = new Date();
      return deadline.getDate() == today.getDate()
        && deadline.getMonth() == today.getMonth()
        && deadline.getFullYear() == today.getFullYear();
    },
    isOverdue() {
      if (this.modelValue == null) {
        return false;
      }

      if (this.isDone || this.isCancelled) {
        return false;
      }
      const deadline = new Date(this.modelValue.deadline);
      const today = new Date();
      return deadline < today;
    },
    isDone() {
      if (this.modelValue == null) {
        return false;
      }

      return this.modelValue.status == "done";
    },
    isCancelled() {
      if (this.modelValue == null) {
        return false;
      }

      return this.modelValue.status == "cancelled";
    },
    backgroundColor() {
      if (this.isCancelled) {
        return "lightgray";
      } else if (this.isDone) {
        return "lightgreen";
      } else if (this.isOverdue) {
        return "salmon";
      } else if (this.isDueToday) {
        return "#fbfba4";
      } else {
        return "lightblue";
      }
    },
  },
  methods: {
    clear() {
      this.modelValue = null;
      this.togglingStatus = false;
      this.delaying = false;
      this.cancelling = false;
    },
    hide() {
      this.$refs.modal.hide();
      this.clear();
    },
    show(task) {
      this.clear();
      this.modelValue = task;
      this.$refs.modal.show();
    },
    postComment() {
      
    },
    toggleStatus() {
      this.togglingStatus = true;
      this.$services.task.toggleStatus(this.modelValue.id)
        .then((response) => {
          this.updateTaskField("status", response.data.status);
        })
        .finally(() => {
          this.togglingStatus = false;
        });
    },
    toggleCancel() {
      this.cancelling = true;
      this.$services.task.toggleCancel(this.modelValue.id)
        .then((response) => {
          this.updateTaskField("status", response.data.status);
        })
        .finally(() => {
          this.cancelling = false;
        });
    },
    delay() {
      this.$refs.delayModal.show();
    },
    onActionChange(event) {
      const newAction = event.target.value;

      this.$services.task.patch(
        this.modelValue.id,
        { action: newAction },
      )
        .then((response) => {
          this.updateTaskField("action", response.data.action);
        });
    },
    onNewAssignedAt(user) {
      this.$services.task.patch(
        this.modelValue.id,
        { assigned_id: user ? user.id : null },
      )
        .then((response) => {
          this.updateTaskField("assigned", response.data.assigned);
        });
    },
    updateTaskField(field, value) {
      this.modelValue = {
        ...this.modelValue,
        [field]: value,
      };
      this.$emit("change", this.modelValue);
    },
  },
};

</script>

<style lang="scss" scoped>

</style>