<template>
  <div class="card-body">
    <div class="info-grid-layout" style="margin-bottom: 10px;">
      <div>Référence</div>
      <div>{{ invoice.ref }}</div>
      <div>
        Nombre d'interventions
      </div>
      <div>
        <span v-if="invoice.interventions != null">
          {{ invoice.interventions.length }}/{{ invoice.final_number_of_interventions }}
        </span>
        <span v-else>
          <i>inconnu</i>/{{ invoice.final_number_of_interventions }}
        </span>
      </div>
      <div>
        Montant<span v-if="invoice.credit_note"> avant NC</span>
      </div>
      <div>{{ invoice.total_incl_tax }}€ TTC / {{ invoice.total_excl_tax }}€ HT</div>
      <div v-if="invoice.credit_note">
        NC
      </div>
      <div v-if="invoice.credit_note">
        {{ invoice.credit_note.total_incl_tax }}€ TTC / {{ invoice.credit_note.amount }}€ HT
      </div>
      <div v-if="invoice.credit_note">
        Montant après NC
      </div>
      <div v-if="invoice.credit_note">
        {{ invoice.total_incl_tax - invoice.credit_note.total_incl_tax }}€ TTC / {{ invoice.total_excl_tax - invoice.credit_note.amount }}€ HT
      </div>
      <div>Date</div>
      <div>{{ $filters.dateStringToHumanizedDate(invoice.created_at) }}</div>
      <div>Auteur</div>
      <div>{{ $filters.fullname(invoice.author) }}</div>
    </div>

    <div class="flow-spaced-container">
      <div
        v-if="invoice.send_date"
        class="highlighted green tooltip"
        style="margin-right: 5px;">
        Envoyé au client
        <div class="tooltip-text">
          {{ $filters.dateStringToHumanizedDate(invoice.send_date) }}
        </div>
      </div>
      <div
        v-else
        class="highlighted red"
        style="margin-right: 5px;">
        Non envoyé au client
      </div>
      <div
        v-if="invoice.odoo_created_at"
        style="margin-right: 5px;"
        class="highlighted green">
        Verrouillée
      </div>
      <div
        v-else
        style="margin-right: 5px;"
        class="highlighted red">
        Non verrouillée
      </div>
      <div
        v-if="invoice.to_pay <= 0"
        class="highlighted green">
        Payé
      </div>
      <div
        v-else
        class="highlighted red">
        {{ invoice.to_pay / 100 }}€ à payer
      </div>
    </div>

    <div style="margin-top: 10px;">
      <div class="flow-spaced-container">
        <router-link :to="{ name: 'invoice', params: { id: invoice.id } }" class="btn-link">
          Voir la facture
        </router-link>
        <router-link v-if="invoice.credit_note" :to="{ name: 'credit-note', params: { id: invoice.credit_note.id } }" class="btn-link">
          Voir la note de crédit
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "InvoiceCompactCardBody",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
};

</script>