<template>
  <Modal ref="modal">
    <LocationForm
      v-if="location"
      v-model="location"
      defaultShowCustomForm />

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading || !canSubmit"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import LocationForm from "@/components/forms/LocationForm.vue";

export default {
  name: "EditLocationModal",
  components: {
    Modal,
    LocationForm,
  },
  emits: ["change"],
  data() {
    return {
      location: null,
      loading: false,
    };
  },
  computed: {
    canSubmit() {
      return this.location != null && this.location.id != null && this.location.street != null && this.location.zipcode != null && this.location.city != null && this.location.country_code != null;
    },
  },
  methods: {
    show(location) {
      this.location = {
        ...location,
      };
      this.loading = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.location = null;
      this.loading = false;
    },
    submit() {
      this.loading = true;
      
      this.$services.location.patch(
        this.location.id,
        {
          street_number: this.location.street_number,
          street: this.location.street,
          bp: this.location.bp,
          zipcode: this.location.zipcode,
          city: this.location.city,
          country_code: this.location.country_code,
        },
      )
        .then((response) => {
          this.$emit("change", response.data);
          this.hide();
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>