<template>
  <LoaderView v-if="schema == null" />
  <div v-else>
    <div class="form-group">
      <VueGoogleAutocomplete
        id="address-search"
        :country="countryCodes"
        type="text"
        placeholder="Recherchez une adresse"
        class="form-control"
        @placechanged="onAddressSuggestion" />
    </div>
    <div class="row">
      <div class="col-sm">
        <FormField :schema="schema.street_number" :errors="validationErrors?.street_number" class="form-group">
          <template #default>
            <input v-model="internalValue.street_number" type="text" class="form-control">
          </template>
        </FormField>
      </div>
      <div class="col-sm">
        <FormField :schema="schema.street" :errors="validationErrors?.street" class="form-group">
          <template #default>
            <input v-model="internalValue.street" type="text" class="form-control">
          </template>
        </FormField>
      </div>
      <div class="col-sm">
        <FormField :schema="schema.bp" :errors="validationErrors?.bp" class="form-group">
          <template #default>
            <input v-model="internalValue.bp" type="text" class="form-control">
          </template>
        </FormField>
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        <FormField :schema="schema.zipcode" :errors="validationErrors?.zipcode" class="form-group">
          <template #default>
            <input v-model="internalValue.zipcode" type="text" class="form-control">
          </template>
        </FormField>
      </div>
      <div class="col-sm">
        <FormField :schema="schema.city" :errors="validationErrors?.city" class="form-group">
          <template #default>
            <input v-model="internalValue.city" type="text" class="form-control">
          </template>
        </FormField>
      </div>
      <div class="col-sm">
        <FormField :schema="schema.country_code" :errors="validationErrors?.country_code" class="form-group">
          <template #default>
            <CountrySelect v-model="internalValue.country_code" class="form-select" />
          </template>
        </FormField>
      </div>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import CountrySelect from "@/components/inputs/CountrySelect.vue";
import { favoriteCountries } from "@/countries";

export default {
  name: "AddressForm",
  components: {
    FormField,
    LoaderView,
    VueGoogleAutocomplete,
    CountrySelect,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "valid", "invalid"],
  data() {
    return {
      internalValue: this.modelValue,
      schema: null,
    };
  },
  computed: {
    isValid() {
      return this.internalValue.street != null && this.internalValue.zipcode != null && this.internalValue.city != null && this.internalValue.country_code != null;
    },
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
    },
    isValid(newVal) {
      if (newVal) {
        this.$emit("valid");
      } else {
        this.$emit("invalid");
      }
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.address.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    onAddressSuggestion(addressData) {
      this.internalValue.street_number = addressData.street_number;
      this.internalValue.street = addressData.route;
      this.internalValue.zipcode = addressData.postal_code;
      this.internalValue.city = addressData.locality;
      this.internalValue.country_code = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }

      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
  },
};
</script>

<style>

</style>