<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Produits</span>
      <button v-if="!editMode" class="small-tool-btn" @click="edit">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <div v-if="!canEdit" class="form-group text-red">
        <i>Facture vérrouillée ! Vous pouvez seulement modifier le descriptif des produits.</i>
      </div>
      <div class="form-group">
        <InvoiceProductsForm
          v-model:products="editedItems"
          v-model:discountPercent="editedDiscountPercent"
          v-model:pricesIncludesTax="editedPricesIncludesTax"
          :canAddProducts="canEdit"
          :canRemoveProducts="canEdit"
          :canEditPrice="canEdit"
          :taxRate="taxRate" />
      </div>
      <div class="flow-spaced-container" style="justify-content: flex-end;">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="endEdit">
          Annuler
        </button>
        <button
          class="btn-primary"
          :class="{ 'loading': submitting }"
          :disabled="submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <table style="width: 100%;">
        <thead>
          <tr>
            <th />
            <th>Intitulé</th>
            <th>Quantité</th>
            <th>Prix €/unité <span v-if="modelValue.prices_includes_tax">TTC</span><span v-else>HT</span></th>
            <th>Remise</th>
            <th>Prix €/unité avec remise <span v-if="modelValue.prices_includes_tax">TTC</span><span v-else>HT</span></th>
            <th>Total ligne <span v-if="modelValue.prices_includes_tax">TTC</span><span v-else>HT</span></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in modelValue.items"
            :key="index">
            <td>
              <router-link
                v-if="item.product"
                class="btn-link"
                :to="{ name: 'product', params: { 'id': item.product.id } }">
                <i class="bi bi-eye" />
              </router-link>
            </td>

            <td>
              {{ item.product_description }}
            </td>
            <td style="text-align: center">
              {{ item.product_quantity }}
            </td>
            <td style="text-align: center">
              {{ item.product_price }}€ 
            </td>
            <td style="text-align: center">
              {{ item.discount_percent != null ? item.discount_percent : modelValue.discount_percent }}%
            </td>
            <td style="text-align: center">
              {{ productPriceExclTaxWithDiscount(item) / 100 }}€
            </td>
            <td style="text-align: center">
              {{ totalLineExclTaxWithDiscount(item) / 100 }}€
            </td>
          </tr>
        </tbody>
      </table>
      <table style="width: max-content; margin-left: auto;">
        <tr style="text-align: right">
          <td>
            Remise générale
          </td>
          <td>
            {{ modelValue.discount_percent }} %
          </td>
        </tr>
        <tr style="text-align: right">
          <td>Sous total HTVA</td>
          <td>{{ totalExclTax / 100 }} €</td>
        </tr>
        <tr style="text-align: right">
          <td>TVA ({{ taxRate ? taxRate : 'Inconnu' }}%)</td>
          <td>{{ totalTax / 100 }} €</td>
        </tr>
        <tr style="text-align: right">
          <td><strong>Total TVA</strong></td>
          <td><strong>{{ totalInclTax / 100 }} €</strong></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import InvoiceProductsForm from "@/components/forms/InvoiceProductsForm.vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "InvoiceAndQuoteProductsCard",
  components : {
    InvoiceProductsForm,
  },
  props: {
    modelValue: { // The invoice or quote
      type: Object,
      required: true,
      validator: (value) => {
        for (const requiredField of [
          "id",
          "discount_percent",
          "prices_includes_tax",
          "items",
          "is_locked",
          "custom_tax_rate",
        ]) {
          if (!(requiredField in value)) {
            throw new Error(`Prop modelValue missing field ${requiredField} on component InvoiceAndQuoteProductsCard`);
          }
        }

        return true;
      },
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ["invoice", "quote"].includes(value);
      },
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      submitting: false,
      editedItems: null,
      editedDiscountPercent: null,
      editedPricesIncludesTax: null,
      editMode: false,
      meStore: useMeStore(),
    };
  },
  computed: {
    canEdit() {
      return !this.modelValue.is_locked;
    },
    taxRate() {
      if (this.modelValue.custom_tax_rate != null) {
        return this.modelValue.custom_tax_rate;
      } else {
        return this.modelValue.journal.origin.tax_rate;
      }
    },
    totalExclTax() {// in cents
      if (!this.modelValue.items) {
        return 0;
      } else if (this.modelValue.prices_includes_tax) {
        return NaN;
      } else {
        var total = 0;
        for (const productLine of this.modelValue.items) {
          total = total + this.totalLineExclTaxWithDiscount(productLine);
        }
        return total;
      }
    
    },
    totalTax() { // In cents
      if (this.taxRate == null) {
        return NaN;
      } else if (!this.modelValue.items) {
        return 0;
      } else if (this.modelValue.prices_includes_tax) {
        return NaN;
      } else {
        var total = 0;
        for (const productLine of this.modelValue.items) {
          total = total + this.totalLineTax(productLine);
        }
        return total;
      }
    },
    totalInclTax() { // In cents
      if (this.taxRate == null) {
        return NaN;
      } else if (!this.modelValue.items) {
        return 0;
      } else {
        var total = 0;
        for (const productLine of this.modelValue.items) {
          total = total + this.totalLineInclTaxWithDiscount(productLine);
        }
        return total;
      }
    },
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
  },
  methods: {
    productPriceExclTaxWithDiscount(productLine) {
      return Math.round(this.totalLineExclTaxWithDiscount(productLine) / productLine.product_quantity);
    },
    totalLineExclTaxWithDiscount(productLine) {
      const priceExclTax = productLine.product_price * 100;
      const discountPercent = productLine.discount_percent != null ? productLine.discount_percent : this.modelValue.discount_percent;
      const priceExclTaxAfterDiscount = priceExclTax - (priceExclTax * discountPercent / 100);
      return Math.round(priceExclTaxAfterDiscount * productLine.product_quantity);
    },
    totalLineTax(productLine) {
      const totalLineExclTaxWithDiscount = this.totalLineExclTaxWithDiscount(productLine);
      const totalLineTax = totalLineExclTaxWithDiscount * this.taxRate / 100;
      return Math.round(totalLineTax);
    },
    totalLineInclTaxWithDiscount(productLine) {
      if (this.modelValue.prices_includes_tax) {
        return this.totalLineExclTaxWithDiscount(productLine);
      } else {
        return this.totalLineExclTaxWithDiscount(productLine) + this.totalLineTax(productLine);
      }
    },
    edit() {
      this.editedItems = [
        ...this.modelValue.items,
      ];
      this.editedDiscountPercent = this.modelValue.discount_percent;
      this.editedPricesIncludesTax = this.modelValue.prices_includes_tax;
      this.editMode = true;
    },
    endEdit() {
      this.editMode = false;
    },
    submit() {
      this.submitting = true;

      let service;
      if (this.type == "invoice") {
        service = this.$services.invoice;

      } else {
        service = this.$services.quote;
      }

      const data = {
        items: this.editedItems,
        discount_percent: this.editedDiscountPercent,
        prices_includes_tax: this.editedPricesIncludesTax,
      };

      service.patch(this.modelValue.id, data)
        .then((response) => {
          this.$emit("update:modelValue", response.data);
          this.endEdit();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};

</script>