import { defineStore } from "pinia";


interface State {
  actions: Array<any>;
  improvementNeeds: Array<any>;
  safetyRules: Array<any>;
  rooms: Array<any>;
}

export const useInterventionReportOptionsStore = defineStore("interventionReportOptions", {
  state: (): State => ({
    actions: [],
    improvementNeeds: [],
    safetyRules: [],
    rooms: [],
  }),
  getters: {
    getActions: state => {
      return state.actions;
    },
    getImprovementNeeds: state => {
      return state.improvementNeeds;
    },
    getSafetyRules: state => {
      return state.safetyRules;
    },
    getRooms: state => {
      return state.rooms;
    },
  },
  actions: {
    setActions(actions: Array<any>) {
      this.actions = actions;
    },
    setImprovementNeeds(improvementNeeds: Array<any>) {
      this.improvementNeeds = improvementNeeds;
    },
    setSafetyRules(safetyRules: Array<any>) {
      this.safetyRules = safetyRules;
    },
    setRooms(rooms: Array<any>) {
      this.rooms = rooms;
    },
  },
});