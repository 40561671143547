<template>
  <ManyToManyForm
    v-model="internalValue"
    :elements="rules"
    fieldToDisplay="description" />
</template>

<script>

import ManyToManyForm from "@/components/forms/ManyToManyForm.vue";
import { useInterventionReportOptionsStore } from "@/stores/interventionReportOptions";

export default {
  name: "SafetyRulesForm",
  components: {
    ManyToManyForm,
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => {return [];},
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      internalValue: this.modelValue,
      interventionReportOptionsStore: useInterventionReportOptionsStore(),
    };
  },
  computed: {
    rules() {
      return this.interventionReportOptionsStore.getSafetyRules;
    },
  },
  watch: {
    internalValue(newVal) {
      this.$emit("update:modelValue", newVal);
    },
    modelValue(newVal) {
      this.internalValue = newVal;
    },
  },
};
</script>
