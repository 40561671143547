<template>
  <LoaderView v-if="!object || !schema" />

  <div v-else>
    <ProductInternalInformationCard v-model="object" :schema="schema" style="margin-bottom: 10px;" />

    <ProductGeneralInformationCard v-if="object.type != 'service'" v-model="object" :schema="schema" style="margin-bottom: 10px;" />

    <ProductSaleInformationCard v-model="object" :schema="schema" style="margin-top: 10px; margin-bottom: 10px;" />

    <ProductPurchaseInformationCard v-if="['simple', 'variation', 'equipment'].includes(object.type)" v-model="object" :schema="schema" style="margin-top: 10px; margin-bottom: 10px;" />

    <ServiceCostCard v-if="object.type == 'service'" v-model="object" :schema="schema" style="margin-top: 10px; margin-bottom: 10px;" />

    <FormSection
      v-if="object.type == 'variation'"
      title="Produit parent"
      style="margin-top: 10px;"
      viewOnly>
      <template #default>
        <p>Produit parent : <strong>{{ object.parent.name }}</strong></p>
      </template>
    </FormSection>
  </div>
</template>

<script>

import FormSection from "@/components/layout/FormSection.vue";
import LoaderView from "@/components/LoaderView.vue";
import ProductGeneralInformationCard from "@/components/cards/ProductGeneralInformationCard.vue";
import ProductSaleInformationCard from "@/components/cards/ProductSaleInformationCard.vue";
import ProductPurchaseInformationCard from "./frags/ProductPurchaseInformationCard.vue";
import ProductInternalInformationCard from "@/components/cards/ProductInternalInformationCard.vue";
import ServiceCostCard from "./frags/ServiceCostCard.vue";

export default {
  name: "ProductObjectView",
  components: {
    FormSection,
    LoaderView,
    ProductGeneralInformationCard,
    ProductSaleInformationCard,
    ProductPurchaseInformationCard,
    ProductInternalInformationCard,
    ServiceCostCard,
  },
  data() {
    return {
      object: null,
      schema: null,
      validationError: null,
      errorFetching: false,
      submitting: false,
    };
  },
  mounted() {
    Promise.all([
      this.fetchSchema(),
      this.fetchObject(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchSchema() {
      this.$services.product.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchObject() {
      this.$services.product.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    cancel() {
      this.$router.push({ name: "product-list" });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>