<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">{{ title }}</span>
      <button v-if="editable && !editMode" class="small-tool-btn" @click="edit">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <AddressForm
        v-model="editedAddress"
        :validationErrors="validationErrors" />
      <div class="flow-spaced-container align-right">
        <button class="btn-secondary" :disabled="submitting" @click="cancel">
          Annuler
        </button>
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else-if="object" class="card-body">
      <div class="info-grid-layout">
        <div>
          Rue
        </div>
        <div>
          {{ object.street }}
        </div>
        <div>
          Numéro
        </div>
        <div>
          {{ object.street_number }} 
        </div>
        <div>
          Boite postale
        </div>
        <div>
          {{ object.bp }}
        </div>
        <div>
          Code postal
        </div>
        <div>
          {{ object.zipcode }}
        </div>
        <div>
          Ville
        </div>
        <div>
          {{ object.city }}
        </div>
        <div>
          Pays
        </div>
        <div>
          {{ countryFromCountryCode(object.country_code) }}
        </div>
      </div>
    </div>

    <div v-else class="card-body">
      <i>Aucun</i>
    </div>
  </div>
</template>

<script>

import AddressForm from "@/components/forms/AddressForm.vue";
import { countries } from "@/countries";
import { notifyError } from "@/notifications";

export default {
  name: "ObjectAddressCard",
  components: {
    AddressForm,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Adresse",
    },
    object: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      editedAddress: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      if (this.object) {
        this.editedAddress = {
          street_number: this.object.street_number,
          street: this.object.street,
          zipcode: this.object.zipcode,
          city: this.object.city,
          country_code: this.object.country_code,
        };
      } else {
        this.editedAddress = {
          street_number: null,
          street: null,
          zipcode: null,
          city: null,
          country_code: "be",
        };
      }
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedAddress = null;
    },
    submit() {
      this.submitting = true;

      this.onSave(this.editedAddress)
        .then(() => {
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    countryFromCountryCode(countryCode) {
      return countries[countryCode].name;
    },
  },
};

</script>