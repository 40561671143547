import { useNotificationsStore } from "@/stores/notifications";



function notify(message: string, submessage: string | null = null, status: string = "success", dismissAfter: number = 2000) {
  if (!["success", "error"].includes(status)) {
    throw new Error(`'status' argument should be 'success' or 'error', got '${status}'`);
  }

  const notificationsStore = useNotificationsStore();

  notificationsStore.pushNotification(
    {
      "message": message,
      "submessage": submessage,
      "status": status,
      "dismissAfter": dismissAfter,
    },
  );
}

function notifyError(message: string, submessage: string | null = null, dismissAfter = 2000) {
  notify(message, submessage, "error", dismissAfter);
}

function notifySuccess(message: string, submessage: string | null = null, dismissAfter = 2000) {
  notify(message, submessage, "success", dismissAfter);
}

export { notify, notifyError, notifySuccess };
