<template>
  <div style="position: relative;" @click.stop="focus">
    <label>
      <div v-if="content" class="content" :class="{ 'valid': valid, 'invalid': invalid }">
        <div class="tag deletable" @click.stop="$emit('removeContent')">{{ content }}</div>
      </div>
      <input
        v-else
        id="input"
        ref="input"
        v-model="internalValue"
        :placeholder="getPlaceholder"
        type="text"
        class="form-control"
        :disabled="disabled"
        autocomplete="off"
        :class="{ 'valid': valid, 'invalid': invalid }"
        @change="onChange"
        @focus="$emit('focus', $event.target.value)"
        @blur="$emit('blur', $event.target.value)">
      <div v-if="internalValue" class="rmv-btn" @click="clear" />
    </label>
  </div>
</template>

<script>

export default {
  name: "SearchInput",
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Rechercher...",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    valid: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    content: {
      type: String,
      required: false,
      default: null,
    },
    updateOnChange: { // If updateOnChange = true, modelValue will be updated on text change instead of on text input
      type: Boolean,
      required: false,
      default: false,
    },
    updateOnInputAfterDelay: { // If updateOnInput = false and updateOnInputAfterDelay = true, modelValue will be updated 1s after last input
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["focus", "blur", "update:modelValue", "removeContent", "update", "change"],
  data() {
    return {
      internalValue: this.modelValue ? this.modelValue : this.value,
      lastUpdatedValue: this.modelValue ? this.modelValue : this.value,
      updateTimer: null,
    };
  },
  computed: {
    getPlaceholder() {
      return this.placeholder;
    },
  },
  watch: { 
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      // Called on each text input

      if (this.updateOnInputAfterDelay) {
        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(
          () => {
            this.update(newVal);
          },
          600,
        );

        return;
      }
      
      if (this.updateOnChange) {
        return;
      }

      this.update(newVal);
    },
  },
  methods: {
    clear() {
      this.internalValue = null;
    },
    focus() {
      this.$refs.input.focus();
    },
    onChange(event) {
      this.$emit("change", event.target.value);
      if (this.updateOnChange) {
        clearTimeout(this.updateTimer);
        this.update(event.target.value);
      }
    },
    update(value) {
      if (value != this.lastUpdatedValue) {
        this.$emit("update:modelValue", value);
        this.$emit("update", value);
        this.lastUpdatedValue = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  label {
    position: relative;
    display: block;
  }

  label:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
  }
  
  
 
  input {
    width: 100%;
    padding-left: 34px;
    -webkit-appearance: none;
    -appearance: none;
    -moz-appearance: none;
  }

  .rmv-btn {
    position: absolute;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 5px;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;

    &:before,
    &::after {
      position: absolute;
      content: ' ';
      width: 60%;
      height: 1px;
      background: gray;
      top: 50%;
      left: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      border-color: black;

      &:before,
      &::after {
        background: black;
      }
    }
  }

  .content {
    display: block;
    padding: 0.375rem 0.75rem 0.375rem 34px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: visible;
    margin: 0;
    font-family: inherit;

    .tag {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    &.valid {
      border-color: #28a745;
    }

    &.invalid {
      border-color: #dc3545;
    }
  }
  

</style>
