<template>
  <div
    class="my-dropdown-mask"
    :class="{ 'visible': activated }"
    @click.stop="activated = false"
    @contextmenu.prevent>
    <div class="my-dropdown appear-from-bottom mobile-menu-dropdown" :class="{ 'visible': activated }">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: "MobileMenuDropdown",
  emits: ["show", "hide"],
  data() {
    return {
      activated: false,
    };
  },
  watch: {
    activated(newVal) {
      if (newVal) {
        this.$emit("show");
      } else {
        this.$emit("hide");
      }
    },
  },
  methods: {
    show() {
      this.activated = true;
    },
  },
};

</script>

<style lang="scss" scoped>

  .my-dropdown-mask {
    display: none;

    &.visible {
      display: block;
    }
  }

  .mobile-menu-dropdown {

    // Position
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10001;

    // Style
    background: white;

  }

  :deep(.tool-btn) {
    width: auto;
    height: 50px;
    justify-content: flex-start;

    i, svg {
      width: 40px;
    }

    .tooltip-container {
      display: inline-block;
      visibility: visible;
      position: relative;
      top: auto;
      left: auto;
      margin: 0;
      margin-left: 5px;
      padding: 0;
      transform: none;
      background: none;
      color: inherit;
      opacity: 1;

      .tooltip-text {
        margin-left: 0;
      }
    }

    .tooltip-text {
      display: inline-block;
      visibility: visible;
      position: relative;
      top: auto;
      left: auto;
      margin: 0;
      margin-left: 5px;
      padding: 0;
      transform: none;
      background: none;
      color: inherit;
      opacity: 1;
    }

    .tooltip-text-red {
      display: none;
    }
  }

</style>