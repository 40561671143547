<!-- Le fichier DropdownSelect.vue -->
<template>
  <div class="form-control" style="padding: 0px;">
    <button class="multi-select-btn" @click="toggleDropdown">
      {{ buttonText }}
    </button>
    <div v-if="isOpen" class="multi-select-content">
      <div
        v-for="option in options"
        :key="option.value"
        class="form-check"
        :style="{ 'background-color': option.backgroundColor }">
        <input
          class="form-check-input"
          type="checkbox"
          :checked="modelValue.includes(option.value)"
          @change="onSelect(option, $event.target.checked)">
        <label class="form-check-label">
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: { // Selected options
      type: Array,
      required: false,
      default: () => ([]),
    },
    options: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selectedOptions() {
      return this.options.filter((option) => {
        return this.modelValue.includes(option.value);
      });
    },
    buttonText() {
      if (this.selectedOptions.length > 0) {
        return this.selectedOptions.map((option) => {
          return option.label;
        }).join(", ");
      } else {
        return "Aucune sélection";
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onSelect(option, checked) {
      if (checked) {
        if (!this.modelValue.includes(option.value)) {
          this.$emit("update:modelValue", [...this.modelValue, option.value]);
        }
      } else {
        this.$emit("update:modelValue", this.modelValue.filter((value) => value !== option.value));
      }
    },
  },
};
</script>

<style scoped>

.multi-select {
  position: relative;
  display: block;
}

.multi-select-btn {
  padding: 0.375rem 0.75rem;
  width: 100%;
  text-align: left;
}

.multi-select-content {
  position: absolute;
  display: block;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  z-index: 1;
  padding: 5px;
}

</style>
