<template>
  <div
    class="phone-number">
    <div class="input-group-prepend">
      <i class="bi bi-telephone-fill" />
    </div>
    <select
      v-model="selectedCountryCode"
      style="border-radius: 0; max-width: 100px;"
      class="form-select">
      <option
        v-for="(country, code) in favoriteCountries"
        :key="code"
        :value="code">
        +{{ country.dialCode }} {{ country.name }}
      </option>
      <option disabled>
        ──────────
      </option>
      <option
        v-for="(country, code) in countries"
        :key="code"
        :value="code">
        +{{ country.dialCode }} {{ country.name }}
      </option>
    </select>
    <input
      :value="localPhoneNumber"
      type="tel"
      style="border-radius: 0; border-left: none; max-width: 134px;"
      class="form-control"
      @input="onPhoneNumberInput">

    <div v-if="validFormat != null || validPhoneNumber != null" class="input-group-append tooltip">
      <Loader v-if="validatingPhoneNumber" />
      <i v-else-if="validFormat == true && validPhoneNumber == true" class="bi bi-check-lg text-green animated" />
      <i v-else-if="validFormat == false || validPhoneNumber == false" class="bi bi-x-lg text-red animated" />
      
      <i v-else class="bi bi-question-lg" />

      <div class="tooltip-container">
        <div v-if="validFormat == true" class="tooltip-text">
          Format valide pour {{ countries[selectedCountryCode].name }}
        </div>

        <div v-else-if="validFormat == false" class="tooltip-text-red">
          Format invalide
        </div>

        <div v-if="validPhoneNumber == true" class="tooltip-text">
          Numéro de téléphone valide
        </div>

        <div v-else-if="validPhoneNumber == false" class="tooltip-text-red">
          Numéro de téléphone invalide
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { countries, favoriteCountries } from "@/countries";
import Loader from "@/components/Loader.vue";

import { api } from "@/api";
import { notifyError } from "@/notifications";

export default {
  name: "PhoneNumber",
  components: {
    Loader,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selectedCountryCode: "be",
      localPhoneNumber: null,
      internationalPhoneNumber: null,
      validFormat: null,
      validatingPhoneNumber: false,
      validPhoneNumber: null,
      selectIsOpen: false,
    };
  },
  computed: {
    countries() {
      return countries;
    },
    favoriteCountries() {
      return favoriteCountries;
    },
  },
  watch: {
    modelValue(newVal) {
      this.parsePhoneNumber(newVal);

      if (this.validFormat == true) {
        this.validatePhoneNumber();
      }
    },
  },
  mounted() {
    this.parsePhoneNumber(this.modelValue);
  },
  methods: {
    onPhoneNumberInput(event) {
      this.parsePhoneNumber(event.target.value);

    },
    parsePhoneNumber(value) {
      if (value) {

        if (value.charAt(0) === "+") {
          const phoneNumber = parsePhoneNumberFromString(value);

          if (phoneNumber) {
            this.selectedCountryCode = phoneNumber.country.toLowerCase();
            this.localPhoneNumber = this.addZeroIfNecessary(phoneNumber.formatNational());
            this.internationalPhoneNumber = phoneNumber.formatInternational();
            this.$emit("update:modelValue", phoneNumber.number);
            this.validFormat = phoneNumber.isValid();

          } else {
            this.localPhoneNumber = value;
            this.internationalPhoneNumber = value;
            this.$emit("update:modelValue", value);
            this.validFormat = false;
          }

        } else if (value.charAt(0) === "0") {
          const phoneNumber = parsePhoneNumberFromString(`+${this.countries[this.selectedCountryCode].dialCode}${value.slice(1)}`);

          if (phoneNumber) {
            this.localPhoneNumber = this.addZeroIfNecessary(phoneNumber.formatNational());
            this.internationalPhoneNumber = phoneNumber.formatInternational();
            this.$emit("update:modelValue", phoneNumber.number);
            this.validFormat = phoneNumber.isValid();

          } else {
            this.localPhoneNumber = value;
            this.internationalPhoneNumber = value;
            this.$emit("update:modelValue", value);
            this.validFormat = false;
          }

        } else {
          this.localPhoneNumber = value;
          this.internationalPhoneNumber = value;
          this.$emit("update:modelValue", value);
          this.validFormat = false;
        }

      } else {
        this.localPhoneNumber = null;
        this.internationalPhoneNumber = null;
        this.$emit("update:modelValue", null);
        this.validFormat = null;
      }
    },
    addZeroIfNecessary(value) {
      if (value && value.charAt(0) !== "0") {
        return `0${value}`;
      } else {
        return value;
      }
    },
    validatePhoneNumber() {
      if (!this.modelValue) {
        this.validPhoneNumber = null;
        return;
      }

      this.validatingPhoneNumber = true;

      api.post(`validate-phone-number/${this.modelValue}/`)
        .then((response) => {
          this.validPhoneNumber = response.data;

        })
        .catch((error) => {
          this.validPhoneNumber = null;
          
          notifyError("Une erreur est survenue lors de la validation du numéro de téléphone");
          throw error;
          
        })
        .finally(() => {
          this.validatingPhoneNumber = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">


.phone-number {
  display: flex;
  align-items: center;
}

.animated {
  animation: shake 0.5s;

  @keyframes shake {
    0% { transform: rotate(0); }
    20% { transform: rotate(10deg); }
    40% { transform: rotate(-30deg); }
    60% { transform: rotate(30deg); }
    80% { transform: rotate(-10deg); }
    100% { transform: rotate(0); }
  }  
}
</style>
