import ModelApiService from "../utils/modelApiService";
import { api } from "@/api";

class LocationApiService extends ModelApiService {
  constructor() {
    super("/locations/", "lieu");
  }

  fetchCoordinates(id: number) {
    return api.get(this.url + id + "/coordinates/");
  }
}

export default LocationApiService;