import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { AxiosResponse } from "axios";

class RegisterAdjustmentApiService extends ModelApiService {
  constructor() {
    super("/register-adjustments/", "ajustement de caisse");
  }

  totalIntoRegister() {
    return new Promise((resolve, reject) => {
      api.get(
        this.url + "total_into_register/",
      )
        .then((response: AxiosResponse<any>) => {
          resolve(response.data);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
}

export default RegisterAdjustmentApiService;