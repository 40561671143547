<template>
  <Modal ref="modal" width="medium">
    <div v-if="html" v-html="html" />

    <div v-else>
      <p>{{ text }}</p>
    </div>

    <template #buttons>
      <button
        class="btn-primary"
        @click="hide">
        Ok
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "InfoModal",
  components: {
    Modal,
  },
  data() {
    return {
      text: null,
      html: null,
    };
  },
  methods: {
    showText(text) {
      this.text = text;
      this.html = null;
      this.$refs.modal.show();
    },
    showHtml(html) {
      this.text = null;
      this.html = html;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.text = null;
      this.html = null;
    },
  },
};

</script>

<style lang="scss" scoped>


</style>