<template>
  <div ref="container" class="isolate flex flex-auto flex-col overflow-auto bg-white">
    <div style="width: 165%" class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
      <div ref="containerNav" class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
        <div class="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            M
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">10</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            T
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">11</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            W
            <span class="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white">12</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            T
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">13</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            F
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">14</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            S
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">15</span>
          </button>
          <button type="button" class="flex flex-col items-center pb-3 pt-2">
            S
            <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">16</span>
          </button>
        </div>

        <div class="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
          <div class="col-end-1 w-14" />
          <div class="flex items-center justify-center py-3">
            <span>Lun <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfMonday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Mar <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfTuesday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Mer <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfWednesday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Jeu <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfThursday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Ven <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfFriday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Sam <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfSaturday }}</span></span>
          </div>
          <div class="flex items-center justify-center py-3">
            <span>Dim <span class="items-center justify-center font-semibold text-gray-900">{{ dayOfSunday }}</span></span>
          </div>
        </div>
      </div>
      <div class="flex flex-auto">
        <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
        <div class="grid flex-auto grid-cols-1 grid-rows-1">
          <!-- Horizontal lines -->
          <div class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100" style="grid-template-rows: repeat(48, minmax(3.5rem, 1fr))">
            <div ref="containerOffset" class="row-end-1 h-7" />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                12AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                1AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                2AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                3AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                4AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                5AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                6AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                7AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                8AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                9AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                10AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                11AM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                12PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                1PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                2PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                3PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                4PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                5PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                6PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                7PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                8PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                9PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                10PM
              </div>
            </div>
            <div />
            <div>
              <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                11PM
              </div>
            </div>
            <div />
          </div>

          <!-- Vertical lines -->
          <div class="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
            <div class="col-start-1 row-span-full" />
            <div class="col-start-2 row-span-full" />
            <div class="col-start-3 row-span-full" />
            <div class="col-start-4 row-span-full" />
            <div class="col-start-5 row-span-full" />
            <div class="col-start-6 row-span-full" />
            <div class="col-start-7 row-span-full" />
            <div class="col-start-8 row-span-full w-8" />
          </div>

          <!-- Events -->
          <ol class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8" style="grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto">
            <li
              v-for="(event, index) in events"
              :key="index"
              :class="['relative mt-px hidden sm:flex', `sm:col-start-${getColumnStart(event)}`]"
              :style="`grid-row: ${calculateGridRowStart(event)} / span ${calculateGridRowSpan(event)}`">
              <a
                href="#"
                class="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-gray-200"
                :class="{'intervention': event.type === 'intervention', 'worked': event.type === 'worked', 'leave': event.type === 'leave', 'perso': event.type === 'perso'}">
                <p class="order-1 font-semibold text-gray-700">
                  {{ event.summary }}
                </p>
                <p class="text-gray-500 group-hover:text-gray-700">
                  <time datetime="2022-01-15T10:00">{{ $filters.dateStringToHumanizedTime(event.start_datetime) }}</time>
                </p>
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "PlanningWeek",
  props: {
    activeDay: {
      type: Date,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    dayOfMonday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -6 : 1);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfTuesday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -5 : 2);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfWednesday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -4 : 3);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfThursday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -3 : 4);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfFriday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -2 : 5);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfSaturday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? -1 : 6);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
    dayOfSunday() {
      const day = this.activeDay.getDay();
      const diff = this.activeDay.getDate() - day + (day === 0 ? 0 : 7);
      const newDate = new Date(this.activeDay);
      newDate.setDate(diff);
      return newDate.getDate();
    },
  },
  methods: {
    getColumnStart(event) {
      const startDate = new Date(event.start_datetime);
      return startDate.getDay();
    },
    calculateGridRowStart(event) {
      // Convertir la date et l'heure de début de l'événement en objet Date
      const startDate = new Date(event.start_datetime);
      
      // Déterminer le nombre de minutes depuis le début de la journée (00:00)
      const startMinutesSinceDayStart = startDate.getHours() * 60 + startDate.getMinutes();
      
      // Calculer le grid-row (ligne de début) en fonction du nombre de minutes depuis le début de la journée
      const gridRowStart = 1 + startMinutesSinceDayStart / 5;
      
      return gridRowStart + 1;
    },
    calculateGridRowSpan(event) {
      // Convertir la date et l'heure de début et de fin de l'événement en objets Date
      const startDate = new Date(event.start_datetime);
      const endDate = new Date(event.end_datetime);

      // Déterminer le nombre de minutes entre l'heure de début et de fin de l'événement
      const eventDurationMinutes = (endDate - startDate) / (1000 * 60);

      // Calculer le grid-row-span (nombre de lignes occupées) en fonction de la durée de l'événement
      const gridRowSpan = Math.ceil(eventDurationMinutes / 5);

      return gridRowSpan;
    },
  },
};

</script>


<style scoped lang="scss">


  .leave {
    background: #F87171;
  }

  .intervention {
    background: #34D399;
  }

  .worked {
    background: #FBBF24;
  }

  .perso {
    background: #60A5FA;
  }

</style>