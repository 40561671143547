<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <InvoiceIcon
        status="quote"
        width="30px"
        height="30px" />
    </template>

    <template #title>
      <span class="big">{{ quote.ref }}</span>
      <span class="small mobile-only">{{ $filters.dateStringToHumanizedDate(quote.created_at) }}</span>
    </template>

    <template #default>
      <td class="desktop-only">
        {{ $filters.dateStringToHumanizedDate(quote.created_at) }}
      </td>
      <td>
        <span class="mobile-only">Client : </span>
        <router-link v-if="quote.customer" class="btn-link" :to="{ name: 'customer', params: { 'id': quote.customer.id } }" @click.stop>
          {{ $filters.printCustomer(quote.customer) }}
        </router-link>
        <span v-else>
          {{ $filters.fullname(quote.contact) }}
        </span>
      </td>
      <td class="small">
        {{ quote.address.street_number }} {{ quote.address.street }}<br>
        {{ quote.address.zipcode }} {{ quote.address.city }} {{ quote.address.country_code }}
      </td>
      <td>
        <span class="mobile-only">Auteur : </span>
        {{ $filters.fullname(quote.author) }}
      </td>
      <td>
        <span class="mobile-only">Montant : </span>
        {{ quote.total_incl_tax }}€(TTC)
        {{ quote.total_excl_tax }}€(HT)
        <span
          v-if="quote.status == 'invoice'"
          style="white-space: nowrap;"
          :class="{ 'text-red': quote.to_pay > 0, 'text-green': quote.to_pay == 0 }">
          Reste à payer : {{ quote.to_pay }}€
        </span>
      </td>
      <td style="white-space: nowrap;">
        <div v-if="quote.send_date != null">
          Envoyé au client : {{ $filters.dateStringToHumanizedDate(quote.send_date) }}
        </div>      
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <QuoteToolButtons v-model="quote" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import QuoteToolButtons from "@/components/toolbuttons/QuoteToolButtons.vue";
import { GBaseRow } from "g-list";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "QuoteRow",
  components: {
    InvoiceIcon,
    QuoteToolButtons,
    GBaseRow,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    quote: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "quote", params: { "id": this.quote.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>