<template>
  <button
    class="simple-btn tooltip"
    :class="{ 'loading': loading }"
    :disabled="isDisabled">
    <slot />
    <i v-if="icon" :class="icon" style="margin-right: 5px;" />
    <span v-if="text">{{ text }}</span>
    <span v-if="tooltipText" class="tooltip-text">{{ tooltipText }}</span>
  </button>
</template>

<script setup lang="ts">

import { computed } from "vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  text: { type: String, required: false, default: null },
  loading: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  disableWhenLoading: { type: Boolean, required: false, default: true },
  icon: { type: String, required: false, default: null },
  tooltipText: { type: String, required: false, default: null },
});

// Computed

const isDisabled = computed(() => {
  return props.disabled || (props.disableWhenLoading && props.loading);
});


</script>

<style lang="scss" scoped>

.simple-btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;

  // Size

  i {
    font-size: 2rem;
  }

  &:disabled {
    color: rgb(200, 200, 200) !important;
    cursor: not-allowed;

    i {
      color: rgb(200, 200, 200) !important;
    }
  }

  &:not(:disabled) {
    color: rgb(100, 100, 100);

    i {
      color: rgb(100, 100, 100);
    }

    &:hover {
      color: black;
      
      i {
        color: black;
      }
    }
  }
}


</style>