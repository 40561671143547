import ModelApiService from "../utils/modelApiService";
import { api } from "@/api";


class CustomerApiService extends ModelApiService {
  constructor() {
    super("/customer-calls/", "appel client");
  }

  convert(id: number) {
    return api.post(this.url + id + "/convert_into_intervention/");
  }
}

export default CustomerApiService;