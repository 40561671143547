<template>
  <div class="search-input-with-dropdow">
    <SearchInput
      ref="search-input"
      :disabled="disabled"
      :placeholder="getPlaceholder"
      :content="selectedValue"
      :valid="valid"
      :invalid="invalid"
      updateOnChange
      updateOnInputAfterDelay
      @update="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @removeContent="$emit('unselect')" />
    <div
      :class="{'visible': show }"
      class="result-dropdown">
      <span v-if="error">Une erreur est survenue, impossible de charger les résultats</span>
      <LoaderView v-else-if="loading" style="padding: 15px;" />
      <div
        v-else-if="results != null && results.length > 0"
        class="result-list">
        <component
          :is="rowComponent"
          v-for="(result, index) in results"
          :key="index"
          class="result-row"
          :object="result"
          @click="onClick(result); reset()" />
        <div v-if="nbResultsTotal - results.length > 0" style="margin: 15px;">
          <strong v-if="nbResultsTotal - results.length === 1">{{ nbResultsTotal - results.length }} autre résultat.</strong>
          <strong v-else>{{ nbResultsTotal - results.length }} autres résultats. Soyez plus spécifique.</strong>
        </div>
      </div>
      <div v-else style="margin: 15px;">
        <i>{{ noResultMessage }}</i>
      </div>
    </div>
  </div>
</template>

<script>

import SearchInput from "@/components/inputs/SearchInput.vue";
import LoaderView from "@/components/LoaderView.vue";
import InterventionResultRow from "@/components/rows/intervention/InterventionResultRow.vue";
import CustomerResultRow from "@/components/rows/customer/CustomerResultRow.vue";
import ProductGenericResultRow from "@/components/rows/product/ProductGenericResultRow.vue";
import StorageResultRow from "@/components/rows/storage/StorageResultRow.vue";
import UserResultRow from "@/components/rows/user/UserResultRow.vue";
import InvoiceResultRow from "@/components/rows/invoice/InvoiceResultRow.vue";
import ContractResultRow from "@/components/rows/contract/ContractResultRow.vue";
import StringResultRow from "@/components/rows/generic/StringResultRow.vue";

export default {
  name: "SelectObject",
  components: {
    SearchInput,
    LoaderView,
    InterventionResultRow,
    CustomerResultRow,
    ProductGenericResultRow,
    StorageResultRow,
    UserResultRow,
    InvoiceResultRow,
    ContractResultRow,
    StringResultRow,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          "customer",
          "intervention",
          "product",
          "storage",
          "user",
          "technician",
          "manager",
          "invoice",
          "quote",
          "contract",
          "provider",
          "brand",
        ].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    selectedValue: {
      type: String,
      required: false,
      default: null,
    },
    valid: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeSearchQueryInResults: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["select", "unselect"],
  data() {
    return {
      loading: false,
      error: false,
      results: [],
      show: false,
      nbResultsTotal: 0,
    };
  },
  computed: {
    rowComponent() {
      return {
        customer: "CustomerResultRow",
        intervention: "InterventionResultRow",
        product: "ProductGenericResultRow",
        storage: "StorageResultRow",
        user: "UserResultRow",
        technician: "UserResultRow",
        manager: "UserResultRow",
        invoice: "InvoiceResultRow",
        quote: "InvoiceResultRow",
        contract: "ContractResultRow",
        provider: "StringResultRow",
        brand: "StringResultRow",
      }[this.type];
    },
    noResultMessage() {
      return {
        customer: "Aucun client trouvé",
        intervention: "Aucune intervention trouvée",
        product: "Aucun produit trouvé",
        storage: "Aucun stockage trouvé",
        user: "Aucun utilisateur trouvé",
        technician: "Aucun technicien trouvé",
        manager: "Aucun responsable trouvé",
        invoice: "Aucune facture trouvé",
        quote: "Aucun devis trouvé",
        contract: "Aucun contrat trouvé",
        provider: "Aucun fournisseur trouvé",
        brand: "Aucune marque trouvée",
      }[this.type];
    },
    service() {
      return {
        customer: this.$services.customer,
        intervention: this.$services.intervention,
        product: this.$services.product,
        storage: this.$services.storage,
        user: this.$services.user,
        technician: this.$services.user,
        manager: this.$services.user,
        invoice: this.$services.invoice,
        quote: this.$services.quote,
        contract: this.$services.contract,
        provider: this.$services.provider,
        brand: this.$services.brand,
      }[this.type];
    },
    additionalArgs() {
      const aditionalArgs = {
        technician: {is_technician: true},
        manager: {is_manager: true},
      };

      if (this.type in aditionalArgs) {
        return aditionalArgs[this.type];
      } else {
        return null;
      }
    },
    getPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      } else {
        return {
          customer: "Rechercher un client...",
          intervention: "Rechercher une intervention...",
          product: "Rechercher un article, un équipement, un service ou un forfait...",
          storage: "Rechercher un stockage...",
          user: "Rechercher un utilisateur...",
          technician: "Rechercher un technicien...",
          manager: "Rechercher un responsable...",
          invoice: "Rechercher une facture...",
          quote: "Rechercher un devis...",
          contract: "Rechercher un contrat...",
          provider: "Rechercher un fournisseur...",
          brand: "Rechercher une marque...",
        }[this.type];
      }
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.$refs["search-input"].clear();
      this.loading = false;
      this.error = false;
      this.results = [];
      this.show = false;
    },
    onClick(object) {
      this.$emit("select", object);
    },
    onInput(query) {
      if (query == null || query.length <= 0) {
        this.reset();
        return;
      }

      this.loading = true;
      this.show = true;

      this.service.search(
        {
          q: query,
          ...this.additionalArgs,
          ...this.filters,
        },
      )
        .then((response) => {
          if (this.includeSearchQueryInResults) {
            this.results = [
              ...response.data.results,
              query,
            ];

            this.nbResultsTotal = response.data.nb_results + 1;

          } else {
            this.results = response.data.results;
            this.nbResultsTotal = response.data.nb_results;
          }

          this.error = false;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          this.loading = false;
          throw err;
        });
    },
    onFocus(value) {
      if (value && value.length > 0) {
        this.show = true;
      }
    },
    onBlur() {
      setTimeout(
        () => {
          this.show = false;
        }, 300,
      );
    },
  },
};
</script>

<style lang="scss" scoped>

  .search-input-with-dropdow {
    position: relative;

    .result-dropdown {

      // Size
      width: 100%;

      // Position
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
      margin-top: 15px;

      // Layout
      display: none;
    
      // Style
      background: rgba(white, 0.97);
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
      border-radius: 10px;

      &.visible {
        display: block;
      }

      .btn-link {
        margin: 5px 0;
        width: 100%;
        text-align: left;
        color: red;
      }

      .result-list {
        .result-row {
          cursor: pointer;
          padding: 10px;
          overflow: hidden;

          &:hover {
            background: rgba(230, 230, 230, 0.5);
          }
        }
      }
    }
  }

</style>
