


export function  openBlob(blob: Blob, filename: string, revoke = false) {
  const fileURL = URL.createObjectURL(blob);
  //var fileURL = window.URL.createObjectURL(blob);
  const fileLink = document.createElement("a");
  fileLink.style.display = "none";
  fileLink.href = fileURL;
  fileLink.download = filename;
  //fileLink.setAttribute("download", filename);
  document.body.appendChild(fileLink);
  fileLink.click();
  if (revoke) {
    URL.revokeObjectURL(fileURL);
  }
}