<template>
  <SlideOver ref="slideOver" title="Déclarer un créneau travaillé">
    <template #default>
      <div class="vertical-spaced-container">
        <div class="flow-spaced-container">
          <div class="form-group">
            <div class="form-label">
              Date et heure de début
            </div>
            <input
              v-model="startDatetime"
              type="datetime-local"
              class="form-control">
          </div>

          <div class="form-group">
            <div class="form-label">
              Date et heure de fin
            </div>
            <input
              v-model="endDatetime"
              type="datetime-local"
              class="form-control">
          </div>

          <div class="form-group">
            <div class="form-label">
              Technicien
            </div>
            <TechnicianSelect v-model="technicianId" />
          </div>
        </div>

        <div class="flow-spaced-container">
          <button class="btn-secondary" :disabled="submitting" @click="cancel">
            Annuler
          </button>
          <button :disabled="!canSubmit || submitting" class="btn-primary" :class="{ 'loading': submitting }" @click="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </template>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/baseComponents/SlideOver.vue";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";


export default {
  name: "AddWorkedSlotSlideOver",
  components: {
    SlideOver,
    TechnicianSelect,
  },
  data() {
    return {
      submitting: false,

      startDatetime: null,
      endDatetime: null,
      technicianId: null,
    };
  },
  computed: {
    canSubmit() {
      return this.startDatetime && this.endDatetime && this.technicianId;
    },
  },
  methods: {
    open() {
      this.reset();
      this.$refs.slideOver.open();
    },
    close() {
      this.$refs.slideOver.close();
      this.reset();
    },
    reset() {
      this.startDatetime = null;
      this.endDatetime = null;
      this.technicianId = null;
      this.submitting = false;
    },
    submit() {
      this.submitting = true;

      this.$services.workedSlot.create({
        start_datetime: this.startDatetime,
        end_datetime: this.endDatetime,
        user_id: this.technicianId,
      }).then(() => {
        this.close();
      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};

</script>

