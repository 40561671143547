<template>
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    class="feather feather-briefcase"
    stroke-linejoin="round"
    stroke-linecap="round"
    stroke-width="2"
    stroke="currentColor"
    fill="none">

    <g>
      <title>Layer 1</title>
      <g id="svg_3">
        <rect
          id="svg_1"
          ry="2"
          rx="2"
          height="11.31647"
          width="16.95227"
          y="9.68353"
          x="2" />
        <path id="svg_2" d="m13.86659,21l0,-12.9331a1.69523,1.61664 0 0 0 -1.69523,-1.61664l-3.39045,0a1.69523,1.61664 0 0 0 -1.69523,1.61664l0,12.9331" />
        <line x1="20" y1="8" x2="20" y2="14" />
        <line x1="23" y1="11" x2="17" y2="11" />
      </g>
    </g>
  </svg>
</template>
