<template>
  <td
    class="day"
    style="height: inherit;"
    :class="{ 'holiday': holidayAtDate != null, 'working': technicianIsDisponible }">
    <div
      v-if="leaves.length > 0"
      style="display: flex; flex-direction: column; height: 100%;">
      <div
        style="display: flex; flex-direction: column; flex-grow: 1;">
        <div
          v-for="(leave, index) in morningLeaves"
          :key="index"
          class="leave"
          :style="{ 'background-color': leave.leave_type.color }"
          @click="openEditionModal(technician, date, true, false)">
          {{ leave.leave_type.code }}
        </div>
      </div>

      <div
        style="display: flex; flex-direction: column; flex-grow: 1;">
        <div
          v-for="(leave, index) in afternoonLeaves"
          :key="index"
          class="leave"
          :style="{ 'background-color': leave.leave_type.color }"
          @click="openEditionModal(technician, date, false, true)">
          {{ leave.leave_type.code }}
        </div>
      </div>
    </div>
    
    <div v-else style="display: flex; height: 100%;">
      <div style="flex-grow: 1; display: flex; flex-direction: column;">
        <div
          class="slot"
          :class="{ 'selected': isMorningSelected, 'disabled': selectedTechnician != null && selectedTechnician.id != technician.id }"
          @mousedown="$emit('mousedown', technician, date, true)"
          @mouseup="$emit('mouseup', technician, date, true)"
          @mouseenter="$emit('mouseenter', technician, date, true)" />
        <div
          class="slot"
          :class="{ 'selected': isAfternoonSelected, 'disabled': selectedTechnician != null && selectedTechnician.id != technician.id }"
          @mousedown="$emit('mousedown', technician, date, false)"
          @mouseup="$emit('mouseup', technician, date, false)"
          @mouseenter="$emit('mouseenter', technician, date, true)" />
      </div>
    </div>
  </td>
</template>

<script>

import { GDate } from "g-date";

export default {
  name: "StaffCalendarCase",
  
  props: {
    technician: {
      type: Object,
      required: true,
    },
    date: {
      type: GDate,
      required: true,
    },
    holidays: {
      type: Object,
      required: true,
    },
    selectedTechnician: {
      type: Object,
      required: false,
      default: null,
    },
    selectionFromDate: {
      type: GDate,
      required: false,
      default: null,
    },
    selectionFromMorning: {
      type: Boolean,
      required: false,
      default: null,
    },
    selectionToDate: {
      type: GDate,
      required: false,
      default: null,
    },
    selectionToMorning: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  emits: ["openEditionModal", "mouseup", "mousedown", "mouseenter"],
  computed: {
    leaves() {
      if (!this.technician.leaves) {
        return [];
      }
      return this.technician.leaves.filter(leave => leave.date == this.date.toISOString());
    },
    morningLeaves() {
      return this.leaves.filter(leave => leave.morning);
    },
    afternoonLeaves() {
      return this.leaves.filter(leave => !leave.morning);
    },
    holidayAtDate() {
      const isoDate = this.date.toISOString();
      
      if (isoDate in this.holidays) {
        return this.holidays[isoDate];
      } else {
        return null;
      }
    },
    technicianIsDisponible() {
      if (this.technician.disponibilities == null) {
        return false;
      }

      for (const disponibility of this.technician.disponibilities) {
        const index = this.date.getWeekDayIndex() + 1;
        if (disponibility.weekdays.includes(index.toString())) {
          return true;
        }
      }
      return false;
    },
    isMorningSelected() {
      if (this.selectionFromDate && this.selectionToDate && this.technician.id == this.selectedTechnician.id) {
        if (!this.date.isBefore(this.selectionFromDate) && !this.date.isAfter(this.selectionToDate)) {
          if (this.date.isEqual(this.selectionFromDate)) {
            return this.selectionFromMorning;
          } else {
            return true;
          }
        }
      }
      return false;
    },
    isAfternoonSelected() {
      if (this.selectionFromDate && this.selectionToDate && this.technician.id == this.selectedTechnician.id) {
        if (!this.date.isBefore(this.selectionFromDate) && !this.date.isAfter(this.selectionToDate)) {
          if (this.date.isEqual(this.selectionToDate)) {
            return this.selectionToMorning == false;
          } else {
            return true;
          }
        }
      }

      return false;
    },
  },
  methods: {
    openEditionModal(technician, date, morning, afternoon) {
      this.$emit("openEditionModal", technician, date, morning, afternoon);
    },
  },
};
</script>

<style scoped lang="scss">

  .day {
    background: white;

    &.working {
      background: #EBFFE6;
    }

    &.holiday {
      background: lightgray;
    }

  }

  .slot {
    border: 1px solid #e6e6e6;
    flex-grow: 1;
    cursor: pointer;

    &:hover,
    &.selected {
      background: lightblue;
    }

    &.disabled {
      background: gray !important;
    }
  }

  .leave {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1px;
    cursor: pointer;

    &:hover {
      background: lightblue !important;
    }
  }

</style>
