<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Techniciens</span>
      <button v-if="!editing" class="small-tool-btn" @click="edit">
        <i class="bi bi-pencil-square" />
      </button>
    </div>
    <div class="card-body">
      <div v-if="!editing">
        <ul>
          <li v-if="intervention.technician">
            <strong>{{ $filters.fullname(intervention.technician) }}</strong> (principal)
          </li>
          <li
            v-for="(technician, index) in intervention.other_technicians"
            :key="index">
            <strong>{{ $filters.fullname(technician) }}</strong>
          </li>
        </ul>
      </div>

      <div v-else>
        <div class="form-group">
          <ul>
            <li
              v-for="(technician, index) in editedTechnicians"
              :key="index"
              style="display: flex">
              <button
                class="small-tool-btn"
                @click="removeTechnician(technician)">
                <i class="bi bi-trash" />
              </button>
              
              <strong>{{ $filters.fullname(technician) }}</strong>

              <span
                v-if="mainTechnicianId == technician.id"
                style="margin-left: 5px;">
                (principal)
              </span>

              <button
                v-else
                class="btn-link"
                style="margin-left: 5px;"
                @click="mainTechnicianId = technician.id">
                Assigner comme tech principal
              </button>
            </li>
          </ul>
        </div>
      
        <div class="form-group">
          <SelectObject
            type="technician"
            placeholder="Ajoutez un technicien"
            @select="addTechnician" />
        </div>
      
        <div class="flow-spaced-container align-right">
          <button
            class="btn-primary"
            style="margin-right: 10px;"
            :disabled="submitting"
            @click="cancel">
            Annuler
          </button>
          <button
            class="btn-primary"
            :class="{ 'loading': submitting }"
            :disabled="submitting || !hasUnsavedModifications"
            @click="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";


export default {
  name: "InterventionTechniciansCard",
  components: {
    SelectObject,
  },
  props: {
    intervention: {
      type: Object,
      required: true,
    },
  },
  emits: ["updateTechnician", "updateOtherTechnicians"],
  data() {
    return {
      editing: false,
      submitting: false,
      editedTechnicians: null,
      mainTechnicianId: null,
    };
  },
  computed: {
    hasUnsavedModifications() {
      if (!this.editedTechnicians) {
        return false;
      }

      for (let i = 0; i < this.editedTechnicians.length; i++) {
        const editedTechnician = this.editedTechnicians[i];

        if (editedTechnician.id == this.intervention.technician?.id) {
          if (this.mainTechnicianId != editedTechnician.id) {
            return true;
          }
        } else {
          if (this.intervention.other_technicians.findIndex((technician) => {return technician.id == editedTechnician.id;}) == -1) {
            return true;
          }
        }
      }

      if (this.intervention.technician) {
        if (this.editedTechnicians.findIndex((technician) => {return technician.id == this.intervention.technician.id;}) == -1) {
          return true;
        }

        if (this.mainTechnicianId != this.intervention.technician.id) {
          return true;
        }
      }

      for (let i = 0; i < this.intervention.other_technicians.length; i++) {
        const otherTechnician = this.intervention.other_technicians[i];

        if (this.editedTechnicians.findIndex((technician) => {return technician.id == otherTechnician.id;}) == -1) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {    
    edit() {
      this.editedTechnicians = [];

      if (this.intervention.technician) {
        this.editedTechnicians.push(this.intervention.technician);
        this.mainTechnicianId = this.intervention.technician.id;
      }

      this.intervention.other_technicians.forEach((technician) => {
        this.editedTechnicians.push(technician);
      });

      this.editing = true;
    },
    cancel() {
      this.editing = false;
      this.editedTechnicians = null;
      this.mainTechnicianId = null;
    },
    
    addTechnician(technician) {
      if (this.editedTechnicians.findIndex((t) => {return t.id == technician.id;}) != -1) {
        return;
      }

      this.editedTechnicians.push(technician);

      if (this.mainTechnicianId == null) {
        this.mainTechnicianId = technician.id;
      }
    },
    
    removeTechnician(technician) {
      this.editedTechnicians = this.editedTechnicians.filter((t) => {return t.id != technician.id;});

      if (this.mainTechnicianId == technician.id) {
        if (this.editedTechnicians.length > 0) {
          this.mainTechnicianId = this.editedTechnicians[0].id;
        } else {
          this.mainTechnicianId = null;
        }
      }
    },
    submit() {
      this.submitting = true;

      const otherTechnicianIds = [];

      this.editedTechnicians.forEach((technician) => {
        if (technician.id != this.mainTechnicianId) {
          otherTechnicianIds.push(technician.id);
        }
      });

      this.$services.intervention.patch(
        this.intervention.id,
        {
          technician_id: this.mainTechnicianId,
          other_technician_ids: otherTechnicianIds,
        },
      ).then((response) => {
        this.$emit("updateTechnician", response.data.technician);
        this.$emit("updateOtherTechnicians", response.data.other_technicians);
        this.cancel();

      }).finally(() => {
        this.submitting = false;
      });
    },
  },
  
};
</script>

<style>

</style>