<template>
  <div />
</template>

<script>

export default {
  name: "DashboardView",
  
};

</script>

