<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">{{ title }}</span>
      <button v-if="editable && !editMode" class="small-tool-btn" @click="edit">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <ContactForm
        v-model="editedContact"
        :enabledFields="enabledFields"
        :validationErrors="validationErrors" />
      <div class="flow-spaced-container align-right">
        <button class="btn-secondary" :disabled="submitting" @click="cancel">
          Annuler
        </button>
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else-if="object" class="card-body">
      <div class="info-grid-layout">
        <div>Nom</div>
        <div>{{ $filters.capitalize(object.title) }} {{ $filters.capitalize(object.first_name) }} {{ $filters.capitalize(object.last_name) }}</div>
        <div>Entreprise</div>
        <div>{{ object.company_name }} {{ object.company_vat }}</div>
        <div>Numéro de téléphone</div>
        <div>{{ object.phone_number }}</div>
        <div>Email</div>
        <div>{{ object.email }}</div>
        <div>Langue</div>
        <div>{{ object.language }}</div>
      </div>
    </div>

    <div v-else class="card-body">
      <i>Aucun</i>
    </div>
  </div>
</template>

<script>

import ContactForm from "@/components/forms/ContactForm.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ObjectContactCard",
  components: {
    ContactForm,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Contact",
    },
    object: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    enabledFields: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      editMode: false,
      editedContact: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      if (this.object) {
        this.editedContact = {
          title: this.object.title,
          first_name: this.object.first_name,
          last_name: this.object.last_name,
          company_name: this.object.company_name,
          company_vat: this.object.company_vat,
          phone_number: this.object.phone_number,
          email: this.object.email,
          language: this.object.language,
        };
      } else {
        this.editedContact = {
          title: "mr",
          first_name: null,
          last_name: null,
          company_name: null,
          company_vat: null,
          phone_number: null,
          email: null,
          language: "fr",
        };
      }
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedContact = null;
    },
    submit() {
      this.submitting = true;

      this.onSave(this.editedContact)
        .then(() => {
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};

</script>