<template>
  <Modal ref="modal">
    <template #default>
      <label class="form-label">Date d'échéance</label>
      <div class="form-group">
        <div class="flow-spaced-container">
          <button
            class="btn-secondary"
            :disabled="loading"
            @click="delayToTomorrow">
            Demain
          </button>
          <button
            class="btn-secondary"
            :disabled="loading"
            @click="delayToNextWeek">
            Lundi prochain
          </button>
          <button
            class="btn-secondary"
            :disabled="loading"
            @click="delayToNextMonth">
            Début du mois prochain
          </button>
          <button
            class="btn-secondary"
            :disabled="loading"
            @click="delay30Days">
            Dans 30 jours
          </button>
        </div>
      </div>
      <div class="form-group">
        <input v-model="date" type="datetime-local" class="form-control">
      </div>
    </template>
    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="loading"
          @click="hide">
          Annuler
        </button>
        <button
          class="btn-primary"
          :class="{ 'loading': loading }"
          :disabled="loading || !canSubmit"
          @click="submit">
          Envoyer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "DelayTaskModal",
  components: {
    Modal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      date: null,
      loading: false,
    };
  },
  computed: {
    canSubmit() {
      return this.date != null;
    },
    tomorrowDateISO() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      // Get the date in ISO 8601 format
      const tomorrowISO = tomorrow.toISOString().split("T")[0] + "T00:00:00";

      return tomorrowISO;
    },
    in30DaysISO() {
      const today = new Date();
      const in30Days = new Date(today);
      in30Days.setDate(today.getDate() + 30);

      // Get the date in ISO 8601 format
      const in30DaysISO = in30Days.toISOString().split("T")[0] + "T00:00:00";

      return in30DaysISO;
    },
    dayAfterTomorrowISO() {
      const today = new Date();
      
      // Calculer le nombre de millisecondes dans une journée
      const oneDayMilliseconds = 24 * 60 * 60 * 1000;

      // Ajouter deux jours à la date actuelle
      const dayAfterTomorrow = new Date(today.getTime() + 2 * oneDayMilliseconds);

      // Réinitialiser l'heure, les minutes, les secondes et les millisecondes à 00:00:00.000
      dayAfterTomorrow.setHours(0, 0, 0, 0);

      // Obtenir la date au format ISO 8601
      const dayAfterTomorrowISO = dayAfterTomorrow.toISOString().split("T")[0] + "T00:00:00";

      return dayAfterTomorrowISO;
    },
    nextMondayISO() {
      const aujourdHui = new Date();
  
      // Obtenir le jour de la semaine (0 = dimanche, 1 = lundi, ..., 6 = samedi)
      const jourSemaine = aujourdHui.getDay();
  
      // Calculer le nombre de jours restants jusqu'au prochain lundi
      const joursRestants = 8 - jourSemaine; // 8 jours pour inclure le lundi de la semaine prochaine
  
      // Créer la date du prochain lundi
      const prochainLundi = new Date(aujourdHui);
      prochainLundi.setDate(aujourdHui.getDate() + joursRestants);

      // Obtient la date au format ISO 8601
      const prochainLundiISO = prochainLundi.toISOString().split("T")[0] + "T00:00:00";

      return prochainLundiISO;
    },
    firstDayOfNextMonthISO() {
      const today = new Date();
  
      // Obtenir le mois actuel
      const currentMonth = today.getMonth();

      // Passer au mois suivant
      const nextMonth = new Date(today);
      nextMonth.setMonth(currentMonth + 1);

      // Définir le jour au 1er jour du mois
      nextMonth.setDate(1);

      // Obtenir la date au format ISO 8601
      const firstDayOfNextMonthISO = nextMonth.toISOString().split("T")[0] + "T00:00:00";

      return firstDayOfNextMonthISO;
    },
  },
  methods: {
    clear() {
      this.date = null;
      this.loading = false;
    },
    hide() {
      this.$refs.modal.hide();
    },
    show() {
      this.clear();
      this.$refs.modal.show();
    },
    delayToTomorrow() {
      this.date = this.tomorrowDateISO;
    },
    delayToAfterTomorrow() {
      this.date = this.dayAfterTomorrowISO;
    },
    delayToNextWeek() {
      this.date = this.nextMondayISO;
    },
    delayToNextMonth() {
      this.date = this.firstDayOfNextMonthISO;
    },
    delay30Days() {
      this.deadline = this.in30DaysISO;
    },
    submit() {
      this.loading = true;
      this.$services.task.patch(
        this.modelValue.id,
        {
          deadline: this.date,
        },
      )
        .then((response) => {
          this.$emit("update:modelValue", response.data);
          this.hide();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>