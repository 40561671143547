<template>
  <ObjectViewLayout
    :title="title"
    :loading="initializing"
    withBackButton
    hideHeaderOnMobile
    @back="$router.push({ name: 'intervention-list' })">
    <template #default>
      <div class="flex justify-center mb-5">
        <Steps :steps="steps" style="flex-grow: 1; max-width: 500px;" @goto="gotoStep" />
      </div>

      <Transition name="tunnel-transition" mode="out-in">
        <!-- Customer -->

        <CustomerCreationStepCard
          v-if="activeStepId == '01'"
          v-model="customer"
          @next="gotoIntervention" />

        <!-- Intervention -->

        <InterventionCreationStepCard
          v-else-if="activeStepId == '02'"
          v-model="intervention"
          :customer="customer"
          :mode="invoiceMode"
          :invoiceOrQuoteToCopy="invoiceToCopy ? invoiceToCopy : quoteToCopy"
          :interventionToCopy="interventionToCopy"
          @gotoInvoice="gotoInvoice"
          @gotoQuote="gotoQuote" />

        <!-- Invoice -->

        <InvoiceCreationStepCard
          v-else-if="activeStepId == '03'"
          :customer="customer"
          :intervention="intervention"
          :mode="invoiceMode"
          :invoiceOrQuoteToCopy="invoiceToCopy ? invoiceToCopy : quoteToCopy" />
      </Transition>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import InvoiceCreationStepCard from "@/components/cards/creationTunnel/InvoiceCreationStepCard.vue";
import InterventionCreationStepCard from "@/components/cards/creationTunnel/InterventionCreationStepCard.vue";
import CustomerCreationStepCard from "@/components/cards/creationTunnel/CustomerCreationStepCard.vue";
import Steps from "@/components/baseComponents/Steps.vue";

export default {
  name: "InterventionObjectAdvancedCreationView",
  components: {
    ObjectViewLayout,
    InvoiceCreationStepCard,
    InterventionCreationStepCard,
    CustomerCreationStepCard,
    Steps,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: (value) => ["intervention", "invoice", "quote"].includes(value),
    },
    defaultSelectedCustomerId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    defaultSelectedInterventionId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    interventionToCopyId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    importProductsFromInvoiceId: { // Import products, origin and activity from another invoice
      type: [Number, String],
      required: false,
      default: null,
    },
    importProductsFromQuoteId: { // Import products, origin and activity from another quote
      type: [Number, String],
      required: false,
      default: null,
    },
    importAllFromInvoiceId: { // Import customer, intervention, products, origin and activity from another invoice. If set, it will overwrite importProductsFromInvoiceId.
      type: [Number, String],
      required: false,
      default: null,
    },
    importAllFromQuoteId: { // Import origin, customer, intervention, products, origin and activity from another quote. If set, it will overwrite importProductsFromQuoteId.
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      
      // States
      activeStepId: "01",
      initializing: false,
      invoiceMode: this.mode,

      // Objects
      customer: null,
      intervention: null,
      invoiceToCopy: null,
      quoteToCopy: null,
      interventionToCopy: null,

    };
  },
  computed: {
    steps() {
      return [
        { id: "01", name: "Client", href: "#", status: this.activeStepId == "01" ? "current" : "complete" },
        { id: "02", name: "Intervention", href: "#", status: this.activeStepId == "02" ? "current" : this.activeStepId == "03" ? "complete" : "upcoming" },
        { id: "03", name: this.mode == "invoice" ? "Facture" : this.mode == "quote" ? "Devis" : "Facturation", href: "#", status: this.activeStepId == "03" ? "current" : "upcoming" },
      ];
    },
    title() {
      var prefix = "";
      switch (this.mode) {
      case "intervention":
        prefix = "Créer une intervention";
        break;
      case "invoice":
        prefix = "Créer une facture";
        break;
      case "quote":
        prefix = "Créer un devis";
        break;
      }

      var suffix = "";

      if (this.importAllFromInvoiceId) {
        suffix = " à partir d'une autre facture";
      } else if (this.importAllFromQuoteId) {
        suffix = " à partir d'un autre devis";
      } else if (this.importProductsFromInvoiceId) {
        suffix = " avec les produits d'une autre facture";
      } else if (this.importProductsFromQuoteId) {
        suffix = " avec les produits d'un autre devis";
      }

      return `${prefix} ${suffix}`;
    },
  },
  async mounted() {
    this.initializing = true;

    this.validateProps();

    try {

      if (this.defaultSelectedInterventionId) {
        const responseIntervention = await this.$services.intervention.retrieve(this.defaultSelectedInterventionId);
        this.intervention = responseIntervention.data;
        this.customer  = responseIntervention.data.customer;

      } else if (this.defaultSelectedCustomerId) {
        const responseCustomer = await this.$services.customer.retrieve(this.defaultSelectedCustomerId);
        this.customer = responseCustomer.data;

      } else if (this.importAllFromQuoteId) {
        const responseQuote = await this.$services.quote.retrieve(this.importAllFromQuoteId);
        this.customer = responseQuote.data.customer;
        this.intervention = responseQuote.data.interventions && responseQuote.data.interventions.length > 0 ? responseQuote.data.interventions[0] : null;
        this.quoteToCopy = responseQuote.data;

      } else if (this.importAllFromInvoiceId) {
        const responseInvoice = await this.$services.invoice.retrieve(this.importAllFromInvoiceId);
        this.customer = responseInvoice.data.customer;
        this.intervention = responseInvoice.data.interventions && responseInvoice.data.interventions.length > 0 ? responseInvoice.data.interventions[0] : null;
        this.invoiceToCopy = responseInvoice.data;

      } else if (this.importProductsFromInvoiceId) {
        const responseInvoice = await this.$services.invoice.retrieve(this.importProductsFromInvoiceId);
        this.invoiceToCopy = responseInvoice.data;

      } else if (this.importProductsFromQuoteId) {
        const responseQuote = await this.$services.quote.retrieve(this.importProductsFromQuoteId);
        this.quoteToCopy = responseQuote.data;

      } else if (this.interventionToCopyId) {
        const responseInterventionToCopy = await this.$services.intervention.retrieve(this.interventionToCopyId);
        this.customer  = responseInterventionToCopy.data.customer;
        this.interventionToCopy = responseInterventionToCopy.data;

      }

    } finally {
      this.initializing = false;
    }

  },
  methods: {
    validateProps() {
      function hasOnlyOneOrNullValue(arr) {
        let count = 0;

        for (const value of arr) {
          if (value !== null) {
            count++;
            if (count > 1) {
              return false; // More than one non-null value
            }
          }
        }

        return true; // Either one or zero non-null values
      }

      if (!hasOnlyOneOrNullValue([this.defaultSelectedCustomerId, this.defaultSelectedInterventionId, this.importAllFromInvoiceId, this.importAllFromQuoteId, this.importProductsFromQuoteId, this.importProductsFromInvoiceId])) {
        throw new Error("Only one of the following props can be set: defaultSelectedCustomerId, defaultSelectedInterventionId, importAllFromInvoiceId, importAllFromQuoteId");
      }

      if (this.defaultSelectedInterventionId && this.mode == "intervention") {
        throw new Error("defaultSelectedInterventionId prop cannot be set when mode is 'intervention'");
      } 

    },

    // Navigation

    gotoStep(stepId) {
      this.activeStepId = stepId;
    },

    gotoIntervention() {
      this.activeStepId = "02";
    },

    gotoInvoice() {
      this.invoiceMode = "invoice";
      this.activeStepId = "03";
    },

    gotoQuote() {
      this.invoiceMode = "quote";
      this.activeStepId = "03";
    },
  },
};
</script>