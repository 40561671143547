<template>
  <Modal ref="modal">
    <SelectObject
      type="user"
      :selectedValue="responsible ? $filters.fullname(responsible) : null"
      @select="onSelectResponsible"
      @unselect="responsible = null" />

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "EditContractResponsibleModal",
  components: {
    Modal,
    SelectObject,
  },

  emits: ["change"],
  data() {
    return {
      contractId: null,
      responsible: null,
      loading: false,
    };
  },
  methods: {
    show(contract) {
      this.contractId = contract.id;
      this.responsible = contract.responsible;
      this.loading = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.contractId = null;
      this.responsible = null;
      this.loading = false;
    },
    onSelectResponsible(user) {
      this.responsible = user;
    },
    submit() {
      this.loading = true;
      
      this.$services.contract.patch(
        this.contractId,
        {
          responsible_id: this.responsible.id,
        },
      )
        .then((response) => {
          this.$emit(
            "change",
            {
              responsible: response.data.responsible,
            },
          );
          this.hide();
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>