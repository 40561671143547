<template>
  <BaseDropdown alignRight>
    <template #component>
      <div class="loggin-icon">
        <i class="bi bi-person-circle" style="margin-right: 5px; color: rgb(100, 100, 100); font-size: 2.4rem;" />
        <i class="arrow down" />
      </div>
    </template>

    <div>
      <strong>{{ me.email }}</strong>
    </div>

    <hr>

    <SimpleButton
      icon="bi bi-person-circle"
      text="Mon profil"
      @click="$router.push({ 'name': 'my-provider-profil' })" />
      
    <SimpleButton
      icon="bi bi-box-arrow-right"
      text="Se déconnecter"
      @click="logout" />
  </BaseDropdown>
</template>

<script>

import BaseDropdown from "@/components/vueComponents/base/BaseDropdown.vue";
import { useMeStore } from "@/stores/me";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

export default {
  name: "LoggedIn",
  components: {
    BaseDropdown,
    SimpleButton,
  },
  data() {
    return {
      meStore: useMeStore(),
    };
  },
  computed: {
    me() {
      return this.meStore.getMe;
    },
  },
  methods: {
    logout() {
      this.meStore.logout();
    },
  },
};
</script>

<style lang="scss" scoped>

  .loggin-icon {
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }

</style>