import { api } from "@/api";
import { notifyError, notifySuccess } from "@/notifications";

class PaymentApiService {

  create(type: "cash" | "bancontact" | "wire", data: object) : Promise<object> {
    if (!["cash", "bancontact", "wire"].includes(type)) {
      throw new Error("PaymentApiService.create: type must be one of [\"cash\", \"bancontact\", \"wire\"] but got " + type + " instead");
    }

    return new Promise((resolve, reject) => {
      api.post(`/payments/${type}/`, data)
        .then((response) => {
          notifySuccess("Paiement créé");
          resolve(response);
        }).catch((error) => {
          if (!error.response || error.response.status != 400) {
            notifyError("Erreur lors de la création du paiement");
          }
          reject(error);
        });
    });
  }

  patch(type: "cash" | "bancontact" | "wire", id: number, data: object) : Promise<object> {
    if (!["cash", "bancontact", "wire"].includes(type)) {
      throw new Error("PaymentApiService.patch: type must be one of [\"cash\", \"bancontact\", \"wire\"] but got " + type + " instead");
    }

    return new Promise((resolve, reject) => {
      api.patch(`/payments/${type}/${id}/`, data)
        .then((response) => {
          notifySuccess("Paiement mis à jour");
          resolve(response);
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            notifyError("Des champs n'ont pas été remplis correctements");
          } else if (error.response && error.response.status === 404) {
            notifyError("Vous essayez de modifier un paiement qui n'existe pas ou plus.", "Essayez de rafraichir la page");
          } else {
            notifyError("Impossible de mettre à jour le paiement");
          }
          reject(error);
        });
    });
  }

  delete(type: "cash" | "bancontact" | "wire" | "paypal", id: number) : Promise<object> {
    if (!["cash", "bancontact", "wire", "paypal"].includes(type)) {
      throw new Error("PaymentApiService.delete: type must be one of [\"cash\", \"bancontact\", \"wire\", \"paypal\"] but got " + type + " instead");
    }

    return new Promise((resolve, reject) => {
      api.delete(`/payments/${type}/${id}/`)
        .then((response) => {
          notifySuccess("Paiement supprimé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  listCashPayments() {
    return api.get(
      "/payments/cash/",
    );
  }

  listCashPaymentsInRegister() {
    return api.get(
      "/payments/cash/?in_register=true",
    );
  }

  listCashPaymentsInTechnicianWallet(technicianId: number) {
    return api.get(
      `/payments/cash/?receiver_id=${technicianId}`,
    );
  }
  

  sendToRegister(type: "cash" | "bancontact" | "wire", id: number) {
    if (!["cash"].includes(type)) {
      throw new Error("PaymentApiService.sendToRegister: type must be one of [\"cash\"] but got " + type + " instead");
    }

    return new Promise((resolve, reject) => {
      api.post(
        `/payments/cash/${id}/send-to-register/`,
      )
        .then((response) => {
          notifySuccess("Paiement transféré dans la caisse");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }


}

export default PaymentApiService;