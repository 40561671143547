<template>
  <div class="card">
    <div class="card-header">
      <span class="title">{{ title }}</span>
    </div>
    <div class="card-body">
      <div>
        <strong>{{ remarks }}</strong><br>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RemarkForm",
  props: {
    title: {
      type: String,
      required: false,
      default: "Remarques internes",
    },
    remarks: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style>

</style>