<template>
  <GBaseRow>
    <template #icon>
      <i class="bi bi-currency-euro" />
    </template>

    <template #title>
      <span class="big">Remboursement de {{ refund.amount }}€</span>
    </template>

    <td>
      <span>{{ $filters.dateStringToHumanizedDate(refund.created_at) }}</span>
    </td>

    <td>
      <span v-if="refund.refunded_at">
        Remboursé le {{ $filters.dateStringToHumanizedDate(refund.refunded_at) }}
      </span>
      <span v-else>
        En attente de remboursement
      </span>
    </td>
  </GBaseRow>
</template>

<script>

import {GBaseRow} from "g-list";

export default {
  name: "RefundRow",
  components: {
    GBaseRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    refund: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>


</style>