import { RouteConfig } from "vue-router";

import SaleObjectCreationView from "@/views/sale/SaleObjectCreationView.vue";
import SaleListLayout from "@/views/sale/SaleListLayout.vue";
import SaleObjectView from "@/views/sale/SaleObjectView.vue";
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import SaleRow from "@/components/rows/sale/SaleRow.vue";
import { useSaleListStore } from "@/stores/saleList";


const saleRoutes: RouteConfig[] = [
  {
    path: "/ventes",
    component: SaleListLayout,
    redirect: "/ventes/a-expedier",
    name: "sale-list",
    children: [
      {
        path: "a-expedier",
        component: PaginatedStoreList,
        name: "sale-list-to-ship",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "to_ship",
          };
        },
      },
      {
        path: "en-livraison",
        component: PaginatedStoreList,
        name: "sale-list-delivering",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "delivering",
          };
        },
      },
      {
        path: "livrees",
        component: PaginatedStoreList,
        name: "sale-list-delivered",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "delivered",
          };
        },
      },
      {
        path: "en-retour",
        component: PaginatedStoreList,
        name: "sale-list-returning",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "returning",
          };
        },
      },
      {
        path: "retorune",
        component: PaginatedStoreList,
        name: "sale-list-returned",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "returned",
          };
        },
      },
      {
        path: "remises-en-main-propre",
        component: PaginatedStoreList,
        name: "sale-list-no-delivery",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "no_delivery",
          };
        },
      },
      {
        path: "annulees",
        component: PaginatedStoreList,
        name: "sale-list-cancelled",
        props: () => {
          return {
            rowComponent: SaleRow,
            columnsNames: [null, "Montant", "Acheteur", "Livraison", "Auteur", "Espace de stokage", "Produits", "Status"],
            store: useSaleListStore(),
            subList: "cancelled",
          };
        },
      },
    ],
  },
  {
    path: "/ventes/nouvelle/",
    component: SaleObjectCreationView,
    name: "create-sale",
  },
  {
    path: "/ventes/:id/",
    component: SaleObjectView,
    name: "sale",
  },
];

export default saleRoutes;
