<template>
  <div class="card">
    <div class="card-header">
      <span class="title">
        Taches
      </span>
    </div>

    <div class="card-body">
      <div class="flow-spaced-container">
        <Task
          v-for="(task, index) in modelValue.tasks"
          :key="index"
          :task="task"
          @click="$refs.editTaskModal.show(task)" />
        
        <TaskModal
          ref="editTaskModal"
          @change="onTaskChanged" />
      </div>

      <div v-if="!modelValue.tasks || modelValue.tasks.length <= 0">
        <i>Aucune</i>
      </div>

      <div style="border-top: 1px solid rgb(100, 100, 100); text-align: center; color: rgb(100, 100, 100); padding: 10px; margin-top: 10px;">
        <div>Ajouter une tache</div>
        <div class="flow-spaced-container" style="align-items: center; justify-content: center;">
          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('call')">
            <i class="bi bi-telephone" />
            <div class="tooltip-text">
              Appeler le client
            </div>
          </button>

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('sms')">
            <i class="bi bi-chat-dots" />
            <div class="tooltip-text">
              Envoyer un SMS
            </div>
          </button>

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('email')">
            <i class="bi bi-envelope-at" />
            <div class="tooltip-text">
              Envoyer un email au client
            </div>
          </button>

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('rdv')">
            <i class="bi bi-calendar" />
            <div class="tooltip-text">
              Fixer un rendez-vous
            </div>
          </button>

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('close')">
            <i class="bi bi-check-circle" />
            <div class="tooltip-text">
              Cloturer l'intervention
            </div>
          </button>

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show('cancel')">
            <i class="bi bi-x-circle" />
            <div class="tooltip-text">
              Annuler l'intervention
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <AddTaskToInterventionModal
    ref="addTaskModal"
    v-model="internalValue"
    @click.stop />
</template>

<script>

import Task from "@/components/others/Task.vue";
import AddTaskToInterventionModal from "@/components/modals/AddTaskToInterventionModal.vue";
import TaskModal from "@/components/modals/TaskModal.vue";

export default {
  name: "InterventionTasksCard",
  components: {
    Task,
    AddTaskToInterventionModal,
    TaskModal,
  },
  props: {
    modelValue: { // The intervention
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    onTaskChanged(task) {
      const index = this.internalValue.tasks.findIndex((t) => t.id === task.id);
      this.internalValue.tasks[index] = task;
    },
  },

};

</script>