<template>
  <LoaderView v-if="schema == null" />

  <div v-else>
    <div class="mobile-only" style="display: flex; justify-content: flex-end; margin-bottom: 5px;">
      <button
        :class="{ 'btn-secondary': showCustomForm, 'btn-primary': !showCustomForm }"
        style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-right-radius: 0; border-bottom-right-radius: 0;"
        @click="showCustomForm = false">
        <i class="bi bi-search" style="font-size: 1rem;" />
      </button>
      <button
        :class="{ 'btn-secondary': !showCustomForm, 'btn-primary': showCustomForm }"
        style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-left-radius: 0; border-bottom-left-radius: 0;"
        @click="showCustomForm = true">
        <i class="bi bi-keyboard" style="font-size: 1rem;" />
      </button>
    </div>

    <div v-if="showCustomForm" class="desktop-only" style="display: flex; justify-content: flex-end; margin-bottom: 5px;">
      <button
        :class="{ 'btn-secondary': showCustomForm, 'btn-primary': !showCustomForm }"
        style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-right-radius: 0; border-bottom-right-radius: 0;"
        @click="showCustomForm = false">
        <i class="bi bi-search" style="font-size: 1rem;" />
      </button>
      <button
        :class="{ 'btn-secondary': !showCustomForm, 'btn-primary': showCustomForm }"
        style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-left-radius: 0; border-bottom-left-radius: 0;"
        @click="showCustomForm = true">
        <i class="bi bi-keyboard" style="font-size: 1rem;" />
      </button>
    </div>

    <div v-if="!showCustomForm" class="horizontal-spaced-container">
      <div style="flex: 1;">
        <VueGoogleAutocomplete
          :id="`address-search-${index}`"
          style="width: 100%;"
          :country="countryCodes"
          type="text"
          placeholder="Recherchez une adresse"
          class="form-control"
          @placechanged="onAddressSuggestion" />
      </div>

      <div class="desktop-only" style="display: flex;">
        <button
          :class="{ 'btn-secondary': showCustomForm, 'btn-primary': !showCustomForm }"
          style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-right-radius: 0; border-bottom-right-radius: 0;"
          @click="showCustomForm = false">
          <i class="bi bi-search" style="font-size: 1rem;" />
        </button>
        <button
          :class="{ 'btn-secondary': !showCustomForm, 'btn-primary': showCustomForm }"
          style="display: flex; font-weight: normal; font-size: 1rem; align-items: center; border-top-left-radius: 0; border-bottom-left-radius: 0;"
          @click="showCustomForm = true">
          <i class="bi bi-keyboard" style="font-size: 1rem;" />
        </button>
      </div>
    </div>
    
    <div v-else class="form-group">
      <div class="flow-spaced-container">
        <FormField :schema="schema.street_number" style="max-width: 100px;">
          <input
            v-model="internalValue.street_number"
            type="text"
            class="form-control">
        </FormField>
        <FormField
          :schema="schema.street"
          style="flex-grow: 1;">
          <input
            v-model="internalValue.street"
            type="text"
            class="form-control">
        </FormField>
        <FormField :schema="schema.bp" style="max-width: 100px;">
          <input
            v-model="internalValue.bp"
            type="text"
            class="form-control">
        </FormField>
      </div>

      <div class="flow-spaced-container">
        <FormField :schema="schema.zipcode">
          <input
            v-model="internalValue.zipcode"
            type="text"
            class="form-control">
        </FormField>
        <FormField
          style="flex-grow: 1;"
          :schema="schema.city">
          <input
            v-model="internalValue.city"
            type="text"
            class="form-control">
        </FormField>
        <FormField
          :schema="schema.country_code">
          <CountrySelect
            v-model="internalValue.country_code"
            class="form-select" />
        </FormField>
      </div>
    </div>

    <div v-if="isTouched && !isValid">
      <small
        class="text-red">
        Adresse incomplète
      </small>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import CountrySelect from "@/components/inputs/CountrySelect.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { favoriteCountries } from "@/countries";

export default {
  name: "LocationForm",
  components: {
    FormField,
    LoaderView,
    CountrySelect,
    VueGoogleAutocomplete,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    defaultShowCustomForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      schema: null,
      showCustomForm: this.defaultShowCustomForm,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
    isTouched() {
      return (this.internalValue.street != null && this.internalValue.street.length > 0) || (this.internalValue.zipcode != null && this.internalValue.zipcode.length > 0) || (this.internalValue.city != null && this.internalValue.city.length > 0);
    },
    isValid() {
      return this.internalValue.street != null && this.internalValue.street.length > 0 && this.internalValue.street != null && this.internalValue.street.length > 0 && this.internalValue.zipcode != null && this.internalValue.zipcode.length > 0 && this.internalValue.city != null && this.internalValue.city.length > 0 && this.internalValue.country_code != null;
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.location.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    onAddressSuggestion(addressData) {
      this.internalValue.street_number = addressData.street_number;
      this.internalValue.street = addressData.route;
      this.internalValue.zipcode = addressData.postal_code;
      this.internalValue.city = addressData.locality;
      this.internalValue.country_code = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }

      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
  },
};
</script>

<style lang="scss" scoped>

.bi {
  transition: color 0.5s;
  font-size: 1.6rem;
  color: lightgray;

  &.valid {
    color: #165825;
    animation: shake 0.5s; /* Nom de l'animation, durée de 1 seconde, délai de 1 seconde */
  }

  @keyframes shake {
      0% { transform: translateX(0); }
      25% { transform: translateX(-2px) rotate(-20deg); }
      50% { transform: translateX(2px) rotate(20deg); }
      75% { transform: translateX(-2px) rotate(-20deg); }
      100% { transform: translateX(0); }
  }
}


</style>