<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <i class="bi bi-boxes" style="font-size: 2rem;" />
    </template>

    <template #title>
      <span class="big">{{ storage.name }}</span>
    </template>

    <template #default>
      <td>
        {{ storage.value_in_storage }}€
      </td>
      <td>
        <span v-if="storage.in_charge">{{ storage.in_charge }}</span>
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <StorageToolButtons v-model="storage" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import StorageToolButtons from "@/components/toolbuttons/StorageToolButtons.vue";
import { GBaseRow } from "g-list";
import Toolbar from "@/components/Toolbar.vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "StorageRow",
  components: {
    GBaseRow,
    StorageToolButtons,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      meStore: useMeStore(),
    };
  },
  computed: {
    meIsAdminOrManager() {
      return this.meStore.meIsAdmin || this.meStore.meIsManager;
    },
    storage: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "storage", params: { "id": this.storage.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>