import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useCreditNoteListStore = buildPaginatedMultiListStore(
  "creditNoteList",
  "/credit-notes/",
  "Aucune note de crédit trouvée",
  true,
  (filters: object, subList: string) => {
    if (subList != "all") {
      filters.journal_id = subList;
    }

    return filters;
  },
);
