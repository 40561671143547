<template>
  <tr class="my-list-row selectable">
    <td>
      <span class="text-bigger mr-2">Pour {{ $filters.printCustomer(object.customer) }}</span>
      <span v-if="object.rdv_date" class="text-smaller mr-2">Rdv le {{ $filters.dateStringToHumanizedDate(object.rdv_date) }}</span>
      <span class="text-smaller">à {{ object.location.street }} {{ object.location.city }}</span>
    </td>
  </tr>
</template>

<script>

export default {
  name: "InterventionResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
