<script setup lang="ts">

import { Ref, onMounted, ref  } from "vue";
import List from "./List.vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  rowComponent: {type: Object, required: true },
  columnsNames: {type: Array<String>, required: false, default: null },
  load: {type: Function, required: true },
  noResultMessage: {type: String, required: false, default: null },
});

// Data

const isLoading: Ref<boolean> = ref(false);
const results: Ref<Array<any> | undefined> = ref(undefined);
const page: Ref<number> = ref(1);

// Mounted

onMounted(() => {
  refresh();
});

// Methods

const refresh = () => {
  isLoading.value = true;
  props.load(page.value)
    .then((response: { data: undefined; }) => {
      results.value = response.data.results;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const onResultUpdated = (result) => {
  console.log("Update result", result);
  // Update result in list

  if (!results.value) {
    return;
  }

  const index = results.value.findIndex((r) => r.id === result.id);
  console.log("Index", index);
  
  if (index >= 0) {
    results.value.splice(index, 1, result);
  }
};

</script>

<template>
  <List
    :rowComponent="rowComponent"
    :columnsNames="columnsNames"
    :isLoading="isLoading"
    :results="results"
    :noResultMessage="noResultMessage"
    @updateResult="onResultUpdated" />
</template>
  