<template>
  <div
    class="history-row"
    @click="$router.push({ name: 'customer-call', params: { id: object.id } })">
    Appel du {{ $filters.dateStringToHumanizedDate(object.created_at) }}
    <div v-if="object.notes" class="text-muted">
      {{ object.notes.substring(0, 40) }}<span v-if="object.notes.length > 40">...</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomerCallCompactRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
