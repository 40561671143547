<template>
  <Modal ref="modal" width="big" @click.stop>
    <div style="text-align: center; margin-bottom: 20px;">
      <strong>Retour colis</strong>
    </div>

    <div class="vertical-spaced-container">
      <div class="form-group">
        <label class="form-label">
          Raison du retour
        </label>
        <input
          v-model="returnReason"
          type="text"
          class="form-control"
          style="max-width: 300px;">
      </div>

      <div class="form-group">
        <label class="form-label">
          Date de réception du retour colis
        </label>
        <input
          v-model="returnReceptionDate"
          type="date"
          class="form-control"
          style="max-width: 300px;">
      </div>

      <table>
        <tr>
          <th>
            Produit
          </th>
          <th>
            Quantité vendue
          </th>
          <th>
            Prix unitaire vendu
          </th>
          <th>
            Total vendu
          </th>
          <th>
            Quantité retournée
          </th>
          <th>
            Etat
          </th>
          <th>
            Montant à rembourser
          </th>
        </tr>
        <tr
          v-for="line of lines"
          :key="line.id">
          <td>
            {{ line.description }}
          </td>
          <td>
            {{ line.quantitySold }}
          </td>
          <td>
            {{ line.priceSold }}€
          </td>
          <td>
            {{ line.totalExclTax }}€ HT {{ line.totalInclTax }}€ TTC
          </td>
          <td>
            <input
              v-model="line.quantityReturned"
              type="number"
              class="form-control"
              style="max-width: 70px;">
          </td>
          <td>
            <select
              v-model="line.returnedCondition"
              class="form-select">
              <option
                value="reusable">
                Réutilisable
              </option>
              <option
                value="not-reusable">
                Non réutilisable
              </option>
            </select>
          </td>
          <td>
            <input
              v-model="line.amountRefunded"
              type="number"
              class="form-control"
              style="max-width: 100px;">
          </td>
        </tr>
      </table>

      <div>
        Total à rembourser : {{ totalToRefund }} €
      </div>
    </div>

    

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="hide">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="!canSubmit || submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "SaleReturnModal",
  components: {
    Modal,
  },
  emits: ["update"],
  data() {
    return {
      submitting: false,
      sale: null,
      returnReason: "",
      returnReceptionDate: new Date(),
      lines: [],
    };
  },
  computed: {
    canSubmit() {
      return this.returnReason != null
        && this.returnReason.length > 0
        && this.lines.length > 0;
    },
    totalToRefund() {
      return this.lines.reduce(
        (total, line) => total + line.amountRefunded,
        0,
      );
    },
  },
  methods: {
    clear()  {
      this.submitting = false;
      this.returnReason = "";
      this.returnReceptionDate = new Date();
      this.sale = null;
      this.lines = [];
    },
    show(sale) {
      this.clear();
      
      this.sale = sale;
      
      if (sale.return_reason) {
        this.returnReason = sale.return_reason;
      }

      if (sale.return_reception_date) {
        this.returnReceptionDate = new Date(sale.return_reception_date);
      }

      for (let line of sale.invoice.items) {
        this.lines.push({
          id: line.id,
          description: line.product_description,
          quantitySold: line.product_quantity,
          priceSold: line.product_price,
          totalExclTax: line.total_excl_tax_with_discount,
          totalInclTax: line.total_incl_tax_with_discount,
          quantityReturned: line.quantity_returned,
          returnedCondition: line.returned_condition,
          amountRefunded: this.getInitialRefundedAmount(sale, line),
        });
      }
      this.$refs.modal.show();
    },
    getInitialRefundedAmount(sale, line) {
      if (!sale.invoice.refund) {
        return line.total_incl_tax_with_discount;
      }

      const correspondingRefundLine = sale.invoice.refund.lines.find((refundLine) => refundLine.original_line_id === line.id);
      
      if (correspondingRefundLine) {
        return correspondingRefundLine.refunded_amount;
      } else {
        return line.total_incl_tax_with_discount;
      }
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.submitting = true;

      this.$services.saleV2.return(
        this.sale.id,
        this.returnReason,
        this.lines.map((line) => ({
          original_line_id: line.id,
          quantity_returned: line.quantityReturned,
          returned_condition: line.returnedCondition,
          amount_refunded: line.amountRefunded,
        })),
        this.returnReceptionDate.toISOString().split("T")[0],
      )
        .then((response) => {
          this.$emit(
            "update",
            response.data,
          );
          this.hide();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>