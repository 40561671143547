<template>
  <div class="card">
    <div class="card-header">
      <span class="title">
        Evolution du volume d'interventions
      </span>
    </div>
    <div class="card-body">
      <GFilterBar>
        <template #filter>
          <div class="filter-item">
            <div class="filter-item-title">
              Fréquence
            </div>
            <select
              v-model="frequency"
              class="form-select"
              style="width: auto;">
              <option value="monthly">
                Mensuel
              </option>
              <option value="weekly">
                Hebdomadaire
              </option>
              <option value="daily">
                Journalier
              </option>
            </select>
          </div>
          <div class="filter-item">
            <div class="filter-item-title">
              Technicien
            </div>
            <select v-model="technician" style="margin-left: 5px; width: auto;" class="form-select">
              <option
                :value="null">
                Tous
              </option>
              <option
                v-for="(technicianChoice, index) in technicians"
                :key="index"
                :value="technicianChoice">
                {{ technicianChoice.first_name }} {{ technicianChoice.last_name }}
              </option>
            </select>
          </div>
        </template>
      </GFilterBar>

      <div class="cat-list">
        <span
          v-for="(origin, index) in origins"
          :key="index"
          class="cat-list-item"
          :class="{ 'active': selectedOriginCode == origin.code }"
          style="cursor: pointer"
          @click="(selectedOriginCode = origin.code)">
          {{ origin.code }}
        </span>

        <span
          class="cat-list-item"
          :class="{ 'active': selectedOriginCode == 'all' }"
          style="cursor: pointer"
          @click="(selectedOriginCode = 'all')">
          Tous
        </span>
      </div>
      
      <Line :data="chartData" :options="chartOptions" style="max-height: 300px;" />
    </div>
  </div>
</template>

<script>

import { api, uri } from "@/api";
import { GFilterBar } from "g-list";
import { useOriginsStore } from "@/stores/origins";
import { useTechniciansStore } from "@/stores/technicians";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default {
  name: "InterventionsLineChart",
  components: {
    Line,
    GFilterBar,
  },
  data() {
    return {
      data: null,
      loadingData: false,
      selectedOriginCode: "all",
      frequency: "monthly",
      technician: null,
      originsStore: useOriginsStore(),
      techniciansStore: useTechniciansStore(),
    };
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
    },
    labels() {
      if (this.frequency == "monthly") {
        return ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
      } else if (this.frequency == "weekly") {
        let chiffres = [];
        for (let i = 1; i <= 52; i++) {
          chiffres.push(i);
        }
        return chiffres;
      } else if (this.frequency == "daily") {
        let chiffres = [];
        for (let i = 1; i <= 365; i++) {
          chiffres.push(i);
        }
        return chiffres;
      } else {
        return [];
      }
    },
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            label: "Interventions créées",
            backgroundColor: "darkblue",
            data: this.data ? this.data.datasets.nb_created_interventions : [],
            borderColor: "darkblue",
            fill: false,
          },
          {
            label: "Interventions planifiées",
            backgroundColor: "blue",
            data: this.data ? this.data.datasets.nb_created_and_planned_interventions : [],
            borderColor: "blue",
            fill: false,
          },
          {
            label: "Interventions facturées",
            backgroundColor: "lightblue",
            data: this.data ? this.data.datasets.nb_created_and_billed_interventions : [],
            borderColor: "lightblue",
            fill: false,
          },
        ],
      };
    },
    origins() {
      return this.originsStore.getOrigins;
    },
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
  },
  watch: {
    selectedOriginCode() {
      this.fetchData();
    },
    frequency() {
      this.fetchData();
    },
    technician() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loadingData = true;
      return api.get(`${uri.interventionStats()}?frequency=${this.frequency}${this.selectedOriginCode != "all" ? "&origin=" + this.selectedOriginCode : ""}${this.technician ? "&technician=" + this.technician.id : ""}`)
        .then((response) => {
          this.data = response.data;
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
  },
};

</script>