<template>
  <div>
    <span v-if="object.sku">[{{ object.sku }}]</span><span>{{ object.name }}</span>
  </div>
</template>

<script>

export default {
  name: "ProductGenericResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
