<template>
  <div class="card">
    <div class="card-header">
      <span class="title">{{ title }}</span>
      <span v-if="!viewOnly && !editOnly" style="margin-left: 5px;">
        <button v-if="edition" class="btn btn-link" @click="cancel">
          (annuler)
        </button>
        <button v-else class="btn btn-link" @click="edit">
          (editer)
        </button>
      </span>
    </div>
    <div class="card-body">
      <div v-if="editMode">
        <slot name="edition" />
      </div>
      <div v-else-if="viewMode">
        <slot />
      </div>  
    </div>
  </div>
</template>

<script>
  
export default {
  name: "FormSection",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    editOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    activated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      edition: false,
      submitting: false,
      validation: null,
    };
  },
  computed: {
    viewMode() {
      if (this.viewOnly) {
        return true;
      } else if (this.editOnly) {
        return false;
      } else {
        return !this.edition;
      }
    },
    editMode() {
      if (this.editOnly) {
        return true;
      } else if (this.viewOnly) {
        return false;
      } else {
        return this.edition;
      }
    },
  },
  mounted() {
    if (this.activated) {
      this.edit();
    }
  },
  methods: {
    edit() {
      this.edition = true;
    },
    cancel() {
      this.edition = false;
    },
  },
};
</script>

<style lang="scss" scoped>


</style>