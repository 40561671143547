<template>
  <Modal ref="modal">
    <h2>Déplacer des unités de {{ product.name }}</h2>
    <div class="form-inline-group">
      <label class="form-label">
        Nombre d'unités à déplacer
      </label>
      <input
        v-model="nbUnits"
        type="number"
        class="form-control"
        style="margin-left: 5px;">
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="form-label">
            Espace de stockage de départ
          </label>
          <span v-if="srcStorage">{{ srcStorage.name }}</span>
          <SelectObject v-else type="storage" @select="onSrcStorageSelected" />
        </div>
      </div>
      <div class="col" style="flex-grow: 0;">
        {{ '<=>' }}
      </div>
      <div class="col">
        <div class="form-group">
          <label class="form-label">
            Espace de stockage d'arrivé
          </label>
          <span v-if="dstStorage">{{ dstStorage.name }}</span>
          <SelectObject v-else type="storage" @select="onDstStorageSelected" />
        </div>
      </div>
    </div>

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': submitting }"
        :disabled="submitting"
        style="margin-left: 5px;"
        @click="submit">
        Valider
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "MoveUnitsOfProductInStockModal",
  components: {
    Modal,
    SelectObject,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    srcStorage: {
      type: Object,
      required: false,
      default: null,
    },
    dstStorage: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      nbUnits: 0,
      internalSrcStorage: this.srcStorage,
      internalDstStorage: this.dstStorage,
      submitting: false,
    };
  },
  computed: {
    product: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(
          "update:modelValue",
          value,
        );
      },
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onSrcStorageSelected(storage) {
      this.internalSrcStorage = storage;
    },
    onDstStorageSelected(storage) {
      this.internalDstStorage = storage;
    },
    submit() {
      this.submitting = true;

      this.$services.storage.moveProductUnits(this.internalSrctStorage.id, this.internalDstStorage.id, this.product.id, this.nbUnit)
        .then(() => {
          this.hide();
        }).finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>