<template>
  <ObjectViewLayout
    :title="me ? $filters.fullname(me) ? $filters.fullname(me) : me.email : ''"
    :loading="me == null || schema == null"
    :error="errorFetching">
    <template #title-icon-left>
      <i class="bi bi-person-circle" style="font-size: 2rem;" />
    </template>

    <template #default>
      <div v-if="editMe" class="card">
        <div class="card-header">
          <span class="title">Mes informations</span>
        </div>
        <div class="card-body">
          <div class="row">
            <FormField :schema="schema.first_name" :error="validationErrors ? validationErrors.first_name : null" class="col">
              <template #default>
                <input v-model="editedMe.first_name" type="text" class="form-control">
              </template>
            </FormField>

            <FormField :schema="schema.last_name" :error="validationErrors ? validationErrors.last_name : null" class="col">
              <template #default>
                <input v-model="editedMe.last_name" type="text" class="form-control">
              </template>
            </FormField>
          </div>

          <div class="row">
            <FormField :schema="schema.email" :error="validationErrors ? validationErrors.email : null" class="col">
              <template #default>
                <input v-model="editedMe.email" type="text" class="form-control">
              </template>
            </FormField>

            <FormField :schema="schema.birthdate" :error="validationErrors ? validationErrors.birthdate : null" class="col">
              <template #default>
                <input v-model="editedMe.birthdate" type="date" class="form-control">
              </template>
            </FormField>
          </div>

          <FormField
            :schema="schema.languages"
            :error="validationErrors?.languages">
            <div v-for="(language, index) in ['fr', 'nl', 'en']" :key="index" class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                :checked="editedMe.languages && editedMe.languages.includes(language)"
                @change="onLanguageSelected(language, $event.target.checked)">
              <label class="form-check-label">{{ language }}</label>
            </div>
          </FormField>          
            
          <FormField
            :schema="schema.receive_email_for_intervention_rdv_set"
            :errors="validationErrors?.receive_email_for_intervention_rdv_set"
            hideTitle>
            <template #default>
              <div class="form-check">
                <input
                  v-model="editedMe.receive_email_for_intervention_rdv_set"
                  class="form-check-input"
                  type="checkbox">
                <label class="form-check-label">
                  {{ schema.receive_email_for_intervention_rdv_set.verbose_name }}
                </label>
              </div>
            </template>
          </FormField>

          <FormField :schema="schema.google_calendar_id" :error="validationErrors ? validationErrors.google_calendar_id : null">
            <template #default>
              <input v-model="editedMe.google_calendar_id" type="text" class="form-control">
            </template>
          </FormField>

          <FormField :schema="schema.bank_account_number" :error="validationErrors ? validationErrors.bank_account_number : null">
            <template #default>
              <input v-model="editedMe.bank_account_number" type="text" class="form-control">
            </template>
          </FormField>
        </div>

        <div class="card-footer justify-right">
          <button
            class="btn-secondary"
            :disabled="savingMe"
            @click="stopEditMe">
            Annuler
          </button>
          <button
            class="btn-secondary"
            :class="{ 'loading': savingMe }"
            :disabled="savingMe"
            @click="saveMe">
            Valider
          </button>
        </div>
      </div>

      <div v-else class="card">
        <div class="card-header">
          <span class="title" style="margin-right: 5px;">
            Mes informations
          </span>
          <button class="small-tool-btn" @click="startEditMe">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div class="card-body">
          <div class="info-grid-layout">
            <div>Prénom</div>
            <div>{{ me.first_name }}</div>
            <div>Nom</div>
            <div>{{ me.last_name }}</div>
            <div>Email</div>
            <div>{{ me.email }}</div>
            <div>Date de naissance</div>
            <div>{{ me.birthdate }}</div>
            <div>Langues parlées</div>
            <div>{{ me.languages.join(', ') }}</div>
            <div>Recevoir les mails de RDV d'intervention</div>
            <div>
              <i v-if="me.receive_email_for_intervention_rdv_set" class="bi bi-check-circle-fill text-green" />
              <i v-else class="bi bi-x-circle-fill text-red" />
            </div>
            <div>Agenda Google</div>
            <div>
              <i v-if="me.google_calendar_id" class="bi bi-check-circle-fill text-green" />
              <i v-else class="bi bi-x-circle-fill text-red" />
            </div>
            <div>Numéro de compte bancaire</div>
            <div>
              <i v-if="me.bank_account_number" class="bi bi-check-circle-fill text-green" />
              <i v-else class="bi bi-x-circle-fill text-red" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="editPassword" class="card">
        <div class="card-header">
          <span class="title">Mot de passe</span>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label class="form-label">Ancien mot de passe</label>
            <input v-model="oldPassword" type="password" class="form-control" placeholder="********">
          </div>
          <div class="form-group">
            <label class="form-label">Nouveau mot de passe</label>
            <input v-model="newPassword1" type="password" class="form-control" placeholder="********">
            <span :class="{ 'text-red': !isNewPasswordLongEnough, 'text-green': isNewPasswordLongEnough }">
              Le mot de passe doit contenir au moins 8 caractères
            </span><br>
            <span :class="{ 'text-red': !doesNewpasswordContainsOneNumber, 'text-green': doesNewpasswordContainsOneNumber }">
              Le mot de passe doit contenir au moins 1 chiffre
            </span><br>
            <span :class="{ 'text-red': !doesNewpasswordContainsOneLowerCaseLetter, 'text-green': doesNewpasswordContainsOneLowerCaseLetter }">
              Le mot de passe doit contenir au moins 1 lettre minuscule
            </span><br>
            <span :class="{ 'text-red': !doesNewpasswordContainsOneUpperCaseLetter, 'text-green': doesNewpasswordContainsOneUpperCaseLetter }">
              Le mot de passe doit contenir au moins 1 lettre majuscule
            </span>
          </div>
          <div class="form-group">
            <label class="form-label">Nouveau de passe encore une fois</label>
            <input v-model="newPassword2" type="password" class="form-control" placeholder="********">
            <span v-if="doesPasswordCorrespond" class="text-green">
              Correspond
            </span>
            <span v-else class="text-red">
              Ne correspond pas
            </span>
          </div>
        </div>
        <div class="card-footer justify-right">
          <button class="btn-primary" :disabled="savingPassword" @click="stopEditPassword">
            Annuler
          </button>
          <button
            class="btn-primary"
            :disabled="!canSavePassword || savingPassword"
            :class="{ 'loading': savingPassword }"
            @click="savePassword">
            Enregistrer
          </button>
        </div>
      </div>
      <div v-else class="card">
        <div class="card-header">
          <span class="title" style="margin-right: 5px;">Mot de passe</span>
          <button class="small-tool-btn" @click="startEditPassword">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div class="card-body">
          <p>Mot de passe : <strong>********</strong></p>
        </div>
      </div>

      <AddressForm
        v-if="editAddress"
        ref="addressForm"
        v-model="editedAddress"
        :validationErrors="validationErrors?.address"
        @valid="addressFormIsValid = true"
        @invalid="addressFormIsValid = false" />

      <div class="card">
        <div class="card-header">
          <span class="title" style="margin-right: 5px;">Adresse</span>
          <button class="small-tool-btn" @click="startEditAddress">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div v-if="me.address" class="card-body">        
          <strong>{{ me.address.street_number }} {{ me.address.street }} {{ me.address.bp }}</strong><br>
          <strong>{{ me.address.zipcode }} {{ me.address.city }} {{ me.address.country_code }}</strong>
        </div>
        <div v-else class="card-body">
          Aucune adresse
        </div>
      </div>

      <div v-if="editActivities" class="card">
        <div class="card-header">
          <span class="title">Types de nuisibles</span>
        </div>
        <div class="card-body">
          <MultiActivityInput v-model="me.activity_ids" />
        </div>
        <div class="card-footer justify-right">
          <button class="btn-primary" :disabled="savingActivities" @click="stopEditActivities">
            Annuler
          </button>
          <button
            class="btn-primary"
            :disabled="savingActivities"
            :class="{ 'loading': savingActivities }"
            @click="saveActivities">
            Enregistrer
          </button>
        </div>
      </div>

      <div v-else class="card">
        <div class="card-header">
          <span class="title" style="margin-right: 5px;">Types de nuisibles</span>
          <button class="small-tool-btn" @click="startEditActivities">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div class="card-body">
          <p>Type de nuisibles: <strong v-if="activitiesHumanized">{{ activitiesHumanized }}</strong><span v-else><i>Aucun</i></span></p>
        </div>
      </div>
    </template>

    <template #rightBar>
      <div class="card">
        <div class="card-body">
          <p>Positions : <strong>{{ positions }}</strong></p>
          <p>Status : <strong>{{ me.status }}</strong></p>
          <p>Membre depuis : <strong>{{ $filters.dateStringToHumanizedDate(me.date_joined) }}</strong></p>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormField from "@/components/forms/FormField.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";
import { notifyError } from "@/notifications";

export default {
  name: "MyProviderProfile",
  components: {
    ObjectViewLayout,
    FormField,
    AddressForm,
    MultiActivityInput,
  },
  data() {
    return {
      me: null,
      schema: null,
      errorFetching: false,
      editMe: false,
      editedMe: null,
      savingMe: false,
      editPassword: false,
      oldPassword: null,
      newPassword1: null,
      newPassword2: null,
      savingPassword: false,
      editAddress: false,
      editedAddress: null,
      savingAddress: false,
      editActivities: false,
      editedActivityIds: null,
      savingActivities: false,
      validationErrors: null,
    };
  },
  computed: {
    positions() {
      const positions = [];
      
      if (this.me.is_staff) {
        positions.push("Admin");
      }

      if (this.me.is_technician) {
        positions.push("Technicien");
      }

      if (this.me.is_secretary) {
        positions.push("Secrétaire");
      }

      if (this.me.is_manager) {
        positions.push("Responsable");
      }

      return positions.join(", ");
    },
    canSavePassword() {
      return this.oldPassword != null && this.oldPassword.length > 0 && this.doesPasswordCorrespond && this.isNewPasswordLongEnough && this.doesNewpasswordContainsOneNumber && this.doesNewpasswordContainsOneLowerCaseLetter && this.doesNewpasswordContainsOneUpperCaseLetter;
    },
    doesPasswordCorrespond() {
      return this.newPassword1 == this.newPassword2;
    },
    isNewPasswordLongEnough() {
      return this.newPassword1 != null && this.newPassword1.length >= 8;
    },
    doesNewpasswordContainsOneNumber() {
      return this.newPassword1 != null && /\d/.test(this.newPassword1);
    },
    doesNewpasswordContainsOneLowerCaseLetter() {
      return this.newPassword1 != null && /[a-z]/.test(this.newPassword1);
    },
    doesNewpasswordContainsOneUpperCaseLetter() {
      return this.newPassword1 != null && /[A-Z]/.test(this.newPassword1);
    },
    activitiesHumanized() {
      if (this.me.activities) {
        const activities = [];
        
        for (const activity of this.me.activities) {
          activities.push(activity.name);
        }
        return activities.join(", ");
      }
      return null;
    },
  },
  mounted() {
    Promise.all([
      this.fetchMe(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchMe() {
      return this.$services.me.fetchFull()
        .then((response) => {
          this.me = response.data;
        });
    },
    fetchSchema() {
      return this.$services.user.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    startEditMe() {
      this.editedMe = {
        first_name: this.me.first_name,
        last_name: this.me.last_name,
        email: this.me.email,
        birthdate: this.me.birthdate,
        receive_email_for_intervention_rdv_set: this.me.receive_email_for_intervention_rdv_set,
        google_calendar_id: this.me.google_calendar_id,
        bank_account_number: this.me.bank_account_number,
        languages: this.me.languages,
      };
      this.editMe = true;
    },
    onLanguageSelected(language, selected) {
      if (selected) {
        if (this.editedMe.languages == null) {
          this.editedMe.languages = [];
        }

        if (!this.editedMe.languages.includes(language)) {
          this.editedMe.languages.push(language);
        }

      } else {
        if (this.editedMe.languages != null) {
          const index = this.editedMe.languages.indexOf(language);
          if (index > -1) { // only splice array when item is found
            this.editedMe.languages.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    },
    stopEditMe() {
      this.editMe = false;
      this.editedMe = null;
    },
    startEditPassword() {
      this.editPassword = true;
    },
    stopEditPassword() {
      this.editPassword = false;
      this.oldPassword = null;
      this.newPassword1 = null;
      this.newPassword2 = null;
    },

    // Edit activities

    startEditActivities() {
      this.editedActivityIds = this.me.activity_ids;
      this.editActivities = true;
    },
    stopEditActivities() {
      this.editActivities = false;
    },
    saveActivities() {
      this.savingActivities = true;
      this.validationErrors = null;

      this.$services.me.patch(
        {
          activity_ids: this.me.activity_ids,
        },
      )
        .then((response) => {
          this.me = response.data;
          this.editActivities = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }

        }).finally(() => {
          this.savingActivities = false;
        });
    },

    // Edit me

    saveMe() {
      this.savingMe = true;
      this.validationErrors = null;

      this.$services.me.patch(this.editedMe)
        .then((response) => {
          this.me = response.data;
          this.stopEditMe();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }

        }).finally(() => {
          this.savingMe = false;
        });
    },
    
    savePassword() {
      this.savingPassword = true;

      this.$services.me.changePassword(this.oldPassword, this.newPassword1)
        .finally(() => {
          this.savingPassword = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>

  .card {
    margin: 5px 0px;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

</style>