import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess } from "@/notifications";
import { AxiosResponse } from "axios";

class StorageApiService extends ModelApiService {
  constructor() {
    super("/storages/", "stockage");
  }

  addProductUnits(id: number, productId: number, nbUnits: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/add_product_units/",
        {
          product_id: productId,
          nb_unit: nbUnits,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Unités ajoutées");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  addCart(id: number, cart: any) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/add_cart/",
        cart,
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Panier ajouté");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  removeProductUnits(id: number, productId: number, nbUnits: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/remove_product_units/",
        {
          product_id: productId,
          nb_unit: nbUnits,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Unités retirées");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  removeCart(id: number, cart: any) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/remove_cart/",
        cart,
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Panier retiré");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  moveProductUnits(id: number, dstStorageId: number, productId: number, nbUnits: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/move_product_units/",
        {
          product_id: productId,
          nb_unit: nbUnits,
          dst_storage_id: dstStorageId,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Unités déplacées");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  moveCart(id: number, dstStorageId: number, cart: any) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/move_cart/",
        {
          cart: cart,
          dst_storage_id: dstStorageId,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Panier déplacé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

}

export default StorageApiService;