<template>
  <ObjectCustomerCard :customer="customer" :onSave="onSave" />
</template>

<script>

import ObjectCustomerCard from "@/components/cards/customerCards/ObjectCustomerCard.vue";

export default {
  name: "ContractCustomerCard",
  components: {
    ObjectCustomerCard,
  },
  props: {
    modelValue: { // The contract
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    customer() {
      return this.modelValue.customer;
    },
  },
  methods: {
    onSave(customer) {

      return new Promise((resolve, reject) => {
        const body = {
          customer_id: customer.id,
        };

        this.$services.contract.patch(
          this.modelValue.id,
          body,

        ).then((response) => {
          this.$emit("update:modelValue", {
            ...this.modelValue,
            customer: response.data.customer,
            customer_id: response.data.customer_id,
          });
          resolve();
          
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  
};
</script>
