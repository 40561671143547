<template>
  <LoaderView v-if="schema == null" />
  <div v-else>
    <div class="row">
      <FormField
        :schema="schema.title"
        :errors="validationErrors?.title"
        class="col-sm"
        style="min-width: 100px;">
        <select
          v-model="internalContact.title"
          class="form-select"
          :disabled="enabledFields != null && !enabledFields.includes('title')">
          <option value="mme">
            Madame
          </option>
          <option value="mr">
            Monsieur
          </option>
          <option value="mx">
            Neutre
          </option>
        </select>
      </FormField>

      <FormField
        :schema="schema.last_name"
        :errors="validationErrors?.last_name"
        class="col-sm"
        style="min-width: 100px;">
        <template #default>
          <input v-model="internalContact.last_name" type="text" class="form-control" :disabled="enabledFields != null && !enabledFields.includes('last_name')">
        </template>
      </FormField>

      <FormField
        :schema="schema.first_name"
        :errors="validationErrors?.first_name"
        style="min-width: 100px;"
        class="col-sm">
        <template #default>
          <input v-model="internalContact.first_name" type="text" class="form-control" :disabled="enabledFields != null && !enabledFields.includes('first_name')">
        </template>
      </FormField>
    </div>

    <div class="row">
      <FormField
        :schema="schema.company_vat"
        :errors="validationErrors?.company_vat"
        class="col-sm-3"
        style="min-width: 100px;">
        <template #default>
          <input
            v-model="internalContact.company_vat"
            :class="{ 'valid': vatNumberIsValid, 'invalid': vatNumberIsInvalid }"
            type="text"
            class="form-control"
            :disabled="enabledFields != null && !enabledFields.includes('company_vat')"
            @blur="checkCompanyVATDoNotExists(); validateVatNumber();">
          <Loader v-if="validatingVatNumber" />
          <small
            v-if="existingCustomerWithSaveCompanyVAT"
            class="form-text text-red">
            <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSaveCompanyVAT.id)">Ce client</button> existes déjà avec le même numéro de TVA
          </small>
          <small
            v-if="vatNumberValidationResult == false"
            class="form-text text-red">
            Numéro de TVA invalide
          </small>
          <small
            v-if="companyNameFound"
            class="form-text">
            <strong>Société correspondante</strong>
            <br>
            {{ companyNameFound }}
            <br>
            {{ companyAddressFound }} {{ companyCountryCodeFound }}
          </small>
        </template>
      </FormField>

      <FormField
        :schema="schema.company_name"
        :errors="validationErrors?.company_name"
        class="col-sm-3"
        style="min-width: 100px;">
        <template #default>
          <input
            v-model="internalContact.company_name"
            type="text"
            class="form-control"
            :disabled="enabledFields != null && !enabledFields.includes('company_name')"
            @blur="checkCompanyNameDoNotExists">
          <small
            v-if="existingCustomerWithSaveCompanyName"
            class="form-text text-red">
            <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSaveCompanyName.id)">Ce client</button> existes déjà avec le même nom de société
          </small>
        </template>
      </FormField>
    </div>

    <div class="row">
      <FormField
        :schema="schema.phone_number"
        :errors="validationErrors?.phone_number"
        class="col-sm"
        style="min-width: 200px;">
        <template #default>
          <PhoneNumber v-model="internalContact.phone_number" :disabled="enabledFields != null && !enabledFields.includes('phone_number')" @blur="checkPhoneNumberDoNotExists" />
          <small
            v-if="existingCustomerWithSavePhoneNumber"
            class="form-text text-red">
            <button class="btn-link" @click="openCustomerPageInNewTab(existingCustomerWithSavePhoneNumber.id)">Ce client</button> existes déjà avec le même numéro de téléphone
          </small>
        </template>
      </FormField>

      <FormField
        :schema="schema.email"
        :errors="validationErrors?.email"
        class="col-sm"
        style="min-width: 100px;">
        <template #default>
          <input v-model="internalContact.email" type="text" class="form-control" :disabled="enabledFields != null && !enabledFields.includes('email')">
        </template>
      </FormField>

      <FormField
        :schema="schema.language"
        :errors="validationErrors?.language"
        class="col-sm"
        style="min-width: 100px;">
        <template #default>
          <select v-model="internalContact.language" class="form-select" :disabled="enabledFields != null && !enabledFields.includes('language')">
            <option value="fr">
              Français
            </option>
            <option value="nl">
              Néerlandais
            </option>
          </select>
        </template>
      </FormField>
    </div>
  </div>
</template>

<script>

// TODO move duplicated customer validation in CustomerForm component

import { api } from "@/api";
import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import PhoneNumber from "@/components/vueComponents/input/PhoneNumber.vue";
import Loader from "@/components/Loader.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ContactForm",
  components: {
    FormField,
    LoaderView,
    PhoneNumber,
    Loader,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      required: false,
      default: null,
    },
    checkExistingCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabledFields: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ["valid", "invalid", "update:modelValue"],
  data() {
    return {
      internalContact: this.modelValue,
      schema: null,
      existingCustomerWithSaveCompanyName: null,
      existingCustomerWithSaveCompanyVAT: null,
      existingCustomerWithSavePhoneNumber: null,
      vatNumberValidationResult: undefined,
      companyNameFound: null,
      companyAddressFound: null,
      companyCountryCodeFound: null,
      validatingVatNumber: false,
    };
  },
  computed: {
    vatNumberIsValid() {
      return this.internalContact.company_vat != null && this.internalContact.company_vat.length > 0 && this.vatNumberValidationResult == true;
    },
    vatNumberIsInvalid() {
      return this.internalContact.company_vat != null && this.internalContact.company_vat.length > 0 && this.vatNumberValidationResult == false;
    },
    formIsValid() {
      return this.internalContact.last_name != null
      && this.internalContact.language != null
      && this.internalContact.phone_number != null
      && this.existingCustomerWithSaveCompanyName == null
      && this.existingCustomerWithSaveCompanyVAT == null
      && this.existingCustomerWithSavePhoneNumber == null;
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalContact = newVal;
    },
    internalContact(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
    },
    formIsValid(newVal) {
      if (newVal) {
        this.$emit("valid");
      } else {
        this.$emit("invalid");
      }
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.contact.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    checkCompanyNameDoNotExists() {
      if (!this.checkExistingCustomer) {
        return;
      }

      if (!this.internalContact.company_name) {
        this.existingCustomerWithSaveCompanyName = null;
        return;
      }

      this.$services.customer.search({company_name: this.internalContact.company_name})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSaveCompanyName = response.data.results[0];
          } else {
            this.existingCustomerWithSaveCompanyName = null;
          }
        });

    },
    checkCompanyVATDoNotExists() {
      if (!this.checkExistingCustomer) {
        return;
      }

      if (!this.internalContact.company_vat) {
        this.existingCustomerWithSaveCompanyVAT = null;
        return;
      }

      this.$services.customer.search({company_vat: this.internalContact.company_vat})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSaveCompanyVAT = response.data.results[0];
          } else {
            this.existingCustomerWithSaveCompanyVAT = null;
          }
        });
    },
    checkPhoneNumberDoNotExists() {
      if (!this.checkExistingCustomer || this.internalContact.phone_number == null || this.internalContact.phone_number.length == 0) {
        this.existingCustomerWithSavePhoneNumber = null;
        return;
      }

      if (!this.internalContact.phone_number) {
        this.existingCustomerWithSavePhoneNumber = null;
        return;
      }

      this.$services.customer.search({phone_number: this.internalContact.phone_number})
        .then((response) => {
          if (response.data.nb_results > 0) {
            this.existingCustomerWithSavePhoneNumber = response.data.results[0];
          } else {
            this.existingCustomerWithSavePhoneNumber = null;
          }
        });
    },
    validateVatNumber() {
      if (!this.internalContact.company_vat) {
        this.vatNumberValidationResult = undefined;
        return;
      }

      this.validatingVatNumber = true;

      api.post("validate-vat-number/", {
        vat_number: this.internalContact.company_vat,
      })
        .then((response) => {
          this.vatNumberValidationResult = response.data.is_valid;

          this.companyNameFound = response.data.business_name;
          this.companyAddressFound = response.data.business_address;
          this.companyCountryCodeFound = response.data.business_country_code;

          if (!this.internalContact.company_name) {
            this.internalContact.company_name = this.companyNameFound;
          }

        })
        .catch((error) => {
          this.vatNumberValidationResult = undefined;
          if (error.response?.status == 429 || error.response?.status == 503) {
            notifyError("Impossible de vérifier le numéro de TVA pour l'instant. Réessayez plus tard.");
          } else {
            notifyError("Une erreur est survenue lors de la vérification du numéro de TVA");
            throw error;
          }
          
        })
        .finally(() => {
          this.validatingVatNumber = false;
        });
      
    },
    openCustomerPageInNewTab(customer_id) {
      const routeData = this.$router.resolve({name: "customer", params: {id: customer_id}});
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>

</style>