<template>
  <div>
    <ObjectViewLayout
      title="Nouvel article"
      :loading="!object || !schema">
      <template #default>
        <FormSection>
          <template #default>
            <FormField :schema="schema.name" :error="validationError ? validationError.name : null" class="form-group">
              <template #default>
                <input v-model="object.name" type="text" class="form-control">
              </template>
            </FormField>

            <FormField :schema="schema.description" :error="validationError ? validationError.description : null" class="form-group">
              <template #default>
                <input v-model="object.description" type="text" class="form-control">
              </template>
            </FormField>

            <div class="row">
              <FormField :schema="schema.sku" :error="validationError ? validationError.sku : null" class="col form-group">
                <template #default>
                  <input v-model="object.sku" type="text" class="form-control">
                </template>
              </FormField>

              <FormField :schema="schema.category" :error="validationError ? validationError.category : null" class="col form-group">
                <template #default>
                  <select v-model="object.category" class="form-select">
                    <option
                      v-for="(option, index) in schema.category.choices"
                      :key="index"
                      :value="option[0]">
                      {{ option[1] }}
                    </option>
                  </select>
                </template>
              </FormField>

              <FormField :schema="schema.status" :error="validationError ? validationError.status : null" class="col form-group">
                <template #default>
                  <select v-model="object.status" class="form-select">
                    <option value="draft">
                      Brouillon
                    </option>
                    <option value="published">
                      Publié
                    </option>
                    <option value="deleted">
                      Supprimé
                    </option>
                  </select>
                </template>
              </FormField>

              <FormField :schema="schema.unit" :error="validationError ? validationError.unit : null" class="col form-group">
                <template #default>
                  <select v-model="object.unit" class="form-select">
                    <option
                      v-for="(option, index) in schema.unit.choices"
                      :key="index"
                      :value="option[0]">
                      {{ option[1] }}
                    </option>
                  </select>
                </template>
              </FormField>
            </div>

            <div class="row">
              <FormField :schema="schema.brand" :error="validationError ? validationError.brand : null" class="col form-group">
                <template #default>
                  <input v-model="object.brand" type="text" class="form-control">
                </template>
              </FormField>

              <FormField :schema="schema.model" :error="validationError ? validationError.model : null" class="col form-group">
                <template #default>
                  <input v-model="object.model" type="text" class="form-control">
                </template>
              </FormField>
            </div>

            <FormField :schema="schema.restricted_circuit_product" :error="validationError ? validationError.restricted_circuit_product : null" class="form-group">
              <template #default>
                <div class="form-check">
                  <input
                    v-model="schema.restricted_circuit_product"
                    class="form-check-input"
                    type="checkbox">
                  <label class="form-check-label">
                    {{ schema.restricted_circuit_product.verbose_name }}
                  </label>
                </div>
              </template>
            </FormField>
          </template>
        </FormSection>

        <FormSection title="Fournisseur">
          <template #default>
            <FormField :schema="schema.provider" :error="validationError ? validationError.provider : null" class="form-group">
              <template #default>
                <input v-model="object.provider" type="text" class="form-control">
              </template>
            </FormField>

            <FormField :schema="schema.provider_product_name" :error="validationError ? validationError.provider_product_name : null" class="form-group">
              <template #default>
                <input v-model="object.provider_product_name" type="text" class="form-control">
              </template>
            </FormField>
          </template>
        </FormSection>

        <FormSection title="Achat" collapsable>
          <template #default>
            <div class="row">
              <FormField :schema="schema.purchase_price_per_unit" :error="validationError ? validationError.purchase_price_per_unit : null" class="col form-group">
                <template #default>
                  <input v-model="object.purchase_price_per_unit" type="number" class="form-control">
                </template>
              </FormField>

              <FormField :schema="schema.reorder_threshold" :error="validationError ? validationError.reorder_threshold : null" class="col form-group">
                <template #default>
                  <input v-model="object.reorder_threshold" type="number" class="form-control">
                </template>
              </FormField>

              <FormField :schema="schema.reorder_unit_count" :error="validationError ? validationError.reorder_unit_count : null" class="col form-group">
                <template #default>
                  <input v-model="object.reorder_unit_count" type="number" class="form-control">
                </template>
              </FormField>
            </div>
          </template>
        </FormSection>

        <FormSection title="Vente" collapsable>
          <template #default>
            <FormField :schema="schema.public_name" :error="validationError ? validationError.public_name : null" class="form-group">
              <template #default>
                <input v-model="object.public_name" type="text" class="form-control">
              </template>
            </FormField>

            <FormField :schema="schema.public_description" :error="validationError ? validationError.public_description : null" class="form-group">
              <template #default>
                <input v-model="object.public_description" type="text" class="form-control">
              </template>
            </FormField>

            <div class="row">
              <FormField :schema="schema.sale_price_per_unit" :error="validationError ? validationError.sale_price_per_unit : null" class="col form-group">
                <template #default>
                  <input v-model="object.sale_price_per_unit" type="number" class="form-control">
                </template>
              </FormField>
            </div>
          </template>
        </FormSection>

      
        <button class="btn-outline-secondary" style="margin-right: 10px;" @click="cancel">
          Annuler
        </button>
        <button class="btn-secondary" @click="submit">
          <Loader v-if="submitting" />
          Valider
        </button>
      </template>
    </ObjectViewLayout>
  </div>
</template>

<script>

import { api, uri } from "@/api";
import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormSection from "@/components/layout/FormSection.vue";
import Loader from "@/components/Loader.vue";
import FormField from "@/components/forms/FormField.vue";

export default {
  name: "ItemCreationView",
  components: {
    ObjectViewLayout,
    FormSection,
    Loader,
    FormField,
  },
  data() {
    return {
      object: null,
      schema: null,
      validationError: null,
      submitting: false,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      return api.get(uri.itemsSchema())
        .then((response) => {
          this.schema = response.data;
          this.object = {};
          for (const [key, value] of Object.entries(this.schema)) {
            if (value.default) {
              this.object[key] = value.default;
            }
          }
        });
    },
    submit() {
      this.submitting = true;
      this.validationError = null;

      let submittedObject = JSON.parse(JSON.stringify(this.object));

      api.post(uri.item(), submittedObject)
        .then((response) => {
          this.$router.push({ name: "item", params: { id: response.data.id } });

        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationError = error.response.data;
          } else {    
            throw error;
          }

        }).finally(() => {
          this.submitting = false;
        });
    },
    cancel() {
      this.$router.push({ name: "product-list" });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>