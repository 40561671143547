<template>
  <Modal ref="modal">
    <template #default>
      <div class="form-group">
        <label class="form-label">
          Nom
        </label>
        <input
          v-model="name"
          type="text"
          placeholder="Nom"
          class="form-control">
      </div>

      <div class="form-group">
        <label class="form-label">
          Personne en charge
        </label>
        <SelectObject
          type="user"
          :selectedValue="inCharge ? $filters.fullname(inCharge) : null"
          @select="onInChargeSelected" />
      </div>
    </template>

    <template #buttons>
      <button
        class="btn-outline-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': submitting }"
        :disabled="submitting"
        style="margin-left: 5px;"
        @click="submit">
        Valider
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "EditStorageModal",
  components: {
    Modal,
    SelectObject,
  },
  props: {
    storage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: this.storage.name,
      inCharge: this.storage.in_charge,
      submitting: false,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onInChargeSelected(user) {
      this.inCharge = user;
    },
    submit() {
      this.submitting = true;
      this.$services.storage.patch(
        this.storage.id,
        {
          name: this.name,
          in_charge_id: this.inCharge ? this.inCharge.id : null,
        },
      ).then((response) => {
        this.$store.commit("storageList/updateElement", response.data);
        this.hide();
      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>