<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            :placeholder="'Numéro, nom, email, téléphone...'"
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Status
          </div>
          <select
            v-model="refundState"
            class="form-select">
            <option :value="null">
              Tous
            </option>
            <option value="refunded">
              Déjà remboursé
            </option>
            <option value="not_refund">
              En attente de remboursement
            </option>
          </select>
        </div>

        <div class="filter-item">
          <ResetFiltersButton :store="refundListStore" />
        </div>
      </div>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link
        :to="{ name: 'refund-list' }"
        class="cat-list-item"
        :class="{ 'active': !$route.query.journalId }">
        Tous
        <span
          v-if="refundListStore.getTotal('all') != null">
          ({{ refundListStore.getTotal('all') }})
        </span>
      </router-link>
    </div>

    <router-view style="min-height: 100%;" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import { useRefundListStore } from "@/stores/refundList";
import { useJournalsStore } from "@/stores/journals";

export default {
  name: "RefundListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
  },
  data() {
    return {
      refundListStore: useRefundListStore(),
      journalsStore: useJournalsStore(),
    };
  },
  computed: {
    query: {
      get() {
        return this.refundListStore.getFilter("q");
      },
      set(val) {
        this.refundListStore.addFilter("q", val);
        this.refundListStore.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.refundListStore.getFilter("created_at_after"),
          this.refundListStore.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.refundListStore.addFilter("created_at_after", val[0]);
        this.refundListStore.addFilter("created_at_before", val[1]);
        this.refundListStore.refresh();
      },
    },
    sortBy: {
      get() {
        return this.refundListStore.getFilter("sort_by");
      },
      set(val) {
        this.refundListStore.addFilter("sort_by", val);
        this.refundListStore.refresh();
      },
    },
    refundState: {
      get() {
        return this.refundListStore.getFilter("refund_state");
      },
      set(val) {
        this.refundListStore.addFilter("refund_state", val);
        this.refundListStore.refresh();
      },
    },
    journals() {
      return this.journalsStore.getJournals;
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
