import { RouteConfig } from "vue-router";

// Customers
import CustomerListLayout from "@/views/customers/CustomerListLayout.vue";
import CustomerObjectView from "@/views/customers/CustomerObjectView.vue";
import CustomerObjectCreationView from "@/views/customers/CustomerObjectCreationView.vue";
import CustomersFusionView from "@/views/customers/CustomersFusionView.vue";

// Customer calls
import CustomerCallListLayout from "@/views/customerCalls/CustomerCallListLayout.vue";
import CustomerCallObjectView from "@/views/customerCalls/CustomerCallObjectView.vue";
import CustomerCallObjectCreationView from "@/views/customerCalls/CustomerCallObjectCreationView.vue";

// Contract
import ContractListLayout from "@/views/contracts/ContractListLayout.vue";
import ContractObjectCreationView from "@/views/contracts/ContractObjectCreationView.vue";
import ContractObjectView from "@/views/contracts/ContractObjectView.vue";


const customerRoutes: RouteConfig[] = [

  //// Customers

  {
    path: "/clients",
    component: CustomerListLayout,
    name: "customer-list",
  },
  {
    path: "/clients/nouveau",
    component: CustomerObjectCreationView,
    name: "create-customer",
  },
  {
    path: "/clients/:id",
    component: CustomerObjectView,
    name: "customer",
  },
  {
    path: "/clients/:id/fusionner-avec/:id2",
    component: CustomersFusionView,
    name: "customer-fusion",
  },

  //// Customer calls

  {
    path: "/appels-clients",
    component: CustomerCallListLayout,
    name: "customer-call-list",
  },
  {
    path: "/appels-clients/nouveau",
    component: CustomerCallObjectCreationView,
    name: "create-customer-call",
  },
  {
    path: "/appels-clients/:id",
    component: CustomerCallObjectView,
    name: "customer-call",
  },

  //// Contracts

  {
    path: "/contrats",
    component: ContractListLayout,
    name: "contract-list",
  },
  {
    path: "/contrats/nouveau",
    component: ContractObjectCreationView,
    name: "create-contract",
    props: (route: any) => ({
      mode: "quote",
      defaultSelectedCustomerId: route.query.customer,
    }),
  },
  {
    path: "/contrats/:id",
    component: ContractObjectView,
    name: "contract",
  },
];

export default customerRoutes;
