<template>
  <GBaseRow>
    <template #icon>
      <i class="bi bi-cash-stack" style="font-size: 2rem;" />
    </template>

    <template #title>
      <span class="text-bigger">{{ payment.amount / 100 }}€</span>
      <br>
      <span class="text-smaller">{{ $filters.dateStringToHumanizedDate(payment.created_at, "short") }}</span>
    </template>

    <td>
      <router-link :to="{ name: 'invoice', params: { 'id': payment.invoice.id } }" class="btn-link">
        {{ payment.invoice.ref }}
      </router-link><br>
      <span class="text-smaller">
        Du {{ $filters.dateStringToHumanizedDate(payment.invoice.created_at, "short") }}<br>
        Pour {{ $filters.printCustomer(payment.invoice.customer) }}<br>
        {{ payment.invoice.customer.billing_zipcode }} {{ payment.invoice.customer.billing_city }} {{ payment.invoice.customer.billing_country_code }}
      </span>
    </td>
    <td>
      {{ $filters.fullname(payment.receiver) }} 
    </td>
    <td>
      <span v-if="payment.invoice.odoo_payment_state == 'paid'" class="text-green">
        Paiement réconsilié dans Odoo
      </span>
      <span v-else-if="payment.transfered_by_wire_at" class="text-green">
        Argent indiqué comme remboursé par virement le {{ $filters.dateStringToHumanizedDate(payment.transfered_by_wire_at) }}
      </span>
      <span v-else-if="payment.transfered_to_register_at" class="text-green">
        Argent mis dans la caisse le {{ $filters.dateStringToHumanizedDate(payment.transfered_to_register_at) }}
      </span>
      <span v-else class="text-yellow">
        Argent toujours dans le porte-monnaie de {{ $filters.fullname(payment.receiver) }}
      </span>
    </td>
    <td>
      <i v-if="payment.odoo_id" class="bi bi-check-circle-fill text-green tooltip">
        <div class="tooltip-text">
          Push le {{ $filters.dateStringToHumanizedDate(payment.odoo_created_at, "short") }} dans Odoo
        </div>
      </i>
      <i v-else-if="payment.odoo_error_message" class="bi bi-x-circle-fill text-red tooltip">
        <div class="tooltip-text">
          <p>{{ payment.odoo_error_message }}</p>
          <p>{{ payment.push_to_odoo_attempt_left }} tentatives restantes</p>
        </div>
      </i>
    </td>

    <template #toolButtons>
      <CashPaymentToolButton v-model="payment" />
    </template>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";
import CashPaymentToolButton from "@/components/toolbuttons/CashPaymentToolButton.vue";

export default {
  name: "CashPaymentRow",
  components: {
    GBaseRow,
    CashPaymentToolButton,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    payment: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>


</style>