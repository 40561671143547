<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Gestion interne</span>
      <button v-if="editMode" class="btn btn-link" @click="cancel">
        (annuler)
      </button>
      <button v-else class="btn btn-link" @click="edit">
        (editer)
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <FormField
        :schema="schema.barcode"
        :error="validationErrors?.barcode">
        <template #default>
          <input v-model="editedProduct.barcode" type="text" class="form-control">
        </template>
      </FormField>
      
      <FormField :schema="schema.name" :error="validationErrors?.name">
        <template #default>
          <input v-model="editedProduct.name" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.description" :error="validationErrors?.description">
        <template #default>
          <textarea v-model="editedProduct.description" class="form-control" />
        </template>
      </FormField>

      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <p>Nom : <strong>{{ modelValue.name }}</strong></p>
      <div class="row">
        <div class="col">
          <p>Description :</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="modelValue.description" />
        </div>
      </div>
      <p>SKU : <strong>{{ modelValue.sku }}</strong></p>
      <p>EAN : <strong>{{ modelValue.ean }}</strong></p>
      <p>Code bar : <strong>{{ modelValue.barcode }}</strong></p>
      <p>Type de produit : <strong>{{ modelValue.type }}</strong></p>
      <p>Status dans l'ERP : <strong>{{ modelValue.status }}</strong></p>
      <p>Activités : <strong>{{ modelValue.activities }}</strong></p>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ProductInternalInformationCard",
  components: {
    FormField,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editMode: false,
      editedProduct: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      this.editedProduct = {
        barcode: this.modelValue.barcode,
        name: this.modelValue.name,
        description: this.modelValue.description,
      };
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedProduct = null;
    },
    submit() {
      this.submitting = true;

      this.$services.product.patch(this.modelValue.id, this.editedProduct)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        });
    },
  },
};

</script>