<template>
  <div
    v-if="editMode"
    class="invoice-payment-card">
    <FormField
      title="Type de paiement">
      <template #default>
        <select
          v-model="editedType"
          class="form-select">
          <option
            v-for="(choice, index) in [['cash', 'liquide'], ['wire', 'virement'], ['bancontact', 'bancontact'], ['payment_on_another_plateform', 'en ligne']]"
            :key="index"
            :disabled="(choice[0] == 'wire' && !meIsAdmin && !meIsManager) || (choice[0] == 'payment_on_another_plateform' && !meIsAdmin)"
            :value="choice[0]">
            {{ choice[1] }}
          </option>
        </select>
      </template>
    </FormField>

    <FormField
      title="Montant">
      <template #default>
        <input v-model="editedAmount" type="number" class="form-control">
      </template>
    </FormField>

    <FormField
      v-if="editedType == 'cash'"
      title="Collecteur">
      <SelectObject
        type="user"
        :selectedValue="editedReceiver ? $filters.fullname(editedReceiver) : null"
        @select="onReceiverSelected" />
    </FormField>

    <div class="flow-spaced-container">
      <button :disabled="submitting" class="btn btn-secondary" @click="stopEditing">
        Annuler
      </button>
      <button
        :disabled="submitting || !canSubmit"
        :class="{ 'loading': submitting }"
        class="btn btn-primary"
        @click="submit">
        Enregistrer
      </button>
    </div>
  </div>
  
  <div
    v-else
    class="invoice-payment-card">
    {{ modelValue.amount / 100 }}€ payé {{ translatePaymentType(modelValue.type) }} le {{ $filters.dateStringToHumanizedDate(modelValue.created_at, "short") }} 
    <div style="display: flex; justify-content: flex-end;">
      <button
        v-if="modelValue.type == 'cash' && meIsAdmin"
        class="small-tool-btn"
        :disabled="!canSendToRegister"
        @click="sendToRegister">
        <i class="bi bi-r-square" />
      </button>
      <button
        class="small-tool-btn"
        :disabled="!canEdit"
        @click="startEditing">
        <i class="bi bi-pencil-square" />
      </button>
      <button
        class="small-tool-btn"
        :disabled="!canDelete"
        @click="deletePayment">
        <i class="bi bi-trash" />
      </button>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "InvoicePaymentCard",
  components: {
    FormField,
    SelectObject,
  },
  props: {
    modelValue: { // The payment
      type: Object,
      required: true,
      validator: function (value) {
        for (const field of [
          "id",
          "created_at",
          "amount",
          "type",
        ]) {
          if (!Object.prototype.hasOwnProperty.call(value, field)) {
            console.warn(`Missing field '${field}' in prop modelValue of component InvoicePaymentCard`);
            return false;
          }
        }
        return true;
      },
    },
  },
  emits: ["update:modelValue", "delete"],
  data() {
    return {
      editedAmount: null,
      editedType: null,
      editedReceiver: null,
      editMode: false,
      submitting: false,
    };
  },
  computed: {
    canEdit() {
      return !this.modelValue.odoo_id && !this.modelValue.transfered_by_wire_at;
    },
    canDelete() {
      return !this.modelValue.odoo_id && !this.modelValue.transfered_by_wire_at;
    },
    canSendToRegister() {
      return !this.modelValue.odoo_id && !this.modelValue.transfered_by_wire_at;
    },
    canSubmit() {
      return this.editedAmount > 0 && this.editedType != null && this.modelValue.id != null;
    },
    meIsAdmin() {
      const meStore = useMeStore();
      return meStore.meIsAdmin;
    },
    meIsManager() {
      const meStore = useMeStore();
      return meStore.meIsManager;
    },
  },
  methods: {
    startEditing() {
      this.editedAmount = this.modelValue.amount / 100;
      this.editedType = this.modelValue.type;
      this.editedReceiver = this.modelValue.receiver;
      this.editMode = true;
    },
    stopEditing() {
      this.editMode = false;
    },    
    onReceiverSelected(user) {
      this.editedReceiver = user;
    },
    submit() {
      this.submitting= true;

      const body = {
        amount: Math.round(this.editedAmount * 100),
        type: this.editedType,
      };

      if (this.editedReceiver) {
        body.receiver_id = this.editedReceiver.id;
      }

      this.$services.payment.patch(this.modelValue.type, this.modelValue.id, body)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
          this.stopEditing();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    deletePayment() {
      const answer = confirm("Êtes-vous sûr de vouloir supprimer ce paiement ?");
      if (answer) {
        this.$services.payment.delete(this.modelValue.type, this.modelValue.id)
          .then(() => {
            this.$emit("delete");
          });
      }
    },
    sendToRegister() {
      const answer = confirm("Êtes-vous sûr de vouloir transférer l'argent de ce paiement dans la caisse ?");
      if (answer) {
        this.$services.payment.sendToRegister(this.modelValue.type, this.modelValue.id)
          .then((response) => {
            this.$emit(
              "update:modelValue",
              response.data,
            );
          });
      }
    },
    translatePaymentType(type) {
      switch (type) {
      case "cash":
        return "en espèces";
      case "wire":
        return "par virement";
      case "bancontact":
        return "par bancontact";
      case "payment_on_another_plateform":
        return "en ligne";
      case "stripe":
        return "par Stripe";
      default:
        return type;
      }

    },
  },
};

</script>


<style lang="scss" scoped>

  .invoice-payment-card {
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid black;
  }

</style>