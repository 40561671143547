<template>
  <div class="multi-user-week-planning">
    <MultiUserDayPlanning
      v-for="(weekDay, index) in dates"
      :key="index"
      :events="getEventsForDate(weekDay)"
      :date="weekDay"
      @dateSelected="onDateSelected(weekDay)" />
  </div>
</template>

<script setup lang="ts">

import { computed } from "vue";
import MultiUserDayPlanning from "@/components/calendar/MultiUserDayPlanning.vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  date: { type: String, required: true }, // Beginin of the week on monday
  events: { type: Array<any>, required: false, default: [] },
});

// Emits

// eslint-disable-next-line no-undef
const emits = defineEmits(["dateSelected"]);

// Computed

const dates = computed(
  function get() {
    const startDate = new Date(props.date);

    const dates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      const isoDate = date.toISOString().split("T")[0]; // Format ISO : yyyy-mm-dd
      dates.push(isoDate);
    }

    return dates;
  },
);

// Methods

function getEventsForDate(date: string) {
  return props.events.filter((event) => {
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);
    const filterDate = new Date(date);

    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();

    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();

    const filterYear = filterDate.getFullYear();
    const filterMonth = filterDate.getMonth();
    const filterDay = filterDate.getDate();

    return (
      startYear <= filterYear && startMonth <= filterMonth && startDay <= filterDay &&
      endYear >= filterYear && endMonth >= filterMonth && endDay >= filterDay
    );
  });
}

function onDateSelected(date: string) {
  emits("dateSelected", date);
}


</script>

<style lang="scss" scoped>

  .multi-user-week-planning {
    display: flex;
  }

</style>