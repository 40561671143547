<template>
  <Modal ref="modal">
    <h2>Ajouter des unités de {{ product.name }}</h2>
    <div class="form-inline-group">
      <label class="form-label">
        Nombre d'unités à ajouter
      </label>
      <input
        v-model="nbUnits"
        type="number"
        class="form-control"
        style="margin-left: 5px;">
    </div>
    <div class="form-group">
      <StorageSelect v-model="dstStorageId" />
    </div>

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
      
      <button
        class="btn-primary"
        :class="{ 'loading': submitting }"
        :disabled="submitting || nbUnits <= 0 || dstStorageId == null"
        style="margin-left: 5px;"
        @click="submit">
        Valider
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import StorageSelect from "@/components/selects/StorageSelect.vue";
import { useStoragesStore } from "@/stores/storages";

export default {
  name: "AddUnitsOfProductToStock",
  components: {
    Modal,
    StorageSelect,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    dstStorage: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      nbUnits: 0,
      selectedStorageId: null,
      submitting: false,
      storagesStore: useStoragesStore(),
    };
  },
  computed: {
    product: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("From AddUnitsOfProductToStock set product with unit_count = " + value.unit_count);
        this.$emit(
          "update:modelValue",
          value,
        );
      },
    },
    storages() {
      return this.storagesStore.getStorages;
    },
    defaultStorage() {
      if (!this.storages) return null;
      return this.storages.find(storage => storage.default);
    },
    dstStorageId: {
      get() {
        if (this.selectedStorageId) {
          return this.selectedStorageId;
        } else if (this.dstStorage) {
          return this.dstStorage.id;
        } else if (this.defaultStorage) {
          return this.defaultStorage.id;
        } else {
          return null;
        }
      },
      set(value) {
        this.selectedStorageId = value;
      },
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.submitting = true;

      this.$services.storage.addProductUnits(this.dstStorageId, this.product.id, this.nbUnits)
        .then((response) => {
          this.product = response.data.product;
          this.hide();
        }).finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>