<template>
  <div>
    <p v-if="!internalValue || internalValue.length == 0">
      <i>Aucune</i>
    </p>
    <p
      v-for="(intervention, index) in internalValue"
      :key="index">
      {{ intervention.ref_number }}
      <button
        class="btn-link"
        style="margin-left: 5px;"
        @click="removeIntervention(intervention)">
        Retirer
      </button>
    </p>
    <SelectObject
      placeholder="Ajouter une intervention"
      type="intervention"
      :filters="filters"
      @select="addIntervention" />
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "InterventionListForm",
  components: {
    SelectObject,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
    },
  },
  methods: {
    removeIntervention(intervention) {
      this.internalValue = this.internalValue.filter((el) => { return el.id != intervention.id; }); 
    },
    addIntervention(intervention) {
      this.internalValue.push(intervention);
    },
  },
};
</script>

<style>

</style>