import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";


class CreditNoteApiService extends ModelApiService {
  constructor() {
    super("/credit-notes/", "note de crédit");
  }

  fetchPdf(id: number) {
    return api.get(this.url + id + "/pdf/");
  }

}

export default CreditNoteApiService;