<template>
  <div class="loader-view">
    <div class="loader-icon">
      <loader />
    </div>
    <div style="margin-left: 10px;">
      {{ text }}
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";

export default {
  name: "LoaderView",
  components: {
    loader: Loader,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  margin: auto;

  .loader-icon {
    transform: scale(1.5);
  }
}
</style>
