import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useRefundListStore = buildPaginatedMultiListStore(
  "refundList",
  "/refunds/",
  "Aucun remboursement trouvé",
  true,
  (filters: object, subList: string | null) => {
    if (subList != "all") {
      filters.journal_id = subList;
    }

    return filters;
  },
);
