<template>
  <div class="card">
    <div class="card-header">
      <div class="title">
        Mes tâches
      </div>
    </div>
    <div class="card-body">
      <div class="vertical-spaced-container">
        <div style="display: flex">
          <button
            class="tool-btn"
            @click="gotoPreviousDay">
            <i class="bi bi-chevron-left" />
          </button>
          <div style="flex-grow: 1; display: flex; justify-content: center; align-items: center;">
            {{ $filters.dateToHumanizedDate(selectedDate) }}
          </div>
          <button
            class="tool-btn"
            @click="gotoNextDay">
            <i class="bi bi-chevron-right" />
          </button>
        </div>
        <div style="min-height: 200px; ">
          <LoaderView
            v-if="!tasks[toIsoDate(selectedDate)]" />
          <div v-else-if="tasks[selectedDate] == 'error'">
            <div class="alert alert-danger">
              Impossible de charger les tâches
            </div>
          </div>
          <div v-else-if="tasks[toIsoDate(selectedDate)].length == 0">
            <div class="alert alert-info">
              Aucune tâche pour cette date
            </div>
          </div>
          <div v-else class="vertical-spaced-container">
            <Task
              v-for="task in tasks[toIsoDate(selectedDate)]"
              :key="task.id"
              :modelValue="task"
              @update:modelValue="updateTask" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Task from "@/components/others/Task.vue";
import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "InterventionTasksPlanningCard",
  components: {
    Task,
    LoaderView,
  },
  data() {
    return {
      selectedDate: new Date(),
      tasks: {},
    };
  },
  watch: {
    selectedDate() {
      this.fetchTasksAtDate(this.selectedDate);
    },
  },
  mounted() {
    this.fetchTasksAtDate(this.selectedDate);
  },
  methods: {
    gotoPreviousDay() {
      const newDate = new Date(this.selectedDate);
      newDate.setDate(newDate.getDate() - 1);
      this.selectedDate = newDate;
    },
    gotoNextDay() {
      const newDate = new Date(this.selectedDate);
      newDate.setDate(newDate.getDate() + 1);
      this.selectedDate = newDate;
    },
    fetchTasksAtDate(date) {
      const isoDate = this.toIsoDate(date);
      
      this.$services.task.search({
        deadline: isoDate,
      }).then((response) => {
        this.tasks[isoDate] = response.data.results;

        return response.data.results;
      }).catch((error) => {
        this.tasks[isoDate] = "error";
        throw error;
      });
    },
    toIsoDate(date) {
      return date.toISOString().split("T")[0];
    },
    updateTask(task) {
      this.tasks[this.toIsoDate(this.selectedDate)] = this.tasks[this.toIsoDate(this.selectedDate)].map((t) => {
        if (t.id == task.id) {
          return task;
        }
        return t;
      });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>