<template>
  <Modal ref="modal" width="big">
    <div class="horizontal-spaced-container" style="overflow: auto;">
      <div
        v-for="invoice of invoices"
        :key="invoice.id">
        <InvoiceCompactCard
          :modelValue="invoice">
          <template #header>
            <button
              class="small-tool-btn tooltip"
              @click="unlinkInvoice(invoice)">
              <i class="bi bi-x-circle" />
              <div class="tooltip-text">
                Délier la facture du contrat
              </div>
            </button>
          </template>
        </InvoiceCompactCard>
      </div>
    </div>
      
    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-primary"
          @click="hide">
          Ok
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import InvoiceCompactCard from "@/components/cards/invoiceCards/InvoiceCompactCard.vue";

export default {
  name: "CustomerInvoicesModal",
  components: {
    Modal,
    InvoiceCompactCard,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
    unlinkInvoice(invoice) {
      this.$services.invoice.patch(invoice.id, {contract_id: null})
        .then(() => {
          
        });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>