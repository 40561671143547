import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";

class QuoteApiService extends ModelApiService {
  constructor() {
    super("/quotes/", "devis");
  }

  export() {
    return api.get(this.url + "export/");
  }

  fetchPdfPreview(data: any) {
    return api.post(
      this.url + "pdf_preview/",
      data,
    );
  }

  sendToCustomerEmail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_to_customer_by_email/")
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Devis envoyée par email");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi du devis");
          reject(error);
        });
    });
  }

  sendToCustomerMail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_to_customer_by_mail/")
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Devis transmis à Hello");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi du devis");
          reject(error);
        });
    });
  } 


  fetchPdf(id: number) {
    return api.get(this.url + id + "/pdf/");
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

}

export default QuoteApiService;