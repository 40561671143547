<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Information du contrat</span>
    </div>
    <div class="card-body">
      <div class="info-grid-layout">
        <div>
          Date de signature
        </div>
        <div class="horizontal-spaced-container align-middle">
          {{ object.signature_date }}
          <button
            class="small-tool-btn"
            @click="$refs.editContractModal.show(object)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div>
          Type de nuisibles
        </div>
        <div class="horizontal-spaced-container align-middle">
          <span v-if="objectActivitiesHumanized">{{ objectActivitiesHumanized }}</span>
          <span v-else><i>Aucun</i></span>
          <button
            class="small-tool-btn"
            @click="$refs.editActivitiesModal.show(object)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div>
          Afsca
        </div>
        <div>
          <span v-if="object.afsca">Oui</span>
          <span v-else><i>Non</i></span>
        </div>
        <div>
          Fréquence
        </div>
        <div class="horizontal-spaced-container align-middle">
          {{ object.nb_interventions }} interventions tous les {{ object.iteration_duration }} mois
          <button
            class="small-tool-btn"
            @click="$refs.editContractModal.show(object)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div>
          Prix
        </div>
        <div class="horizontal-spaced-container align-middle">
          {{ object.price / 100 }}€ HTVA / {{ object.price_model }}
          <button
            class="small-tool-btn"
            @click="$refs.editContractModal.show(object)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
        <div>
          Résponsable
        </div>
        <div class="horizontal-spaced-container align-middle">
          <span v-if="object.responsible">{{ $filters.fullname(object.responsible) }}</span>
          <span v-else><i>Aucun</i></span>
          <button
            class="small-tool-btn"
            @click="$refs.editResponsibleModal.show(object)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
      </div>
    </div>
    <EditContractModal
      ref="editContractModal"
      @change="object = {...object, ...$event}" />

    <EditContractActivitiesModal
      ref="editActivitiesModal"
      @change="object = {...object, ...$event}" />

    <EditContractResponsibleModal
      ref="editResponsibleModal"
      @change="object = {...object, ...$event}" />
  </div>
</template>

<script>

import EditContractActivitiesModal from "@/components/modals/EditContractActivitiesModal.vue";
import EditContractModal from "@/components/modals/EditContractModal.vue";
import EditContractResponsibleModal from "@/components/modals/EditContractResponsibleModal.vue";

export default {
  name: "ContractInformation",
  components: {
    EditContractActivitiesModal,
    EditContractModal,
    EditContractResponsibleModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    object: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    objectActivitiesHumanized() {
      if (this.object.activities) {
        const activities = [];
        
        for (const activity of this.object.activities) {
          activities.push(activity.name);
        }
        return activities.join(", ");
      }
      return null;
    },
  },
};
</script>


<style scoped lang="scss">


</style>