<template>
  <ObjectAddressCard
    title="Adresse de livraison"
    :object="modelValue"
    :editable="editable"
    :onSave="onSave" />
</template>

<script>

import ObjectAddressCard from "@/components/cards/addressCards/ObjectAddressCard.vue";

export default {
  name: "SaleAddressCard",
  components: {
    ObjectAddressCard,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    saleId: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onSave(newAddress) {
      return new Promise((resolve, reject) => {
        this.$services.saleV2.patch(
          this.saleId,
          {
            address: newAddress,
          },
        ).then((response) => {
          this.$emit(
            "update:modelValue",
            response.data.address,
          );
          resolve();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            error.response.data = error.response.data.address;
          }
          reject(error);
        });      
      });
    },
  },
};

</script>