<template>
  <ObjectCustomerCard
    :customer="customer"
    :allowEdit="!modelValue.is_locked"
    :editBtnTooltipText="modelValue.is_locked ? 'La facture ne peut pas être modifiée car elle a déjà été envoyée sur Odoo' : null"
    :onSave="onSave" />
</template>

<script>

import ObjectCustomerCard from "@/components/cards/customerCards/ObjectCustomerCard.vue";

export default {
  name: "InvoiceCustomerCard",
  components: {
    ObjectCustomerCard,
  },
  props: {
    modelValue: { // The invoice
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],
  computed: {
    customer() {
      return this.modelValue.customer;
    },
  },
  methods: {
    onSave(customer) {

      return new Promise((resolve, reject) => {
        const body = {
          customer_id: customer.id,
        };

        const answer2 = window.confirm(`Voulez-vous mettre à jour les informations de contact et l'adresse de cette facture avec les informations de contact et l'adresse de ${this.$filters.printCustomer(customer)} ?`);

        if (answer2) {
          body.contact = {
            first_name: customer.billing_person_first_name,
            last_name: customer.billing_person_last_name,
            email: this.modelValue.contact.email,
            phone_number: this.modelValue.contact.phone_number,
            language: this.modelValue.contact.language,
          };

          body.address = {
            street_number: customer.billing_street_number,
            street: customer.billing_street,
            zipcode: customer.billing_zipcode,
            city: customer.billing_city,
            country_code: customer.billing_country_code,
          
          };
        }

        this.$services.invoice.patch(
          this.modelValue.id,
          body,

        ).then((response) => {
          this.$emit("update:modelValue", {
            ...this.modelValue,
            customer: response.data.customer,
            customer_id: response.data.customer_id,
            contact: response.data.contact,
            address: response.data.address,
          });

          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  
};
</script>
