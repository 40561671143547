import type { RouteRecordRaw } from "vue-router";

import InterventionListLayout from "@/views/interventions/InterventionListLayout.vue";
import InterventionObjectView from "@/views/interventions/InterventionObjectView.vue";
import InterventionObjectAdvancedCreationView from "@/views/interventions/InterventionObjectAdvancedCreationView.vue";
import InterventionReportView from "@/views/interventions/InterventionReportView.vue";
import InterventionReportEditionView from "@/views/interventions/InterventionReportEditionView.vue";
import InterventionsCalendarView from "@/views/interventions/InterventionsCalendarView.vue";
import InterventionObjectMakeAppointment from "@/views/interventions/InterventionObjectMakeAppointment.vue";
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import InterventionRow from "@/components/rows/intervention/InterventionRow.vue";
import InterventionToPlanRow from "@/components/rows/intervention/InterventionToPlanRow.vue";
import { useInterventionListStore } from "@/stores/interventionList";
import { useInterventionToPlanListStore } from "@/stores/interventionToPlanList";


const interventionRoutes: RouteRecordRaw[] = [
  {
    path: "/interventions",
    component: InterventionListLayout,
    redirect: "/interventions/todo",
    name: "intervention-list",
    children: [
      {
        path: "todo",
        component: PaginatedStoreList,
        name: "intervention-list.todo",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "todo",
          };
        },
      },
      {
        path: "open",
        component: PaginatedStoreList,
        name: "intervention-list.open",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "open",
          };
        },
      },
      {
        path: "action-requise",
        component: PaginatedStoreList,
        name: "intervention-list.action-required",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "action_required",
          };
        },
      },
      {
        path: "a-plannifier",
        component: PaginatedStoreList,
        name: "intervention-list.to-plan",
        props: () => {
          return {
            rowComponent: InterventionToPlanRow,
            columnsNames: ["Référence", "Contrat", "Date suggérée de rdv", "Créé le", "Nuisibles", "Adresse", "Technicien", "Facturation"],
            store: useInterventionToPlanListStore(),
            noResultMessage: "Aucune intervention à plannifier",
          };
        },
      },
      {
        path: "plannifiees",
        component: PaginatedStoreList,
        name: "intervention-list.planned",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "planned",
          };
        },
      },
      {
        path: "a-fermer",
        component: PaginatedStoreList,
        name: "intervention-list.to-close",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "to_close",
          };
        },
      },
      {
        path: "fermees",
        component: PaginatedStoreList,
        name: "intervention-list.closed",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "close",
          };
        },
      },
      {
        path: "annulees",
        component: PaginatedStoreList,
        name: "intervention-list.canceled",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "cancel",
          };
        },
      },
      {
        path: "toutes-les-miennes",
        component: PaginatedStoreList,
        name: "intervention-list.mine",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "mine",
          };
        },
      },
      {
        path: "toutes",
        component: PaginatedStoreList,
        name: "intervention-list.all",
        props: () => {
          return {
            rowComponent: InterventionRow,
            columnsNames: [null, "Créé le", "Entreprise", "Nuisibles", "Adresse", "Date d'intervention", "Technicien", "Facturation", "To do"],
            store: useInterventionListStore(),
            noResultMessage: "Aucune intervention à traiter",
            subList: "all",
          };
        },
      },
    ],
  },      
  {
    path: "/interventions/calendrier",
    component: InterventionsCalendarView,
    name: "intervention-calendar",
  },
  {
    path: "/interventions/nouvelle",
    component: InterventionObjectAdvancedCreationView,
    name: "create-intervention",
    props: (route: any) => ({
      mode: "intervention",
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),
  },
  {
    path: "/interventions/:id",
    component: InterventionObjectView,
    name: "intervention",
  },
  {
    path: "/interventions/:id/programmer-rendez-vous",
    component: InterventionObjectMakeAppointment,
    name: "intervention-make-appointment",
  },
  {
    path: "/interventions/:id/rapport",
    component: InterventionReportView,
    name: "intervention-report",
  },
  {
    path: "/interventions/:id/rapport/editer",
    component: InterventionReportEditionView,
    name: "edit-intervention-report",
  },
  {
    path: "/interventions/:id/copier",
    component: InterventionObjectAdvancedCreationView,
    name: "copy-intervention",
    props: (route: any) => ({
      mode: "intervention",
      interventionToCopyId: route.params.id,
    }),
  },

];

export default interventionRoutes;
