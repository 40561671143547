<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Sites d'interventions</span>
    </div>
    <div class="card-body">
      <div class="vertical-spaced-container">
        <div
          v-for="(location, index) in modelValue"
          :key="location.id">
          <div style="display: flex;">
            <strong>
              Lieu #{{ index + 1 }}
            </strong>
            <button class="small-tool-btn" @click="$refs.editLocationModal.show(location)">
              <i class="bi bi-pencil-square" />
            </button>
          </div>
          <p>{{ location.street_number }} {{ location.street }}</p>
          <p>{{ location.zipcode }} {{ location.city }} {{ location.country_code }}</p>
        </div>
      </div>
    </div>

    <EditLocationModal
      ref="editLocationModal"
      @change="onLocationChanged" />
  </div>
</template>

<script>

import EditLocationModal from "@/components/modals/EditLocationModal.vue";

export default {
  name: "LocationsCard",
  components: {
    EditLocationModal,
  },
  props: {
    modelValue: { // The locations
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onLocationChanged(newLocation) {
      this.$emit(
        "update:modelValue", 
        this.modelValue.map((location) => {
          if (location.id === newLocation.id) {
            return newLocation;
          }
          return location;
        }),
      );
    },
  },
};

</script>

<style lang="scss" scoped>


</style>