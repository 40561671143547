<template>
  <ObjectContactCard :object="modelValue" :editable="editable" :onSave="onSave" />
</template>

<script>

import ObjectContactCard from "@/components/cards/contactCards/ObjectContactCard.vue";

export default {
  name: "CreditNoteContactCard",
  components: {
    ObjectContactCard,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    creditNoteId: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onSave(newContact) {
      return new Promise((resolve, reject) => {
        this.$services.creditNote.patch(
          this.creditNoteId,
          {
            contact: newContact,
          },
        ).then((response) => {
          this.$emit(
            "update:modelValue",
            response.data.contact,
          );
          resolve();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            error.response.data = error.response.data.contact;
          }
          reject(error);
        });      
      });
    },
  },
};

</script>