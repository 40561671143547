import { api, encode } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";
import { openBlob } from "@/utils";

class InvoiceApiService extends ModelApiService {
  constructor() {
    super("/invoices/", "facture");
  }

  fetchPdfPreview(data: any) {
    return api.post(
      this.url + "pdf_preview/",
      data,
    );
  }

  sendToCustomerEmail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_to_customer_by_email/")
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Facture envoyée par email");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de la facture par email");
          reject(error);
        });
    });
  }

  sendToCustomerMail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_to_customer_by_mail/")
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Facture transmis à Hello");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de la transmission de la facture à Hello");
          reject(error);
        });
    });
  }

  sendPaymentRequestToCustomer(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_payment_request/")
        .then((response) => {
          notifySuccess("Rappel de paiement envoyée");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi du rappel de paiement");
          reject(error);
        });
    });
  }

  sendToCollectionCompany(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_to_collection_company/")
        .then((response) => {
          notifySuccess("Facture transmise à la société de recouvrement");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de la transmission de la facture à la société de recouvrement");
          reject(error);
        });
    });
  }

  fetchPdfs(args: object | string | null = null) {
    // args can even be an object or a string. If args is a string, the requests will end with ?q=args

    return new Promise((resolve, reject) => {


      const finalArgs = {
        q: null as string | null,
        ...(typeof args === "string" || args instanceof String ? { q: args } : args),
      };
      
      api.get(
        `${this.url}pdfs/?${encode(finalArgs)}`,
        {
          responseType: "blob", // Spécifier le type de réponse comme un blob
        },
      ).then((response) => {
        openBlob(new Blob([response.data]), "export.zip", true);
        resolve(response);
        
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  fetchPdf(id: number) {
    return api.get(
      this.url + id + "/pdf/",
      {
        responseType: "blob", // Spécifier le type de réponse comme un blob
      },
    );
    
  }

  fetchPdfAndOpen(id: number) {
    return new Promise((resolve, reject) => {
      api.get(
        this.url + id + "/pdf/",
        {
          responseType: "blob", // Spécifier le type de réponse comme un blob
        },
      ).then((response) => {
        openBlob(new Blob([response.data]), "facture.pdf", true);
        resolve(response);

      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
}

export default InvoiceApiService;