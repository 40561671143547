<template>
  <Modal ref="modal" width="big">
    <div v-if="event">
      <div style="margin-bottom: 20px;">
        <strong v-if="event.cancelled">[ANNULE]</strong>
        <strong v-if="event.type == 'intervention'">Intervention</strong>
        <strong v-else>{{ event.summary }}</strong>
      </div>
      
      <div class="info-grid-layout">
        <div>Utilisateur</div>
        <div>{{ $filters.fullname(event.user) }}</div>
        <div>Début</div>
        <div>{{ $filters.dateStringToHumanizedDate(event.start_date) }} {{ event.start_time }}</div>
        <div>Fin</div>
        <div>{{ $filters.dateStringToHumanizedDate(event.end_date) }} {{ event.end_time }}</div>
        <div>Recurence</div>
        <div>{{ event.recurence }}</div>
        <div>Lieu</div>
        <div>{{ event.location }}</div>
        <div>Description</div>
        <div>{{ event.description }}</div>
      </div>
    </div>

    <template #buttons>
      <button
        class="btn-secondary"
        @click="hide">
        Ok
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "CalendarEventModal",
  components: {
    Modal,
  },
  data() {
    return {
      event: null,
    };
  },
  methods: {
    show(event) {
      this.event = event;
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>