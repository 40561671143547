<template>
  <div class="card">
    <InvoiceCompactCardHeader :invoice="modelValue">
      <template #header>
        <slot name="header" />
      </template>
    </InvoiceCompactCardHeader>

    <InvoiceCompactCardBody :invoice="modelValue" style="flex-grow: 0;" />

    <div v-if="modelValue.interventions != null" class="card-body" style="background: #e6e6e6; padding: 10px;">
      <div
        v-for="intervention of modelValue.interventions"
        :key="intervention.id">
        <div>
          <time :datetime="intervention.created_at" class="text-xs text-gray-700">
            Créé le {{ $filters.dateStringToHumanizedDateTime(intervention.created_at, "short") }}
          </time>
        </div>
        <InterventionCompactCard
          style="margin-top: 5px; margin-bottom: 10px;"
          :modelValue="intervention"
          :showInvoice="false"
          :showQuote="false" />
      </div>

      <div v-if="modelValue.interventions.length == 0">
        <i>Aucune intervention</i>
      </div>
    </div>
  </div>
</template>

<script>

import InterventionCompactCard from "@/components/cards/interventionCards/InterventionCompactCard.vue";
import InvoiceCompactCardHeader from "@/components/cards/invoice/InvoiceCompactCardHeader.vue";
import InvoiceCompactCardBody from "@/components/cards/invoice/InvoiceCompactCardBody.vue";

export default {
  name: "InvoiceCompactCard",
  components: {
    InvoiceCompactCardBody,
    InterventionCompactCard,
    InvoiceCompactCardHeader,
  },
  props: {
    modelValue: { // The invoice
      type: Object,
      required: true,
    },
  },
};

</script>