<template>
  <Modal ref="modal" width="big" @click.stop>
    <div style="text-align: center; margin-bottom: 20px;">
      <strong>Créer un contrat</strong>
    </div>

    <LoaderView v-if="schema == null" />

    <div v-else>
      <div class="flow-spaced-container">
        <FormField
          :schema="schema.customer"
          :error="validationErrors?.customer">
          <SelectObject
            type="customer"
            :selectedValue="customer ? $filters.printCustomer(customer) : null"
            @select="onSelectCustomer"
            @unselect="customer = null" />
        </FormField>

        <FormField
          :schema="schema.responsible"
          :error="validationErrors?.responsible"
          style="max-width: 300px;">
          <SelectObject
            type="user"
            :selectedValue="responsible ? $filters.fullname(responsible) : null"
            @select="onResponsibleSelected" />
        </FormField>
      </div>

      <div class="flow-spaced-container">
        <FormField
          :schema="schema.signature_date"
          :error="validationErrors?.start_date">
          <Datepicker
            v-model="signatureDate"
            :format="dateToHumanized"
            style="max-width: 400px;"
            :enable-time-picker="false"
            :clearable="false"
            locale="fr"
            cancelText=""
            selectText="Valider"
            :teleport-center="isMobile" />
        </FormField>

        <div class="form-group">
          <label class="form-label">
            Date de début du contrat
          </label>

          <Datepicker
            v-model="startDate"
            :format="dateToHumanized"
            style="max-width: 400px;"
            :enable-time-picker="false"
            :clearable="false"
            locale="fr"
            cancelText=""
            selectText="Valider"
            :teleport-center="isMobile" />
        </div>

        <FormField
          :schema="schema.iteration_duration"
          :error="validationErrors?.iteration_duration">
          <div class="input-group">
            <input
              v-model="object.iteration_duration"
              type="number"
              min="0"
              class="form-control"
              style="width: auto;">
            <div class="input-group-append">
              mois
            </div>
          </div>
        </FormField>
      </div>

      <FormField
        :schema="schema.nb_interventions"
        :error="validationErrors?.nb_interventions">
        <template #default>
          <input
            v-model="object.nb_interventions"
            type="number"
            class="form-control"
            style="max-width: 100px;">
        </template>
      </FormField>

      <div class="flow-spaced-container">
        <FormField
          :schema="schema.price"
          :error="validationErrors?.price">
          <div class="input-group">
            <input
              v-model="priceInEuro"
              type="number"
              min="0"
              class="form-control"
              style="width: auto;">
            <div class="input-group-append">
              € HTVA
            </div>
          </div>
        </FormField>

        <FormField
          :schema="schema.price_model"
          :error="validationErrors?.price_model">
          <select
            v-model="object.price_model"
            class="form-select">
            <option value="per_iteration">
              Pour tout le contrat
            </option>
            <option value="per_intervention">
              Par intervention
            </option>
            <option value="per_month">
              Par mois
            </option>
          </select>
        </FormField>
      </div>

      <FormField
        title="Activités"
        :error="validationErrors?.activity_ids">
        <MultiActivityInput v-model="object.activity_ids" />
      </FormField>
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="hide">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="!canSubmit || submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "CreateContractModal",
  components: {
    Modal,
    LoaderView,
    FormField,
    Datepicker,
    MultiActivityInput,
    SelectObject,
  },
  emits: ["created"],
  data() {
    return {
      schema: null,
      object: {
        activity_ids: [],
        iteration_duration: 12,
      },
      validationErrors: null,
      submitting: false,
      customer: null,
      responsible: null,
      priceInEuro: 0,
      startDate: new Date(),
      signatureDate: new Date(),
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 500;
    },
    canSubmit() {
      return this.customer != null
        && this.responsible != null
        && this.object.activity_ids.length > 0
        && this.priceInEuro > 0
        && this.startDate != null
        && this.signatureDate != null
        && this.object.nb_interventions > 0
        && this.object.iteration_duration > 0;
    },
  },
  methods: {
    clear()  {
      this.object = {
        activity_ids: [],
        iteration_duration: 12,
      };
      this.validationErrors = null;
      this.submitting = false;
      this.customer = null;
      this.responsible = null;
      this.priceInEuro = 0;
      this.startDate = new Date();
      this.signatureDate = new Date();
    },
    show(customer = null) {
      this.clear();

      if (this.schema == null) {
        this.fetchSchema();
      }
      
      this.customer = customer;
      this.$refs.modal.show();
    },
    createFromInvoice(invoice) {
      this.clear();

      if (this.schema == null) {
        this.fetchSchema();
      }

      this.customer = invoice.customer;
      this.signatureDate = new Date(invoice.created_at);
      this.startDate = new Date(invoice.created_at);
      this.priceInEuro = invoice.total_excl_tax;
      this.object.price_model = "per_iteration";
      this.object.nb_interventions = invoice.final_number_of_interventions;

      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onSelectCustomer(customer) {
      this.customer = customer;
    },
    onResponsibleSelected(responsible) {
      this.responsible = responsible;
    },
    fetchSchema() {
      this.$services.contract.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    dateToHumanized(date) {
      const options = { weekday: "short", month: "short", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    },
    submit() {
      this.submitting = true;
      this.validationErrors = null;

      this.$services.contract.create(
        {
          ...this.object,
          start_date: this.startDate.toISOString().split("T")[0],
          signature_date: this.signatureDate.toISOString().split("T")[0],
          customer_id: this.customer.id,
          responsible_id: this.responsible.id,
          price: this.priceInEuro * 100,
        },
      )
        .then((response) => {
          this.$emit("created", response.data);
          this.hide();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>