<template>
  <div class="vertical-spaced-container">
    <div>
      <div class="flow-spaced-container">
        <div class="progress-bar">
          <div
            v-for="i in iteration.nb_interventions"
            :key="i"
            class="step"
            :class="{ 'completed': i <= iteration.interventions.length }" />
        </div>

        <div>
          {{ iteration.interventions.length }} / {{ iteration.nb_interventions }} interventions
        </div>

        <div v-if="nextPlannedIntervention">
          Prochaine intervention plannifiée le {{ $filters.dateStringToHumanizedDate(nextPlannedIntervention.rdv_date) }}
        </div>

        <div v-else-if="optimizedFutureInterventionDate">
          Prochaine intervention à plannifier le {{ $filters.dateStringToHumanizedDate(optimizedFutureInterventionDate) }}
        </div>
      </div>
    </div>

    <div>
      <div v-if="iteration.price_model == 'per_intervention'" class="flow-spaced-container">
        <div class="progress-bar">
          <div
            v-for="i in iteration.nb_interventions"
            :key="i"
            class="step"
            :class="{ 'completed': i <= iteration.invoices.length }" />
        </div>

        <div>
          {{ iteration.invoices.length }} / {{ iteration.nb_interventions }} factures
        </div>

        <div v-if="nextBillingDate">
          Prochaine facturation le {{ $filters.dateStringToHumanizedDate(nextBillingDate) }}
        </div>

        <div v-else class="text-green">
          Facturation complète <i class="bi bi-check-lg" />
        </div>
      </div>

      <div v-else-if="iteration.price_model == 'per_iteration'" class="flow-spaced-container">
        <div class="progress-bar">
          <div
            class="step"
            :class="{ 'completed': iteration.invoices.length > 0 }" />
        </div>

        <div>
          {{ iteration.invoices.length }} / 1 facture
        </div>

        <div v-if="iteration.invoices.length == 0">
          A facturer asap
        </div>

        <div v-else class="text-green">
          Facturation complète <i class="bi bi-check-lg" />
        </div>
      </div>

      <div v-else-if="iteration.price_model == 'per_month'" class="flow-spaced-container">
        <div>TODO</div>
        <div v-if="nextBillingDate">
          Prochaine facturation le {{ $filters.dateStringToHumanizedDate(nextBillingDate) }}
        </div>

        <div v-else class="text-green">
          Facturation complète <i class="bi bi-check-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ContractProgressBar",
  props: {
    iteration: { // The iteration object
      type: Object,
      required: true,
    },
  },
  computed: {
    nbInterventionLeftToDo() {
      return this.iteration.nb_interventions - this.pastInterventions.length;
    },
    plannifiedInterventions() {
      return this.iteration.interventions.filter((intervention) => intervention.rdv_date != null).sort((a, b) => new Date(a.rdv_date) - new Date(b.rdv_date));
    },
    pastInterventions() {
      return this.plannifiedInterventions.filter((intervention) => {
        if (intervention.rdv_date == null) {
          return false;
        }
        return new Date(intervention.rdv_date) < new Date();
      });
    },
    lastIntervention() {
      if (this.pastInterventions.length === 0) {
        return null;
      }

      return this.pastInterventions[this.pastInterventions.length - 1];
    },
    futureInterventions() {
      return this.plannifiedInterventions.filter((intervention) => {
        if (intervention.rdv_date == null) {
          return false;
        }
        return new Date(intervention.rdv_date) >= new Date();
      });
    },
    nextPlannedIntervention() {
      if (this.futureInterventions.length === 0) {
        return null;
      }

      return this.futureInterventions[0];
    },
    optimizedFutureInterventionDate() {
      if (this.nbInterventionLeftToDo === 0) {
        return null;
      }

      const lastInterventionDate = this.lastIntervention ? this.lastIntervention.rdv_date : this.iteration.start_date;
      const endDate = this.iteration.end_date;

      return this.calculerDeuxiemeRdv(lastInterventionDate, endDate, this.nbInterventionLeftToDo + 1);
    },
    
    nextBillingDate() {
      if (this.iteration.price_model == "per_intervention") {
        return this.nextPlannedIntervention ? this.nextPlannedIntervention.rdv_date : this.optimizedFutureInterventionDate ? this.optimizedFutureInterventionDate : null;

      } else if (this.iteration.price_model == "per_month") {

        const nbMonthsBetweenStartAndEnd = Math.round((new Date(this.iteration.end_date) - new Date(this.iteration.start_date)) / (1000 * 60 * 60 * 24 * 30));

        const nbOfInvoicesAlreadyBilled = this.iteration.invoices.length;

        if (nbOfInvoicesAlreadyBilled >= nbMonthsBetweenStartAndEnd) {
          return null;
        }

        const begininOfMonthToBeBilled = new Date(this.iteration.start_date);
        begininOfMonthToBeBilled.setMonth(begininOfMonthToBeBilled.getMonth() + nbOfInvoicesAlreadyBilled);

        return begininOfMonthToBeBilled.toISOString().slice(0, 10); // Format YYYY-MM-DD
        
      } else {
        return null;
      }
    },
  },
  methods: {
    calculerDeuxiemeRdv(datePremierRdv, dateDernierRdv, nombreTotalRdv) {
      // Convertir les dates en objets Date
      const premierRdv = new Date(datePremierRdv);
      const dernierRdv = new Date(dateDernierRdv);

      // Calculer l'intervalle de temps entre chaque rendez-vous
      const intervalle = (dernierRdv - premierRdv) / (nombreTotalRdv - 1);

      // Calculer la date du deuxième rendez-vous en ajoutant l'intervalle au premier rendez-vous
      const deuxiemeRdv = new Date(premierRdv.getTime() + intervalle);

      return deuxiemeRdv.toISOString().slice(0, 10); // Format YYYY-MM-DD
    },
  },
  
};
</script>

<style lang="scss" scoped>

  .progress-bar {

    height: 20px;
    display: flex;
    border-right: 1px solid gray;


    .step {
      flex: 1;
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
      border-left: 1px solid gray;
      min-width: 20px;

      &.completed {
        background: green;
      }
    }
    
  }

</style>