<template>
  <SlideOver ref="slideOver" title="Ajouter un congé">
    <template #default>
      <div class="flow-spaced-container">
        <div class="form-group no-bottom-margin">
          <div class="form-label">
            Date et heure de début
          </div>
          <input
            v-model="startDatetime"
            type="datetime-local"
            class="form-control">
        </div>

        <div class="form-group no-bottom-margin">
          <div class="form-label">
            Date et heure de fin
          </div>
          <input
            v-model="endDatetime"
            type="datetime-local"
            class="form-control">
        </div>

        <div class="form-group no-bottom-margin">
          <div class="form-label">
            Technicien
          </div>
          <TechnicianSelect v-model="technicianId" />
        </div>

        <div class="form-group no-bottom-margin">
          <div class="form-label">
            Type de congé
          </div>
          <select
            v-model="leaveType"
            class="form-control">
            <option
              v-for="(type, index) in leaveTypes"
              :key="index"
              :value="type">
              {{ type.label ? type.label : type.code }}
            </option>
          </select>
        </div>

        <button class="btn-secondary" :disabled="submitting" @click="cancel">
          Annuler
        </button>
        <button :disabled="!canSubmit || submitting" class="btn-primary" :class="{ 'loading': submitting }" @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </SlideOver>
</template>

<script>

import SlideOver from "@/components/baseComponents/SlideOver.vue";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";
import { useLeaveTypesStore } from "@/stores/leaveTypes";


export default {
  name: "AddLeaveSlideOver",
  components: {
    SlideOver,
    TechnicianSelect,
  },
  data() {
    return {
      submitting: false,

      startDatetime: null,
      endDatetime: null,
      technicianId: null,
      leaveType: null,
      leaveTypesStore: useLeaveTypesStore(),
    };
  },
  computed: {
    canSubmit() {
      return this.startDatetime && this.endDatetime && this.technicianId && this.leaveType;
    },
    leaveTypes() {
      return this.leaveTypesStore.getLeaveTypes;
    },
  },
  mounted() {
    if (this.leaveType == null) {
      this.leaveTypesStore.fetchLeaveTypes();
    }
  },
  methods: {
    open() {
      this.reset();
      this.$refs.slideOver.open();
    },
    close() {
      this.$refs.slideOver.close();
      this.reset();
    },
    reset() {
      this.startDatetime = null;
      this.endDatetime = null;
      this.technicianId = null;
      this.leaveType = null;
      this.submitting = false;
    },
    submit() {
      this.submitting = true;

      this.$services.leaveEvent.create({
        start_datetime: this.startDatetime,
        end_datetime: this.endDatetime,
        user_id: this.technicianId,
        type: this.leaveType.code,
      }).then(() => {
        this.close();
      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};

</script>

