<template>
  <ObjectViewLayout
    :title="title"
    :errorStr="errorStr"
    :loading="object == null"
    withBackButton
    @back="$router.push({ name: 'intervention', params: { id: $route.params.id } })">
    <template #title-icon-left>
      <BriefcaseIcon />
    </template>

    <template #rightButton>
      <InterventionReportToolButtons v-model="object" />
    </template>

    <template #default>
      <PdfCard :pdfData="reportPdf" />
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import InterventionReportToolButtons from "@/components/toolbuttons/InterventionReportToolButtons.vue";
import PdfCard from "@/components/cards/PdfCard.vue";

export default {
  name: "InterventionReportView",
  components: {
    ObjectViewLayout,
    BriefcaseIcon,
    PdfCard,
    InterventionReportToolButtons,
  },
  data() {
    return {
      object: null,
      reportPdf: null,
      errorStr: null,
    };
  },
  computed: {
    title() {
      if (!this.object) {
        return "Chargement de l'intervention...";
      } else {
        return "Rapport d'intervention";
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchReportPdf(),
    ]).catch((err) => {
      this.errorStr = "Une erreur est survenue lors du chargement de l'intervention";
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.intervention.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.errorStr = "L'intervention n'existe pas ou vous n'avez pas les droits pour la consulter. Contactez le Webmaster si vous pensez qu'il s'agit d'une erreur.";
          } else {
            throw error;
          }
        });
    },
    fetchReportPdf() {
      return this.$services.intervention.fetchReportPdf(this.$route.params.id)
        .then((response) => {
          this.reportPdf = response.data;
        });
    },
  },
};
</script>