<template>
  <div class="card">
    <div class="card-header">
      <InvoiceIcon
        status="quote"
        width="20px"
        height="20px" />
      <span class="title">
        Devis de {{ modelValue.total_incl_tax }}€
      </span>
    </div>
    <div class="card-body">
      <div class="info-grid-layout">
        <div>Référence</div>
        <div>{{ modelValue.ref }}</div>
        <div>
          Montant
        </div>
        <div>{{ modelValue.total_incl_tax }}€ TTC / {{ modelValue.total_excl_tax }}€ HT</div>
        <div>Date</div>
        <div>{{ $filters.dateStringToHumanizedDate(modelValue.created_at) }}</div>
        <div>Auteur</div>
        <div>{{ $filters.fullname(modelValue.author) }}</div>
      </div>
      <div class="flow-spaced-container">
        <span
          v-if="modelValue.send_date"
          class="highlighted green tooltip"
          style="margin-right: 5px;">
          Envoyé au client
          <div class="tooltip-text">{{ $filters.dateStringToHumanizedDate(modelValue.send_date) }}</div>
        </span>
        <span
          v-else
          class="highlighted red"
          style="margin-right: 5px;">
          Non envoyé au client
        </span>
      </div>
      <div class="flow-spaced-container">
        <router-link :to="{ name: 'quote', params: { id: modelValue.id } }" class="btn-link">
          Voir le devis
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";

export default {
  name: "QuoteCompactCard",
  components: {
    InvoiceIcon,
  },
  props: {
    modelValue: { // The quote
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

};

</script>