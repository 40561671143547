<template>
  <Modal ref="modal">
    <template #default>
      <div class="form-group">
        <label v-if="label" class="form-label">{{ label }}</label>
        <Datepicker
          v-if="type == 'datetime'"
          v-model="internalValue"
          :format="dateToHumanized"
          :clearable="false"
          locale="fr"
          cancelText=""
          selectText="Valider"
          :teleport-center="isMobile"
          :start-time="{ 'hours': 8, 'minutes': 0 }"
          :disabled="loading"
          minutes-increment="15"
          minutes-grid-increment="15" />
        <input
          v-else
          v-model="internalValue"
          :type="type"
          :disabled="loading"
          class="form-control">
      </div>
    </template>
    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="!internalValue || loading"
        style="margin-left: 5px;"
        @click="$emit('submit', internalValue)">
        Envoyer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "InputModal",
  components: {
    Modal,
    Datepicker,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
      validator: (value) => {
        return ["text", "datetime", "number"].indexOf(value) !== -1;
      },
    },
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["submit", "update:modelValue"],
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 500;
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit(
        "update:modelValue",
        newVal,
      );
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    dateToHumanized(date) {
      console.log(typeof date);
      return date.toLocaleDateString("fr-FR", { weekday: "short", month: "short", day: "numeric" }) + " " + date.toLocaleTimeString("fr-FR", {hour: "2-digit", minute:"2-digit"});
    },
  },
};

</script>

<style lang="scss" scoped>


</style>