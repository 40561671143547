import { defineStore } from "pinia";


interface State {
  notifications: any;
}

export const useNotificationsStore = defineStore("notifications",{
  state: (): State => ({
    notifications: [],
  }),
  getters: {
    getNotifications: state => {
      return state.notifications;
    },
  },
  actions: {
    pushNotification(notification) {
      this.notifications.push(
        {
          "id": (Math.random().toString(36) + Date.now().toString(36)).substr(2),
          ...notification,
        },
      );
    },
    removeNotification(notificationToRemove) {
      this.notifications = this.notifications.filter(
        notification => {
          return notification.id != notificationToRemove.id;
        },
      );
    },
  },
});
