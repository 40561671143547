<script setup lang="ts">

import { computed } from "vue";
import Pagination from "./Pagination.vue";

// Props

const props = defineProps({
  store: {type: Object, required: true },
  subList: { type: String, required: false, default: null },
});

// Computed

const page = computed(() => {
  if (props.subList) {
    return props.store.getPage(props.subList);
  } else {
    return props.store.getPage;
  }
});

const limit = computed(() => {
  if (props.subList) {
    return props.store.getLimit(props.subList);
  } else {
    return props.store.getLimit;
  }
});

const total = computed(() => {
  if (props.subList) {
    return props.store.getTotal(props.subList);
  } else {
    return props.store.getTotal;
  }
});

const isLoading = computed(() => {
  if (props.subList) {
    return props.store.isLoading(props.subList);
  } else {
    return props.store.isLoading;
  }
});

const allowExport = computed(() => {
  return props.store.allowExport;
});

const exporting = computed(() => {
  if (props.subList) {
    return props.store.isExporting(props.subList);
  } else {
    return props.store.isExporting;
  }
});

// Methods

const firstPage = () => {
  if (props.subList) {
    props.store.loadPage(props.subList, 1);
  } else {
    props.store.loadPage(1);
  }
};

const previousPage = () => {
  if (props.subList) {
    props.store.loadPreviousPage(props.subList);
  } else {
    props.store.loadPreviousPage();
  }
};

const nextPage = () => {
  if (props.subList) {
    props.store.loadNextPage(props.subList);
  } else {
    props.store.loadNextPage();
  }
};

const doExport = () => {
  if (props.subList) {
    props.store.export(props.subList);
  } else {
    props.store.export();
  }
};

</script>

<template>
  <Pagination
    :page="page"
    :limit="limit"
    :total="total"
    :loading="isLoading"
    :allowExport="allowExport"
    :exporting="exporting"
    @first="firstPage"
    @previous="previousPage"
    @next="nextPage"
    @export="doExport" />
</template>