import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./assets/styles/base.scss";
import router from "./router";
import { setInterceptors } from "@/api";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import * as filters from "@/filters";
import services from "@/services";

// Import Geobootstrap icons

// We are using this lib : https://icons.getbootstrap.com/
// Install with npm i bootstrap-icons
// Then import css :
import "bootstrap-icons/font/bootstrap-icons.css";
// Then display icons like explained in this doc : https://icons.getbootstrap.com/#usage

// Create pinia stores

const pinia = createPinia();

// Create app

const app = createApp(App);

// Http response interceptor

setInterceptors();


// Sentry init

Sentry.init({
  app,
  dsn: "https://57d3c7b817244249b9cc91e0359d21ff@o1430205.ingest.sentry.io/6781180",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [import.meta.env.VITE_VUE_APP_URL],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 0%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,

  // Please note that if you enable this integration, by default Vue will not call its `logError` internally. This means that errors occurring in the Vue renderer will not show up in the developer console. If you want to preserve this functionality, make sure to pass the `logErrors: true` option. 
  logErrors: true,
  environment: import.meta.env.VITE_VUE_APP_ENV,
});



// Configure app

app.use(pinia);
app.use(router);

// Set filters

app.config.globalProperties.$filters = filters;

// Set services

app.config.globalProperties.$services = services;
app.provide("$services", services);

// Mount app

app.mount("#app");
