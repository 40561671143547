<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Informations article</span>
      <button v-if="editMode" class="btn btn-link" @click="cancel">
        (annuler)
      </button>
      <button v-else class="btn btn-link" @click="edit">
        (editer)
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <FormField
        :schema="schema.restricted_circuit_product"
        :error="validationErrors?.restricted_circuit_product"
        hideTitle>
        <template #default>
          <div class="form-check">
            <input
              v-model="editedProduct.restricted_circuit_product"
              class="form-check-input"
              type="checkbox">
            <label class="form-check-label">
              {{ schema.restricted_circuit_product.verbose_name }}
            </label>
          </div>
        </template>
      </FormField>

      <FormField :schema="schema.brand" :error="validationErrors?.brand">
        <template #default>
          <input v-model="editedProduct.brand" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.model" :error="validationErrors?.model">
        <template #default>
          <input v-model="editedProduct.model" type="text" class="form-control">
        </template>
      </FormField>

      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <p>Unité : <strong>{{ modelValue.unit }}</strong></p>
      <p>Produit circuit restreint : <strong>{{ modelValue.restricted_circuit_product }}</strong></p>
      <p>Marque : <strong>{{ modelValue.brand }}</strong></p>
      <p>Modèle : <strong>{{ modelValue.model }}</strong></p>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ProductGeneralInformationCard",
  components: {
    FormField,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editMode: false,
      editedProduct: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      this.editedProduct = {
        restricted_circuit_product: this.modelValue.restricted_circuit_product,
        brand: this.modelValue.brand,
        model: this.modelValue.model,
      };
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedProduct = null;
    },
    submit() {
      this.submitting = true;

      this.$services.product.patch(this.modelValue.id, this.editedProduct)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        });
    },
  },
};

</script>