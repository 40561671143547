<template>
  <Modal ref="modal">
    <template #default>
      <h1 v-if="technician && dateFrom && morningFrom != null && dateTo && morningTo != null">
        {{ dateFrom }} <span v-if="morningFrom">matin</span><span v-else>après-midi</span><br>
        => {{ dateTo }} <span v-if="morningTo">matin</span><span v-else>après-midi</span><br>
        Pour : {{ $filters.fullname(technician) }}
      </h1>

      <div style="display: flex; flex-direction: column;">
        <button
          v-for="(type, index) in leaveTypes"
          :key="index"
          class="btn-primary"
          style="margin: 2px; 0px;"
          :style="{ 'background-color': type.color }"
          :disabled="submitting"
          @click="set(type)">
          {{ type.label ? type.label : type.code }}
        </button>

        <button
          class="btn-secondary"
          style="margin: 2px; 0px;"
          :disabled="submitting"
          @click="set(null)">
          Rien
        </button>

        <LoaderView v-if="submitting" />
      </div>
    </template>

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import LoaderView from "@/components/LoaderView.vue";
import { useLeaveTypesStore } from "@/stores/leaveTypes";

export default {
  name: "EditStaffCalendarDateModal",
  components: {
    Modal,
    LoaderView,
  },
  emits: ["onLeavesCreated"],
  data() {
    return {
      dateFrom: null,
      morningFrom: null,
      dateTo: null,
      morningTo: null,
      technician: null,
      submitting: false,
      leaveTypesStore: useLeaveTypesStore(),
    };
  },
  computed: {
    leaveTypes() {
      return this.leaveTypesStore.getLeaveTypes;
    },
  },
  methods: {
    show(technician, dateFrom, morningFrom, dateTo, morningTo) {
      this.dateFrom = dateFrom;
      this.morningFrom = morningFrom;
      this.dateTo = dateTo;
      this.morningTo = morningTo;
      this.technician = technician;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    set(type = null, description = null) {
      this.submitting = true;

      this.$services.user.setLeaves(
        this.technician.id,
        this.dateFrom.toISOString(),
        this.morningFrom,
        this.dateTo.toISOString(),
        this.morningTo,
        type ? type.code : null,
        description,
      ).then((response) => {
        this.$emit("onLeavesCreated", response.data);
        this.hide();
      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>