<template>
  <div>
    <div class="input-group">
      <div class="input-group-prepend">
        TVA
      </div>

      <input
        v-model="internalValue"
        :class="{ 'valid': vatNumberIsValid, 'invalid': vatNumberIsInvalid }"
        type="text"
        class="form-control"
        :disabled="disabled"
        @blur="validateVatNumber">
    </div>

    <div v-if="validatingVatNumber" class="horizontal-spaced-container align-middle text-red">
      <Loader />

      <div>
        Validation...
      </div>
    </div>

    <div v-else>
      <small
        v-if="vatNumberValidationResult == false"
        class="form-text text-red">
        Numéro de TVA invalide
      </small>

      <small
        v-if="companyNameFound"
        class="form-text">
        <strong>Société correspondante</strong>
        <br>
        {{ companyNameFound }}
        <br>
        {{ companyAddressFound }} {{ companyCountryCodeFound }}
      </small>
    </div>
  </div>
</template>

<script>

import { api } from "@/api";
import Loader from "@/components/Loader.vue";
import { notifyError } from "@/notifications";

export default {
  name: "VatNumber",
  components: {
    Loader,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "onCompanyNameFound", "onCompanyAddressFound", "onCompanyCountryCodeFound"],
  data() {
    return {
      vatNumberValidationResult: undefined,
      companyNameFound: null,
      companyAddressFound: null,
      companyCountryCodeFound: null,
      validatingVatNumber: false,
    };
  },
  computed: {
    vatNumberIsValid() {
      return this.modelValue != null && this.modelValue.length > 0 && this.vatNumberValidationResult == true;
    },
    vatNumberIsInvalid() {
      return this.modelValue != null && this.modelValue.length > 0 && this.vatNumberValidationResult == false;
    },
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
  },
  
  methods: {
    validateVatNumber() {
      if (!this.modelValue) {
        this.vatNumberValidationResult = undefined;
        return;
      }

      this.validatingVatNumber = true;

      api.post("validate-vat-number/", {
        vat_number: this.modelValue,
      })
        .then((response) => {
          this.vatNumberValidationResult = response.data.is_valid;

          this.companyNameFound = response.data.business_name;
          this.companyAddressFound = response.data.business_address;
          this.companyCountryCodeFound = response.data.business_country_code;

          if (response.data.business_name) {
            this.$emit("onCompanyNameFound", response.data.business_name);
          }

          if (response.data.companyAddressFound) {
            this.$emit("onCompanyAddressFound", response.data.companyAddressFound);
          }

          if (response.data.companyCountryCodeFound) {
            this.$emit("onCompanyCountryCodeFound", response.data.companyCountryCodeFound);
          }

        })
        .catch((error) => {
          this.vatNumberValidationResult = undefined;
          if (error.response?.status == 429 || error.response?.status == 503) {
            notifyError("Impossible de vérifier le numéro de TVA pour l'instant. Réessayez plus tard.");
          } else {
            notifyError("Une erreur est survenue lors de la vérification du numéro de TVA");
            throw error;
          }
          
        })
        .finally(() => {
          this.validatingVatNumber = false;
        });
      
    },
  },
};
</script>

<style scoped lang="scss">


</style>
