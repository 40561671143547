

<script setup lang="ts">

import LoaderView from "@/components/LoaderView.vue";
import { Ref, ref  } from "vue";


// Props

defineProps({
  rowComponent: {type: Object, required: true },
  columnsNames: {type: Array<string>, required: false, default: null },
  isLoading: {type: Boolean, required: false, default: false },
  isError: {type: Boolean, required: false, default: false },
  results: {type: Array<Object>, required: false, default: null },
  noResultMessage: {type: String, required: false, default: "Aucun résultat à afficher" },
});

// Data

const wheelDown: Ref<boolean> = ref(false);
var startX: Ref<number> = ref(0);
var startScrollLeft: Ref<number> = ref(0);

// Emits

const emits = defineEmits(["updateResult"]);

// Methods

const onResultUpdated = (result: any) => {
  emits("updateResult", result);
};

const startScroll = (event: any) => {
  if (event.button != 1) {
    return;
  }

  const table = event.currentTarget;

  wheelDown.value = true;
  startX.value = event.clientX;
  startScrollLeft.value = table.scrollLeft;
};


const handleMouseMove = (event: any) => {
  if (wheelDown.value) {
    const table = event.currentTarget;

    const distanceX = event.clientX - startX.value;
    table.scrollLeft = startScrollLeft.value - distanceX;
  }
};

const stopScroll = () => {
  wheelDown.value = false;
};

</script>

<template>
  <div
    class="list"
    :style="{ 'cursor': wheelDown ? 'move' : 'default' }"
    @mousedown="startScroll"
    @mousemove="handleMouseMove"
    @mouseup="stopScroll">
    <div v-if="isError" style="text-align: center; padding: 20px;">
      Une erreur est survenue lors du chargement des données
    </div>
    <LoaderView v-else-if="isLoading" style="margin: 20px;" />
    <table v-else-if="results && results.length > 0" cellspacing="0">
      <thead v-if="columnsNames && columnsNames.length > 0" class="desktop-only">
        <tr>
          <th />
          <th
            v-for="(column, index) in columnsNames"
            :key="index">
            {{ column }}
          </th>
        </tr>
      </thead>

      <tbody>
        <component
          :is="rowComponent"
          v-for="(result, index) in results"
          :key="index"
          :style="{ 'cursor': wheelDown ? 'move' : 'default' }"
          :modelValue="result"
          @update:modelValue="onResultUpdated" />
      </tbody>
    </table>
    
    <div v-else style="display: flex; justify-content: center; margin: 20px;">
      {{ noResultMessage }}
    </div>
  </div>
</template>


<style lang="scss" scoped>

  .list {
    display: flex;
    flex-direction: column;
    background: white;
    width: fit-content;
    min-width: 100%;

    table {

      thead {
        tr {
          th {
            text-align: left;
            padding: 10px;
          }
        }
      }
    }
  }

</style>