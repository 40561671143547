<template>
  <GBaseRow>
    <template #icon>
      <i class="bi bi-box-seam" style="font-size: 2rem;" />
    </template>

    <template #title>
      <span class="big">{{ batch.product.name }}</span>
      <br>
      <span class="small">{{ batch.product.sku }}</span>
    </template>

    <template #default>
      <td>
        {{ batch.storage.name }}
      </td>
      <td>
        {{ batch.quantity_humanized }}
      </td>
      <td>
        {{ batch.batch_value }}€
      </td>
    </template>

    <template #toolButtons>
      <BatchToolButtons v-model="batch" />
    </template>
  </GBaseRow>
</template>

<script>

import BatchToolButtons from "@/components/toolbuttons/BatchToolButtons.vue";
import {GBaseRow} from "g-list";

export default {
  name: "BatchRow",
  components: {
    BatchToolButtons,
    GBaseRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    batch: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("Emit from BatchRow");
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>


</style>