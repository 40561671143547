<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <i class="bi bi-person" />
    </template>

    <template #title>
      <span class="text-bigger">{{ $filters.printCustomer(customer) }}</span>
      <br>
      <span class="text-smaller">{{ customer.contacts[0].phone_number ? customer.contacts[0].phone_number : customer.contacts[0].email ? customer.contacts[0].email : '' }}</span>
    </template>
    
    <template #default>
      <td>
        {{ customer.billing_company_name }}
      </td>
      <td v-if="customer.last_intervention">
        <span v-if="customer.last_intervention.rdv_date">
          Dernière intervention programmée le {{ $filters.dateStringToHumanizedDate(customer.last_intervention.rdv_date) }}
        </span>
        <span v-else>
          Dernière intervention créé le {{ $filters.dateStringToHumanizedDate(customer.last_intervention.created_at) }}
        </span>
        <br>
        <span v-if="customer.last_intervention.invoice">
          Facture créée le {{ $filters.dateStringToHumanizedDate(customer.last_intervention.invoice.created_at) }}
        </span>
        <span v-else-if="customer.last_intervention.quote">
          Devis créé le {{ $filters.dateStringToHumanizedDate(customer.last_intervention.quote.created_at) }}
        </span>
        <span v-else>
          Pas encore facturé
        </span>
      </td>
      <td v-else-if="customer.last_invoice">
        Dernière facture créé le {{ $filters.dateStringToHumanizedDate(customer.last_invoice.created_at) }}
      </td>
      <td v-else>
        Nouveau client
      </td>
      <td>
        {{ customer.billing_zipcode }} ({{ customer.billing_country_code }})
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <CustomerToolButtons v-model="customer" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";
import CustomerToolButtons from "@/components/toolbuttons/CustomerToolButtons.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "CustomerRow",
  components: {
    CustomerToolButtons,
    Toolbar,
    GBaseRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    customer: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "customer", params: { "id": this.customer.id } });
    },
  },
};
</script>

<style lang="scss" scoped>



.popup-menu-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: black;
  z-index: 10000;

  display: none;

  &.visible {
    display: block;
  }
}

.popup-menu {
  // Position
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  z-index: 10001;

  // Style
  background: white;

  // Hide for animation
  overflow: hidden;
  transition: transform 0.05s ease-in-out;
  transform: scaleY(0);
  transform-origin: bottom;

  &.visible {
    // Make visible
    transform: scaleY(1);
  }
}

</style>