<template>
  <div>
    <StorePagination
      :store="store"
      subList="grid"
      style="margin-bottom: 10px;" />

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Type</th>

            <th class="text-blue">
              SKU
            </th>
            <th class="text-blue">
              Nom interne
            </th>
            <th class="text-blue">
              EAN
            </th>
            <th class="text-blue">
              Barcode
            </th>
            <th class="text-blue">
              Circuit restreint
            </th>

            <th>Stock</th>
            <th>Unité</th>
            <th class="text-yellow">
              Nom publique
            </th>
          
            <th class="text-yellow">
              Prix de vente
            </th>
            <th class="text-green">
              Fournisseur
            </th>
            <th class="text-green">
              Marque
            </th>
            <th class="text-green">
              Modèle
            </th>
            <th class="text-green">
              Référence produit
            </th>
            <th class="text-green">
              Quantité par achat
            </th>
            <th class="text-green">
              Prix d'achat à l'unité
            </th>
            <th class="text-green">
              Prix d'achat à la commande
            </th>
            
            <th class="text-green">
              Seuil de réaprovisionnement
            </th>
          </tr>
        </thead>
        <tbody v-if="products">
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.type }}</td>
            <td>
              <div class="editable" @click="editField(product, 'sku', 'SKU')">
                {{ product.sku }}
              </div>
            </td>
            <td>
              <input
                style="width: 300px;"
                :value="product.name"
                class="form-control"
                @change="updateField(product, 'name', $event.target.value)">
            </td>

            <td>
              <div class="editable" @click="editField(product, 'ean', 'EAN')">
                {{ product.ean }}
              </div>
            </td>

            <td>
              <input
                style="width: 130px;"
                :value="product.barcode"
                class="form-control"
                @change="updateField(product, 'barcode', $event.target.value)">
            </td>

            <td>
              <i v-if="product.restricted_circuit_product == true" class="bi bi-check" />
              <i v-else-if="product.restricted_circuit_product == false" class="bi bi-x" />
              <i v-else class="bi bi-question" />
            </td>

            <td>
              <span v-if="product.wc_manage_stock">{{ product.stock_unit_count }}</span>
              <i v-else class="bi bi-infinity" />
            </td>

            <td>
              <select
                v-model="product.unit"
                class="form-select"
                style="width: fit-content;"
                @change="updateField(product, 'unit', $event.target.value)">
                <option
                  v-for="(choice, index) in [['unit', 'unité'], ['g', 'g'], ['kg', 'kg'], ['L', 'L']]"
                  :key="index"
                  :value="choice[0]">
                  {{ choice[1] }}
                </option>
              </select>
            </td>

            <td>
              <input
                style="width: 300px;"
                :value="product.public_name"
                class="form-control"
                @change="updateField(product, 'public_name', $event.target.value)">
            </td>

            <td>
              <div class="input-group">
                <input
                  style="width: 120px;"
                  :value="product.sale_price_per_unit"
                  class="form-control"
                  type="number"
                  @change="updateField(product, 'sale_price_per_unit', $event.target.value)">
                <div class="input-group-append">
                  €
                </div>
              </div>
            </td>
          

            <td>
              <SelectObject
                style="width: 100px;"
                includeSearchQueryInResults
                type="provider"
                @select="updateField(product, 'provider', $event)" />
            </td>

            <td>
              <SelectObject
                style="width: 100px;"
                includeSearchQueryInResults
                type="brand"
                @select="updateField(product, 'brand', $event)" />
            </td>

            <td>
              <input
                style="width: 100px;"
                :value="product.model"
                class="form-control"
                @change="updateField(product, 'model', $event.target.value)">
            </td>

            

            <td>
              <input
                style="width: 100px;"
                :value="product.provider_product_name"
                class="form-control"
                @change="updateField(product, 'provider_product_name', $event.target.value)">
            </td>

            <td>
              <div class="input-group">
                <input
                  style="width: 80px;"
                  :value="product.reorder_unit_count"
                  class="form-control"
                  type="number"
                  @change="updateField(product, 'reorder_unit_count', $event.target.value)">
                <div class="input-group-append">
                  {{ product.unit }}
                </div>
              </div>
            </td>

            <td>
              <div class="input-group">
                <input
                  style="width: 120px;"
                  :value="product.purchase_price_per_unit"
                  class="form-control"
                  type="number"
                  @change="updateField(product, 'purchase_price_per_unit', $event.target.value)">
                <div class="input-group-append">
                  €
                </div>
              </div>
            </td>

            <td>
              <div class="input-group">
                <input
                  :disabled="product.reorder_unit_count == null || product.reorder_unit_count <= 0"
                  style="width: 120px;"
                  :value="product.purchase_price_per_unit * product.reorder_unit_count"
                  class="form-control"
                  type="number"
                  @change="updateField(product, 'purchase_price_per_unit', $event.target.value / product.reorder_unit_count)">
                <div class="input-group-append">
                  €
                </div>
              </div>
            </td>

            

            <td>
              <div class="input-group">
                <input
                  style="width: 80px;"
                  :value="product.reorder_threshold"
                  class="form-control"
                  type="number"
                  @change="updateField(product, 'reorder_threshold', $event.target.value)">
                <div class="input-group-append">
                  {{ product.unit }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="loading" class="overlay">
        <!-- Loader -->
        <Loader width="50px" height="50px" />
      </div>
    </div>

    <Modal ref="editFieldModal">
      <div v-if="fieldToEdit" class="form-group">
        <label class="form-label">{{ fieldToEdit.label }}</label>
        <input
          v-model="fieldToEdit.value"
          :type="fieldToEdit.type"
          class="form-control">
      </div>

      <template #buttons>
        <button
          class="btn-secondary"
          @click="cancelEditField">
          Annuler
        </button>

        <button
          class="btn-primary"
          style="margin-left: 5px;"
          @click="submitEditField">
          Envoyer
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>

import StorePagination from "@/components/listV2/StorePagination.vue";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import { useProductListStore } from "@/stores/productList";

export default {
  name: "ProductGrid",
  components: {
    StorePagination,
    Loader,
    Modal,
    SelectObject,
  },
  data() {
    return {
      store: useProductListStore(),
      loading: false,
      fieldToEdit: null,
    };
  },
  computed: {
    products() {
      return this.store.getResults("grid");
    },
  },
  mounted() {
    this.store.refresh("grid");
  },
  methods: {
    editField(product, field, label, type = "text") {
      this.fieldToEdit = {
        label: label,
        product: product,
        field: field,
        value: product[field],
        type: type,
      };
      this.$refs.editFieldModal.show();
    },
    submitEditField() {
      this.updateField(this.fieldToEdit.product, this.fieldToEdit.field, this.fieldToEdit.value);
      this.cancelEditField();
    },
    cancelEditField() {
      this.$refs.editFieldModal.hide();
      this.fieldToEdit = null;
    },
    updateField(product, field, value) {
      this.loading = true;
      this.$services.product.patch(
        product.id,
        { [field]: value },

      ).then(() => {
        this.store.refresh("grid");

      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>


td, th {
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.table-container {
  overflow: auto;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(231, 231, 231, 0.8); /* Overlay transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Assurez-vous que le loader est au-dessus du contenu de la carte */
  }

  .editable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

</style>
