<template>
  <LoaderView v-if="customerSchema == null || locationSchema == null" />

  <div v-else style="max-width: 700px; width: 100%;">
    <div
      v-for="(contact, index1) in contacts"
      :key="index1">
      <div class="form-group horizontal-spaced-container">
        <strong v-if="index1 == 0">
          Contact principal
        </strong>
        <strong v-else>
          Contact #{{ index1 + 1 }}
        </strong>
        <button
          v-if="index1 > 0"
          class="small-tool-btn"
          @click="contacts.splice(index1, 1)">
          <i class="bi bi-trash" />
        </button>
      </div>
      <PersonForm
        :modelValue="contact"
        @update:modelValue="contacts[index1] = $event" />
    </div>

    <button
      class="btn-link form-group"
      @click="contacts.push({ title: 'mr', language: 'fr' })">
      Ajouter un contact supplémentaire
    </button>

    <hr class="form-group" style="border-color: lightgray;">

    <div
      v-for="(location, index2) in locations"
      :key="index2">
      <div class="form-group horizontal-spaced-container">
        <strong>Adresse d'intervention #{{ index2 + 1 }}</strong>
        <button
          v-if="index2 > 0"
          class="small-tool-btn"
          @click="locations.splice(index2, 1)">
          <i class="bi bi-trash" />
        </button>
      </div>

      <LocationForm
        :modelValue="location"
        :index="index2"
        @update:modelValue="locations[index] = $event" />
    </div>

    <button
      class="btn-link form-group"
      @click="locations.push({ country_code: 'be' })">
      Ajouter une autre adresse d'intervention
    </button>

    <hr class="form-group" style="border-color: lightgray;">

    <div class="form-group">
      <strong>Informations de facturation</strong>
    </div>

    <div v-if="billingNameDifferentFromFirstContact" class="horizontal-spaced-container form-group">
      <div>
        <div class="form-label">
          Nom
        </div>
        <input
          v-model="customBillingPersonLastName"
          type="text"
          class="form-control">
      </div>

      <div>
        <div class="form-label">
          Prénom
        </div>
        <input
          v-model="customBillingPersonFirstName"
          type="text"
          class="form-control">
      </div>
    </div>

    <div v-else class="horizontal-spaced-container form-group">
      <div>Nom et prénom :</div>
      <div>{{ contacts[0].last_name }} {{ contacts[0].first_name }}</div>
      <button
        class="btn-link"
        @click="billingNameDifferentFromFirstContact = true">
        Enregistrer un nom différent pour la facturation
      </button>
    </div>

    <div v-if="showCompanyForm" class="horizontal-spaced-container form-group">
      <div>
        <div class="form-label">
          Nom de la société
        </div>
        <input
          v-model="billingCompanyName"
          type="text"
          class="form-control">
      </div>

      <div>
        <div class="form-label">
          Numéro de TVA
        </div>
        <VatNumber v-model="billingCompanyVat" />
      </div>
    </div>

    <div v-else class="horizontal-spaced-container form-group">
      <div>Société :</div>

      <i>Aucune</i>

      <button
        class="btn-link"
        @click="showCompanyForm = true">
        Enregistrer une société
      </button>
    </div>

    <div v-if="billingAddressDifferentFromFirstLocationAddress">
      <div class="form-group">
        <VueGoogleAutocomplete
          id="billing-address-search"
          :country="countryCodes"
          type="text"
          placeholder="Recherchez une adresse"
          class="form-control"
          @placechanged="onBillingAddressSuggestion" />
      </div>

      <div class="row form-group">
        <div class="col-sm-4">
          <div class="form-label">
            Numéro
          </div>
          <input
            v-model="customBillingStreetNumber"
            type="text"
            class="form-control">
        </div>
        <div class="col-sm">
          <div class="form-label">
            Rue
          </div>
          <input
            v-model="customBillingStreet"
            type="text"
            class="form-control">
        </div>
        <div class="col-sm-4">
          <div class="form-label">
            Boîte postale
          </div>
          <input
            v-model="customBillingBp"
            type="text"
            class="form-control">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm">
          <div class="form-label">
            Code postal
          </div>
          <input
            v-model="customBillingZipcode"
            type="text"
            class="form-control">
        </div>
        <div class="col-sm">
          <div class="form-label">
            Ville
          </div>
          <input
            v-model="customBillingCity"
            type="text"
            class="form-control">
        </div>
        <div class="col-sm">
          <div class="form-label">
            Pays
          </div>
          <CountrySelect
            v-model="customBillingCountryCode"
            class="form-select" />
        </div>
      </div>
    </div>

    <div v-else class="horizontal-spaced-container form-group">
      <div>Adresse :</div>
      <div>{{ locations[0].street_number }} {{ locations[0].street }} {{ locations[0].bp }} {{ locations[0].zipcode }} {{ locations[0].city }} {{ locations[0].country_code }}</div>
      <button
        class="btn-link"
        @click="billingAddressDifferentFromFirstLocationAddress = true">
        Enregistrer une adresse différente
      </button>
    </div>
  </div>
</template>

<script>

import CountrySelect from "@/components/inputs/CountrySelect.vue";
import LoaderView from "@/components/LoaderView.vue";
import PersonForm from "@/components/forms/PersonForm.vue";
import LocationForm from "@/components/forms/LocationForm.vue";
import { favoriteCountries } from "@/countries";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VatNumber from "@/components/vueComponents/input/VatNumber.vue";

export default {
  name: "NewCustomerForm",
  components: {
    CountrySelect,
    LoaderView,
    PersonForm,
    LocationForm,
    VueGoogleAutocomplete,
    VatNumber,
  },
  emits: ["change"],
  data() {
    return {
      customerSchema: null,
      locationSchema: null,

      billingAddressDifferentFromFirstLocationAddress: false,
      billingNameDifferentFromFirstContact: false,
      showCompanyForm: false,

      contacts: [{ title: "mr", language: "fr" }],
      locations: [{ country_code: "be" }],
      billingCompanyName: null,
      billingCompanyVat: null,

      customBillingPersonFirstName: null,
      customBillingPersonLastName: null,
      
      customBillingStreetNumber: null,
      customBillingStreet: null,
      customBillingBp: null,
      customBillingZipcode: null,
      customBillingCity: null,
      customBillingCountryCode: "be",
    };
  },
  computed: {
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
  },
  watch: {
    contacts: {
      handler() {
        this.updateModelValue();
      },
      deep: true,
    },
    locations: {
      handler() {
        this.updateModelValue();
      },
      deep: true,
    },
    billingCompanyName() {
      this.updateModelValue();
    },
    billingCompanyVat() {
      this.updateModelValue();
    },
    customBillingPersonFirstName() {
      this.updateModelValue();
    },
    customBillingPersonLastName() {
      this.updateModelValue();
    },
    customBillingStreetNumber() {
      this.updateModelValue();
    },
    customBillingStreet() {
      this.updateModelValue();
    },
    customBillingBp() {
      this.updateModelValue();
    },
    customBillingZipcode() {
      this.updateModelValue();
    },
    customBillingCity() {
      this.updateModelValue();
    },
    customBillingCountryCode() {
      this.updateModelValue();
    },
    billingAddressDifferentFromFirstLocationAddress() {
      this.updateModelValue();
    },
    billingNameDifferentFromFirstContact() {
      this.updateModelValue();
    },
  },
  mounted() {
    this.fetchCustomerSchema();
    this.fetchLocationSchema();
  },
  methods: {
    fetchCustomerSchema() {
      return this.$services.customer.schema()
        .then((response) => {
          this.customerSchema = response.data;
        });
    },
    fetchLocationSchema() {
      return this.$services.location.schema()
        .then((response) => {
          this.locationSchema = response.data;
        });
    },
    updateModelValue() {

      const customer = {
        contacts: this.contacts,
        locations: this.locations,
        billing_company_name: this.billingCompanyName,
        billing_company_vat: this.billingCompanyVat ? this.billingCompanyVat.replace(/[. ]/g, "") : null,
      };

      if (this.billingNameDifferentFromFirstContact) {
        customer.billing_person_first_name = this.customBillingPersonFirstName;
        customer.billing_person_last_name = this.customBillingPersonLastName;
      } else {
        customer.billing_person_first_name = this.contacts[0].first_name;
        customer.billing_person_last_name = this.contacts[0].last_name;
      }

      if (this.billingAddressDifferentFromFirstLocationAddress) {
        customer.billing_street_number = this.customBillingStreetNumber;
        customer.billing_street = this.customBillingStreet;
        customer.billing_bp = this.customBillingBp;
        customer.billing_zipcode = this.customBillingZipcode;
        customer.billing_city = this.customBillingCity;
        customer.billing_country_code = this.customBillingCountryCode;
      } else {
        customer.billing_street_number = this.locations[0].street_number;
        customer.billing_street = this.locations[0].street;
        customer.billing_bp = this.locations[0].bp;
        customer.billing_zipcode = this.locations[0].zipcode;
        customer.billing_city = this.locations[0].city;
        customer.billing_country_code = this.locations[0].country_code;
      }

      this.$emit("change", customer);
    },
    onBillingAddressSuggestion(addressData) {
      this.customBillingStreetNumber = addressData.street_number;
      this.customBillingStreet = addressData.route;
      this.customBillingZipcode = addressData.postal_code;
      this.customBillingCity = addressData.locality;
      this.customBillingCountryCode = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }

      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
  },
};
</script>

<style>

</style>