<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Vente</span>
      <button v-if="editMode" class="btn btn-link" @click="cancel">
        (annuler)
      </button>
      <button v-else class="btn btn-link" @click="edit">
        (editer)
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <FormField :schema="schema.public_name" :error="validationErrors?.public_name">
        <template #default>
          <input v-model="editedProduct.public_name" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.public_name_nl" :error="validationErrors?.public_name_nl">
        <template #default>
          <input v-model="editedProduct.public_name_nl" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.public_description" :error="validationErrors?.public_description">
        <template #default>
          <input v-model="editedProduct.public_description" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.sale_price_per_unit" :error="validationErrors?.sale_price_per_unit">
        <template #default>
          <input v-model="editedProduct.sale_price_per_unit" type="number" class="form-control">
        </template>
      </FormField>

      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <p>Nom à afficher sur les factures en français : <strong>{{ modelValue.public_name }}</strong></p>
      <p>Nom à afficher sur les factures en néerlandais : <strong>{{ modelValue.public_name_nl }}</strong></p>
      <div class="row">
        <div class="col">
          <p>Description publique :</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="modelValue.public_description" />
        </div>
      </div>
      <p>Prix de vente conseillé par unité (HTVA) : <strong>{{ modelValue.sale_price_per_unit }}</strong></p>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ProductSaleInformationCard",
  components: {
    FormField,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editMode: false,
      editedProduct: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      this.editedProduct = {
        public_name: this.modelValue.public_name,
        public_name_nl: this.modelValue.public_name_nl,
        public_description: this.modelValue.public_description,
        sale_price_per_unit: this.modelValue.sale_price_per_unit,
      };
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedProduct = null;
    },
    submit() {
      this.submitting = true;

      this.$services.product.patch(this.modelValue.id, this.editedProduct)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        });
    },
  },
};

</script>