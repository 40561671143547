<template>
  <div class="card">
    <div class="card-header">
      <span class="title">
        Facture
      </span>
      <button
        v-if="modelValue && !selectingInvoice"
        class="btn btn-link"
        style="margin-left: 5px;"
        @click="gotoInvoice">
        Voir
      </button>
    </div>

    <div v-if="selectingInvoice" class="card-body">
      <p v-if="selectedInvoice">
        {{ selectedInvoice.ref }}
      </p>
      <div class="form-group">
        <SelectObject
          placeholder="Assigner une facture à cette intervention"
          type="invoice"
          @select="onInvoiceSelected" />
      </div>
      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :disabled="savingInvoice" @click="endSelectingInvoice">
          Annuler
        </button>
        <button
          class="btn-primary"
          :class="{ 'loading': savingInvoice }"
          style="margin-left: 5px;"
          :disabled="selectedInvoice == null || savingInvoice"
          @click="saveInvoice">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else-if="modelValue" class="card-body">
      <InvoiceCompactRow
        :object="modelValue" />
    </div>
    
    <div v-else class="card-body">
      <i>Aucune</i>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import InvoiceCompactRow from "@/components/rows/invoice/InvoiceCompactRow.vue";

export default {
  name: "CreditNoteInvoiceCard",
  components: {
    SelectObject,
    InvoiceCompactRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    creditNoteId: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selectingInvoice: false,
      selectedInvoice: null,
      savingInvoice: false,
    };
  },
  methods: {
    gotoInvoice() {
      this.$router.push({ name: "invoice", params: { id: this.modelValue.id }});
    },
    onInvoiceSelected(invoice) {
      this.selectedInvoice = invoice;
    },
    saveInvoice() {
      this.savingInvoice = true;

      this.$services.creditNote.patch(
        this.creditNoteId,
        {
          invoice_id: this.selectedInvoice.id,
        },
      ).then((response) => {
        this.$emit(
          "update:modelValue",
          response.data.invoice,
        );
        this.endSelectingInvoice();
        
      }).finally(() => {
        this.savingInvoice = false;
      });
    },
    endSelectingInvoice() {
      this.selectedInvoice = null;
      this.selectingInvoice = false;
    },
  },
};

</script>