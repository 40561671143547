<template>
  <div>
    <span>{{ object }}</span>
  </div>
</template>

<script>

export default {
  name: "StringResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
