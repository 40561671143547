import { api, encode } from "@/api";


class PlanningApiService {

  fetchPlanning(fromDate: string, toDate: string, technicianId: number | null = null) {
    if (technicianId != null) {
      return api.get(`/planning/?from_date=${fromDate}&to_date=${toDate}&technician_id=${technicianId}`);
    }
    return api.get(`/planning/?from_date=${fromDate}&to_date=${toDate}`);
  }

  fetchFreeSlots(
    interventionId: number | null = null,
    zipcode: string | null = null,
    countryCode: string | null = null,
    technicianIds: Array<string | number> | null = null,
    activityIds: Array<string | number> | null = null,
    dateFrom: string | null = null,
    dateTo: string | null = null,
  ) {
    const args = {
      intervention_id: interventionId,
      zipcode: zipcode,
      country_code: countryCode,
      technician_ids: technicianIds != null ? `[${technicianIds.join(",")}]` : null,
      activity_ids: activityIds ? `[${activityIds.join(",")}]` : null,
      start_date: dateFrom,
      end_date: dateTo,
    };

    return api.get(`/planning/free_slots/?${encode(args)}`);
  }
}

export default PlanningApiService;