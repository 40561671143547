<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <i class="bi bi-telephone" />
    </template>

    <template #title>
      <span class="text-bigger">Appel <span v-if="customerCall.activity">pour {{ customerCall.activity.name }}</span> <span v-if="customerCall.city">à {{ customerCall.city }}</span></span>
      <br>
      {{ $filters.dateStringToHumanizedDateTime(customerCall.created_at) }}
    </template>
    
    <td>
      <router-link v-if="customerCall.customer" class="btn-link" :to="{ name: 'customer', params: { 'id': customerCall.customer.id } }" @click.stop>
        {{ $filters.printCustomer(customerCall.customer) }}
      </router-link>
      <span v-if="customerCall.first_name || customerCall.last_name" class="text-bigger">{{ $filters.fullname(customerCall) }}</span>
      <span v-else class="text-bigger">Aucun nom</span>
      <br>
      <span v-if="customerCall.phone_number" class="text-smaller">{{ customerCall.phone_number }}</span>
      <span v-else class="text-smaller">{{ customerCall.email }}</span>
    </td>
    <td>
      {{ customerCall.company_name }}<br>
      {{ customerCall.company_vat }}
    </td>
    <td>
      <span class="text-smaller">
        {{ customerCall.street }} {{ customerCall.street_number }} {{ customerCall.bp }}
        <br>
        {{ customerCall.zipcode }} {{ customerCall.city }} {{ customerCall.country_code }}
      </span>
    </td>
    <td>
      <span v-if="customerCall.author">{{ $filters.fullname(customerCall.author) }}</span>
    </td>
    <td>
      <div v-if="customerCall.terminated" class="highlighted green">
        Terminée
      </div>
    </td>

    <template #toolButtons>
      <Toolbar>
        <CustomerCallToolButtons v-model="customerCall" />
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";
import CustomerCallToolButtons from "@/components/toolbuttons/CustomerCallToolButtons.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "CustomerCallRow",
  components: {
    CustomerCallToolButtons,
    GBaseRow,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    customerCall: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "customer-call", params: { "id": this.customerCall.id } });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>