<template>
  <Modal ref="confirm-modal" width="small">
    <template #default>
      <strong>{{ message }}</strong>
    </template>
    <template #buttons>
      <button
        :disabled="loading"
        class="btn-primary"
        style="margin-right: 10px;"
        @click="$emit('confirm');">
        <i v-if="loading" class="fa fa-spinner fa-spin" />
        {{ confirmText }}
      </button>
      <button
        :disabled="loading"
        class="btn-secondary"
        @click="hide(); $emit('cancel')">
        {{ cancelText }}
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "ConfirmModal",
  components: {
    Modal,
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    confirmText: {
      type: String,
      required: false,
      default: "Oui",
    },
    cancelText: {
      type: String,
      required: false,
      default: "Annuler",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["confirm", "cancel"],
  methods: {
    show() {
      this.$refs["confirm-modal"].show();
    },
    hide() {
      this.$refs["confirm-modal"].hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>