<template>
  <div class="card-header">
    <div style="display: flex; width: 100%;">
      <div style="flex-grow: 1;">
        <div class="flow-spaced-container">
          <InvoiceIcon
            status="invoice"
            width="20px"
            height="20px" />

          <div class="title">
            Fac. de {{ invoice.total_incl_tax }}€
          </div>

          <div
            v-if="invoice.credit_note"
            class="highlighted red"
            style="width: fit-content;">
            Note de crédit
          </div>
        </div>
      </div>

      <div>
        <slot name="header" />
      </div>
    </div>
  </div>
</template>

<script>

import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";

export default {
  name: "InvoiceCompactCardHeader",
  components: {
    InvoiceIcon,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
};

</script>