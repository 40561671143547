<template>
  <div class="splashscreen">
    <div class="logo-container" style="margin-bottom: 5px;">
      <img src="/logo_EDN_part.png" alt="Logo" class="logo-part">
      <img src="/logo_EDN.png" alt="Logo" class="logo">
    </div>
    <div class="subtitle">
      Espace <span class="gray">prestataire</span>
    </div>
  </div>
</template>

<script>


export default {
  name: "SplashScreen",
};
</script>

<style scoped lang="scss">

.splashscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .logo-container {
    position: relative;
    width: 200px;
    height: 80px;

    .logo {
      width: 200px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .logo-part {
      position: absolute;
      top: 0;
      left: 0;
      width: 68px;
      animation: Animation 1.5s infinite;
      transition-property: transform;
      transform-origin: 50% 50%;
      background: #e6e6e6;
      z-index: 2;
      border-radius: 50%;

      @keyframes Animation {
        0% {
          transform: rotate(0deg);
        }
        40% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .subtitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #e92628;
    font-size: 2rem;

    .gray {
      color: #3b3d40;
    }
  }
  
}
</style>
