<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Evènements Agenda Google</span>
      <button
        class="tool-btn tooltip"
        :disabled="modelValue.rdv_date == null || synchronizingWithGoogleCalendar"
        @click.stop="syncWithGoogleCalendar">
        <Loader v-if="synchronizingWithGoogleCalendar" />
        <i v-else class="bi bi-arrow-repeat" />
        <div class="tooltip-text left">
          Synchroniser avec Google Agenda maintenant <span v-if="modelValue.rdv_date == null" class="text-red">(Assignez d'abord une date de rdv)</span>
        </div>
      </button>
    </div>
    <div class="card-body">
      <table v-if="modelValue.calendar_events && modelValue.calendar_events.length > 0">
        <tr
          v-for="(googleEvent, index) in modelValue.calendar_events"
          :key="index">
          <td>
            <div v-if="googleEvent.google_calendar_event_id != null" class="tooltip">
              Créé dans l'agenda de <strong>{{ $filters.fullname(googleEvent.user) }}</strong> pour le {{ googleEvent.last_pushed_start_date != null ? $filters.dateStringToHumanizedDate(googleEvent.last_pushed_start_date) : 'date inconnue' }} à {{ googleEvent.last_pushed_start_time != null ? googleEvent.last_pushed_start_time : 'heure inconnue' }}
              <span class="tooltip-text">Mis à jour le {{ $filters.dateStringToHumanizedDateTime(googleEvent.last_pushed_to_google_calendar_at) }}</span>
            </div>
            <div v-else>
              Pas encore créé dans l'agenda de <strong>{{ $filters.fullname(googleEvent.user) }}</strong>
            </div>
          </td>
        </tr>
      </table>
      <i v-else>Aucune</i>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader.vue";


export default {
  name: "InterventionCalendarEventsCard",
  components: {
    Loader,
  },
  props: {
    modelValue: { // The intervention
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      synchronizingWithGoogleCalendar: false,
    };
  },
  methods: {
    syncWithGoogleCalendar() {
      this.synchronizingWithGoogleCalendar = true;

      this.$services.intervention.syncWithGoogleCalendar(this.modelValue.id)
        .then((response) => {
          this.$emits(
            "update:modelValue",
            {
              ...this.modelValue,
              calendar_events: response.data.calendar_events,
            },
          );
        })
        .finally(() => {
          this.synchronizingWithGoogleCalendar = false;
        });
    },
  },

};

</script>