<template>
  <BaseDropdown>
    <template #component>
      <div class="loggin-icon">
        <i
          class="bi bi-box-arrow-up-right"
          style="color: rgb(100, 100, 100); font-size: 2rem;" />
        <i class="arrow down" />
      </div>
    </template>
    <SimpleButton
      :loading="exportingToCsv"
      icon="bi bi-filetype-csv"
      text="CSV"
      @click="exportToCsv" />
    <SimpleButton
      :loading="exportingToXlsx"
      icon="bi bi-filetype-xlsx"
      text="XLSX"
      @click="exportToXlsx" />
  </BaseDropdown>
</template>

<script setup lang="ts">

import BaseDropdown from "@/components/vueComponents/base/BaseDropdown.vue";
import { ref } from "vue";
import { api } from "@/api";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  uri: { type: String, required: true },
});

// Data

const exportingToCsv = ref(false);
const exportingToXlsx = ref(false);

// Methods

const exportToCsv = () => {
  exportingToCsv.value = true;

  api.get(`${props.uri}?output=csv`)
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "historique_de_caisse.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      exportingToCsv.value = false;
    });
};

const exportToXlsx = () => {
  exportingToXlsx.value = true;

  api.get(`${props.uri}?output=xlsx`, {responseType: "arraybuffer"})
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      // Créer un objet URL pour le Blob
      const URL = window.URL.createObjectURL(blob);

      // Créer un lien temporaire pour le téléchargement du fichier Excel
      const a = document.createElement("a");
      a.href = URL;
      a.download = "historique_de_caisse.xlsx";
      a.click();

      // Libérer les ressources
      window.URL.revokeObjectURL(URL);
    })
    .finally(() => {
      exportingToXlsx.value = false;
    });
};




</script>

<style lang="scss" scoped>

  .loggin-icon {
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }


</style>