<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Informations générales</span>
      <button v-if="!editMode" class="small-tool-btn" @click="edit">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <FormField title="Nombre final d'interventions" :error="validationErrors?.final_nb_interventions">
        <input
          v-model="finalNbInterventions"
          class="form-control"
          style="width: auto;"
          min="0"
          type="number">
      </FormField>

      <FormField
        title="Remarques à afficher sur la facture"
        :error="validationErrors?.public_remarks">
        <input
          v-model="publicRemarks"
          type="text"
          class="form-control">
      </FormField>
      
      <div style="display: flex; justify-content: flex-end;">
        <button
          class="btn-secondary mr-2"
          :disabled="submitting"
          @click="cancel">
          Annuler
        </button>

        <button
          :class="{ 'loading': submitting }"
          class="btn-primary"
          :disabled="submitting"
          @click="submit">
          Enregistrer les modifications
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <div>
        <p>Journal: <strong>{{ modelValue.journal.origin.code }} : {{ modelValue.journal.invoice_code_prefix }}</strong></p>
        <p>
          Nombre final d'interventions: <strong>{{ modelValue.final_nb_interventions }}</strong>
        </p>
        <p>
          Remarques à afficher sur la facture : <strong v-if="modelValue.public_remarks">{{ modelValue.public_remarks }}</strong><i v-else>Aucune</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/forms/FormField.vue";

export default {
  name: "InvoiceInfoCard",
  components : {
    FormField,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      submitting: false,
      validationErrors: null,
      editedInvoice: {},
      editMode: false,
    };
  },
  computed: {
    finalNbInterventions: {
      get() {
        return this.modelValue.final_number_of_interventions;
      },
      set(val) {
        this.editedInvoice.final_nb_interventions = val;
      },
    },
    publicRemarks: {
      get() {
        return this.modelValue.public_remarks;
      },
      set(val) {
        this.editedInvoice.public_remarks = val;
      },
    },
  },
  methods: {
    edit() {
      this.editedInvoice = {};
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
    },
    submit() {
      this.submitting = true;
      this.validationErrors = null;

      this.$services.invoice.patch(this.modelValue.id, this.editedInvoice)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            {
              ...this.modelValue,
              final_nb_interventions: response.data.final_nb_interventions,
              public_remarks: response.data.public_remarks,
            },
          );
          this.editMode = false;

        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }

        }).finally(() => {
          this.submitting = false;
        });
    },
  },
};

</script>