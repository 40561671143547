import { defineStore } from "pinia";

interface State {
  objects: Array<any>;
}

export const useInterventionSnoozeReasonChoicesStore = defineStore("interventionSnoozeReasonChoices", {
  state: (): State => ({
    objects: [],
  }),
  getters: {
    get: state => {
      return state.objects;
    },
  },
  actions: {
    set(objects: Array<any>) {
      this.objects = objects;
    },
  },
});
