import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useSaleListStore = buildPaginatedMultiListStore(
  "saleList",
  "/sales/",
  "Aucune vente trouvée",
  false,
  (filters: object, subList: string) => {
    filters.status = subList;
    return filters;
  },
  () => ({
    tempNbSaleToShip: 0,
  }),
  {
    getNbSaleToShip: state => () => {
      if (state.subLists.to_ship) {
        return state.subLists.to_ship.total;
      } else {
        return state.tempNbSaleToShip;
      }
    },
  },
  {
    setTempNbSaleToShip(tempNbSaleToShip: any) {
      this.tempNbSaleToShip = tempNbSaleToShip;
    },
  },
);
