import { defineStore } from "pinia";

interface State {
  storages: Array<any>;
}

export const useStoragesStore = defineStore("storages",{
  state: (): State => ({
    storages: [],
  }),
  getters: {
    getStorages: state => {
      return state.storages;
    },
    getStorageById: state => (id: number) => {
      if (!state.storages) {
        return null;
      }
      return state.storages.find(storage => storage.id == id);
    },
  },
  actions: {
    setStorages(storages: Array<any>) {
      this.storages = storages;
    },
  },
});
