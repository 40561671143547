<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Historique</span>
    </div>
    <div class="card-body">
      <ul v-if="history && history.length > 0" role="list" class="space-y-6" style="margin-bottom: 10px;">
        <li
          v-for="(historicalRecord, historicalRecordIndex) in history"
          :key="historicalRecordIndex"
          class="relative flex gap-x-4">
          <div v-if="historicalRecordIndex != history.length - 1" class="absolute left-0 flex w-6 justify-center" style="top: 20px; height: 100%;">
            <div class="w-px bg-gray-500 text-gray-500" />
          </div>
          <div v-if="historicalRecord.history_user_id">
            <UserCircleIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
          </div>
          <div v-else class="relative flex h-6 w-6 flex-none items-center justify-center">
            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
          </div>
          <HistoricalRecord
            :historicalRecord="historicalRecord"
            :previousHisoricalRecord="historicalRecordIndex >= history.length - 1 ? null : history[historicalRecordIndex - 1]"
            :objectName="objectName" />
        </li>
      </ul>

      <!-- New comment form -->

      <div style="display: flex;">
        <i class="bi bi-person-circle" style="padding: 0 5px; font-size: 1.2rem;" />
        <div style="position: relative; flex-grow: 1;">
          <textarea
            v-model="comment"
            :disabled="commenting"
            rows="3"
            class="form-control"
            style="resize: none; width: 100%;"
            placeholder="Ajouter un commentaire..." />
          <button
            class="tool-btn"
            :disabled="commenting || !comment"
            style="position: absolute; bottom: 0; right: 0px; padding: 5px;"
            @click="submitComment">
            <Loader v-if="commenting" />
            <i v-else class="bi bi-send" style="font-size: 1.4rem;" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { UserCircleIcon } from "@heroicons/vue/24/solid";
import HistoricalRecord from "@/components/cards/HistoricalRecord.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "HistoryCard",
  components: {
    UserCircleIcon,
    HistoricalRecord,
    Loader,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Evènements",
    },
    objectName: {
      type: String,
      required: false,
      default: "l'objet",
    },
    history: {
      type: Array,
      required: true,
    },
    postComment: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      comment: null,
      commenting: false,
    };
  },
  methods: {
    reset() {
      this.comment = null;
      this.commenting = false;
    },
    submitComment() {
      this.commenting = true;
      this.postComment(this.comment)
        .then(() => {
          this.reset();
        });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>