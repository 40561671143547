<template>
  <select v-model="internalValue">
    <option
      v-for="(country, code) in favoriteCountries"
      :key="code"
      :value="code">
      {{ country.name }}
    </option>
    <option disabled>
      ──────────
    </option>
    <option
      v-for="(country, code) in countries"
      :key="code"
      :value="code">
      {{ country.name }}
    </option>
  </select>
</template>

<script>


import { countries, favoriteCountries } from "@/countries";

export default {
  name: "CountrySelect",
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit(
          "update:modelValue",
          newVal,
        );
      },
    },
    favoriteCountries() {
      return favoriteCountries;
    },
    countries() {
      return countries;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>