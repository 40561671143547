<template>
  <div>
    <ObjectViewLayout
      title="Nouveau client"
      withBackButton
      @back="$router.push({ name: 'customer-list' })">
      <div class="card" style="margin-bottom: 10px;">
        <div class="card-body">
          <NewCustomerForm @change="object = $event" />
        </div>
      </div>
        
      <div style="display: flex; justify-content: center; margin-top: 10px;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting || !canSubmit" @click="submit">
          Valider
        </button>
      </div>
    </ObjectViewLayout>
  </div>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import NewCustomerForm from "@/components/forms/NewCustomerForm.vue";

export default {
  name: "CustomerCreationView",
  components: {
    ObjectViewLayout,
    NewCustomerForm,
  },
  data() {
    return {
      submitting: false,
      object: null,
    };
  },
  computed: {
    canSubmit() {
      if (this.object == null) {
        return false;
      }
      return this.billingInfoAreValid && this.locationsAreValid && this.contactsAreValid;
    },
    billingInfoAreValid() {
      if (this.object == null) {
        return false;
      }
      return this.object.billing_person_last_name != null && this.object.billing_person_last_name.length > 0 && this.object.billing_street != null && this.object.billing_street.length > 0 && this.object.billing_city != null && this.object.billing_city.length > 0 && this.object.billing_country_code != null && this.object.billing_country_code.length > 0;
    },
    locationsAreValid() {
      if (this.object == null) {
        return false;
      }
      
      if (this.object.locations.length == 0) {
        return false;
      }

      for (let location of this.object.locations) {
        if (location.street == null || location.street.length == 0 || location.zipcode == null || location.zipcode.length == 0 || location.city == null || location.city.length == 0 || location.country_code == null || location.country_code.length == 0) {
          return false;
        }
      }

      return true;
    },
    contactsAreValid() {
      if (this.object == null) {
        return false;
      }

      if (this.object.contacts.length == 0) {
        return false;
      }

      for (let contact of this.object.contacts) {
        if (contact.last_name == null || contact.last_name.length == 0) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    submit() {
      this.submitting = true;

      this.$services.customer.create(this.object)
        .then((response) => {
          this.$router.push({ name: "customer", params: { id: response.data.id } });


        }).finally(() => {
          this.submitting = false;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-list" });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>