const favoriteCountries = {
  be: { name: "Belgique", dialCode: "32" },
  fr: { name: "France", dialCode: "33" },
  lu: { name: "Luxembourg", dialCode: "352" },
  nl: { name: "Pays-Bas", dialCode: "31" },
};

const countries = {
  al: { name: "Albanie", dialCode: "355" },
  ad: { name: "Andorre", dialCode: "376" },
  am: { name: "Arménie", dialCode: "374" },
  at: { name: "Autriche", dialCode: "43" },
  az: { name: "Azerbaïdjan", dialCode: "994" },
  by: { name: "Biélorussie", dialCode: "375" },
  be: { name: "Belgique", dialCode: "32" },
  ba: { name: "Bosnie-Herzégovine", dialCode: "387" },
  bg: { name: "Bulgarie", dialCode: "359" },
  hr: { name: "Croatie", dialCode: "385" },
  cy: { name: "Chypre", dialCode: "357" },
  cz: { name: "République tchèque", dialCode: "420" },
  dk: { name: "Danemark", dialCode: "45" },
  ee: { name: "Estonie", dialCode: "372" },
  fi: { name: "Finlande", dialCode: "358" },
  fr: { name: "France", dialCode: "33" },
  ge: { name: "Géorgie", dialCode: "995" },
  de: { name: "Allemagne", dialCode: "49" },
  gr: { name: "Grèce", dialCode: "30" },
  hu: { name: "Hongrie", dialCode: "36" },
  is: { name: "Islande", dialCode: "354" },
  ie: { name: "Irlande", dialCode: "353" },
  it: { name: "Italie", dialCode: "39" },
  kz: { name: "Kazakhstan", dialCode: "7" },
  lv: { name: "Lettonie", dialCode: "371" },
  li: { name: "Liechtenstein", dialCode: "423" },
  lt: { name: "Lituanie", dialCode: "370" },
  lu: { name: "Luxembourg", dialCode: "352" },
  mk: { name: "Macédoine du Nord", dialCode: "389" },
  mt: { name: "Malte", dialCode: "356" },
  md: { name: "Moldavie", dialCode: "373" },
  mc: { name: "Monaco", dialCode: "377" },
  me: { name: "Monténégro", dialCode: "382" },
  nl: { name: "Pays-Bas", dialCode: "31" },
  no: { name: "Norvège", dialCode: "47" },
  pl: { name: "Pologne", dialCode: "48" },
  pt: { name: "Portugal", dialCode: "351" },
  ro: { name: "Roumanie", dialCode: "40" },
  ru: { name: "Russie", dialCode: "7" },
  sm: { name: "Saint-Marin", dialCode: "378" },
  rs: { name: "Serbie", dialCode: "381" },
  sk: { name: "Slovaquie", dialCode: "421" },
  si: { name: "Slovénie", dialCode: "386" },
  es: { name: "Espagne", dialCode: "34" },
  se: { name: "Suède", dialCode: "46" },
  ch: { name: "Suisse", dialCode: "41" },
  tr: { name: "Turquie", dialCode: "90" },
  ua: { name: "Ukraine", dialCode: "380" },
  gb: { name: "Royaume-Uni", dialCode: "44" },
  va: { name: "Cité du Vatican", dialCode: "379" },
};

export { countries, favoriteCountries };
