<template>
  <Modal ref="customer-creation-modal" width="full">
    TODO
    <!--
    <CustomerForm
      v-model="customer"
      @valid="customerCreationFormIsValid = true"
      @invalid="customerCreationFormIsValid = false" />
    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          @click="cancel">
          Annuler
        </button>

        <button
          class="btn-primary"
          :disabled="!customerCreationFormIsValid"
          @click="submit">
          Ok
        </button>
      </div>
    </template>
    -->
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import NewCustomerForm from "@/components/forms/NewCustomerForm.vue";

export default {
  name: "CreateCustomerModal",
  components: {
    Modal,
    NewCustomerForm,
  },
  emits: ["submit"],
  data() {
    return {
      customer: {
        contact: {
          title: "mr",
          language: "fr",
        },
        address: {
          country_code: "be",
        },
      },
      customerCreationFormIsValid: false,
    };
  },
  methods: {
    clear()  {
      this.customer = {
        contact: {
          title: "mr",
          language: "fr",
        },
        address: {
          country_code: "be",
        },
      };

      this.customerCreationFormIsValid = false;
    },
    show() {
      this.clear();
      this.$refs["customer-creation-modal"].show();
    },
    hide() {
      this.$refs["customer-creation-modal"].hide();
    },
    submit() {
      this.$emit("submit", this.customer);
      this.hide();
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>