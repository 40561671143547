<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-file">
    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
    <polyline points="13 2 13 9 20 9" />
    <text
      v-if="status == 'quote'"
      font-weight="bold"
      transform="matrix(0.357303, 0, 0, 0.389627, 8.35466, 16.87)"
      xml:space="preserve"
      text-anchor="start"
      font-size="28"
      stroke-width="0"
      fill="currentColor"
      y="5"
      x="-5">
      D
    </text>
    <text
      v-else-if="status == 'draft'"
      font-weight="bold"
      transform="matrix(0.357303, 0, 0, 0.389627, 8.35466, 16.87)"
      xml:space="preserve"
      text-anchor="start"
      font-size="28"
      stroke-width="0"
      fill="currentColor"
      y="5"
      x="-5">
      B
    </text>
    <text
      v-else-if="status == 'invoice'"
      font-weight="bold"
      transform="matrix(0.357303, 0, 0, 0.389627, 8.35466, 16.87)"
      xml:space="preserve"
      text-anchor="start"
      font-size="28"
      stroke-width="0"
      fill="currentColor"
      y="5"
      x="-5">
      F
    </text>
    <text
      v-else-if="status == 'creditNote'"
      font-weight="bold"
      transform="matrix(0.357303, 0, 0, 0.389627, 8.35466, 16.87)"
      xml:space="preserve"
      text-anchor="start"
      font-size="28"
      stroke-width="0"
      fill="currentColor"
      y="5"
      x="-5">
      NC
    </text>
    <text
      v-else-if="status == 'contract'"
      font-weight="bold"
      transform="matrix(0.357303, 0, 0, 0.389627, 8.35466, 16.87)"
      xml:space="preserve"
      text-anchor="start"
      font-size="28"
      stroke-width="0"
      fill="currentColor"
      y="5"
      x="-5">
      C
    </text>
  </svg>
</template>

<script>

export default {
  name: "InvoiceIcon",
  props: {
    status: {
      type: String,
      required: false,
      default: null,
      validator: (value) => {
        return [
          "draft",
          "quote",
          "invoice",
          "creditNote",
          "contract",
        ].indexOf(value) !== -1;
      },
    },
  },
};

</script>

<style>

</style>