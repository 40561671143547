<template>
  <div class="row">
    <div
      v-for="(activitiesInThatCategory, category) in availableActivitiesPerCategoy"
      :key="category"
      class="col">
      <div v-for="(activity, index) in activitiesInThatCategory" :key="index" class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          :checked="internalActivityIds.includes(activity.id)"
          @change="onActivitySelected(activity, $event.target.checked)">
        <label class="form-check-label">{{ activity.icon }} {{ activity.name }}</label>
      </div>
    </div>
  </div>  
</template>

<script>

import { useActivitiesStore } from "@/stores/activities";

export default {
  name: "MultiActivityInput",
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => {return [];},
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      activitiesStore: useActivitiesStore(),
      internalActivityIds: [...this.modelValue],
    };
  },
  computed: {
    availableActivitiesPerCategoy() {
      if (this.availableActivities == null || this.availableActivities.length == 0) {
        return {};
      }

      const res = {};

      this.availableActivities.forEach((activity) => {
        if (!res[activity.category]) {
          res[activity.category] = [];
        }
        res[activity.category].push(activity);
      });

      Object.values(res).forEach((activities) => {
        activities.sort((a, b) => {
          return a.order < b.order;
        });
      });

      return res;
    },
    availableActivities() {
      return this.activitiesStore.getActivities;
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.internalActivityIds = [...newValue];
      },
      deep: true,
    },
  },
  methods: {
    onActivitySelected(activity, selected) {

      if (this.internalActivityIds == null) {
        this.internalActivityIds = [];
      }

      const index = this.internalActivityIds.indexOf(activity.id);

      if (!selected && index != -1) {
        this.internalActivityIds.splice(index, 1);

      } else if (selected && index == -1) {
        this.internalActivityIds.push(activity.id);
      }

      this.$emit("update:modelValue", this.internalActivityIds);

      this.$emit("change", this.internalActivityIds);
      
      this.$emit(selected ? "select" : "unselect", activity);
    },
  },
};
</script>
