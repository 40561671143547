import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";
import { api } from "@/api";
import { AxiosResponse } from "axios";


class SaleV2ApiService extends ModelApiService {
  constructor() {
    super("/sales/", "vente");
  }

  pushToSendcloud(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/push_to_sendcloud/",
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commande envoyée sur SendCloud. Retrouvez la commande sur SendCloud pour générer une étiquette d'envoi.");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de la commande sur SendCloud");
          reject(error);
        });
    });
  }

  fetchLabel(id: number) {
    return api.get(
      this.url + id + "/label/",
    );
  }

  return(id: number, reason: string, lines: Array<any>, receptionDate: Date | null = null) {

    const body = {
      reason: reason,
      lines: lines,
    };

    if (receptionDate) {
      body["reception_date"] = receptionDate;
    }
    
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/sale_return/",
        body,
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Retour enregistré");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'enregistrement du retour");
          reject(error);
        });
    });
  }

}

export default SaleV2ApiService;