<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Intervention</span>
    </div>

    <div v-if="selectExistingIntervention" class="card-body">
      <div class="form-group" style="max-width: 300px;">
        <SelectObject
          type="intervention"
          :selectedValue="modelValue ? modelValue.ref_number : null"
          @select="onExistingInterventionSelect"
          @unselect="onExistingInterventionSelect(null)" />
        <small
          v-if="mode == 'invoice' && modelValue != null && modelValue.invoice != null"
          class="form-text text-red">
          Cette intervention est déjà liée à une facture
        </small>
        <small
          v-else-if="mode == 'quote' && modelValue != null && modelValue.quote != null"
          class="form-text text-red">
          Cette intervention est déjà liée à un devis
        </small>
      </div>
      <div>
        <button class="btn-link" @click="selectExistingIntervention = false">
          Créer une nouvelle intervention
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <div v-if="mode != 'intervention'">
        <button class="btn-link" @click="selectExistingIntervention = true">
          Sélectionner une intervention existante
        </button>
      </div>
      <LoaderView v-if="schemaIntervention == null" />
      <div v-else>
        <FormField :schema="schemaIntervention.origin" :errors="validationErrors?.origin" style="max-width: 200px;">
          <template #default>
            <OriginSelect v-model="selectedOriginCode" />
          </template>
        </FormField>
        
        <FormField :schema="schemaIntervention.rdv_date" :errors="validationErrors?.rdv_date">
          <template #default>
            <RdvDatePicker
              v-model="newIntervention.rdv_date" />
          </template>
        </FormField>

        <div class="flow-spaced-container">
          <FormField
            title="Le technicien peut arriver à partir de"
            :schema="schemaIntervention.rdv_min_start_time"
            :errors="validationErrors?.rdv_min_start_time">
            <template #default>
              <TimeInput
                v-model="newIntervention.rdv_min_start_time" />
            </template>
          </FormField>

          <FormField
            title="Le technicien peut arriver jusqu'à"
            :schema="schemaIntervention.rdv_max_start_time"
            :errors="validationErrors?.rdv_max_start_time">
            <template #default>
              <TimeInput
                v-model="newIntervention.rdv_max_start_time" />
            </template>
          </FormField>
        </div>

        <FormField
          v-if="newIntervention.custom_duration != null"
          :schema="schemaIntervention.custom_duration"
          :errors="validationErrors?.custom_duration">
          <template #default>
            <div class="input-group">
              <input
                v-model="newIntervention.custom_duration"
                type="number"
                min="0"
                class="form-control"
                style="width: auto;">
              <div class="input-group-append">
                min
              </div>
            </div>
          </template>
        </FormField>
        
        <FormField :schema="schemaIntervention.is_emergency" :errors="validationErrors?.is_emergency" hideTitle>
          <template #default>
            <div class="form-check">
              <input
                v-model="newIntervention.is_emergency"
                class="form-check-input"
                type="checkbox">
              <label class="form-check-label">
                {{ schemaIntervention.is_emergency.verbose_name }}
              </label>
            </div>
          </template>
        </FormField>
        
        <FormField
          :schema="schemaIntervention.activities"
          :errors="validationErrors?.activities">
          <template #default>
            <MultiActivityInput
              :modelValue="newInterventionActivityIds"
              @select="onActivitySelected"
              @unselect="onActivityUnselected" />
          </template>
        </FormField>

        <FormField
          :schema="schemaIntervention.location"
          :errors="validationErrors?.location">
          <div
            v-for="(location, index) in customer.locations"
            :key="index">
            <div class="form-check">
              <input
                v-model="newIntervention.location_id"
                class="form-check-input"
                type="radio"
                :value="location.id"
                @change="if ($event.target.checked) delete newIntervention.location;">
              <label class="form-check-label">
                {{ location.street_number }} {{ location.street }} {{ location.bp }}, {{ location.zipcode }} {{ location.city }} {{ location.country_code }}
              </label>
            </div>
          </div>

          <div
            v-if="customLocation">
            <div class="form-check">
              <input
                :checked="newIntervention.location != null"
                class="form-check-input"
                type="radio"
                @change="if ($event.target.checked) newIntervention.location = customLocation; delete newIntervention.location_id;">
              <label class="form-check-label">
                {{ customLocation.street_number }} {{ customLocation.street }} {{ customLocation.bp }}, {{ customLocation.zipcode }} {{ customLocation.city }} {{ customLocation.country_code }}
              </label>
            </div>
          </div>

          <button v-else class="btn-link" @click="editCustomLocation">
            Autre adresse
          </button>
        </FormField>
        
        <FormField
          :schema="schemaIntervention.remarks"
          :errors="validationErrors?.remarks">
          <template #default>
            <textarea v-model="newIntervention.remarks" class="form-control" />
          </template>
        </FormField>
      </div>
    </div>

    <div class="card-footer justify-right">
      <div class="flow-spaced-container">
        <button
          v-if="!selectExistingIntervention"
          class="btn btn-secondary"
          :disabled="!interventionIsValid || submitting"
          :class="{ 'loading': submitting }"
          @click="submitAndEnd">
          Créer l'intervention puis terminer
        </button>

        <button
          v-if="['quote', 'intervention'].includes(mode)"
          class="btn btn-primary"
          :disabled="!interventionIsValid || submitting"
          @click="submitAndGotoQuote">
          Créer un devis
        </button>

        <button
          v-if="['quote'].includes(mode)"
          class="btn btn-secondary"
          :disabled="submitting"
          @click="confirmNoInterventionThenGotoQuote">
          Créer un devis sans intervention
        </button>

        <button
          v-if="['invoice', 'intervention'].includes(mode)"
          class="btn btn-primary"
          :disabled="!interventionIsValid || submitting"
          @click="submitAndGotoInvoice">
          Créer une facture
        </button>

        <button
          v-if="['invoice'].includes(mode)"
          class="btn btn-secondary"
          :disabled="submitting"
          @click="confirmNoInterventionThenGotoInvoice">
          Créer une facture sans intervention
        </button>
      </div>
    </div>

    <Modal ref="editLocationModal">
      <LocationForm
        v-if="editedCustomLocation"
        v-model="editedCustomLocation"
        defaultShowCustomForm />

      <template #buttons>
        <button
          class="btn-secondary"
          @click="editedCustomLocation = null; $refs.editLocationModal.hide()">
          Annuler
        </button>

        <button
          class="btn-primary"
          style="margin-left: 5px;"
          :disabled="editedCustomLocation == null || editedCustomLocation.street == null || editedCustomLocation.zipcode == null || editedCustomLocation.city == null || editedCustomLocation.country_code == null"
          @click="onSaveEditedCustomLocation">
          Enregistrer
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";
import OriginSelect from "@/components/selects/OriginSelect.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";
import Modal from "@/components/modals/Modal.vue";
import LocationForm from "@/components/forms/LocationForm.vue";

export default {
  name: "InterventionCreationStepCard",
  components: {
    LoaderView,
    SelectObject,
    FormField,
    MultiActivityInput,
    OriginSelect,
    RdvDatePicker,
    TimeInput,
    Modal,
    LocationForm,
  },
  props: {
    modelValue: { // The intervention
      type: Object,
      required: false,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
      validator: (value) => {
        if (!value.billing_person_last_name) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain a billing_person_last_name field");
        }

        if (!value.billing_street) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain a billing_street field");
        }

        if (!value.billing_zipcode) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain a billing_zipcode field");
        }

        if (!value.billing_city) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain a billing_city field");
        }

        if (!value.billing_country_code) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain a billing_country_code field");
        }

        if (!value.contacts || value.contacts.length <= 0) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain at least one contact");
        }

        if (!value.locations || value.locations.length <= 0) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain at least one location");
        }

        return true;
      },
    },
    mode: {
      type: String,
      required: true,
      validator: (value) => {
        return ["invoice", "quote", "intervention"].includes(value);
      },
    },
    interventionToCopy: {
      type: Object,
      required: false,
      default: null,
      validator: (value) => {
        return value == null || value.origin != null;
      },
    },
    invoiceOrQuoteToCopy: { // If specified, and if has intervention, copy origin and activities from this intervention
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "gotoInvoice", "gotoQuote"],
  data() {
    return {

      // States
      initializing: false,
      submitting: false,

      // Schemas
      schemaIntervention: null,

      // Intervention to create
      selectedOriginCode: null,
      newInterventionActivities: null,
      newIntervention: {},
      customLocation: null,
      editedCustomLocation: null,

      // ValidationErrors
      validationErrors: null,

      // Modes
      selectExistingIntervention: this.mode != "intervention",

      editInterventionContact: false,
      editInterventionAddress: false,

    };
  },
  computed: {
    interventionIsValid() {
      if (this.selectExistingIntervention) {
        if (this.modelValue == null) {
          return false;
        } else if (this.mode == "invoice" && this.modelValue.invoice != null) {
          return false;
        } else if (this.mode == "quote" && this.modelValue.quote != null) {
          return false;
        }
        return true;
      } else {
        if (this.selectedOriginCode == null) {
          return false;
        }

        if (this.newInterventionActivities == null) {
          return false;
        }

        if (this.newInterventionActivities.length == 0) {
          return false;
        }

        if (this.newIntervention.location != null) {
          return this.newIntervention.location.street != null && this.newIntervention.location.zipcode != null && this.newIntervention.location.city != null && this.newIntervention.location.country_code != null;
        
        } else {
          return this.newIntervention.location_id != null;
        }

      }
    },
    newInterventionActivityIds() {
      if (this.newInterventionActivities == null) {
        return [];
      }

      const res = [];

      for (const activity of this.newInterventionActivities) {
        res.push(activity.id);
      }

      return res;
    },
  },
  async mounted() {
    this.fetchSchema();

    if (this.invoiceOrQuoteToCopy && this.invoiceOrQuoteToCopy.interventions && this.invoiceOrQuoteToCopy.interventions.length > 0) {
      const interventionToCopy = this.invoiceOrQuoteToCopy.interventions[0];
      this.selectedOriginCode = interventionToCopy.origin.code;
      this.newInterventionActivities = interventionToCopy.activities;
      
    } else if (this.interventionToCopy) {
      this.selectedOriginCode = this.interventionToCopy.origin.code;
      this.newInterventionActivities = this.interventionToCopy.activities;

      if (this.interventionToCopy.technician) {
        this.newIntervention.technician_id = this.interventionToCopy.technician.id;
      }

      if (this.interventionToCopy.custom_duration) {
        this.newIntervention.custom_duration = this.interventionToCopy.custom_duration;
      }
    }

    if (this.customer.locations.length == 1) {
      this.newIntervention.location_id = this.customer.locations[0].id;
    }
  },
  methods: {

    // Fetch schemas

    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schemaIntervention = response.data;
        });
    },

    confirmNoInterventionThenGotoInvoice() {
      const answer = window.confirm("Etes-vous sûr de vouloir créer une facture sans intervention ?");
      if (answer) {
        this.$emit("update:modelValue", null);
        this.$emit("gotoInvoice");
      }
    },

    confirmNoInterventionThenGotoQuote() {
      const answer = window.confirm("Etes-vous sûr de vouloir créer un devis sans intervention ?");
      if (answer) {
        this.$emit("update:modelValue", null);
        this.$emit("gotoQuote");
      }
    },

    // Events

    onExistingInterventionSelect(intervention) {
      this.$emit("update:modelValue", intervention);
      this.selectExistingIntervention = true;
    },

    onActivitySelected(activity) {
      if (this.newInterventionActivities == null) {
        this.newInterventionActivities = [];
      }

      const index = this.newInterventionActivities.findIndex((alreadySelectedActivity) => {
        return alreadySelectedActivity.id == activity.id;
      });

      if (index == -1) {
        this.newInterventionActivities.push(activity);
      }
    },

    onActivityUnselected(activity) {
      if (this.newInterventionActivities == null) {
        this.newInterventionActivities = [];
      }

      const index = this.newInterventionActivities.findIndex((alreadySelectedActivity) => {
        return alreadySelectedActivity.id == activity.id;
      });

      if (index != -1) {
        this.newInterventionActivities.splice(index, 1);
      }
    },

    editCustomLocation() {
      if (this.editedCustomLocation == null) {
        this.editedCustomLocation = {};
      }
      this.$refs.editLocationModal.show();
    },

    onSaveEditedCustomLocation() {
      this.customLocation = this.editedCustomLocation;
      this.editedCustomLocation = null;
      this.newIntervention.location = this.customLocation;
      delete this.newIntervention.location_id;
      this.$refs.editLocationModal.hide();
    },

    // Create intervention

    submitAndGotoInvoice() {
      this.submit()
        .then(() => {
          this.$emit("gotoInvoice");
        });
    },

    submitAndGotoQuote() {
      this.submit()
        .then(() => {
          this.$emit("gotoQuote");
        });
    },

    submitAndEnd() {
      this.submit()
        .then((response) => {
          if (response) {
            this.$router.push({ name: "intervention", params: { id: response.data.id } });
          }
        });
    },

    submit() {
      return new Promise((resolve, reject) => {
        if (!this.selectExistingIntervention) {
          this.createIntervention()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },

    createIntervention() {
      return new Promise((resolve, reject) => {
        this.submitting = true;

        const activityIds = [];

        for (const activity of this.newInterventionActivities) {
          activityIds.push(activity.id);
        }

        if (this.newIntervention.location_id != null && this.newIntervention.location != null) {
          throw new Error("Both location_id and location are set");
        }

        var data = {
          ...this.newIntervention,
          activity_ids: activityIds,
          origin_code: this.selectedOriginCode,
          customer_id: this.customer.id,
        };

        this.$services.intervention.create(data)
          .then((response) => {
            this.$emit("update:modelValue", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.validationErrors = error.response.data;
            }
            reject(error);
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
  },
};
</script>
