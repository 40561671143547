<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512"
    fill="currentColor">
    <g>
      <g>
        <path
          d="M196.923,236.308v-39.385h78.769V0H0v196.923h78.769v39.385H0V512h512V236.308H196.923z M39.385,157.538V39.385h196.923
			v118.154H39.385z M157.538,196.923v39.385h-39.385v-39.385H157.538z M472.615,472.615H39.385V275.692h433.231V472.615z" />
      </g>
    </g>
  </svg>
</template>
