<template>
  <table class="planning-table">
    <tr>
      <th />
      <th
        v-for="(monthDay, index2) in dates"
        :key="index2">
        <PlanningDate
          :date="monthDay"
          @click="onDateSelected(monthDay)" />
      </th>
    </tr>
    <tr
      v-for="(user, index) in users"
      :key="index">
      <td>
        {{ $filters.capitalize(user.last_name) }}
      </td>

      <td
        v-for="(monthDay, index3) in dates"
        :key="index3">
        <UserDayPlanning
          style="height: 100%;"
          :user="user"
          :date="monthDay"
          :events="getEventsForUserAndDate(user, monthDay)" />
      </td>
    </tr>
  </table>
</template>

<script setup lang="ts">

import { computed } from "vue";
import UserDayPlanning from "@/components/calendar/UserDayPlanning.vue";
import PlanningDate from "@/components/calendar/PlanningDate.vue";
import { useTechniciansStore } from "@/stores/technicians";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  date: { type: String, required: true },
  events: { type: Array<any>, required: false, default: [] },
});

// Emits

// eslint-disable-next-line no-undef
const emits = defineEmits(["dateSelected"]);

// Data

const techniciansStore = useTechniciansStore();

// Computed

const dates = computed(
  function get() {
    const dates = [];
    const date = new Date(props.date);

    while (date.getMonth() == new Date(props.date).getMonth()) {
      dates.push(date.toISOString().split("T")[0]);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  },
);

const users = computed(
  function get() {
    return techniciansStore.getTechnicians;
  },
);

// Methods

function getEventsForUserAndDate(user: any, date: string) {

  props.events.filter((event) => {
    return event.user.id == user.id && event.start_date == date;
  });

}


function onDateSelected(date: string) {
  emits("dateSelected", date);
}


</script>

<style lang="scss" scoped>

.planning-table {
  table-layout: fixed;
  width: 1px;

  th, td {
    width: 100px;
    
  }

  td {
    height: 150px;
  }
}

</style>