<template>
  <Modal ref="modal">
    <template #default>
      <div v-if="customer?.contact?.email">
        <div class="form-group">
          <label class="form-label">Votre sujet</label>
          <input v-model="subjectOfMessageToSend" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label class="form-label">Votre message</label>
          <input v-model="textOfMessageToSend" type="text" class="form-control">
        </div>
      </div>
      <div v-else>
        <p>Oups... Ce client n'a pas d'email</p>
      </div>
    </template>
    <template #buttons>
      <button
        class="my-button mr-2"
        @click="hide">
        Annuler
      </button>
      <button
        v-if="customer?.contact?.email"
        class="my-button"
        style="margin-left: 5px;"
        @click="submit">
        <Loader v-if="submitting" />
        Envoyer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "SendEmailToCustomerModal",
  components: {
    Modal,
    Loader,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      subjectOfMessageToSend: null,
      textOfMessageToSend: null,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.submitting = true;

      this.$services.customer.sendMessage(this.customer.id, this.subjectOfMessageToSend, this.textOfMessageToSend)
        .then(() => {
          this.hide();
        })
        .finally(() => {
          this.this.submitting = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>