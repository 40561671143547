<template>
  <div class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
    <div class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
      <div class="bg-white py-2">
        L<span class="sr-only sm:not-sr-only">un</span>
      </div>
      <div class="bg-white py-2">
        M<span class="sr-only sm:not-sr-only">ar</span>
      </div>
      <div class="bg-white py-2">
        M<span class="sr-only sm:not-sr-only">er</span>
      </div>
      <div class="bg-white py-2">
        J<span class="sr-only sm:not-sr-only">eu</span>
      </div>
      <div class="bg-white py-2">
        V<span class="sr-only sm:not-sr-only">en</span>
      </div>
      <div class="bg-white py-2">
        S<span class="sr-only sm:not-sr-only">am</span>
      </div>
      <div class="bg-white py-2">
        D<span class="sr-only sm:not-sr-only">im</span>
      </div>
    </div>
    <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
      <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
        <div v-for="day in days" :key="day.date" :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500', 'relative px-3 py-2']">
          <time :datetime="day.date" :class="day.isToday ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white' : undefined">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
          <ol v-if="day.events.length > 0" class="mt-2">
            <li v-for="event in day.events.slice(0, 2)" :key="event.id">
              <a
                :href="event.href"
                class="group flex"
                style="border-radius: 5px; color: white; padding: 0 5px;"
                :class="{'intervention': event.type === 'intervention', 'worked': event.type === 'worked', 'leave': event.type === 'leave', 'perso': event.type === 'perso'}">
                <p class="flex-auto truncate font-medium">
                  {{ event.summary }}
                </p>
                <time :datetime="event.datetime" class="ml-3 hidden flex-none text-gray-200 group-hover:text-indigo-600 xl:block">{{ $filters.dateStringToHumanizedTime(event.start_datetime) }}</time>
              </a>
            </li>
            <li v-if="day.events.length > 2" class="text-gray-500">
              + {{ day.events.length - 2 }} more
            </li>
          </ol>
        </div>
      </div>
      <div class="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
        <button v-for="day in days" :key="day.date" type="button" :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isToday && 'text-indigo-600', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500', 'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10']">
          <time :datetime="day.date" :class="[day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full', day.isSelected && day.isToday && 'bg-indigo-600', day.isSelected && !day.isToday && 'bg-gray-900', 'ml-auto']">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
          <span class="sr-only">{{ day.events.length }} events</span>
          <span v-if="day.events.length > 0" class="-mx-0.5 mt-auto flex flex-wrap-reverse">
            <span v-for="event in day.events" :key="event.id" class="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*

const days = [
  { date: "2021-12-27", events: [] },
  { date: "2021-12-28", events: [] },
  { date: "2021-12-29", events: [] },
  { date: "2021-12-30", events: [] },
  { date: "2021-12-31", events: [] },
  { date: "2022-01-01", isCurrentMonth: true, events: [] },
  { date: "2022-01-02", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-03",
    isCurrentMonth: true,
    events: [
      { id: 1, name: "Design review", time: "10AM", datetime: "2022-01-03T10:00", href: "#" },
      { id: 2, name: "Sales meeting", time: "2PM", datetime: "2022-01-03T14:00", href: "#" },
    ],
  },
  { date: "2022-01-04", isCurrentMonth: true, events: [] },
  { date: "2022-01-05", isCurrentMonth: true, events: [] },
  { date: "2022-01-06", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-07",
    isCurrentMonth: true,
    events: [{ id: 3, name: "Date night", time: "6PM", datetime: "2022-01-08T18:00", href: "#" }],
  },
  { date: "2022-01-08", isCurrentMonth: true, events: [] },
  { date: "2022-01-09", isCurrentMonth: true, events: [] },
  { date: "2022-01-10", isCurrentMonth: true, events: [] },
  { date: "2022-01-11", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-12",
    isCurrentMonth: true,
    isToday: true,
    events: [{ id: 6, name: "Sam's birthday party", time: "2PM", datetime: "2022-01-25T14:00", href: "#" }],
  },
  { date: "2022-01-13", isCurrentMonth: true, events: [] },
  { date: "2022-01-14", isCurrentMonth: true, events: [] },
  { date: "2022-01-15", isCurrentMonth: true, events: [] },
  { date: "2022-01-16", isCurrentMonth: true, events: [] },
  { date: "2022-01-17", isCurrentMonth: true, events: [] },
  { date: "2022-01-18", isCurrentMonth: true, events: [] },
  { date: "2022-01-19", isCurrentMonth: true, events: [] },
  { date: "2022-01-20", isCurrentMonth: true, events: [] },
  { date: "2022-01-21", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-22",
    isCurrentMonth: true,
    isSelected: true,
    events: [
      { id: 4, name: "Maple syrup museum", time: "3PM", datetime: "2022-01-22T15:00", href: "#" },
      { id: 5, name: "Hockey game", time: "7PM", datetime: "2022-01-22T19:00", href: "#" },
    ],
  },
  { date: "2022-01-23", isCurrentMonth: true, events: [] },
  { date: "2022-01-24", isCurrentMonth: true, events: [] },
  { date: "2022-01-25", isCurrentMonth: true, events: [] },
  { date: "2022-01-26", isCurrentMonth: true, events: [] },
  { date: "2022-01-27", isCurrentMonth: true, events: [] },
  { date: "2022-01-28", isCurrentMonth: true, events: [] },
  { date: "2022-01-29", isCurrentMonth: true, events: [] },
  { date: "2022-01-30", isCurrentMonth: true, events: [] },
  { date: "2022-01-31", isCurrentMonth: true, events: [] },
  { date: "2022-02-01", events: [] },
  { date: "2022-02-02", events: [] },
  { date: "2022-02-03", events: [] },
  {
    date: "2022-02-04",
    events: [{ id: 7, name: "Cinema with friends", time: "9PM", datetime: "2022-02-04T21:00", href: "#" }],
  },
  { date: "2022-02-05", events: [] },
  { date: "2022-02-06", events: [] },
];
const selectedDay = days.find((day) => day.isSelected);

*/


export default {
  name: "PlanningMonth",
  props: {
    activeDay: {
      type: Date,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    firstDayOfMonth() {
      return new Date(this.activeDay.getFullYear(), this.activeDay.getMonth(), 1);
    },
    firstDayOfNextMonth() {
      return new Date(this.activeDay.getFullYear(), this.activeDay.getMonth() + 1, 0);
    },
    lastDayOfMonth() {
      return new Date(this.firstDayOfNextMonth.getTime() - 1);
    },
    activeMonth() {
      return this.activeDay.getMonth();
    },
    activeYear() {
      return this.activeDay.getFullYear();
    },
    days() {
      const firstDayOfMonth = this.firstDayOfMonth;
      const lastDayOfMonth = this.lastDayOfMonth;
      const daysInMonth = lastDayOfMonth.getDate();
  
      // Find the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday) for the first day of the month
      const firstDayOfWeek = firstDayOfMonth.getDay();

      // Calculate how many days from the previous month should be included at the beginning
      const daysFromPrevMonth = (firstDayOfWeek + 6) % 7;

      const days = [];

      // Add days from the previous month
      const prevMonthYear = this.activeMonth === 0 ? this.activeYear - 1 : this.activeYear;
      const prevMonth = this.activeMonth === 0 ? 11 : this.activeMonth - 1;
      const prevMonthDays = new Date(prevMonthYear, prevMonth + 1, 0).getDate();
      for (let i = prevMonthDays - daysFromPrevMonth + 1; i <= prevMonthDays; i++) {
        const date = new Date(prevMonthYear, prevMonth, i + 1).toISOString().split("T")[0];
        const day = {
          date,
          isCurrentMonth: false,
          isToday: date === new Date().toISOString().split("T")[0],
          events: this.getEventsAtDate(date),
        };
        days.push(day);
      }

      // Add days from the current month
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(this.activeYear, this.activeMonth, i + 1).toISOString().split("T")[0];
        const day = {
          date,
          isCurrentMonth: true,
          isToday: date === new Date().toISOString().split("T")[0],
          events: this.getEventsAtDate(date),
        };
        days.push(day);
      }

      // Add days from the next month to fill the grid
      const lastDayOfWeek = lastDayOfMonth.getDay();
      const daysFromNextMonth = (6 - lastDayOfWeek + 7) % 7; // Ensure positive value
      const nextMonthYear = this.activeMonth === 11 ? this.activeYear + 1 : this.activeYear;
      const nextMonth = this.activeMonth === 11 ? 0 : this.activeMonth + 1;
      for (let i = 1; i <= daysFromNextMonth + 1; i++) {
        const date = new Date(nextMonthYear, nextMonth, i + 1).toISOString().split("T")[0];
        const day = {
          date,
          isCurrentMonth: false,
          isToday: date === new Date().toISOString().split("T")[0],
          events: this.getEventsAtDate(date),
        };
        days.push(day);
      }

      return days;
    },
  },
  methods: {
    getEventsAtDate(date) {
      return this.events.filter((event) => event.start_datetime.split("T")[0] === date);
    },
  },
};

</script>

<style scoped lang="scss">


  .leave {
    background: #F87171;
  }

  .intervention {
    background: #34D399;
  }

  .worked {
    background: #FBBF24;
  }

  .perso {
    background: #60A5FA;
  }

</style>