import ModelApiService from "./utils/modelApiService";
import InterventionApiService from "./modules/interventionApiService";
import CustomerApiService from "./modules/customerApiService";
import CustomerCallApiService from "./modules/customerCallApiService";
import InvoiceApiService from "./modules/invoiceApiService";
import QuoteApiService from "./modules/quoteApiService";
import MeApiService from "./modules/meApiService";
import InterventionTaskApiService from "./modules/interventionTaskApiService";
import CreditNoteApiService from "./modules/creditNoteApiService";
import UserApiService from "./modules/userApiService";
import ContractApiService from "./modules/contractApiService";
import ContractIterationApiService from "./modules/contractIterationApiService";
import StorageApiService from "./modules/storageApiService";
import SaleApiService from "./modules/saleApiService";
import SaleV2ApiService from "./modules/saleV2ApiService";
import OrderApiService from "./modules/orderApiService";
import RegisterAdjustmentApiService from "./modules/registerAdjustmentApiService";
import PaymentApiService from "./modules/paymentApiService";
import PlanningApiService from "./modules/planningApiService";
import JobApiService from "./modules/jobApiService";
import PersonApiService from "./modules/personApiService";
import LocationApiService from "./modules/locationApiService";
import StatsApiService from "./modules/statsApiService";


const services = {
  invoice:            new InvoiceApiService(),
  quote:              new QuoteApiService(),
  creditNote:         new CreditNoteApiService(),
  customer:           new CustomerApiService(),
  customerCall:       new CustomerCallApiService(),
  intervention:       new InterventionApiService(),
  contact:            new ModelApiService("/contacts/", "contact"),
  address:            new ModelApiService("/addresses/", "adresse"),
  orderAddress:       new ModelApiService("/order-addresses/", "adresse"),
  product:            new ModelApiService("/products/", "produit"),
  provider:           new ModelApiService("/providers/", "fournisseurs"),
  brand:              new ModelApiService("/brands/", "marque"),
  batch:              new ModelApiService("/batches/", "lot"),
  me:                 new MeApiService(),
  user:               new UserApiService(),
  task:               new InterventionTaskApiService(),
  contract:           new ContractApiService(),
  contractIteration:  new ContractIterationApiService(),
  storage:            new StorageApiService(),
  sale:               new SaleApiService(),
  saleV2:             new SaleV2ApiService(),
  order:              new OrderApiService(),
  personnalEvent:     new ModelApiService("/personnal-events/", "évènement personnel"),
  leaveEvent:         new ModelApiService("/leave-events/", "congé"),
  interventionRdvEvent: new ModelApiService("/intervention-google-events/", "rendez-vous d'intervention"),
  workedSlot:         new ModelApiService("/worked-slots/", "créneau travaillé"),
  registerAdjustment: new RegisterAdjustmentApiService(),
  payment:            new PaymentApiService(),
  planning:           new PlanningApiService(),
  job:                new JobApiService(),
  person:             new PersonApiService(),
  location:           new LocationApiService(),
  refund:             new ModelApiService("/refunds/", "remboursement"),
  stats: new StatsApiService(),

};


export default services;