import { api } from "@/api";
import { defineStore } from 'pinia';

interface State {
  journals: any;
};

export const useJournalsStore = defineStore("journals", {
  state: () => ({
    journals: null,
  }),
  getters: {
    getJournals: state => {
      return state.journals;
    },
  },
  actions: {
    setJournals(journals) {
      this.journals = journals;
    },
  },
});
