<template>
  <div style="padding: 10px;">
    <div class="vertical-spaced-container">
      <div>
        <div class="flow-spaced-container">
          <div class="filter-item">
            <div class="filter-item-title">
              Filter par période
            </div>
            <DateRangeInput v-model="period" />
          </div>

          <div class="filter-item">
            <div class="filter-item-title">
              Filter par technicien
            </div>
            <TechnicianSelect v-model="technicianId" allOption />
          </div>
        </div>
      </div>

      <div>
        <div class="flow-spaced-container">
          <div class="card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Interventions reçus</div>
                <div class="tooltip">
                  <i class="bi bi-question-circle" />
                  <div class="tooltip-text">
                    Ce chiffre correspond au nombre d'interventions créées (plannifiées ou non, réalisées ou non, facturées ou non...) dans l'ERP entre le {{ period[0] }} inclus et le {{ period[1] }} exclus<span v-if="technicianId != null"> et pour lesquelles le technicien sélectionné est assigné en tant que technicien principal ou secondaire</span>.<br>
                    Ce chiffre est exacte ! Dans le cas ou il ne correspondrait pas à vos attentes, veuillez prendre en compte le fait que certaines interventions pourraient ne pas avoir été encodées, ou pas à la bonne date. <span v-if="technicianId != null">De plus, il est fort probable que le technicien sélectionné ai oublié de s'assigner à certaines interventions auxquelles il a participé, et ces interventions ne sont donc pas prises en compte ici.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_interventions }}</span>
            </div>
            <div v-if="!loading" class="card-footer">
              <span class="text-muted">Dont reçus depuis les webshops : </span>
              <span>{{ stats.nb_interventions_from_webshops }}</span>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-briefcase" />
                <div>Interventions ouvertes</div>
                <div class="tooltip">
                  <i class="bi bi-question-circle" />
                  <div class="tooltip-text">
                    Ce chiffre correspond au nombre d'interventions créées (plannifiées ou non, réalisées ou non, facturées ou non...) dans l'ERP entre le {{ period[0] }} inclus et le {{ period[1] }} exclus<span v-if="technicianId != null"> et pour lesquelles le technicien sélectionné est assigné en tant que technicien principal ou secondaire</span>, et qui sont encore ouvertes à l'heure actuelle (donc ni cloturée, ni annulée).<br>
                    Ce chiffre est exacte ! Dans le cas ou il ne correspondrait pas à vos attentes, veuillez prendre en compte le fait que certaines interventions pourraient ne pas avoir été encodées, ou pas à la bonne date. De plus, il est probable que certaines factures n'aient pas été marquées comme cloturées et apparaissent encore dans ce calcule alors qu'elles ont en réalité été cloturées. <span v-if="technicianId != null">De plus, il est fort probable que le technicien sélectionné ai oublié de s'assigner à certaines interventions auxquelles il a participé, et ces interventions ne sont donc pas prises en compte ici.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_open_interventions }}</span>
            </div>
            <div v-if="!loading" class="card-footer">
              <span class="text-muted">Dont en attente : </span>
              <span>{{ stats.nb_waiting_interventions }}</span><br>
              <span class="text-muted">Dont en cours : </span>
              <span>{{ stats.nb_processing_interventions }}</span><br>
              <span class="text-muted">Dont planifiées : </span>
              <span>{{ stats.nb_planned_interventions }}</span><br>
              <span class="text-muted">Dont à cloturer : </span>
              <span>{{ stats.nb_to_close_interventions }}</span><br>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="text-muted" style="display: flex; align-items: center;">
                <BriefcaseIcon style="margin-right: 5px;" validated />
                Interventions cloturées
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_closed_interventions }}</span>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="text-muted" style="display: flex; align-items: center;">
                <BriefcaseIcon cancelled style="margin-right: 5px;" />
                Interventions annulées
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <span v-else class="text">{{ stats.nb_cancelled_interventions }}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div class="flow-spaced-container">
          <div class="card">
            <div class="card-header">
              <div class="flow-spaced-container align-middle text-muted">
                <i class="bi bi-cash-coin" />
                <div>CA facturé HT</div>
                <div class="tooltip">
                  <i class="bi bi-question-circle" />
                  <div class="tooltip-text">
                    Ce chiffre prend en compte les factures créées dans l'ERP entre le {{ period[0] }} inclus et le {{ period[1] }} exclus. Il correspond à la somme des montants HT de ces factures, brute (sans prendre en compte l'éventuelle note de crédit) et net (en prenant en compte l'éventuelle note de crédit).
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span class="text-muted" style="white-space: nowrap;">{{ $filters.numberWithSpaces(stats.total_billed_excl_tax_before_nc) }}€ brut HT</span>
                  <span class="text" style="white-space: nowrap;">{{ $filters.numberWithSpaces(stats.total_billed_excl_tax_after_nc) }}€ net HT</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="text-muted" style="display: flex; align-items: center;">
                <CashCoinIcon style="margin-right: 5px;" />
                Chiffre d'affaire TTC à percevoir
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else-if="stats.total_billed_but_unpaid_excl_tax_after_nc != null">
                <span class="text-muted">TTC </span>
                <span class="text">{{ $filters.numberWithSpaces(stats.total_billed_but_unpaid_excl_tax_after_nc) }} €</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="stats?.stats_per_origins" class="flow-spaced-container">
        <div
          v-for="(statsForOrigin, originCode) in stats.stats_per_origins"
          :key="originCode">
          <div class="card">
            <div class="card-header">
              <div class="text-muted" style="display: flex; align-items: center;">
                <CashCoinIcon style="margin-right: 5px;" />
                <span v-if="originCode != 'null'">Chiffre d'affaire des interventions<br>de {{ originCode }}</span>
                <span v-else>Chiffre d'affaire des interventions pour toutes les origines</span>
              </div>
            </div>
            <div class="card-body">
              <LoaderView v-if="loading" />
              <div v-else>
                <div>
                  <span class="text-muted">HT </span>
                  <span class="text">{{ $filters.numberWithSpaces(statsForOrigin.total_turnover_excluding_tax) }} €</span> <span class="text-muted" style="white-space: nowrap;">{{ $filters.numberWithSpaces(statsForOrigin.total_turnover_including_tax) }} € TTC</span>
                </div>
                <div>
                  <span class="text-muted">Moyenne :</span>
                </div>
                <div>
                  <span class="text-muted">HT </span>
                  <span class="text">{{ $filters.numberWithSpaces(statsForOrigin.average_turnover_per_intervention_excluding_tax) }} €</span> <span class="text-muted" style="white-space: nowrap;">{{ $filters.numberWithSpaces(statsForOrigin.average_turnover_per_intervention_including_tax) }}€ TTC</span>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <span class="text-muted">Prend en compte le montant des interventions cloturées et facturées</span>
            </div>
          </div>
        </div>
      </div>
      
      <InterventionsLineChart />
      <TurnoverCharts />
    </div>
  </div>
</template>

<script>

import { api, uri } from "@/api";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import CashCoinIcon from "@/assets/iconsVue/CashCoinIcon.vue";
import LoaderView from "@/components/LoaderView.vue";
import InterventionsLineChart from "@/components/charts/InterventionsLineChart.vue";
import TurnoverCharts from "@/components/charts/TurnoverCharts.vue";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";

export default {
  name: "StatsView",
  components: {
    BriefcaseIcon,
    CashCoinIcon,
    LoaderView,
    DateRangeInput,
    InterventionsLineChart,
    TurnoverCharts,
    TechnicianSelect,
  },
  data() {
    return {
      loadingStats: false,
      stats: null,
      period: [this.getISODateWithoutTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), this.getISODateWithoutTime(new Date())],
      technicianId: null,
    };
  },
  computed: {
    uriFilters() {
      var args = [];

      if (this.period[0]) {
        args.push(`date_from=${this.period[0]}`);
      }

      if (this.period[1]) {
        args.push(`date_to=${this.period[1]}`);
      }

      if (this.technicianId) {
        args.push(`technician=${this.technicianId}`);
      }

      return args.join("&");
    },
    loading() {
      return this.loadingStats || this.stats == null;
    },
  },
  watch: {
    period() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.uriFilters) {
        this.loadingStats = true;
        return api.get(`${uri.stats()}?${this.uriFilters}`)
          .then((response) => {
            this.stats = response.data;
          })
          .finally(() => {
            this.loadingStats = false;
          });
      }
    },
    getISODateWithoutTime(date) {
      const isoString = date.toISOString();
      return isoString.split("T")[0];
    },
  },
};
</script>

<style lang="scss" scoped>

  .card {
    height: 100%;

    .card-header {
      align-items: left;
      text-align: left;
    }

    .card-body {
      text-align: center;
      align-items: middle;
      justify-content: center;
    }

    .text {
      font-size: 34px;
      font-weight: 500;
    }
  }

</style>