import { api, uri } from "@/api";
import { defineStore } from 'pinia';

interface State {
  origins: any;
};

export const useOriginsStore = defineStore("origins",{
  state: () => ({
    origins: null,
  }),
  getters: {
    getOrigins: state => {
      return state.origins;
    },
    getOriginFromCode: state => (code) => {
      if (state.origins == null) {
        return null;
      }
      return state.origins.find(o => o.code == code);
    },
  },
  actions: {
    setOrigins(origins) {
      this.origins = origins;
    },
  },
});

