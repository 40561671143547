import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useInterventionListStore = buildPaginatedMultiListStore(
  "interventionList",
  "/interventions/",
  "Aucune intervention trouvée",
  true,
  (filters: object, subList: string) => {
    if (subList == "all") {
      filters.all = true;
    } else if (subList == "mine") {
      // Do nothing
    } else {
      filters.progress = subList;
    }

    return filters;
  },
  () => ({
    tempNbInterventionTodo: 0,
  }),
  {
    getNbInterventionTodo: state => () => {
      if (state.subLists.todo) {
        return state.subLists.todo.total;
      } else {
        return state.tempNbInterventionTodo;
      }
    },
  },
  {
    setTempNbInterventionTodo(tempNbInterventionTodo: any) {
      this.tempNbInterventionTodo = tempNbInterventionTodo;
    },
  },
);
