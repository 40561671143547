<template>
  <div style="padding: 10px; background: white; height: 100%; display: flex; flex-direction: column;">
    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <CalendarDateSelector
        v-model="date"
        :mode="scale" />
      <div>
        <button
          class="scale-button"
          :class="{ active: scale == 'day' }"
          @click="scale = 'day'">
          Jour
        </button>
        <button
          class="scale-button"
          :class="{ active: scale == 'week' }"
          @click="scale = 'week'">
          Semaine
        </button>
        <button
          class="scale-button"
          :class="{ active: scale == 'month' }"
          @click="scale = 'month'">
          Mois
        </button>
      </div>
    </div>

    <div style="overflow-x: auto; flex: 1; position: relative;">
      <div v-if="loading" class="overlay">
        <!-- Loader -->
        <Loader />
      </div>
      <MultiUserMonthPlanning
        v-if="scale == 'month'"
        :date="date"
        :events="events"
        @dateSelected="onDateSelected" />

      <MultiUserWeekPlanning
        v-else-if="scale == 'week'"
        style="height: 100%;"
        :date="date"
        :events="weekEvents"
        @dateSelected="onDateSelected" />

      <MultiUserDayPlanning
        v-else-if="scale == 'day'"
        style="height: 100%;"
        :date="date"
        :events="dayEvents" />
    </div>
  </div>
</template>

<script>

import MultiUserMonthPlanning from "@/components/calendar/MultiUserMonthPlanning.vue";
import MultiUserWeekPlanning from "@/components/calendar/MultiUserWeekPlanning.vue";
import MultiUserDayPlanning from "@/components/calendar/MultiUserDayPlanning.vue";
import CalendarDateSelector from "@/components/calendar/CalendarDateSelector.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "TimelineCalendar",
  components: {
    MultiUserMonthPlanning,
    MultiUserWeekPlanning,
    MultiUserDayPlanning,
    CalendarDateSelector,
    Loader,
  },
  data() {
    return {
      events: [],
      loading: false,
    };
  },
  computed: {
    firstDayOfMonth() {
      return `${this.year}-${this.month+1}-01`;
    },
    lastDayOfMonth() {
      return `${this.year}-${this.month+1}-${new Date(this.year, this.month+1, 0).getDate()}`;
    },
    date: {
      get() {
        if (this.$route.query.date) {
          return this.$route.query.date;
        }
        return new Date().toISOString().split("T")[0];
      },
      set(val) {
        this.$router.push({
          query: {
            date: val,
            scale: this.scale,
          },
        });
      },
    },
    scale: {
      get() {
        if (this.$route.query.scale) {
          return this.$route.query.scale;
        }
        return "week";
      },
      set(val) {
        this.$router.push({
          query: {
            date: this.date,
            scale: val,
          },
        });
      },
    },
    month: {
      get() {
        return parseInt(this.date.split("-")[1]) - 1;
      },
      set(value) {
        const date = new Date(this.date);
        date.setMonth(value);
        this.date = date.toISOString().split("T")[0];
      },
    },
    weekIndex: {
      get() {
        return this.getWeekIndex(this.date);
      },

      set(value) {
        const date = new Date(this.date);
        date.setDate(date.getDate() + (value - this.weekIndex) * 7);
        this.date = date.toISOString().split("T")[0];
      },
    },
    year: {
      get() {
        return parseInt(this.date.split("-")[0]);
      },
      set(value) {
        const date = new Date(this.date);
        date.setFullYear(value);
        this.date = date.toISOString().split("T")[0];
      },
    },
    weekEvents() {
      return this.events.filter((event) => {
        const eventDate = new Date(event.start_date);
        const eventYear = eventDate.getFullYear();
        const eventWeekIndex = this.getWeekIndex(eventDate);
        return eventYear == this.year && eventWeekIndex == this.weekIndex;
      });
    },
    dayEvents() {
      return this.events.filter((event) => {
        return event.start_date == this.date || event.end_date == this.date;
      });
    },
  },
  watch: {
    month() {
      this.fetchPlanning();
    },
  },
  mounted() {
    this.fetchPlanning();
  },
  methods: {
    getWeekIndex(date) {
      const clonedDate = new Date(date);
      const firstDayOfYear = new Date(clonedDate.getFullYear(), 0, 1);
      const pastDaysOfYear = (clonedDate.getTime() - firstDayOfYear.getTime()) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7) - 1;
    },
    fetchPlanning() {
      this.loading = true;
      this.$services.planning.fetchPlanning(
        this.firstDayOfMonth,
        this.lastDayOfMonth,
      ).then((response) => {
        this.events = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    onDateSelected(date) {
      this.date = date;
      this.scale = "day";
    },
  },
};
</script>

<style scoped lang="scss">

  .scale-button {
    padding: 5px;
    border: 1px solid #e6e6e6;
    background: white;
    cursor: pointer;
    height: 100%;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      background: #e6e6e6;
    }

    &.active {
      background: lightsteelblue;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Overlay transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Assurez-vous que le loader est au-dessus du contenu de la carte */
  }



</style>
