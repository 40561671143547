<template>
  <div class="card">
    <div class="card-header">
      <span class="title">
        Vente
      </span>
      <button
        class="btn btn-link"
        style="margin-left: 5px;"
        @click="gotoSale">
        Voir
      </button>
    </div>

    <div class="card-body">
      {{ sale.id }}
    </div>
  </div>
</template>

<script>

export default {
  name: "InvoiceSaleCard",
  props: {
    sale: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    gotoSale() {
      this.$router.push({ name: "sale", params: { id: this.sale.id }});
    },
  },
};

</script>