import { api } from "@/api";
import { defineStore } from "pinia";
import services from "@/services";


export const useCurrentCustomerCallStore = defineStore("currentCustomerCall", {
  state: () => ({
    call: {
      id: null,
      activity_id: null,
    },
    notes: {},
    possibleReasons: [],
  }),
  getters: {
    getCall: (state) => state.call,
    getNotes: (state) => {
      if (state.call.activity_id && state.notes[state.call.activity_id]) {
        return state.notes[state.call.activity_id];
      } else {
        return null;
      }
    },
    getPossibleReasons: (state) => state.possibleReasons,
  },
  actions: {
    setCall(call: any) {
      this.call = call;
      localStorage.setItem("CustomerCall", JSON.stringify(this.call));
    },
    setNotes(notes: string) {
      this.notes[this.call.activity_id] = notes;
      localStorage.setItem("CustomerCallNotes", JSON.stringify(this.notes));
    },
    setAllNotes(notes: any) {
      if (notes == null) {
        this.notes = {};
      } else {
        this.notes = notes;
      }
    },
    clear() {
      this.call = {
        id: null,
        activity_id: null,
      };
      localStorage.removeItem("CustomerCall");
      localStorage.removeItem("CustomerCallNotes");
    },
    load() {
      // Load from local storage
  
      try {

        const localCustomerCall = localStorage.getItem("CustomerCall");
        const localCustomerCallNotes = localStorage.getItem("CustomerCallNotes");

        if (localCustomerCall) {
          this.setCall(JSON.parse(localCustomerCall));
          this.setAllNotes(JSON.parse(localCustomerCallNotes));
        }
  
      } catch (e) {
        this.clear();
        throw e;
      }
    },
    save(terminate = false) {
  
      return new Promise<any>((resolve, reject) => {
  
        // Save locally in local storage
  
        localStorage.setItem("CustomerCall", JSON.stringify(this.call));
        localStorage.setItem("CustomerCallNotes", JSON.stringify(this.notes));
        
        // Save on server
  
        if (this.call.id) {

          // Save

          const dataToSave = {
            ...this.call,
            id: this.call.id,
            notes: this.getNotes,
            terminated: terminate,
          };

          delete dataToSave.id;

          services.customerCall.patch(this.call.id, dataToSave)
            .then((response) => {
              resolve(response);
            }).catch((error: any) => {
              reject(error);
            });
        } else {

          // Create

          const dataToSave = {
            ...this.call,
            id: null,
            notes: this.getNotes,
            terminated: terminate,
          };

          delete dataToSave.id;

          services.customerCall.create(dataToSave)
            .then((response: any) => {
              this.setCall(response.data);
              resolve(response);
            }).catch((error: any) => {
              reject(error);
            });
        }
      });
    },
    convert() {
      return new Promise<any>((resolve, reject) => {
        this.save()
          .catch((error: any) => {
            reject(error);
          })
          .then((response) => {
            services.customerCall.convert(response.data.id)
              .then((response) => {
                this.clear();
                resolve(response);
              })
              .catch((error: any) => {
                reject(error);
              });
          });
      });
        

    },
    terminate() {
      return new Promise<any>((resolve, reject) => {
  
        this.save(true)
          .then((response) => {
            this.clear();
            resolve(response);
          }).catch((error: any) => {
            reject(error);
          });
      });
    },
    fetchPossibleReasons() {
      return api.get("/customer-call-reasons/")
        .then((response) => {
          this.possibleReasons = response.data;
        });
    },
  },
});
