
<script setup lang="ts">

import { onMounted, computed, watch, ComputedRef  } from "vue";
import List from "./List.vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  rowComponent: {type: Object, required: true },
  columnsNames: {type: Array<string>, required: false, default: null },
  setColumnsNamesFromResults: {type: Boolean, required: false, default: false },
  store: {type: Object, required: true },
  subList: { type: String, required: false, default: null },
  noResultMessage: {type: String, required: false, default: null },
});

// Computed

const isLoading: ComputedRef<boolean> = computed((): boolean => {
  if (props.subList) {
    return !props.store.getResults(props.subList) && props.store.isLoading(props.subList);
  } else {
    return !props.store.getResults && props.store.isLoading;
  }
});

const isError: ComputedRef<boolean> = computed((): boolean => {
  if (props.subList) {
    return props.store.isError(props.subList);
  } else {
    return props.store.isError;
  }
});

const results: ComputedRef<any> = computed((): any => {
  if (props.subList) {
    return props.store.getResults(props.subList);
  } else {
    return props.store.getResults;
  }
});

const noResultMessage: ComputedRef<string | null> = computed((): string | null => {
  if (props.noResultMessage) {
    return props.noResultMessage;
  } else if (props.store.getNoResultMessage) {
    return props.store.getNoResultMessage;
  } else {
    return "Aucun résultat à afficher";
  }
});

const computeColumnsNames: ComputedRef<string[] | undefined> = computed((): string[] | undefined => {
  if (props.setColumnsNamesFromResults) {
    if (results.value && results.value.length > 0) {
      return Object.keys(results.value[0]);
    } else {
      return undefined;
    }
  } else {
    return props.columnsNames;
  }
});

// Watch

watch(() => props.subList, () => {
  refreshResults();
});

// Mounted

onMounted(() => {
  refreshResults();
});

// Methods

const refreshResults = () => {
  // Force refresh of result list

  if (props.subList) {
    props.store.refresh(props.subList);
  } else {
    props.store.refresh();
  }
};

const onResultUpdated = (element: any) => {
  console.log("StoreList : Update element");
  props.store.updateResult(element);
};

</script>

<template>
  <List
    :rowComponent="rowComponent"
    :columnsNames="computeColumnsNames"
    :isLoading="isLoading"
    :isError="isError"
    :results="results"
    :noResultMessage="noResultMessage"
    @updateResult="onResultUpdated" />
</template>
  