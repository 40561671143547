<template>
  <div class="card">
    <iframe v-if="pdfUrl" :src="pdfUrl" width="100%" height="800px" />
    <LoaderView v-else />
  </div>
</template>

<script>

import LoaderView from "@/components/LoaderView.vue";

export default {
  name: "PdfCard",
  components: {
    LoaderView,
  },
  props: {
    pdfData: {
      type: String,
      required: false,
      default: null,
    },
    pdfBlob: {
      type: Blob,
      required: false,
      default: null,
    },
  },
  computed: {
    pdfUrl() {
      if (this.pdfBlob) {
        return window.URL.createObjectURL(this.pdfBlob);
      } else if (this.pdfData) {
        return window.URL.createObjectURL(new Blob([this.pdfData], { type: "application/pdf" }));
      } else {
        return null;
      }
    },
  },  
};
</script>

<style>

</style>