import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useInvoiceListStore = buildPaginatedMultiListStore(
  "invoiceList",
  "/invoices/",
  "Aucune facture trouvée",
  true,
  (filters: object, subList: string) => {    
    if (subList != "all") {
      filters.journal_id = subList;
    }

    return filters;
  },
);
