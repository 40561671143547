<template>
  <Datepicker
    v-model="time"
    :clearable="true"
    :time-picker="true"
    locale="fr"
    cancelText=""
    selectText="Valider"
    :disabled="disabled"
    :teleport-center="isMobile"
    :start-time="{ 'hours': 8, 'minutes': 0 }"
    minutes-increment="10"
    minutes-grid-increment="15" />
</template>

<script setup lang="ts">

// https://vue3datepicker.com/

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed } from "vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: { type: String, required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
});

// Emits

// eslint-disable-next-line no-undef
const emits = defineEmits(["update:modelValue"]);

// Computed

const time = computed({
  get() {
    return props.modelValue != null ? {
      hours: props.modelValue.split(":")[0],
      minutes: props.modelValue.split(":")[1],
    } : null;
  },
  set(value) {
    if (value == null) {
      emits("update:modelValue", null);
    } else {
      emits("update:modelValue", `${value.hours}:${value.minutes}`);
    }
  },
});

const isMobile = computed(() => {
  return window.innerWidth <= 500;
});

</script>