<template>
  <div ref="user-day-planning" class="user-day-planning" @click="onClick">
    <div v-if="showUser" style="font-weight: 700;">
      {{ $filters.capitalize(user.last_name) }}
    </div>
    <div v-if="showDate">
      {{ $filters.capitalize($filters.dateStringToHumanizedDate(date)) }}
    </div>
    <div>
      <i class="bi bi-briefcase" /> {{ nbInterventions }}
    </div>
    <LoaderView v-if="events == undefined" />
    <div v-else class="user-day-planning-container">
      <div v-if="showTimeScale" class="hour-markers">
        <div v-for="hour in 24" :key="hour" class="hour-marker">
          {{ hour }}
        </div>
      </div>
      <div
        v-for="(event, index) in events"
        :key="index"
        class="event tooltip"
        :style="getStylesForEvent(event)"
        @click.stop="onEventClick(event)">
        <div class="event-container">
          <span v-if="event.cancelled">
            [ANNULE]
          </span>
          <span>{{ event.summary }}</span>
        </div>
        
        <div class="tooltip-text">
          <p v-if="event.cancelled">
            ANNULE
          </p>
          <p v-if="event.start_time && event.end_time">
            {{ event.start_time.substring(0, 5) }} - {{ event.end_time.substring(0, 5) }}
          </p>
          <p>{{ event.summary }}</p>
          <p v-if="event.recurrence">
            Récurence : {{ event.recurrence }}
          </p>
        </div>
      </div>
      <div
        v-if="highlightedStartDatetime != null || highlightedEndDatetime != null"
        class="highlighted-slot tooltip"
        :style="highlightedSlotPosition">
        <div class="tooltip-text">
          <p>Créneau libre entre {{ $filters.dateStringToHumanizedTime(highlightedStartDatetime) }} - {{ $filters.dateStringToHumanizedTime(highlightedEndDatetime) }}</p>
        </div>
      </div>
    </div>
  </div>
  <CalendarEventModal ref="calendarEventModal" />
</template>

<script setup lang="ts">

import LoaderView from "@/components/LoaderView.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import CalendarEventModal from "@/components/modals/CalendarEventModal.vue";

// Interface

interface Event {
  id: number;
  summary: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  type: string;
  recurrence?: string;
  intervention?: any;
  cancelled: boolean;
}


// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  user: { type: Object, required: true },
  date: { type: String, required: true },
  events: { type: Array<Event>, required: false, default: [] },
  highlightedStartDatetime: { type: String, required: false, default: null },
  highlightedEndDatetime: { type: String, required: false, default: null },
  showUser: { type: Boolean, required: false, default: false },
  showDate: { type: Boolean, required: false, default: false },
  showTimeScale: { type: Boolean, required: false, default: false },
});

// Data

const router = useRouter();

// DOM elements

const calendarEventModal = ref(null);

// Computed

const nbInterventions = computed(
  function get() {
    return props.events.filter((event) => {
      return event.type == "intervention";
    }).length;
  },
);

const highlightedSlotPosition = computed(
  function get() {
    const start = props.highlightedStartDatetime ? new Date(props.highlightedStartDatetime) : null;
    const end = props.highlightedEndDatetime ? new Date(props.highlightedEndDatetime): null;

    if (start == null && end == null) {
      throw new Error("highlightedStartDatetime and highlightedEndDatetime must be defined");
    }

    if (start != null && end != null) {

      const startHours = start.getHours();
      const startMinutes = start.getMinutes();
      const startMinutesInDay = startHours * 60 + startMinutes;

      const endHours = end.getHours();
      const endMinutes = end.getMinutes();
      const endMinutesInDay = endHours * 60 + endMinutes;

      const duration = endMinutesInDay - startMinutesInDay;

      return {
        top: `${startMinutesInDay / 1440 * 100}%`,
        height: `${duration / 1440 * 100}%`,
        width: "100%",
        background: "yellow",
      };

    }

    if (start != null) {

      const startHours = start.getHours();
      const startMinutes = start.getMinutes();
      const startMinutesInDay = startHours * 60 + startMinutes;

      return {
        top: `${startMinutesInDay / 1440 * 100}%`,
        height: "100px",
        width: "100%",
        background: "linear-gradient(to bottom, yellow, transparent)",
      };

    }

    if (end != null) {

      const endHours = end.getHours();
      const endMinutes = end.getMinutes();
      const endMinutesInDay = endHours * 60 + endMinutes;

      return {
        top: `calc(${endMinutesInDay / 1440 * 100}% - 100px)`,
        height: "100px",
        width: "100%",
        background: "linear-gradient(to top, yellow, transparent)",
      };

    }

    return null;
  },
);

// Methods

const getTopAndHeightForEvent = (event: Event) => {

  const date = new Date(props.date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const eventStart = new Date(event.start_date + "T" + event.start_time);
  const eventEnd = new Date(event.end_date + "T" + event.end_time);

  const eventStartYear = eventStart.getFullYear();
  const eventStartMonth = eventStart.getMonth();
  const eventStartDay = eventStart.getDate();

  const eventEndYear = eventEnd.getFullYear();
  const eventEndMonth = eventEnd.getMonth();
  const eventEndDay = eventEnd.getDate();

  // Calculate top

  const startTimePart = event.start_time;
  const [startHourStr, startMinuteStr] = startTimePart.substring(0, 5).split(":");

  const startHours = parseInt(startHourStr, 10);
  const startMinutes = parseInt(startMinuteStr, 10);
  const startMinutesInDay = startHours * 60 + startMinutes;

  var top = Math.round(startMinutesInDay / 1440 * 100);

  // Calculate botton

  const endTimePart = event.end_time;
  const [endHourStr, endMinuteStr] = endTimePart.substring(0, 5).split(":");

  const endHours = parseInt(endHourStr, 10);
  const endMinutes = parseInt(endMinuteStr, 10);
  const endMinutesInDay = endHours * 60 + endMinutes;

  var bottom = Math.round(endMinutesInDay / 1440 * 100);

  // Calculate height

  var height;

  if ((eventEndYear > year || eventEndMonth > month || eventEndDay > day) && (eventStartYear < year || eventStartMonth < month || eventStartDay < day)) { // Event start date is in the past and event end date is in the future
    top = 0;
    height = 100;

  } else if (eventEndYear > year || eventEndMonth > month || eventEndDay > day) { // Event end date is in the future
    height = 100 - top;

  } else if (eventStartYear < year || eventStartMonth < month || eventStartDay < day) { // Event start date is in the past
    top = 0;
    height = bottom;

  } else {
    const timeDifferenceInMs = Math.abs(eventEnd - eventStart);
    const timeDifferenceInMinutes = Math.floor(timeDifferenceInMs / (1000 * 60));
    height = Math.round(timeDifferenceInMinutes / 1440 * 100);
  }

  return {
    "top": top,
    "height": height,
  };
};

const getStylesForEvent = (event: Event) => {
  const background = event.cancelled ? "rgba(0, 0, 0, 0.1)" : event.type == "intervention" ? "lightgreen" : event.summary == "Indispo" ? "lightcyan" : "lightblue";

  if (props.showTimeScale == false) {
    return {
      "position": "relative",
      "margin-top": "2px",
      "margin-bottom": "2px",
      "height": "25px",
      "background": background,
    };
  }

  if (event.start_time == null || event.end_time == null) {
    return {
      "position": "relative",
      "margin-top": "2px",
      "margin-bottom": "2px",
      "height": "25px",
      "background": background,
    };
  }

  const position = getTopAndHeightForEvent(event);

  const samePositionEvents = props.events.filter((e) => {
    if (props.showTimeScale == false) {
      return false;
    }

    if (e.start_time == null || e.end_time == null) {
      return false;
    }

    const otherEventPosition = getTopAndHeightForEvent(e);

    const eventTop = position.top;
    const eventBottom = position.top + position.height;
    const otherEventTop = otherEventPosition.top;
    const otherEventBottom = otherEventPosition.top + otherEventPosition.height;

    return (otherEventTop >= eventTop && otherEventTop < eventBottom) || (otherEventBottom > eventTop && otherEventBottom <= eventBottom);
  });

  const index = samePositionEvents.findIndex((e) => {
    return e == event;
  });

  return {
    "position": "absolute",
    "top": `${position.top}%`,
    "height": `${position.height}%`,
    "max-height": `calc(100% - ${position.top}%)`,
    "width": `${100 / samePositionEvents.length}%`,
    "left": `${index * 100 / samePositionEvents.length}%`,
    "background": background,
  };
};

const onClick = () => {
  if (router.currentRoute.value.query.user == props.user.id) {
    router.push({
      name: "intervention-calendar",
      query: {
        date: props.date,
        scale: "day",
      },
    });
    
  } else {
    router.push({
      name: "intervention-calendar",
      query: {
        date: props.date,
        scale: "day",
        user: props.user.id,
      },
    });
  }
};

const onEventClick = (event: Event) => {
  if (calendarEventModal.value !== null) {
    calendarEventModal.value.show(event);
  }

};




</script>

<style lang="scss" scoped>

.user-day-planning {
  background: white;
  border: 1px solid #e5e5e5;
  padding: 5px;
  flex: 1;
  min-height: 100px;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    z-index: 2;
  }

  .user-day-planning-container {
    position: relative;
    flex-grow: 1;

    .hour-markers {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
      justify-content: space-between;
      padding-top: 10px; /* Espace pour les chiffres */

      .hour-marker {
        flex: 1;
        text-align: center;
        opacity: 0.5;
        font-size: 0.7rem;
      }
    }


    .event {

      &:hover {
        z-index: 2;
      }

      .event-container {
        height: 100%;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        overflow: hidden;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);

        &:hover {
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 1);
        }
      }
    }

    .highlighted-slot {
      position: absolute;
      border-radius: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}

</style>