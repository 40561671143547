<template>
  <button
    v-if="product.wc_manage_stock && (product.type == 'simple' || product.type == 'variation')"
    class="tool-btn tooltip"
    @click.stop="$refs.addUnitsOfProductToStockModal.show()">
    <i class="bi bi-plus-square" />
    <div class="tooltip-text left">
      Ajouter des unités de ce produit au stock
    </div>
    <AddUnitsOfProductToStockModal 
      ref="addUnitsOfProductToStockModal"
      v-model="product" />
  </button>

  <div style="display: flex; justify-content: center; align-items: center; margin: 0px 5px;">
    <span
      v-if="product.wc_manage_stock"
      :class="{ 'highlighted red': product.stock_unit_count != null && product.stock_unit_count <= 0 }">
      {{ product.stock_unit_count_humanized }} en stock
    </span>
  </div>

  <button
    v-if="product.wc_manage_stock && (product.type == 'simple' || product.type == 'variation')"
    class="tool-btn tooltip"
    @click.stop="$refs.removeUnitsOfProductFromStockModal.show()">
    <i class="bi bi-dash-square" />
    <div class="tooltip-text left">
      Retirer des unités de ce produit du stock
    </div>
    <RemoveUnitsOfProductFromStockModal
      ref="removeUnitsOfProductFromStockModal"
      v-model="product" />
  </button>

  <button
    v-if="product.wc_manage_stock && (product.type == 'simple' || product.type == 'variation')"
    class="tool-btn tooltip"
    @click.stop="$refs.MoveUnitsOfProductInStockModal.show()">
    <i class="bi bi-arrow-left-right" />
    <div class="tooltip-text left">
      Déplacer des unités de ce produit d'un espace stockage à un autre
    </div>
    <MoveUnitsOfProductInStockModal
      ref="MoveUnitsOfProductInStockModal"
      v-model="product" />
  </button>
</template>

<script>

import AddUnitsOfProductToStockModal from "@/components/modals/AddUnitsOfProductToStockModal.vue";
import RemoveUnitsOfProductFromStockModal from "@/components/modals/RemoveUnitsOfProductFromStockModal.vue";
import MoveUnitsOfProductInStockModal from "@/components/modals/MoveUnitsOfProductInStockModal.vue";

export default {
  name: "ProductToolButtons",
  components: {
    AddUnitsOfProductToStockModal,
    RemoveUnitsOfProductFromStockModal,
    MoveUnitsOfProductInStockModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    product: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("From ProductToolButtons set product with unit_count = " + value.unit_count);
        this.$emit(
          "update:modelValue",
          value,
        );
      },
    },
  },
  methods: {
    seeBatches() {
      this.$router.push({ name: "product-stock", params: { id: this.product.id } });
    },
  },
};

</script>

<style>

</style>