<template>
  <div
    class="reason-section">
    <div class="reason-header" @click="extend = !extend">
      <i v-if="extend" class="bi bi-caret-down" /><i v-else class="bi bi-caret-right" />{{ reason.name }}
    </div>
    <div v-if="extend" class="reason-body">
      {{ reason.questions }}
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomerCallReason",
  props: {
    reason: {
      type: Object,
      required: true,

    },
  },
  data() {
    return {
      extend: false,
    };
  },
};
</script>

<style lang="scss" scoped>

  .reason-section {
    margin-bottom: 5px;

    .reason-header {
      padding: 5px;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    .reason-body {
      padding: 5px 15px;
    }

    
  }

</style>