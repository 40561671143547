<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Informations générales</span>
    </div>

    <div class="card-body">
      <div>
        <p>Journal: <strong>{{ modelValue.journal.origin.code }} : {{ modelValue.journal.invoice_code_prefix }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "QuoteInfoCard",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
};

</script>