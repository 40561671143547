<template>
  <button
    class="tool-btn tooltip"
    @click.stop="exportPDF">
    <Loader v-if="fetchingPDF" />
    <i v-else class="bi bi-filetype-pdf" />
    <div class="tooltip-text">
      Télécharger
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="!modelValue.contact?.email && !modelValue.customer?.contact?.email"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un email au client
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
    <SendEmailToCustomerModal v-if="modelValue.customer" ref="sendEmailModal" :customer="modelValue.customer" />
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="sendingQuoteToCustomerByEmail || (!modelValue.contact?.email && !modelValue.customer?.contact?.email)"
    @click.stop="sendQuoteToCustomerByEmail">
    <Loader v-if="sendingQuoteToCustomerByEmail" />
    <i v-else class="bi bi-envelope-paper" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer le devis au client par email
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="sendQuoteToCustomerByMail">
    <Loader v-if="sendingQuoteToCustomerByMail" />
    <i v-else class="bi bi-envelope" />
    <div class="tooltip-text">
      Envoyer le devis au client par courrier
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="createInvoice">
    <InvoiceIcon
      status="invoice" />
    <div class="tooltip-text">
      Créer une facture à partir de ce devis
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="clone">
    <InvoiceIcon
      status="quote" />
    <div class="tooltip-text">
      <span>Créer un autre devis avec les mêmes articles</span>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="deleteQuote">
    <Loader v-if="deletingQuote" />
    <i v-else class="bi bi-trash" />
    <div class="tooltip-text left">
      Supprimer
    </div>
  </button>
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";
import Loader from "@/components/Loader.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";

export default {
  name: "QuoteToolButtons",
  components: {
    SendEmailToCustomerModal,
    Loader,
    InvoiceIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingPDF: false,
      sendingQuoteToCustomerByEmail: false,
      sendingQuoteToCustomerByMail: false,
      sendingPaymentRequestToCustomerByEmail: false,
      deletingQuote: false,
    };
  },
  methods: {
    exportPDF() {
      this.fetchingPDF = true;

      this.$services.quote.fetchPdf(this.modelValue.id)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          var filename = this.modelValue.ref + "_" + this.modelValue.contact.last_name;
          if (this.modelValue.company_name) {
            filename = filename + this.modelValue.contact.company_name;
          }
          filename = filename + ".pdf";

          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          
        }).finally(() => {
          this.fetchingPDF = false;
        });
    },
    sendQuoteToCustomerByEmail() {
      this.sendingQuoteToCustomerByEmail = true;
      this.$services.quote.sendToCustomerEmail(this.modelValue.id)
        .finally(() => {
          this.sendingQuoteToCustomerByEmail = false;
        });
    },
    sendQuoteToCustomerByMail() {
      this.sendingQuoteToCustomerByMail = true;
      this.$services.quote.sendToCustomerMail(this.modelValue.id)
        .finally(() => {
          this.sendingQuoteToCustomerByMail = false;
        });
    },
    createCreditNote() {
      this.$router.push({ name: "create-credit-note", query: { quote: this.modelValue.id } });
    },
    createInvoice() {
      this.$router.push({ name: "create-invoice-from-quote", params: { id: this.modelValue.id } });
    },
    deleteQuote() {
      const answer = window.confirm("Etes-vous sûr de vouloir supprimer ce devis ?");
      if (answer) {
        this.deletingQuote = true;
        this.$services.quote.delete(this.modelValue.id)
          .finally(() => {
            this.deletingQuote = false;
          });
      }
    },
    clone() {
      this.$router.push({ name: "create-quote", query: { quote: this.modelValue.id } });      
    },
  },
};

</script>

<style>

</style>