<template>
  <div
    class="history-row"
    @click="$router.push({ name: 'quote', params: { id: object.id } })">
    <div class="info-grid-layout">
      <div>Ref</div>
      <div>{{ object.ref }}</div>
      <div>Créé le</div>
      <div>{{ $filters.dateStringToHumanizedDate(object.created_at) }}</div>
      <div>Auteur</div>
      <div>
        <span v-if="object.author">{{ $filters.fullname(object.author) }}</span>
        <span v-else><i>Aucun</i></span>
      </div>
    </div>
    <div class="flow-spaced-container">
      <span
        v-if="object.send_date"
        class="highlighted green tooltip"
        style="margin-right: 5px;">
        Envoyé au client
        <div class="tooltip-text">{{ $filters.dateStringToHumanizedDate(object.send_date) }}</div>
      </span>
      <span
        v-else
        class="highlighted red"
        style="margin-right: 5px;">
        Non envoyé au client
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "QuoteCompactRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
