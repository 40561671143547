<template>
  <div class="card">
    <div class="card-header">
      De {{ ovhEmail.sender }} à {{ ovhEmail.receiver }} le {{ ovhEmail.date }} : {{ ovhEmail.subject }}
    </div>

    <div class="card-body">
      <div v-if="ovhEmail.html" style="max-height: 200px; overflow: hidden;" v-html="ovhEmail.html" />
      <div v-else-if="ovhEmail.text" style="max-height: 200px; overflow: hidden;" v-html="ovhEmail.text" />
      <div v-else>
        <i>Aucun message</i>
      </div>
      
      <button
        class="btn-link"
        @click="$refs.modal.show()">
        Voir plus
      </button>
    </div>

    <Modal ref="modal">
      <div class="info-grid-layout">
        <div>De</div>
        <div>{{ ovhEmail.sender }}</div>
        <div>À</div>
        <div>{{ ovhEmail.receiver }}</div>
        <div>Date</div>
        <div>{{ ovhEmail.date }}</div>
        <div>Sujet</div>
        <div>{{ ovhEmail.subject }}</div>
      </div>

      <div v-if="ovhEmail.html" v-html="ovhEmail.html" />
      <div v-else-if="ovhEmail.text" v-html="ovhEmail.text" />
      <div v-else>
        <i>Aucun message</i>
      </div>    
    
      <template #buttons>
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="$refs.modal.hide()">
          Ok
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "CustomerOvhEmailCard",
  components: {
    Modal,
  },
  props: {
    ovhEmail: {
      type: Object,
      required: true,
    },
  },
  

};

</script>