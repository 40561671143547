<template>
  <div v-if="rooms && activities" class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">Pièces inspectées</label>
        <div
          v-for="(room, index) in rooms"
          :key="index"
          class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            :checked="roomIsSelected(room)"
            @change="selectRoom(room, $event.target.checked)">
          <label class="form-check-label">{{ $filters.capitalize(room.name) }}</label>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Nuisibles identifiés</label>
        <div
          v-for="(activity, index) in activities"
          :key="index"
          class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            :checked="activityIsSelected(activity)"
            @change="selectActivity(activity, $event.target.checked)">
          <label class="form-check-label">{{ activity.name }}</label>
        </div>
      </div>
    </div>

    <div class="col vertical-spaced-container">
      <div
        v-for="(room, index) in selectedRooms"
        :key="index">
        <div style="border: 1px #ced4da solid; border-radius: 10px; padding: 5px;">
          <p><strong>{{ $filters.capitalize(room.name) }}</strong></p>
          <table>
            <tr
              v-for="(activity, index2) in selectedActivities"
              :key="index2">
              <td>
                {{ activity.name }}
              </td>
              <td style="width: 100%;">
                <textarea
                  class="form-control"
                  rows="1"
                  placeholder="Commentaire"
                  @change="onCommentChange(room, activity, $event.target.value)" />
              </td>
              <td>
                <InfestationLevelSelector
                  :value="getLevel(room, activity)"
                  @change="onInfestationReportChange(room, activity, $event)" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <LoaderView v-else />
</template>
  
<script>

import LoaderView from "@/components/LoaderView.vue";
import InfestationLevelSelector from "@/components/inputs/InfestationLevelSelector.vue";
import { useActivitiesStore } from "@/stores/activities";
import { useInterventionReportOptionsStore } from "@/stores/interventionReportOptions";

export default {
  name: "InfestationReportsForm",
  components: {
    LoaderView,
    InfestationLevelSelector,
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => {return [];},
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      internalValue: [...this.modelValue],
      selectedRooms: [],
      selectedActivities: [],
      activityToAdd: null,
      activitiesStore: useActivitiesStore(),
      interventionReportOptionsStore: useInterventionReportOptionsStore(),
    };
  },
  computed: {
    rooms() {
      return this.interventionReportOptionsStore.getRooms;
    },
    activities() {
      return this.activitiesStore.getActivities;
    },
  },
  watch: {
    modelValue() {
      this.refresh();
    },
    internalValue: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
        this.$emit("change", newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.internalValue = this.modelValue;
      this.selectedRooms = [];
      this.selectedActivities = [];

      for (const report of this.internalValue) {
        this.selectRoom(report.room);
        this.selectActivity(report.activity);
      }
    },
    selectRoom(room, selected = true) {
      if (selected && !this.roomIsSelected(room)) {
        this.selectedRooms.push(room);

        for (const activity of this.selectedActivities) {
          this.onInfestationReportChange(room, activity, "none");
        }

      } else if (!selected) {
        var index = this.selectedRooms.findIndex((selectedRoom) => {
          return selectedRoom.id == room.id;
        });

        if (index !== -1) {
          this.selectedRooms.splice(index, 1);
        }

        this.internalValue = this.internalValue.filter((report) => {
          return report.room_id != room.id;
        });
      }
    },
    selectActivity(activity, selected = true) {
      if (selected && !this.activityIsSelected(activity)) {
        this.selectedActivities.push(activity);

        for (const room of this.selectedRooms) {
          this.onInfestationReportChange(room, activity, "none");
        }

      } else if (!selected) {
        var index = this.selectedActivities.findIndex((selectedActivities) => {
          return selectedActivities.id == activity.id;
        });

        if (index !== -1) {
          this.selectedActivities.splice(index, 1);
        }

        this.internalValue = this.internalValue.filter((report) => {
          return report.activity_id != activity.id;
        });
      }
    },
    onInfestationReportChange(room, activity, level) {
      var reportIndex = this.internalValue.findIndex((report) => {
        return report.room_id == room.id && report.activity_id == activity.id;
      });

      if (reportIndex !== -1) {
        this.internalValue[reportIndex].level = level;
      } else {
        this.internalValue.push(
          {
            activity_id: activity.id,
            room_id: room.id,
            level: level,
          },
        );
      }
    },
    onCommentChange(room, activity, comment) {
      var reportIndex = this.internalValue.findIndex((report) => {
        return report.room_id == room.id && report.activity_id == activity.id;
      });

      if (reportIndex !== -1) {
        this.internalValue[reportIndex].comment = comment;

      } else {
        this.internalValue.push(
          {
            activity_id: activity.id,
            room_id: room.id,
            level: "none",
            comment: comment,
          },
        );
      }
    },
    getLevel(room, activity) {
      for (const report of this.internalValue) {
        if (report.room_id == room.id && report.activity_id == activity.id) {
          return report.level;
        }
      }
      return "none";
    },
    roomIsSelected(room) {
      for (const selectedRoom of this.selectedRooms) {
        if (selectedRoom.id == room.id) {
          return true;
        }
      }
      return false;
    },
    activityIsSelected(activity) {
      for (const selectedActivity of this.selectedActivities) {
        if (selectedActivity.id == activity.id) {
          return true;
        }
      }
      return false;
    },
  },
};

</script>