<template>
  <div class="card">
    <div class="card-header">
      <div class="title">
        Contrat
      </div>
    </div>

    <div v-if="contract && currentIteration" class="card-body">
      <div>
        Contrat du {{ $filters.dateStringToHumanizedDate(currentIteration.start_date) }} au {{ $filters.dateStringToHumanizedDate(currentIteration.end_date) }}
      </div>

      <div class="info-grid-layout">
        <div>
          Résponsable
        </div>
        <div class="horizontal-spaced-container align-middle">
          <span v-if="currentIteration.responsible">{{ $filters.fullname(currentIteration.responsible) }}</span>
          <span v-else><i>Aucun</i></span>
        </div>
      </div>

      <ContractProgressBar :iteration="currentIteration" />
    </div>

    <div v-else-if="contract" class="card-body">
      On a un contrat mais pas de période
    </div>

    <div v-else class="card-body">
      <div class="form-group">
        <i>Aucun contrat</i>
      </div>

      <div>
        <button
          class="btn-link"
          @click.stop="$refs.createContractModal.show(modelValue)">
          <i class="bi bi-plus-circle" /> Nouveau contrat
        </button>
      </div>

      <CreateContractModal
        ref="createContractModal"
        @created="onContractCreated" />
    </div>
  </div>
</template>

<script>

import ContractProgressBar from "@/components/others/ContractProgressBar.vue";
import CreateContractModal from "@/components/modals/CreateContractModal.vue";

export default {
  name: "ContractCompactCard",
  components: {
    ContractProgressBar,
    CreateContractModal,
  },
  props: {
    modelValue: { // The customer object
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    contract() {
      if (this.modelValue.contract) {
        return this.modelValue.contract;
      } else {
        return null;
      }
    },
    currentIteration() {
      if (this.contract && this.contract.iterations && this.contract.iterations.length > 0) {
        return this.contract.iterations[this.contract.iterations.length - 1];
      } else {
        return null;
      }
    },
  },
  methods: {
    onContractCreated(contract) {
      this.$emit(
        "update:modelValue",
        {
          ...this.modelValue,
          contract: contract,
        },
      );
    },
  },
};

</script>