<template>
  <div
    :class="{'visible': visible, 'hidden': !visible, 'success': notification.status == 'success', 'error': notification.status == 'error'}"
    :style="{'--index': index, '--transition-duration': transitionDuration/1000 + 's'}"
    class="notification-message">
    {{ notification.message }}
    <small v-if="notification.submessage"><br>{{ notification.submessage }}</small>
  </div>
</template>

<script>

import { useNotificationsStore } from "@/stores/notifications";

export default {
  name:"NotificationMessage",
  props: {
    notification: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      transitionDuration: 300, // Duration of the transition on appear and disapear in ms
      notificationsStore: useNotificationsStore(),
    };
  },
  mounted() {
    setTimeout(
      () => {
        this.visible = false;
        setTimeout(
          () => {
            this.notificationsStore.removeNotification(this.notification);
          }, this.transitionDuration,
        );
      }, this.notification.dismissAfter,
    );
  },
};

</script>

<style scoped lang="scss">

  .notification-message {
    position: fixed;
    bottom: calc(20px + var(--index) * 80px);
    border-radius: 5px;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1rem;
    z-index: 10000;
    transition: bottom var(--transition-duration) ease-in-out;
    margin: 10px;

    &.visible {
      animation-duration: var(--transition-duration);
      animation-timing-function: ease-out;
      -o-animation-timing-function: ease-out;
      -moz-animation-timing-function: ease-out;
      -webkit-animation-timing-function: ease-out;

      // On Desktop

      @media(min-width: $mobile-layout-horizontal-breakpoint) {
        right: 10px;
        animation-name: appear-from-right;
      }

      // Mobile

      @media(max-width: $mobile-layout-horizontal-breakpoint) {
        bottom: 10px;
        animation-name: appear-from-bottom;
      }

      // Animation
      
      @-webkit-keyframes appear-from-right {
        0% {opacity: 0; right: -100px;}
        100% {opacity: 1; right: 20px;}
      } 

      @keyframes appear-from-right {
        0% {opacity: 0; right: -100px;}
        100% {opacity: 1; right: 20px;}
      }

      @-webkit-keyframes appear-from-bottom {
        0% {opacity: 0; bottom: -50px;}
        100% {opacity: 1; bottom: 10px;}
      } 

      @keyframes appear-from-bottom {
        0% {opacity: 0; bottom: -50px;}
        100% {opacity: 1; bottom: 10px;}
      }
    }

    &.hidden {
      animation-duration: var(--transition-duration);
      animation-timing-function: ease-in;
      -o-animation-timing-function: ease-in;
      -moz-animation-timing-function: ease-in;
      -webkit-animation-timing-function: ease-in;

      // On Desktop

      @media(min-width: $mobile-layout-horizontal-breakpoint) {
        right: -100px;
        animation-name: dissapear-from-right;
      }

      // Mobile

      @media(max-width: $mobile-layout-horizontal-breakpoint) {
        bottom: -50px;
        animation-name: dissapear-from-bottom;
      }
      
      // Animation

      @-webkit-keyframes dissapear-from-right {
        0% {opacity: 1; right: 20px;}
        100% {opacity: 0; right: -100px;}
      } 

      @keyframes dissapear-from-right {
        0% {opacity: 1; right: 20px;}
        100% {opacity: 0; right: -100px;}
      }

      @-webkit-keyframes dissapear-from-bottom {
        0% {opacity: 1; bottom: 10px;}
        100% {opacity: 0; bottom: -50px;}
      } 

      @keyframes dissapear-from-bottom {
        0% {opacity: 1; bottom: 10px;}
        100% {opacity: 0; bottom: -50px;}
      }
    }
    
    &.success {
      background: #d4edda;
      color: #165825;
    }

    &.error {
      background: rgb(255, 0, 0);
      color: white;
    }
  }

</style>