import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const usePaymentRequestListStore = buildPaginatedMultiListStore(
  "paymentRequestList",
  "/invoices/",
  "Aucun rappel de paiement plannifié",
  false,
  (filters: object, subList: string) => {    
    if (subList != "all") {
      filters.journal_id = subList;
    }

    filters.elligible_for_payment_request = true;

    return filters;
  },
);
