<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Appels</span>
    </div>
    <div class="card-body">
      <div v-if="calls && calls.length > 0">
        <CustomerCallCompactRow
          v-for="(call, index) in calls"
          :key="index"
          :object="call" />
      </div>

      <i v-else>
        Aucun appel
      </i>

      <button
        class="btn-link"
        style="margin-top: 10px;"
        @click.stop="$refs.addCallModal.show()">
        Nouvel appel
      </button>
    </div>
  </div>

  <AddCallModal
    ref="addCallModal"
    :customerId="customerId"
    @click.stop
    @newCall="onNewCall" />
</template>

<script>

import CustomerCallCompactRow from "@/components/rows/customerCall/CustomerCallCompactRow.vue";
import AddCallModal from "@/components/modals/AddCallModal.vue";

export default {
  name: "CallListCard",
  components: {
    CustomerCallCompactRow,
    AddCallModal,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    calls: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      internalCalls: this.calls,
    };
  },
  watch: {
    calls(val) {
      this.internalCalls = val;
    },
  },
  methods: {
    onNewCall(call) {
      this.internalCalls.push(call);
    },
  },
};
</script>

<style lang="scss" scoped>


</style>