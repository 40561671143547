<template>
  <ObjectViewLayout
    title="Remboursement"
    :loading="object == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'refund-list' })">
    <template #rightBar>
      <div class="card">
        <div class="card-body">
          <p>Créé le: <strong>{{ $filters.dateStringToHumanizedDateTime(object.created_at) }}</strong></p>
          <p>Mise à jour le: <strong>{{ $filters.dateStringToHumanizedDateTime(object.updated_at) }}</strong></p>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";


export default {
  name: "RefundObjectView",
  components: {
    ObjectViewLayout,
    
  },
  data() {
    return {
      object: null,
      errorFetching: false,

    };
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.refund.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
  },
};
</script>


<style scoped lang="scss">

</style>