<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Nom..."
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (Plus réçent d'abord)
            </option>
            <option value="created_at">
              Date de création (Plus ancien d'abord)
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Marketplace
          </div>
          <select
            v-model="marketplace"
            class="form-select">
            <option :value="null">
              Toutes
            </option>
            <option
              v-for="choice in marketplaces"
              :key="choice.value"
              :value="choice.value">
              {{ choice.label }}
            </option>
          </select>
        </div>

        <div class="filter-item">
          <ResetFiltersButton :store="store" />
        </div>
      </div>

      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-sale' })">
        <i class="bi bi-cart-plus" />
        <div class="tooltip-text left">
          Nouvelle vente
        </div>
      </button>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link :to="{ name: 'sale-list-to-ship' }" class="cat-list-item" active-class="active">
        A expédier
        ({{ store.getTotal("to_ship") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-delivering' }" class="cat-list-item" active-class="active">
        En livraison
        ({{ store.getTotal("delivering") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-delivered' }" class="cat-list-item" active-class="active">
        Livré
        ({{ store.getTotal("delivered") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-returning' }" class="cat-list-item" active-class="active">
        En retour
        ({{ store.getTotal("returning") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-returned' }" class="cat-list-item" active-class="active">
        Retourné
        ({{ store.getTotal("returned") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-no-delivery' }" class="cat-list-item" active-class="active">
        Remise en main propre
        ({{ store.getTotal("no_delivery") }})
      </router-link>
      <router-link :to="{ name: 'sale-list-cancelled' }" class="cat-list-item" active-class="active">
        Annulé
        ({{ store.getTotal("cancelled") }})
      </router-link>
    </div>
  
    <router-view style="min-height: 100%;" />
  </div>
</template>
  
<script>
  
import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import { useSaleListStore } from "@/stores/saleList";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";

export default {
  name: "SaleV2ListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
  },
  data() {
    return {
      store: useSaleListStore(),
      schema: null,
    };
  },
  computed: {
    query: {
      get() {
        return this.store.getFilter("q");
      },
      set(val) {
        this.store.addFilter("q", val);
        this.store.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.store.getFilter("created_at_after"),
          this.store.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.store.addFilter("created_at_after", val[0]);
        this.store.addFilter("created_at_before", val[1]);
        this.store.refresh();
      },
    },
    sortBy: {
      get() {
        return this.store.getFilter("sort_by");
      },
      set(val) {
        this.store.addFilter("sort_by", val);
        this.store.refresh();
      },
    },
    marketplace: {
      get() {
        return this.store.getFilter("marketplace");
      },
      set(val) {
        this.store.addFilter("marketplace", val);
        this.store.refresh();
      },
    },
    marketplaces() {
      if (this.schema === null) {
        return [];
      }

      return this.schema.marketplace.choices.map((choice) => ({
        value: choice[0],
        label: choice[1],
      }));

    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.saleV2.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
  },
};
</script>
  
<style lang="scss" scoped>


</style>
  