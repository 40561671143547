<template>
  <div
    class="task"
    :class="{
      'cancelled': isCancelled,
      'done': isDone,
      'overdue': isOverdue,
      'due-today': isDueToday,
    }"
    @click.stop>
    <div
      class="tooltip"
      style="display: flex; align-items: center; cursor: pointer;">
      <i v-if="task.action == 'call'" class="bi bi-telephone" />

      <i v-else-if="task.action == 'email'" class="bi bi-envelope-at" />

      <i v-else-if="task.action == 'rdv'" class="bi bi-calendar" />

      <i v-else-if="task.action == 'close'" class="bi bi-check-circle" />

      <i v-else-if="task.action == 'cancel'" class="bi bi-x-circle" />

      <div v-else style="margin-left: 3px">
        {{ task.action }}
      </div>

      <div style="margin-left: 3px">
        <span v-if="isCancelled">Annulé</span>
        <span v-else>{{ $filters.dateStringToRelativeDateTime(task.deadline) }}</span>
      </div>

      <div class="tooltip-text">
        A faire avant le {{ $filters.dateStringToHumanizedDateTime(task.deadline) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Task",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDueToday() {
      if (this.isDone || this.isCancelled) {
        return false;
      }
      const deadline = new Date(this.task.deadline);
      const today = new Date();
      return deadline.getDate() == today.getDate()
        && deadline.getMonth() == today.getMonth()
        && deadline.getFullYear() == today.getFullYear();
    },
    isOverdue() {
      if (this.isDone || this.isCancelled) {
        return false;
      }
      const deadline = new Date(this.task.deadline);
      const today = new Date();
      return deadline < today;
    },
    isDone() {
      return this.task.status == "done";
    },
    isCancelled() {
      return this.task.status == "cancelled";
    },
  },
};
</script>

<style lang="scss" scoped>

  .task {
    background: lightblue;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
      background: lighten(lightblue, 10%);
    }

    &.done {
      background: lightgreen;

      &:hover {
        background: lighten(lightgreen, 10%);
      }
    }

    &.overdue {
      background: salmon;

      &:hover {
        background: lighten(salmon, 10%);
      }
    }

    &.cancelled {
      background: lightgrey;

      &:hover {
        background: lighten(lightgrey, 10%);
      }
    
    }

    &.due-today {
      background: yellow;

      &:hover {
        background: lighten(yellow, 10%);
      }
    }

    
  }

</style>