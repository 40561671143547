<template>
  <div>
    <div
      v-if="showDropdown"
      class="dropdown-mask"
      @click.stop="showDropdown = false;" />
    <div
      style="position: relative;"
      class="desktop-only"
      @click.stop="showDropdown = true;"
      @mouseover="showDropdown = true; mouseHover = true;"
      @mouseleave="mouseHover = false; closeDropdownAfterTimeout();">
      <slot name="component" />
      <div :class="{ 'visible': showDropdown }" class="dropdown-menu" :style="{ 'right': alignRight ? '0' : 'auto'}">
        <slot />
      </div>
    </div>
    <div
      style="position: relative;"
      class="mobile-only"
      @click.stop="showDropdown = true;">
      <slot name="component" />
      <div :class="{ 'visible': showDropdown }" class="dropdown-menu">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseDropdown",
  props: {
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
      mouseHover: false,
    };
  },
  methods: {
    closeDropdownAfterTimeout() {
      setTimeout(
        () => {
          if (!this.mouseHover) {
            this.showDropdown = false;
          }
        }, 200,
      );
    },
  },
};
</script>

<style lang="scss">

  .dropdown-menu {
    position: absolute;
    top: 100%;
    border-radius: 4px;
    z-index: 10001;
    font-weight: normal;
    text-align: left;
    padding: 6px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.4);
    user-select: none;
    background: white;

    hr {
      color: rgba(0, 0, 0, 0.2);
    }

    // Hide for animation

    overflow: hidden;
    transition: transform 0.05s ease-in-out;
    transform: scaleY(0);
    transform-origin: top;

    &.appear-from-bottom {
      transform-origin: bottom;
    }

    &.visible {
      transform: scaleY(1);
    }
  }

  .dropdown-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.2);
  }


</style>