<template>
  <div
    class="planning-date"
    @click="onClick">
    {{ $filters.capitalize(dateHumanized) }}
  </div>
</template>

<script setup lang="ts">

import { computed } from "vue";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  date: { type: String, required: true },
  showMonth: { type: Boolean, required: false, default: false },
});

// Emits

const emits = defineEmits(["click"]);

// computed

const dateHumanized = computed(() => {
  const options: Intl.DateTimeFormatOptions = { weekday: "short", day: "numeric" };

  if (props.showMonth) {
    options.month = "short";
  }

  return new Date(Date.parse(props.date)).toLocaleDateString("fr-FR", options);
});

// Methods

function onClick() {
  emits("click");
}



</script>

<style lang="scss" scoped>

  .planning-date {
    display: flex; justify-content: center; align-items: center; height: 60px; padding: 5px; background: #b0c4de; text-align: center; border-left: 2px solid white; border-right: 2px solid white;
    &:hover {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
      cursor: pointer;
      z-index: 2;
    }
  }

</style>