<template>
  <ObjectViewLayout
    :title="object ? object.ref : null"
    :loading="object == null || schema == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'credit-note-list' })">
    <template #title-icon-left>
      <InvoiceIcon
        status="creditNote" />
    </template>

    <template #title-icon-right>
      <LockIcon v-if="object.is_locked" />
    </template>

    <template #rightButton>
      <CreditNoteToolButtons v-model="object" />
    </template>

    <template #default>
      <FormSection
        title="Informations sur la note de crédit"
        style="margin-bottom: 5px;"
        :viewOnly="object.is_locked">
        <template #default>
          <p>Montant: <strong>{{ object.amount }}€</strong></p>
          <p v-if="object.invoice == null">
            Origine: <strong>{{ object.origin }}</strong>
          </p>
          <p v-if="object.invoice == null">
            Référence de la facture: <strong>{{ object.invoice_ref }}</strong>
          </p>
          <p>
            Remarques à afficher sur le document: <strong>{{ object.public_remarks }}</strong>
          </p>
        </template>

        <template #edition>
          <div class="row">
            <FormField :schema="schema.amount" :error="validationErrors?.amount" class="col form-group">
              <input v-model="object.amount" type="number" class="form-control">
            </FormField>

            <FormField v-if="object.invoice == null" :schema="schema.origin" :error="validationErrors?.origin" class="col form-group">
              <OriginSelect v-model="object.origin" />
            </FormField>

            <FormField v-if="object.invoice == null" :schema="schema.invoice_ref" :error="validationErrors?.invoice_ref" class="col form-group">
              <input v-model="object.invoice_ref" type="text" class="form-control">
            </FormField>

            <FormField :schema="schema.public_remarks" :error="validationErrors?.public_remarks" class="col form-group">
              <input v-model="object.public_remarks" type="text" class="form-control">
            </FormField>
          </div>
          
          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingGeneralInfo }"
              class="btn-secondary"
              @click="saveGeneralInfo">
              Enregistrer les modifications
            </button>
          </div>
        </template>
      </FormSection>

      <div style="display: flex; flex-wrap: wrap; margin: 0 -5px;">
        <CreditNoteContactCard
          v-model="object.contact"
          :creditNoteId="object.id"
          :editable="!object.is_locked"
          style="margin: 5px; flex-grow: 1;" />

        <CreditNoteAddressCard
          v-model="object.address"
          :creditNoteId="object.id"
          :editable="!object.is_locked"
          style="margin: 5px; flex-grow: 1;" />
      </div>

      <EventListCard
        ref="eventListCard"
        style="margin: 5px 0;"
        :events="object.events"
        @submit="onCustomEvent" />

      <PdfCard :pdfData="creditNotePdf" />
    </template>

    <template #rightBar>
      <div class="card">
        <div class="card-body">
          <p>Créé le: <strong>{{ $filters.dateStringToHumanizedDateTime(object.created_at) }}</strong></p>
          <p>Mise à jour le: <strong>{{ $filters.dateStringToHumanizedDateTime(object.updated_at) }}</strong></p>
          <p>Ref: <strong>{{ object.ref }}</strong></p>
          <p>Envoyé au client: <strong v-if="object.send_date">{{ $filters.dateStringToHumanizedDateTime(object.send_date) }}</strong><strong v-else>Non</strong></p>
          <p>Envoyé sur Odoo: <strong v-if="object.odoo_error_message">Erreur</strong><strong v-else-if="object.odoo_id">Oui</strong><strong v-else>Non</strong></p>
          <p v-if="object.odoo_error_message">
            {{ object.odoo_error_message }}
          </p>
        </div>
      </div>

      <CreditNoteInvoiceCard v-model="object.invoice" style="margin-top: 10px; margin-bottom: 10px;" :creditNoteId="object.id" />
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormSection from "@/components/layout/FormSection.vue";
import CreditNoteToolButtons from "@/components/toolbuttons/CreditNoteToolButtons.vue";
import FormField from "@/components/forms/FormField.vue";
import LockIcon from "@/assets/iconsVue/LockIcon.vue";
import EventListCard from "@/components/EventListCard.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import CreditNoteInvoiceCard from "@/components/cards/CreditNoteInvoiceCard.vue";
import CreditNoteContactCard from "@/components/cards/contactCards/CreditNoteContactCard.vue";
import CreditNoteAddressCard from "@/components/cards/addressCards/CreditNoteAddressCard.vue";
import OriginSelect from "@/components/selects/OriginSelect.vue";
import PdfCard from "@/components/cards/PdfCard.vue";

export default {
  name: "CreditNoteObjectView",
  components: {
    ObjectViewLayout,
    FormSection,
    CreditNoteToolButtons,
    FormField,
    InvoiceIcon,
    LockIcon,
    EventListCard,
    PdfCard,
    CreditNoteInvoiceCard,
    CreditNoteContactCard,
    CreditNoteAddressCard,
    OriginSelect,
  },
  data() {
    return {
      object: null,
      schema: null,
      errorFetching: false,
      validationErrors: null,
      creditNotePdf: null,
      savingGeneralInfo: false,

    };
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
      this.fetchCreditNotePdf(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.creditNote.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    fetchSchema() {
      return this.$services.creditNote.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchCreditNotePdf() {
      return this.$services.creditNote.fetchPdf(this.$route.params.id)
        .then((response) => {
          this.creditNotePdf = response.data;
        });
    },
    onCustomEvent(description) {
      this.$services.creditNote.createEvent(this.object.id, description)
        .then((response) => {
          this.object.events = response.data.events;
          this.$refs.eventListCard.reset();
        });
    },
    saveGeneralInfo() {
      this.savingGeneralInfo = true;
      this.validationErrors = null;

      const data = {
        amount: this.object.amount,
        public_remarks: this.object.public_remarks,
      };

      if (this.object.invoice == null) {
        data.origin = this.object.origin;
        data.invoice_ref = this.object.invoice_ref;
      }

      this.$services.creditNote.patch(this.object.id, data)
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationError = error.response.data;
          } else {
            throw error;
          }

        }).finally(() => {
          this.savingGeneralInfo = false;
        });
    },
  },
};
</script>


<style scoped lang="scss">

</style>