import ModelApiService from "../utils/modelApiService";
import { api } from "@/api";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";

class OrderApiService extends ModelApiService {
  constructor() {
    super("/orders/", "commandes");
  }

  pushToMyParcel(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/push_to_myparcel/",
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commande envoyée sur MyParcel. Veuillez rafraichir après quelques secondes.");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de la commande sur MyParcel");
          reject(error);
        });
    });
  }

  updatefromMyParcel(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/update_from_myparcel/",
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commande mise à jour depuis MyParcel");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de la mise à jour de la commande depuis MyParcel");
          reject(error);
        });
    });
  }

  fetchInvoicePdf(id: number) {
    return api.get(
      this.url + id + "/invoice_pdf/",
    );
  }

  sendInvoiceToCustomerByEmail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/send_invoice_to_customer_by_email/",
      )
        .then((response) => {
          notifySuccess("Facture envoyée par email");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de la facture par email");
          reject(error);
        });
    });
  }

}

export default OrderApiService;