import { defineStore } from "pinia";
import { api, encode } from "@/api";


export function buildListStore(
  id: string,
  uri: string,
  noResultMessage: string | null = null,
  allowExport: boolean = false,
  state: Function = () => {},
  getters: any = {},
  actions: any = {},
) {

  return defineStore(id, {
    state: () => ({
      results: undefined,
      filters: {},
      uri: uri,
      loading: false,
      error: false,
      exporting: false,
      noResultMessage: noResultMessage,
      canExport: allowExport,
      ...state(),
    }),
    getters: {
      getNoResultMessage: state => {
        return state.noResultMessage;
      },
      getResults: state => {
        return state.results;
      },
      isLoading: state => {
        return state.loading;
      },
      isError: state => {
        return state.error;
      },
      getFilters: state => {
        return state.filters;
      },
      getFilter: state => (filter: string) => {
        if (filter in state.filters) {
          return state.filters[filter];
        } else {
          return null;
        }
      },
      allowExport: state => {
        return state.canExport;
      },
      isExporting: state => {
        return state.exporting;
      },
      ...getters,
    },
    actions: {
      setResults(results: any) {
        this.results = results;
      },
      updateResult(result: any) {
        const index = this.results.findIndex((r: any) => r.id == result.id);
        if (index == -1) {
          console.warn(`Trying to update element ${result.id} but it is not present in the list`);
        } else {
          console.log("Updating element at index %s", index);
          this.results[index] = result;
        }
      },
      addFilter(filter: string, value: any) {
        this.filters[filter] = value;
      },
      removeFilter(filter: string) {
        delete this.filters[filter];
      },
      clearFilters() {
        this.filters = {};
      },
      refresh() {
        this.loading = true;
        this.error = false;

        return api.get(
          `${this.uri}?${encode(this.filters)}`,
        )
          .then((response) => {
            this.results = response.data;
          }).catch(() => {
            this.error = true;
          }).finally(() => {
            this.loading = false;
          });
      },
      export() {
        this.exporting = true;

        return api.get(
          `${this.uri}export/?${encode(this.filters)}`,
        )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
            this.exporting = false;
          });
      },
      ...actions,
    },
  });
}



export function buildPaginatedListStore(
  id: string,
  uri: string,
  noResultMessage: string | null = null,
  allowExport: boolean = false,
  state: Function = () => {},
  getters: any = {},
  actions: any = {},
) {

  return defineStore(id, {
    state: () => ({
      results: undefined,
      filters: {},
      uri: uri,
      page: undefined,
      limit: undefined,
      total: undefined,
      loading: false,
      error: false,
      canExport: allowExport,
      exporting: false,
      noResultMessage: noResultMessage,
      ...state(),
    }),
    getters: {
      getNoResultMessage: state => {
        return state.noResultMessage;
      },
      getResults: state => {
        return state.results;
      },
      getPage: state => {
        return state.page;
      },
      getLimit: state => {
        return state.limit;
      },
      getTotal: state => {
        return  state.total;
      },
      isLoading: state => {
        return state.loading;
      },
      isError: state => {
        return state.error;
      },
      getFilters: state => {
        return state.filters;
      },
      getFilter: state => (filter: string) => {
        if (filter in state.filters) {
          return state.filters[filter];
        } else {
          return null;
        }
      },
      allowExport: state => {
        return state.canExport;
      },
      isExporting: state => {
        return state.exporting;
      },
      ...getters,
    },
    actions: {
      setResults(results: any) {
        this.results = results;
      },
      updateResult(result: any) {
        const index = this.results.findIndex((r: any) => r.id == result.id);
        if (index == -1) {
          console.warn(`Trying to update element ${result.id} but it is not present in the list`);
        } else {
          console.log("Updating element at index %s", index);
          this.results[index] = result;
        }
      },
      addFilter(filter: string, value: any) {
        this.filters[filter] = value;
      },
      removeFilter(filter: string) {
        delete this.filters[filter];
      },
      clearFilters() {
        this.filters = {};
      },
      refresh() {
        return this.loadPage(this.page);
      },
      loadPage(page: number | null = null) {
        if (page == null) {
          page = 1;
        }

        if (page != this.page) {
          this.results = undefined;
          this.page = page;
        }

        this.loading = true;
        this.error = false;
        
        return api.get(
          `${this.uri}?${encode({
            ...this.filters,
            page: page,
          })}`,
        )
          .then((response) => {
            this.results = response.data.results;
            this.total = parseInt(response.data.nb_results);
            this.limit = parseInt(response.data.limit);
          }).catch(() => {
            this.error = true;
          }).finally(() => {
            this.loading = false;
          });
      },
      loadPreviousPage() {
        if (this.page > 1) {
          return this.loadPage(this.page - 1);
        }
      },
      loadNextPage() {
        if (this.page < Math.ceil(this.total / this.limit)) {
          return this.loadPage(this.page + 1);
        }
      },
      export() {
        this.exporting = true;
        
        return api.get(
          `${this.uri}export/?${encode({
            ...this.filters,
          })}`,
        )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
            this.exporting = false;
          });
      },
      ...actions,
    },
  });
}



export function buildPaginatedMultiListStore(
  id: string,
  uri: string,
  noResultMessage: string | null = null,
  allowExport: boolean = false,
  // eslint-disable-next-line no-unused-vars
  processFiltersForSubList: Function = (filters: object, _subList: string) => {return filters;},
  state: Function = () => {},
  getters: any = {},
  actions: any = {},
) {

  return defineStore(id, {
    state: () => ({
      subLists: [],
      filters: {},
      uri: uri,
      noResultMessage: noResultMessage,
      canExport: allowExport,
      ...state(),
    }),
    getters: {
      getNoResultMessage: state => {
        return state.noResultMessage;
      },
      getResults: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].results;
        } else {
          return undefined;
        }
      },
      getPage: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].page;
        } else {
          return 1;
        }
      },
      getLimit: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].limit;
        } else {
          return undefined;
        }
      },
      getTotal: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].total;
        } else {
          return undefined;
        }
      },
      isLoading: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].loading;
        } else {
          return false;
        }
      },
      isError: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].error;
        } else {
          return false;
        }
      },
      getFilters: state => {
        return state.filters;
      },
      getFilter: state => (filter: string) => {
        if (filter in state.filters) {
          return state.filters[filter];
        } else {
          return null;
        }
      },
      getFiltersForSubList: state => (subList: string) => {
        return processFiltersForSubList(
          {
            ...state.filters,
          },
          subList,
        );
      },
      allowExport: state => {
        return state.canExport;
      },
      isExporting: state => (subList: string) => {
        if (state.subLists[subList]) {
          return state.subLists[subList].exporting;
        } else {
          return false;
        }
      },
      ...getters,
    },
    actions: {
      updateResult(result: any) {
        for (const subList in this.subLists) {
          console.log("Store: updateResult id %s in subList %s", result.id, subList);
          const index = this.subLists[subList].results.findIndex((r: any) => r.id == result.id);
          if (index == -1) {
            console.warn(`Trying to update element ${result.id} in subList ${subList} but it is not present in the list`);
          } else {
            console.log("Updating element at index %s", index);
            this.subLists[subList].results[index] = result;
          }
        }
      },
      addFilter(filter: string, value: any) {
        this.filters[filter] = value;
      },
      removeFilter(filter: string) {
        delete this.filters[filter];
      },
      clearFilters() {
        this.filters = {};
      },
      refresh(subList: string | null = null) {
        if (subList == null) {
          this.loadPageForAllSubLists(this.page);
        } else {
          this.loadPage(subList, this.page);
        }
      },
      loadPage(subList: string, page: number | null = null) {
        if (page == null) {
          page = 1;
        }

        if (this.subLists[subList] == null) {
          this.subLists[subList] = {
            results: undefined,
            page: undefined,
            limit: undefined,
            total: undefined,
            loading: false,
            error: false,
            exporting: false,
          };
        }

        if (page != this.subLists[subList].page) {
          this.subLists[subList].page = page;
          this.subLists[subList].results = undefined;
        }
        
        this.subLists[subList].loading = true;
        this.subLists[subList].error = false;

        return api.get(`${this.uri}?${encode({
          ...this.getFiltersForSubList(subList),
          page: page,
        })}`)
          .then((response) => {
            this.subLists[subList].results = response.data.results;
            this.subLists[subList].limit = parseInt(response.data.limit);
            this.subLists[subList].total = parseInt(response.data.nb_results);
          }).catch(() => {
            this.subLists[subList].error = true;
          }).finally(() => {
            this.subLists[subList].loading = false;
          });
      },
      loadPreviousPage(subList: string) {
        if (this.subLists[subList].page > 1) {
          return this.loadPage(subList, this.subLists[subList].page - 1);
        }
      },
      loadNextPage(subList: string) {
        if (this.subLists[subList].page < Math.ceil(this.subLists[subList].total / this.subLists[subList].limit)) {
          return this.loadPage(subList, this.subLists[subList].page + 1);
        }
      },
      loadPageForAllSubLists(page: number | null = null) {
        for (const subList in this.subLists) {
          this.loadPage(subList, page);
        }
      },
      export(subList: string) {
        this.subLists[subList].exporting = true;

        return api.get(`${this.uri}export/?${encode({
          ...this.getFiltersForSubList(subList),
        })}`)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
            this.subLists[subList].exporting = false;
          });
        
      },
      ...actions,
    },
  });
}

