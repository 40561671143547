<template>
  <ObjectViewLayout
    :title="object ? object.ref : ''"
    :loading="object == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'contract-list' })">
    <template #title-icon-left>
      <FileEarmarkTextIcon />
    </template>

    <template #rightButton>
      <ContractToolButtons v-model="object" />
    </template>

    <div>
      <div class="horizontal-spaced-container align-center align-middle">
        <button
          :disabled="previousIteration == null"
          class="tool-btn"
          @click="gotoPreviousIteration">
          <i class="bi bi-caret-left" />
          <span v-if="previousIteration">Période du {{ $filters.dateStringToHumanizedDate(previousIteration.start_date) }} au {{ $filters.dateStringToHumanizedDate(previousIteration.end_date) }}</span>
          <span v-else>Pas de période précédente</span>
        </button>

        <div>
          <strong>
            Période du {{ $filters.dateStringToHumanizedDate(selectedIteration.start_date) }} au {{ $filters.dateStringToHumanizedDate(selectedIteration.end_date) }}
          </strong>
        </div>

        <button
          v-if="nextIteration"
          class="tool-btn"
          @click="gotoNextIteration">
          <span>Période du {{ $filters.dateStringToHumanizedDate(nextIteration.start_date) }} au {{ $filters.dateStringToHumanizedDate(nextIteration.end_date) }}</span>
          <i class="bi bi-caret-right" />
        </button>

        <button
          v-else
          class="tool-btn"
          @click="$refs.iterationModal.new(object)">
          <span>Prolonger le contrat</span>
          <i class="bi bi-caret-right" />
        </button>
      </div>
    </div>

    <ContractIterationCard
      :modelValue="{...selectedIteration, 'contract': object}"
      @update:modelValue="onIterationSaved" />
    

    <EditContractIterationModal
      ref="iterationModal"
      @saved="onIterationSaved" />

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div style="text-align: center;">
          <strong>Contrat {{ object.ref }}</strong>
        </div>
        <CreatedUpdatedAtCard :object="object" />

        <ContractCustomerCard
          v-model="object" />

        <ContractInformation
          v-model="object" />

        <HistoryCard
          objectName="le contrat"
          :history="object.history"
          :postComment="postComment" />
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FileEarmarkTextIcon from "@/assets/iconsVue/FileEarmarkTextIcon.vue";
import ContractToolButtons from "@/components/toolbuttons/ContractToolButtons.vue";
import ContractCustomerCard from "@/components/cards/customerCards/ContractCustomerCard.vue";
import CreatedUpdatedAtCard from "@/components/cards/generic/CreatedUpdatedAtCard.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";
import ContractIterationCard from "@/components/cards/ContractIterationCard.vue";
import EditContractIterationModal from "@/components/modals/EditContractIterationModal.vue";
import ContractInformation from "./frags/ContractInformation.vue";

export default {
  name: "ContractObjectView",
  components: {
    ObjectViewLayout,
    CreatedUpdatedAtCard,
    HistoryCard,
    FileEarmarkTextIcon,
    ContractToolButtons,
    ContractCustomerCard,
    ContractIterationCard,
    EditContractIterationModal,
    ContractInformation,
  },
  data() {
    return {
      object: null,
      schema: null,
      errorFetching: false,
      selectedIterationIndex: 0,
    };
  },
  computed: {
    objectActivitiesHumanized() {
      if (this.object.activities) {
        const activities = [];
        
        for (const activity of this.object.activities) {
          activities.push(activity.name);
        }
        return activities.join(", ");
      }
      return null;
    },
    selectedIteration() {
      return this.object.iterations[this.object.iterations.length - this.selectedIterationIndex - 1];
    },
    nextIteration() {
      if (this.object.iterations.length > this.selectedIterationIndex + 1) {
        return this.object.iterations[this.object.iterations.length - this.selectedIterationIndex - 2];
      } else {
        return null;
      }
    },
    previousIteration() {
      if (this.selectedIterationIndex > 0) {
        return this.object.iterations[this.object.iterations.length - this.selectedIterationIndex];
      } else {
        return null;
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.contract.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    fetchSchema() {
      return this.$services.contract.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.object.customer.id }});
    },
    postComment(comment) {
      return this.$services.contract.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },
    onIterationSaved(iteration) {
      this.object.iterations.findIndex((i) => i.id === iteration.id) === -1
        ? this.object.iterations.push(iteration)
        : this.object.iterations[this.object.iterations.findIndex((i) => i.id === iteration.id)] = iteration;
    },
    gotoPreviousIteration() {
      if (this.previousIteration) {
        this.selectedIterationIndex--;
      }
    },
    gotoNextIteration() {
      if (this.nextIteration) {
        this.selectedIterationIndex++;
      }
    },
  },
};
</script>


<style scoped lang="scss">


</style>