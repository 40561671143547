import type { RouteRecordRaw } from "vue-router";

// Invoices
import InvoiceListLayout from "@/views/invoices/InvoiceListLayout.vue";
import InvoiceObjectView from "@/views/invoices/InvoiceObjectView.vue";
import InvoiceRow from "@/components/rows/invoice/InvoiceRow.vue";

// Quotes
import QuoteListLayout from "@/views/quotes/QuoteListLayout.vue";
import QuoteObjectView from "@/views/quotes/QuoteObjectView.vue";
import QuoteRow from "@/components/rows/quote/QuoteRow.vue";

// Credit notes
import CreditNoteListLayout from "@/views/creditNotes/CreditNoteListLayout.vue";
import CreditNoteObjectCreationView from "@/views/creditNotes/CreditNoteObjectCreationView.vue";
import CreditNoteObjectView from "@/views/creditNotes/CreditNoteObjectView.vue";
import CreditNoteRow from "@/components/rows/creditNote/CreditNoteRow.vue";

// Payment requests
import PaymentRequestListLayout from "@/views/invoices/PaymentRequestListLayout.vue";

// Utils
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import InterventionObjectAdvancedCreationView from "@/views/interventions/InterventionObjectAdvancedCreationView.vue";
import AddNewInterventionToInvoiceView from "@/views/invoices/AddNewInterventionToInvoiceView.vue";

// Stores
import { useInvoiceListStore } from "@/stores/invoiceList";
import { useQuoteListStore } from "@/stores/quoteList";
import { useCreditNoteListStore } from "@/stores/creditNoteList";
import { usePaymentRequestListStore } from "@/stores/paymentRequestList";
import { useRefundListStore } from "@/stores/refundList";


// Cash transactions
import RegisterView from "@/views/cash/RegisterView.vue";
import TechnicianWalletView from "@/views/cash/TechnicianWalletView.vue";

// Refunds
import RefundListLayout from "@/views/refunds/RefundListLayout.vue";
import RefundObjectView from "@/views/refunds/RefundObjectView.vue";
import RefundRow from "@/components/rows/refund/RefundRow.vue";


const accountingRoutes: RouteRecordRaw[] = [

  //// Invoices

  {
    path: "/factures-layout",
    component: InvoiceListLayout,
    redirect: "/factures",
    children: [
      {
        path: "/factures",
        component: PaginatedStoreList,
        name: "invoice-list",
        props: (route: any) => {
          return {
            rowComponent: InvoiceRow,
            columnsNames: [
              "Référence",
              "Client",
              "Adresse de facturation",
              "Interventions",
              "Autheur",
              "Envois et rappels",
              "Montant",
              "Paiements",
              "Odoo",
            ],
            store: useInvoiceListStore(),
            subList: route.query.journalId ? route.query.journalId : "all",
          };
        },
      },
    ],
  },
  {
    path: "/factures/nouvelle",
    component: InterventionObjectAdvancedCreationView,
    name: "create-invoice",
    props: (route: any) => ({
      mode: "invoice",
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),
  },
  {
    path: "/factures/:id",
    component: InvoiceObjectView,
    name: "invoice",
  },
  {
    path: "/factures/:id/copier",
    component: InterventionObjectAdvancedCreationView,
    name: "create-invoice-from-invoice",
    props: (route: any) => ({
      mode: "invoice",
      importProductsFromInvoiceId: route.params.id,
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),
  },
  {
    path: "/factures/:id/nouvelle-intervention",
    component: AddNewInterventionToInvoiceView,
    name: "add-intervention-to-invoice",
    props: (route: any) => ({
      invoiceId: route.params.id,
    }),
  },
  {
    path: "/factures/rappels-de-paiement-layout",
    component: PaymentRequestListLayout,
    redirect: "/factures/rappels-de-paiement",
    children: [
      {
        path: "/factures/rappels-de-paiement",
        component: PaginatedStoreList,
        name: "payment-request-list",
        props: (route: any) => {
          return {
            rowComponent: InvoiceRow,
            columnsNames: [
              "Référence",
              "Client",
              "Adresse de facturation",
              "Interventions",
              "Autheur",
              "Envois et rappels",
              "Montant",
              "Paiements",
              "Odoo",
            ],
            store: usePaymentRequestListStore(),
            subList: route.query.journalId ? route.query.journalId : "all",
          };
        },
      },
    ],
  },


  //// Quotes

  {
    path: "/devis-layout",
    component: QuoteListLayout,
    redirect: "/devis",
    children: [
      {
        path: "/devis",
        component: PaginatedStoreList,
        name: "quote-list",
        props: (route: any) => {
          return {
            rowComponent: QuoteRow,
            columnsNames: [],
            store: useQuoteListStore(),
            subList: route.query.originCode ? route.query.originCode : "all",
          };
        },
      },
    ],
  },
  {
    path: "/devis/nouveau",
    component: InterventionObjectAdvancedCreationView,
    name: "create-quote",
    props: (route: any) => ({
      mode: "quote",
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),

  },
  {
    path: "/devis/:id",
    component: QuoteObjectView,
    name: "quote",
  },
  {
    path: "/devis/:id/copier",
    component: InterventionObjectAdvancedCreationView,
    name: "create-quote-from-quote",
    props: (route: any) => ({
      mode: "quote",
      importProductsFromQuoteId: route.params.id,
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),
  },
  {
    path: "/devis/:id/transformer-en-facture",
    component: InterventionObjectAdvancedCreationView,
    name: "create-invoice-from-quote",
    props: (route: any) => ({
      mode: "invoice",
      importAllFromQuoteId: route.params.id,
      defaultSelectedCustomerId: route.query.customer,
      defaultSelectedInterventionId: route.query.intervention,
    }),
  },

  //// Credit notes

  {
    path: "/notes-de-credit-layout/",
    component: CreditNoteListLayout,
    redirect: "/notes-de-credit/",
    children: [
      {
        path: "/notes-de-credit/",
        component: PaginatedStoreList,
        name: "credit-note-list",
        props: (route) => {
          return {
            rowComponent: CreditNoteRow,
            columnsNames: ["Référence", "Date de création", "Facture", "Autheur", "Client", "Adresse de facturation", "Envoyé au client", "Envoyé sur Odoo"],
            store: useCreditNoteListStore(),
            subList: route.query.journalId ? route.query.journalId : "all",
          };
        },
      },
    ],
  },
  {
    path: "/notes-de-credit/nouveau/",
    component: CreditNoteObjectCreationView,
    name: "create-credit-note",
  },
  {
    path: "/notes-de-credit/:id/",
    component: CreditNoteObjectView,
    name: "credit-note",
  },

  //// Refunds

  {
    path: "/remboursements-layout/",
    component: RefundListLayout,
    redirect: "/remboursements/",
    children: [
      {
        path: "/remboursements/",
        component: PaginatedStoreList,
        name: "refund-list",
        props: (route) => {
          return {
            rowComponent: RefundRow,
            columnsNames: [null, "Date de création", "Status"],
            store: useRefundListStore(),
            subList: route.query.journalId ? route.query.journalId : "all",
          };
        },
      },
    ],
  },
  {
    path: "/remboursements/:id/",
    component: RefundObjectView,
    name: "refund",
  },

  //// Cash
      
  {
    path: "/caisse",
    component: RegisterView,
    name: "register",
  },

  {
    path: "/porte-monnaie-technicien",
    component: TechnicianWalletView,
    name: "technician-wallet",
  },

];

export default accountingRoutes;
