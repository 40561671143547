<template>
  <button
    class="btn-secondary tooltip"
    @click="onClick">
    <i class="bi bi-arrow-counterclockwise" />
    <div class="tooltip-text">
      Réinitializer les filtres
    </div>
  </button>
</template>

<script setup lang="ts">

// Props

const props = defineProps({
  store: {type: Object, required: true },
});

const onClick = () => {
  props.store.clearFilters();
  props.store.refresh();
};

</script>
