<!-- eslint-disable vue/html-self-closing -->
<template>
  <ObjectViewLayout
    :title="object ? object.ref : ''"
    :loading="object == null || schema == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'invoice-list' })">
    <template #title-icon-left>
      <InvoiceIcon
        :status="object.status" />
    </template>

    <template #title-icon-right>
      <LockIcon v-if="object.is_locked" />
    </template>

    <template #rightButton>
      <InvoiceToolButtons v-model="object" />
    </template>

    <div class="vertical-spaced-container">
      <InvoiceInfoCard v-model="object" />

      <InvoiceAndQuoteProductsCard
        v-model="object"
        type="invoice" />

      <div class="flow-spaced-container fill">
        <InvoiceContactCard
          v-model="object.contact"
          :invoiceId="object.id"
          :locked="object.is_locked" />

        <InvoiceAddressCard
          v-model="object.address"
          :invoiceId="object.id"
          :editable="!object.is_locked" />
      </div>

      <InvoicePaymentsCard v-model="object" />

      <PdfCard :pdfData="invoicePdf" />
    </div>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <CreatedUpdatedAtCard :object="object" />
        
        <div class="card">
          <div class="card-body">
            <div class="info-grid-layout">
              <div>
                Montant<span v-if="object.credit_note"> avant NC</span>
              </div>
              <div>
                <p v-if="object.credit_note" class="text-bigger">
                  {{ object.total_incl_tax }}€ TTC
                </p>
                <p v-else class="text-bigger">
                  <strong>{{ object.total_incl_tax }}€ TTC</strong>
                </p>
                <p class="text-smaller">
                  {{ object.total_excl_tax }}€ HT
                </p>
              </div>
              <div v-if="object.credit_note">
                <span class="highlighted red">Note de crédit</span>
              </div>
              <div v-if="object.credit_note">
                <p class="text-bigger">
                  {{ object.credit_note.total_incl_tax }}€ TTC
                </p>
                <p class="text-smaller">
                  {{ object.credit_note.amount }}€ HT
                </p>
              </div>
              <div v-if="object.credit_note">
                Montant après NC
              </div>
              <div v-if="object.credit_note">
                <p class="text-bigger">
                  <strong>{{ object.total_incl_tax - object.credit_note.total_incl_tax }}€ TTC</strong>
                </p>
                <p class="text-smaller">
                  {{ object.total_excl_tax - object.credit_note.amount }}€ HT
                </p>
              </div>
              <div v-if="object.profit !== undefined">
                Marge
              </div>
              <div v-if="object.profit !== undefined">
                <div v-if="object.profit == null" class="tooltip">
                  Indéterminée <i class="bi bi-question-circle" />
                  <div class="tooltip-text-red">
                    Les couts d'achats de tous les produits n'ont pas été encodés
                  </div>
                </div>
                <div v-else>
                  {{ object.profit }}€ ({{ profitPercent }}%)
                </div>
              </div>
              
              <div>
                Ref
              </div>
              <div>
                {{ object.ref }}
              </div>
              <div>
                Envoyé au client
              </div>
              <div>
                <span v-if="object.send_date" class="highlighted green">{{ $filters.dateStringToHumanizedDateTime(object.send_date) }}</span>
                <i v-else class="bi bi-x-circle-fill text-red" />
              </div>
              <div>
                Envoyé sur Odoo
              </div>
              <div>
                <span v-if="object.odoo_error_message" class="highlighted red tooltip">
                  Erreur, {{ object.push_to_odoo_attempt_left }} tentatives restantes
                  <span class="tooltip-text">{{ object.odoo_error_message }}</span>
                </span>
                <i v-else-if="object.odoo_id" class="bi bi-check-circle-fill text-green" />
                <i v-else class="bi bi-x-circle-fill text-red" />
              </div>
            </div>
          </div>
        </div>

        <InvoiceCustomerCard
          v-model="object" />

        <InvoiceContractCard
          v-model="object" />

        <InterventionsCard :interventions="object.interventions" @addIntervention="addIntervention" />

        <InvoiceSaleCard v-if="object.sale" :sale="object.sale" />

        <InvoiceCreditNoteCard v-if="object.credit_note" v-model="object.credit_note" />

        <EventListCard
          ref="eventListCard"
          :events="object.events"
          @submit="onCustomEvent" />

        <HistoryCard
          objectName="la facture"
          :history="object.history"
          :postComment="postComment" />
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import InvoiceToolButtons from "@/components/toolbuttons/InvoiceToolButtons.vue";
import InvoiceContactCard from "@/components/cards/contactCards/InvoiceContactCard.vue";
import LockIcon from "@/assets/iconsVue/LockIcon.vue";
import EventListCard from "@/components/EventListCard.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import InvoiceCustomerCard from "@/components/cards/customerCards/InvoiceCustomerCard.vue";
import InvoiceAddressCard from "@/components/cards/addressCards/InvoiceAddressCard.vue";
import InvoiceSaleCard from "@/components/cards/InvoiceSaleCard.vue";
import InvoiceInfoCard from "@/components/cards/invoiceCards/InvoiceInfoCard.vue";
import InvoiceAndQuoteProductsCard from "@/components/cards/invoiceAndQuoteCards/InvoiceAndQuoteProductsCard.vue";
import InvoicePaymentsCard from "@/components/cards/invoiceCards/InvoicePaymentsCard.vue";
import InvoiceCreditNoteCard from "@/components/cards/invoiceCards/InvoiceCreditNoteCard.vue";
import PdfCard from "@/components/cards/PdfCard.vue";
import CreatedUpdatedAtCard from "@/components/cards/generic/CreatedUpdatedAtCard.vue";
import InvoiceContractCard from "@/components/cards/contractCards/InvoiceContractCard.vue";
import InterventionsCard from "@/components/cards/InterventionsCard.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";

export default {
  name: "InvoiceObjectView",
  components: {
    ObjectViewLayout,
    InvoiceToolButtons,
    InvoiceContactCard,
    InvoiceIcon,
    LockIcon,
    EventListCard,
    InvoiceCustomerCard,
    InvoiceAddressCard,
    InvoiceSaleCard,
    InvoiceInfoCard,
    InvoiceAndQuoteProductsCard,
    InvoicePaymentsCard,
    InvoiceCreditNoteCard,
    PdfCard,
    CreatedUpdatedAtCard,
    InvoiceContractCard,
    InterventionsCard,
    HistoryCard,
  },
  data() {
    return {
      object: null,
      schema: null,
      editedInterventionList: null,
      errorFetching: false,
      invoicePdf: null,
    };
  },
  computed: {
    profitPercent() {
      if (this.object == null || this.object.profit == null) {
        return null;
      }

      return Math.round(this.object.profit * 100 / this.object.total_excl_tax);
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchInvoicePdf(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.invoice.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    fetchSchema() {
      return this.$services.invoice.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchInvoicePdf() {
      return this.$services.invoice.fetchPdf(this.$route.params.id)
        .then((response) => {
          this.invoicePdf = response.data;
        });
    },
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.object.customer.id }});
    },
    gotoIntervention(intervention) {
      this.$router.push({ name: "intervention", params: { id: intervention.id }});
    },

    addIntervention(intervention) {

      const intervention_customer_id = intervention.customer.id;
      const invoice_customer_id = this.object.customer.id;

      if (intervention_customer_id !== invoice_customer_id) {
        alert(`Le client de l'intervention (${intervention_customer_id}: ${intervention.customer.billing_last_name}) ne correspond pas au client de la facture (${invoice_customer_id}: ${this.object.customer.billing_last_name}).`);
        return;
      }

      if (intervention.invoice != null) {
        alert(`L'intervention ${intervention.ref_number} est déjà liée à une facture.`);
        return;
      }

      const interventionIds = this.object.interventions.map((int) => int.id);

      interventionIds.push(intervention.id);

      this.$services.invoice.patch(
        this.object.id,
        {
          intervention_ids: interventionIds,
        },
      )
        .then((response) => {
          this.object.interventions = response.data.interventions;

        });

    },

    onCustomEvent(description) {
      this.$services.invoice.createEvent(this.object.id, description)
        .then((response) => {
          this.object.events = response.data.events;
          this.$refs.eventListCard.reset();
        });
    },
    postComment(comment) {
      return this.$services.invoice.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },
  },
};
</script>


<style scoped lang="scss">


</style>