<template>
  <div class="lg:flex lg:h-full lg:flex-col">
    <header style="display: flex; justify-content: space-between; padding: 10px;">
      <div style="display: flex; align-items: center;">
        <!-- Date indicator -->
        <h1 class="text-base font-semibold leading-6 text-gray-900 mr-5">
          <time datetime="2022-01">{{ currentDay }}</time>
        </h1>
        
        <!-- Date navigator -->
        <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch mr-5">
          <div class="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300" aria-hidden="true" />
          <button type="button" class="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50" @click="gotoPrevious">
            <span class="sr-only">Mois précédent</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button type="button" class="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block" @click="gotoCurrent">
            Aujourd'hui
          </button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button type="button" class="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50" @click="gotoNext">
            <span class="sr-only">Mois suivant</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <!-- Scale selector -->
        <Menu as="div" class="relative">
          <MenuButton type="button" class="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Vue mois
            <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem>
                  <a href="#" :class="[scale == 'month' ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="scale = 'month'">Vue mois</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" :class="[scale == 'week' ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="scale = 'week'">Vue semaine</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" :class="[scale == 'day' ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="scale = 'day'">Vue jour</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div style="display: flex; align-items: center;">
        <div class="form-group-compact mr-5">
          <div class="form-label">
            Filtrer par technicien
          </div>
          <TechnicianSelect v-model="selectedUser" allOption />
        </div>

        <div class="form-group-compact">
          <div class="form-label">
            Filtrer par type d'évènements
          </div>
          <MultiSelect
            v-model="selectedEventTypes"
            :options="eventTypesOptions" />
        </div>
      </div>

      

      
      
      <div style="display: flex;">
        <div class="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" class="relative">
            <MenuButton type="button" class="flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500">
              Ajouter
              <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem>
                    <button class="text-gray-700 block px-4 py-2 text-sm" @click="$refs.addLeaveSlideOver.open()">
                      Congé
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button class="text-gray-700 block px-4 py-2 text-sm" @click="$refs.addWorkedSlotSlideOver.open()">
                      Créneau travaillé
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <Menu as="div" class="relative ml-6 md:hidden">
          <MenuButton class="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Open menu</span>
            <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Create event</a>
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Go to today</a>
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Day view</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Week view</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Month view</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Year view</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </header>

    <PlanningDay v-if="scale == 'day'" :activeDay="activeDay" :events="events" />
    <PlanningMonth v-else-if="scale == 'month'" :activeDay="activeDay" :events="events" />
    <PlanningWeek v-else-if="scale == 'week'" :activeDay="activeDay" :events="events" />
  </div>

  <AddLeaveSlideOver ref="addLeaveSlideOver" />
  <AddWorkedSlotSlideOver ref="addWorkedSlotSlideOver" />
</template>

<script>

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";
import PlanningDay from "@/views/planning/PlanningDay.vue";
import PlanningWeek from "@/views/planning/PlanningWeek.vue";
import PlanningMonth from "@/views/planning/PlanningMonth.vue";
import AddLeaveSlideOver from "@/components/planning/AddLeaveSlideOver.vue";
import AddWorkedSlotSlideOver from "@/components/planning/AddWorkedSlotSlideOver.vue";
import MultiSelect from "@/components/baseComponents/MultiSelect.vue";

export default {
  name: "PlanningLayout",
  components: {
    Menu,
    TechnicianSelect,
    PlanningDay,
    PlanningWeek,
    PlanningMonth,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    EllipsisHorizontalIcon,
    AddLeaveSlideOver,
    AddWorkedSlotSlideOver,
    MultiSelect,
  },
  data() {
    return {
      // Scale
      scale: "month",

      // Date
      activeDay: new Date(),

      // Filters
      selectedUser: null,
      showLeaveEvents: true,
      showPersonnalEvents: true,
      showInterventionRdvEvents: true,
      showWorkedSlots: true,
      

      // Data
      leaveEvents: undefined,
      personnalEvents: undefined,
      interventionRdvEvents: undefined,
      workedSlots: undefined,

      // State
      loadingLeaveEvents: false,
      loadingPersonnalEvents: false,
      loadingInterventionRdvEvents: false,
      loadingWorkedSlots: false,
      
    };
  },
  computed: {
    loading() {
      return this.loadingLeaveEvents || this.loadingPersonnalEvents || this.loadingInterventionRdvEvents || this.loadingWorkedSlots;
    },
    currentDay() {
      if (this.scale == "day") {
        return this.activeDay;
      } else if (this.scale == "week") {
        return `Semaine du ${this.startDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })} au ${this.endDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}`;
      } else if (this.scale == "month") {
        return this.activeDay.toLocaleString("default", { month: "long", year: "numeric" });
      } else {
        return null;
      }
    },
    startDate() {
      if (this.scale == "day") {
        return this.activeDay;
      } else if (this.scale == "week") {
        const firstDayOfWeek = new Date(this.activeDay);
        const dayOfWeek = firstDayOfWeek.getDay();
        const daysToSubtract = (dayOfWeek + 7 - 0) % 7;
        firstDayOfWeek.setDate(firstDayOfWeek.getDate() - daysToSubtract + 1);
        return firstDayOfWeek;
      } else if (this.scale == "month") {
        const firstDayOfMonth = new Date(this.activeDay);
        firstDayOfMonth.setDate(1);
        return firstDayOfMonth;
      } else {
        return null;
      }
    },
    endDate() {
      if (this.scale == "day") {
        return this.activeDay;
      } else if (this.scale == "week") {
        const lastDayOfWeek = new Date(this.activeDay);
        const dayOfWeek = lastDayOfWeek.getDay();
        const daysToAdd = (7 - dayOfWeek + 0) % 7;
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + daysToAdd);
        return lastDayOfWeek;
      } else if (this.scale == "month") {
        const lastDayOfMonth = new Date(this.activeDay);
        lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
        lastDayOfMonth.setDate(0);
        return lastDayOfMonth;
      } else {
        return null;
      }
    },
    events() {
      return [
        ...(
          this.leaveEvents ? this.leaveEvents.map((obj) => ({
            ...obj,
            type: "leave",
          })) : []
        ),
        ...(
          this.personnalEvents ? this.personnalEvents.map((obj) => ({
            ...obj,
            type: "perso",
          })) : []
        ),
        ...(
          this.interventionRdvEvents ? this.interventionRdvEvents.map((obj) => ({
            ...obj,
            type: "intervention",
          })) : []
        ),
        ...(
          this.workedSlots ? this.workedSlots.map((obj) => ({
            ...obj,
            type: "worked",
          })) : []
        ),
      ];
    },
    eventTypesOptions() {
      return [
        {
          label: "Congés",
          value: "leave",
          backgroundColor: "#F87171",
        },
        {
          label: "Evènements perso",
          value: "perso",
          backgroundColor: "#60A5FA",

        },
        {
          label: "Interventions",
          value: "intervention",
          backgroundColor: "#34D399",
        },
        {
          label: "Créneaux travaillés",
          value: "worked",
          backgroundColor: "#FBBF24",
        },
      ];
    },
    selectedEventTypes: {
      get() {
        const selectedEventTypes = [];
        if (this.showLeaveEvents) {
          selectedEventTypes.push("leave");
        }
        if (this.showPersonnalEvents) {
          selectedEventTypes.push("perso");
        }
        if (this.showInterventionRdvEvents) {
          selectedEventTypes.push("intervention");
        }
        if (this.showWorkedSlots) {
          selectedEventTypes.push("worked");
        }
        return selectedEventTypes;
      },
      set(value) {
        this.showLeaveEvents = value.includes("leave");
        this.showPersonnalEvents = value.includes("perso");
        this.showInterventionRdvEvents = value.includes("intervention");
        this.showWorkedSlots = value.includes("worked");
      },
    },
  },
  watch: {
    activeDay() {
      this.refresh();
    },
    scale() {
      this.refresh();
    },
    showLeaveEvents(newVal) {
      if (!newVal) {
        this.leaveEvents = undefined;
      } else {
        this.refresh();
      }
    },
    showPersonnalEvents(newVal) {
      if (!newVal) {
        this.personnalEvents = undefined;
      } else {
        this.refresh();
      }
    },
    showInterventionRdvEvents(newVal) {
      if (!newVal) {
        this.interventionRdvEvents = undefined;
      } else {
        this.refresh();
      }
    },
    showWorkedSlots(newVal) {
      if (!newVal) {
        this.workedSlots = undefined;
      } else {
        this.refresh();
      }
    },
    selectedUser() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.showLeaveEvents) {
        this.loadingLeaveEvents = true;
        this.$services.leaveEvent.search(
          {
            start_date: this.startDate.toISOString().split("T")[0],
            end_date: this.endDate.toISOString().split("T")[0],
            user: this.selectedUser?.id,
          },
          null,
          null,
        )
          .then((response) => {
            this.leaveEvents = response.data.results;
          }).finally(() => {
            this.loadingLeaveEvents = false;
          });
      } else {
        this.leaveEvents = undefined;
      }

      if (this.showPersonnalEvents) {
        this.loadingPersonnalEvents = true;
        this.$services.personnalEvent.search(
          {
            start_date: this.startDate.toISOString().split("T")[0],
            end_date: this.endDate.toISOString().split("T")[0],
            user: this.selectedUser?.id,
          },
          null,
          null,
        )
          .then((response) => {
            this.personnalEvents = response.data.results;
          }).finally(() => {
            this.loadingPersonnalEvents = false;
          });
      } else {
        this.personnalEvents = undefined;
      }

      if (this.showInterventionRdvEvents) {
        this.loadingInterventionRdvEvents = true;
        this.$services.interventionRdvEvent.search(
          {
            start_date: this.startDate.toISOString().split("T")[0],
            end_date: this.endDate.toISOString().split("T")[0],
            user: this.selectedUser?.id,
          },
          null,
          null,
        )
          .then((response) => {
            this.interventionRdvEvents = response.data.results;
          }).finally(() => {
            this.loadingInterventionRdvEvents = false;
          });
      } else {
        this.interventionRdvEvents = undefined;
      }

      if (this.showWorkedSlots) {
        this.loadingWorkedSlots = true;
        this.$services.workedSlot.search(
          {
            start_date: this.startDate.toISOString().split("T")[0],
            end_date: this.endDate.toISOString().split("T")[0],
            user: this.selectedUser?.id,
          },
          null,
          null,
        )
          .then((response) => {
            this.workedSlots = response.data.results;
          }).finally(() => {
            this.loadingWorkedSlots = false;
          });
      } else {
        this.workedSlots = undefined;
      }
    },
    
    gotoPrevious() {
      if (this.scale == "month") {
        const previousMonthDate = new Date(this.activeDay);
        previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
        previousMonthDate.setDate(1);
        this.activeDay = previousMonthDate;

      } else if (this.scale == "week") {
        const previousWeekDate = new Date(this.activeDay);
        previousWeekDate.setDate(previousWeekDate.getDate() - 7);
        this.activeDay = previousWeekDate;

      } else if (this.scale == "day") {
        this.activeDay.setDate(this.activeDay.getDate() - 1);
      }
    },
    gotoCurrent() {
      console.log("gotoCurrent");
      this.activeDay = new Date();
    },
    gotoNext() {
      if (this.scale == "month") {
        const nextMonthDate = new Date(this.activeDay);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
        nextMonthDate.setDate(1);
        this.activeDay = nextMonthDate;

      } else if (this.scale == "week") {
        const nextWeekDate = new Date(this.activeDay);
        nextWeekDate.setDate(nextWeekDate.getDate() + 7);
        this.activeDay = nextWeekDate;

      } else if (this.scale == "day") {
        const nextDayDate = new Date(this.activeDay);
        nextDayDate.setDate(nextDayDate.getDate() + 1);
        this.activeDay = nextDayDate;
      }
    },
  },
};

</script>

