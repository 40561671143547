<template>
  <ObjectViewLayout
    :title="title"
    :loading="customer1 == null || customer2 == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'customer-list' })">
    <template #title-icon-left>
      <i class="bi bi-person" />
    </template>

    <template #leftBar>
      <div class="vertical-spaced-container">
        <div>
          <div class="horizontal-spaced-container">
            <CustomerContactCard
              v-model="customer1.contact"
              :customerId="customer1.id" />

            <SimpleButton
              :loading="swipingCustomer1Contact"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1Contact" />
          </div>
        </div>

        <div>
          <div class="horizontal-spaced-container">
            <CustomerAddressCard
              v-model="customer1.address"
              :customerId="customer1.id" />

            <SimpleButton
              :loading="swipingCustomer1Address"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1Address" />
          </div>
        </div>

        <div v-if="customer1.billing_address">
          <div class="horizontal-spaced-container">
            <CustomerBillingAddressCard
              v-model="customer1.billing_address"
              :customerId="customer1.id" />

            <SimpleButton
              :loading="swipingCustomer1BillingAddress"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1BillingAddress" />
          </div>
        </div>

        <CallListCard
          :customerId="customer1.id"
          :calls="customer1.calls" />
        
        <div
          v-for="(intervention, index) in customer1.interventions"
          :key="index">
          <div class="horizontal-spaced-container">
            <InterventionCompactCard
              :modelValue="intervention" />

            <SimpleButton
              :loading="swipingCustomer1Intervention"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1Intervention" />
          </div>
        </div>

        <div
          v-for="(invoice, index) in customer1.other_invoices"
          :key="index">
          <div class="horizontal-spaced-container">
            <InvoiceCompactCard
              :modelValue="invoice" />

            <SimpleButton
              :loading="swipingCustomer1OtherInvoice"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1OtherInvoice" />
          </div>
        </div>

        <div
          v-for="(quote, index) in customer1.other_quotes"
          :key="index">
          <div class="horizontal-spaced-container">
            <QuoteCompactCard
              :modelValue="quote" />

            <SimpleButton
              :loading="swipingCustomer1OtherQuote"
              icon="bi bi-arrow-bar-right"
              @click="swipeCustomer1OtherQuote" />
          </div>
        </div>
      </div>
    </template>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div>
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2Contact"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2Contact" />
            <CustomerContactCard
              v-model="customer2.contact"
              :customerId="customer2.id" />
          </div>
        </div>

        <div>
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2Address"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2Address" />
            <CustomerAddressCard
              v-model="customer2.address"
              :customerId="customer2.id" />
          </div>
        </div>

        <div v-if="customer2.billing_address">
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2BillingAddress"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2BillingAddress" />
            <CustomerBillingAddressCard
              v-model="customer2.billing_address"
              :customerId="customer2.id" />
          </div>
        </div>

        <CallListCard
          :customerId="customer2.id"
          :calls="customer2.calls" />
        
        <div
          v-for="(intervention, index) in customer2.interventions"
          :key="index">
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2Intervention"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2Intervention" />
            <InterventionCompactCard
              :modelValue="intervention" />
          </div>
        </div>

        <div
          v-for="(invoice, index) in customer2.other_invoices"
          :key="index">
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2OtherInvoice"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2OtherInvoice" />
            <InvoiceCompactCard
              :modelValue="invoice" />
          </div>
        </div>

        <div
          v-for="(quote, index) in customer2.other_quotes"
          :key="index">
          <div class="horizontal-spaced-container">
            <SimpleButton
              :loading="swipingCustomer2OtherQuote"
              icon="bi bi-arrow-bar-left"
              @click="swipeCustomer2OtherQuote" />
            <QuoteCompactCard
              :modelValue="quote" />
          </div>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import CustomerContactCard from "@/components/cards/contactCards/CustomerContactCard.vue";
import CustomerAddressCard from "@/components/cards/addressCards/CustomerAddressCard.vue";
import CustomerBillingAddressCard from "@/components/cards/addressCards/CustomerBillingAddressCard.vue";
import CallListCard from "@/components/CallListCard.vue";
import InterventionCompactCard from "@/components/cards/interventionCards/InterventionCompactCard.vue";
import InvoiceCompactCard from "@/components/cards/invoiceCards/InvoiceCompactCard.vue";
import QuoteCompactCard from "@/components/cards/quoteCards/QuoteCompactCard.vue";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

export default {
  name: "CustomersFusionView",
  components: {
    ObjectViewLayout,
    CustomerContactCard,
    CustomerAddressCard,
    InvoiceCompactCard,
    CustomerBillingAddressCard,
    CallListCard,
    QuoteCompactCard,
    InterventionCompactCard,
    SimpleButton,
  },
  data() {
    return {
      customer1: null,
      customer2: null,
      errorFetching: false,

      swipingCustomer1Contact: false,
      swipingCustomer1Address: false,
    };
  },
  computed: {
    title() {
      if (this.customer1 == null || this.customer2 == null) {
        return "Chargement des infos clients...";
      } else {
        return `Fusion des clients ${this.customer1.contact.last_name} et ${this.customer2.contact.last_name}`;
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchCustomer1(),
      this.fetchCustomer2(),
    ]);
  },
  methods: {
    fetchCustomer1() {
      return this.$services.customer.retrieve(this.$route.params.id)
        .then((response) => {
          this.customer1 = response.data;
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    fetchCustomer2() {
      return this.$services.customer.retrieve(this.$route.params.id2)
        .then((response) => {
          this.customer2 = response.data;
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-list" });
    },
    swipeCustomer1Contact() {
      const res = window.confirm("Voulez-vous remplacer les informations de contact du client de droite avec celles du client de gauche ?");

      if (res) {
        this.swipingCustomer1Contact = true;

        this.$services.customer.patch(this.customer2.id, {
          contact: this.customer1.contact,
        }).then((response) => {
          this.customer2.contact = response.data.contact;
        }).finally(() => {
          this.swipingCustomer1Contact = false;
        });
      }
    },
    swipeCustomer1Address() {
      const res = window.confirm("Voulez-vous remplacer l'adresse du client de droite avec celles du client de gauche ?");

      if (res) {
        this.swipingCustomer1Address = true;

        this.$services.customer.patch(this.customer2.id, {
          address: this.customer1.address,
        }).then((response) => {
          this.customer2.address = response.data.address;
        }).finally(() => {
          this.swipingCustomer1Address = false;
        });
      }
    },
    swipeCustomer1BillingAddress() {
      const res = window.confirm("Voulez-vous remplacer l'adresse de facturation du client de droite avec celles du client de gauche ?");

      if (res) {
        this.swipingCustomer1BillingAddress = true;

        this.$services.customer.patch(this.customer2.id, {
          billing_address: this.customer1.billing_address,
        }).then((response) => {
          this.customer2.billing_address = response.data.billing_address;
        }).finally(() => {
          this.swipingCustomer1BillingAddress = false;
        });
      }
    },
    swipeCustomer2Contact() {
      const res = window.confirm("Voulez-vous remplacer les informations de contact du client de gauche avec celles du client de droite ?");

      if (res) {
        this.swipingCustomer2Contact = true;

        this.$services.customer.patch(this.customer1.id, {
          contact: this.customer2.contact,
        }).then((response) => {
          this.customer1.contact = response.data.contact;
        }).finally(() => {
          this.swipingCustomer2Contact = false;
        });
      }
    },
    swipeCustomer2Address() {
      const res = window.confirm("Voulez-vous remplacer l'adresse du client de gauche avec celles du client de droite ?");

      if (res) {
        this.swipingCustomer2Address = true;

        this.$services.customer.patch(this.customer1.id, {
          address: this.customer2.address,
        }).then((response) => {
          this.customer1.address = response.data.address;
        }).finally(() => {
          this.swipingCustomer2Address = false;
        });
      }
    },
    swipeCustomer2BillingAddress() {
      const res = window.confirm("Voulez-vous remplacer l'adresse de facturation du client de gauche avec celles du client de droite ?");

      if (res) {
        this.swipingCustomer2BillingAddress = true;

        this.$services.customer.patch(this.customer1.id, {
          billing_address: this.customer2.billing_address,
        }).then((response) => {
          this.customer1.billing_address = response.data.billing_address;
        }).finally(() => {
          this.swipingCustomer2BillingAddress = false;
        });
      }
    },
  },
};

</script>

<style lang="scss" scoped>



</style>