<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Interventions</span>
    </div>

    <div class="card-body">
      <div class="vertical-spaced-container">
        <i v-if="interventions == null || interventions.length == 0">Aucune</i>

        <InterventionCompactRow
          v-for="(intervention, index) in interventions"
          :key="index"
          :object="intervention" />

        <div v-if="!selectInterventionToAdd">
          <div class="flow-spaced-container">
            <button
              class="btn-link"
              @click="selectInterventionToAdd = true">
              Ajouter une intervention existante
            </button>

            <button
              class="btn-link"
              disabled>
              Créer et ajouter une nouvelle intervention
            </button>
          </div>
        </div>
      
        <SelectObject
          v-if="selectInterventionToAdd"
          placeholder="Sélectionnez une intervention"
          type="intervention"
          @select="onInterventionSelected" />

        <div v-if="selectInterventionToAdd" class="flow-spaced-container align-right">
          <button
            class="btn-primary"
            @click="selectInterventionToAdd = false">
            Terminer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import InterventionCompactRow from "@/components/rows/intervention/InterventionCompactRow.vue";

export default {
  name: "InterventionsCard",
  components: {
    SelectObject,
    InterventionCompactRow,
  },
  props: {
    interventions: {
      type: Array,
      required: true,
    },
  },
  emits: ["addIntervention"],
  data() {
    return {
      selectInterventionToAdd: false,
    };
  },
  methods: {
    onInterventionSelected(intervention) {
      this.$emit("addIntervention", intervention);
    },

  },
};

</script>