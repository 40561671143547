<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            :placeholder="'Nom, SKU, fournisseur...'"
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Filtrer par
          </div>
          <select
            v-model="filterBy"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option value="in_stock">
              En stock
            </option>
            <option value="out_of_stock">
              Pas de stock
            </option>
          </select>
        </div>

        <div class="filter-item">
          <ResetFiltersButton :store="store" />
        </div>
      </div>

      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-item' })">
        <i class="bi bi-box" style="font-size: 2rem;" />
        <div class="tooltip-text">
          Nouvel article
        </div>
      </button>

      <button
        class="tool-btn tooltip"
        style="margin-left: 5px;"
        @click="$router.push({ name: 'create-equipment' })">
        <i class="bi bi-tools" />
        <div class="tooltip-text">
          Nouvel equipement
        </div>
      </button>

      <button
        class="tool-btn tooltip"
        style="margin-left: 5px;"
        @click="$router.push({ name: 'create-service' })">
        <i class="bi bi-clipboard" />
        <div class="tooltip-text">
          Nouveau service
        </div>
      </button>

      <button
        class="tool-btn tooltip"
        style="margin-left: 5px;"
        @click="$router.push({ name: 'create-bundle' })">
        <i class="bi bi-grid" />
        <div class="tooltip-text left">
          Nouveau forfait
        </div>
      </button>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link :to="{ name: 'product-grid' }" class="cat-list-item" active-class="active">
        Grille
        ({{ store.getTotal("grid") }})
      </router-link>
      <router-link :to="{ name: 'item-list' }" class="cat-list-item" active-class="active">
        Articles
        ({{ store.getTotal("item") }})
      </router-link>
      <router-link :to="{ name: 'product-list-equipment' }" class="cat-list-item" active-class="active">
        Equipement
        ({{ store.getTotal("equipment") }})
      </router-link>
      <router-link :to="{ name: 'product-list-service' }" class="cat-list-item" active-class="active">
        Services
        ({{ store.getTotal("service") }})
      </router-link>
      <router-link :to="{ name: 'product-list-bundle' }" class="cat-list-item" active-class="active">
        Forfait
        ({{ store.getTotal("bundle") }})
      </router-link>
    </div>
  
    <router-view style="height: 100%;" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import { useProductListStore } from "@/stores/productList";

export default {
  name: "ProductListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    DateRangeInput,
  },
  data() {
    return {
      store: useProductListStore(),
    };
  },
  computed: {
    query: {
      get() {
        return this.store.getFilter("q");
      },
      set(val) {
        this.store.addFilter("q", val);
        this.store.refresh();
      },
    },
    createdDateRange: {
      get() {
        return [
          this.store.getFilter("created_at_after"),
          this.store.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.store.addFilter("created_at_after", val[0]);
        this.store.addFilter("created_at_before", val[1]);
        this.store.refresh();
      },
    },
    filterBy: {
      get() {
        return this.store.getFilter("filter_by");
      },
      set(val) {
        this.store.addFilter("filter_by", val);
        this.store.refresh();
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
