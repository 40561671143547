<template>
  <div class="vertical-spaced-container" style="margin: 10px;">
    <Job
      v-for="job in jobs"
      :key="job"
      :job="job" />
  </div>
</template>

<script>

import Job from "@/components/others/Job.vue";

export default {
  name: "JobsView",
  components: {
    Job,
  },
  data() {
    return {
      jobs: null,
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    fetchJobs() {
      this.$services.job.list().then((response) => {
        this.jobs = response.data;
      });
    },
  },
};

</script>


<style scoped lang="scss">

  .job {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }



</style>


