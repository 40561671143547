<template>
  <Modal ref="modal">
    <div>
      <div class="form-group">
        <label class="form-label">Date de début</label>
        <Datepicker
          v-model="startDate"
          :format="dateToHumanized"
          :enable-time-picker="false"
          :clearable="false"
          locale="fr"
          cancelText=""
          selectText="Valider"
          :teleport-center="isMobile" />
      </div>

      <div class="form-group">
        <label class="form-label">Date de fin</label>
        <Datepicker
          v-model="endDate"
          :format="dateToHumanized"
          :enable-time-picker="false"
          :clearable="false"
          locale="fr"
          cancelText=""
          selectText="Valider"
          :teleport-center="isMobile" />
      </div>

      <div class="form-group">
        <label class="form-label">Nombre d'interventions</label>
        <input
          v-model="nbInterventions"
          type="number"
          min="1"
          class="form-control">
      </div>

      <div class="form-group">
        <label class="form-label">Prix</label>
        <div class="input-group">
          <input
            v-model="priceInEuro"
            type="number"
            min="0"
            class="form-control"
            style="width: auto;">
          <div class="input-group-append">
            € HTVA
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Prix</label>
        <div class="input-group">
          <select
            v-model="priceModel"
            class="form-select">
            <option value="per_iteration">
              Pour toute la période
            </option>
            <option value="per_intervention">
              Par intervention
            </option>
            <option value="per_month">
              Par mois
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Responsable</label>
        <SelectObject
          type="user"
          :selectedValue="responsible ? $filters.fullname(responsible) : null"
          @select="onResponsibleSelected" />
      </div>
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="cancel">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="!canSubmit || submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "EditContractIterationModal",
  components: {
    Modal,
    SelectObject,
    Datepicker,
  },
  emits: ["saved"],
  data() {
    return {
      orginalIteration: null,
      
      responsible: null,
      startDate: null,
      endDate: null,
      priceInEuro: null,
      priceModel: null,
      nbInterventions: 0,
      contract: null,

      submitting: false,
    };
  },
  computed: {
    canSubmit() {
      if (this.orginalIteration == null) {
        return this.contract != null && this.responsible != null && this.startDate != null && this.endDate != null && this.priceInEuro != null && this.priceModel != null && this.nbInterventions > 0;
      } else {
        return this.responsible != null && this.startDate != null && this.endDate != null && this.priceInEuro != null && this.priceModel != null && this.nbInterventions > 0;
      }
    },
    isMobile() {
      return window.innerWidth < 500;
    },
  },
  methods: {
    clear()  {
      this.responsible = null;
      this.startDate = null;
      this.endDate = null;
      this.priceInEuro = null;
      this.priceModel = null;
      this.nbInterventions = 0;
      this.contract = null;
      this.orginalIteration = null;
      this.submitting = false;
    },
    new(contract) {
      this.clear();

      if (contract == null) {
        throw new Error("Contract is required");
      }

      if (contract.id == null) {
        throw new Error("Contract id is required");
      }

      this.contract = contract;
      this.nbInterventions = contract.nb_interventions;
      this.responsible = contract.responsible;
      this.priceInEuro = contract.price / 100;
      this.priceModel = contract.price_model;

      this.startDate = new Date();

      const nbMonth = contract.iteration_duration;

      this.endDate = new Date();
      this.endDate.setMonth(this.endDate.getMonth() + nbMonth);

      this.$refs["modal"].show();
    },
    edit(iteration) {
      if (iteration.id == null) {
        throw new Error("Iteration id is required");
      }

      this.clear();
      this.orginalIteration = iteration;
      this.nbInterventions = iteration.nb_interventions;
      this.priceInEuro = iteration.price / 100;
      this.priceModel = iteration.price_model;
      this.responsible = iteration.responsible;
      this.startDate = new Date(iteration.start_date);
      this.endDate = new Date(iteration.end_date);
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
      this.clear();
    },
    submit() {
      this.submitting = true;

      if (this.orginalIteration == null) {
        this.$services.contractIteration.create(
          {
            contract_id: this.contract.id,
            nb_interventions: this.nbInterventions,
            responsible_id: this.responsible.id,
            start_date: this.toIsoDate(this.startDate),
            end_date: this.toIsoDate(this.endDate),
            price: this.priceInEuro * 100,
            price_model: this.priceModel,
          },
        ).then((response) => {
          this.$emit("saved", response.data);
          this.hide();

        }).finally(() => {
          this.submitting = false;
        });

      } else {
        this.$services.contractIteration.patch(
          this.orginalIteration.id,
          {
            nb_interventions: this.nbInterventions,
            responsible_id: this.responsible.id,
            start_date: this.toIsoDate(this.startDate),
            end_date: this.toIsoDate(this.endDate),
            price: this.priceInEuro * 100,
            price_model: this.priceModel,
          },
        ).then((response) => {
          this.$emit("saved", response.data);
          this.hide();

        }).finally(() => {
          this.submitting = false;
        });
      }
    },
    cancel() {
      this.hide();
    },
    dateToHumanized(date) {
      const options = { weekday: "short", month: "short", day: "numeric", year: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    },
    onResponsibleSelected(user) {
      this.responsible = user;
    },
    toIsoDate(date) {
      return date.toISOString().split("T")[0];
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>