<template>
  <Modal ref="modal" preventHideOnBackgroundClick>
    <template #default>
      <Login @loginSucceed="$emit('loginSucceed')" />
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import Login from "./Login.vue";

export default {
  name: "LoginModal",
  components: {
    Modal,
    Login,
  },
  emits: ["loginSucceed"],
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>