<!-- TODO this component can be put in common with ObjectCustomerCard and InterventionInvoiceCard and InterventionQuoteCard -->
<template>
  <div class="card">
    <div class="card-header">
      <span class="title">
        Devis
      </span>
      <button
        v-if="modelValue && !editing"
        class="small-tool-btn"
        style="margin-left: 5px;"
        @click="gotoQuote">
        <i class="bi bi-eye" />
      </button>
      <button
        v-if="!editing"
        class="small-tool-btn"
        style="margin-left: 5px;"
        @click="startEditing">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div v-if="editing" class="card-body">
      <div class="form-group">
        <SelectObject
          placeholder="Assigner un devis à cette intervention"
          type="quote"
          :selectedValue="selectedQuote ? selectedQuote.ref : null"
          @unselect="selectedQuote = null" 
          @select="onQuoteSelected" />
      </div>
      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :disabled="savingQuote" @click="endEditing">
          Annuler
        </button>
        <button
          class="btn-primary"
          :class="{ 'loading': savingQuote }"
          style="margin-left: 5px;"
          :disabled="selectedQuote?.id == modelValue?.id || savingQuote"
          @click="saveQuote">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else-if="modelValue" class="card-body">
      <QuoteCompactRow
        :object="modelValue" />
    </div>
    
    <div v-else class="card-body">
      <i>Aucun</i>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import QuoteCompactRow from "@/components/rows/quote/QuoteCompactRow.vue";

export default {
  name: "InterventionQuoteCard",
  components: {
    SelectObject,
    QuoteCompactRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    interventionId: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editing: false,
      selectedQuote: this.modelValue,
      savingQuote: false,
    };
  },
  methods: {
    gotoQuote() {
      this.$router.push({ name: "quote", params: { id: this.modelValue.id }});
    },
    startEditing() {
      this.selectedQuote = this.modelValue,
      this.savingQuote = false;
      this.editing = true;
    },
    onQuoteSelected(quote) {
      this.selectedQuote = quote;
    },
    saveQuote() {
      if (this.selectedQuote == null) {
        const res = window.confirm("Etes-vous sûr de vouloir n'assigner aucun devis à cette intervention ?");

        if (!res) {
          return;
        }
      }

      this.savingQuote = true;

      this.$services.intervention.patch(
        this.interventionId,
        {
          quote_id: this.selectedQuote ? this.selectedQuote.id : null,
        },
      ).then((response) => {
        this.$emit(
          "update:modelValue",
          response.data.quote,
        );
        this.endEditing();
        
      }).finally(() => {
        this.savingQuote = false;
      });
    },
    endEditing() {
      this.editing = false;
    },
  },
};

</script>