<template>
  <tr class="my-list-row selectable">
    <td>
      <span class="text-bigger mr-2">{{ object.amount }}€</span>
      <span v-if="object.contact" class="text-bigger mr-2">pour {{ $filters.fullname(object.contact) }}</span>
      <span class="text-smaller">{{ $filters.dateStringToHumanizedDate(object.created_at) }}</span>
    </td>
  </tr>
</template>

<script>

export default {
  name: "CreditNoteResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
