<template>
  <Modal ref="modal" width="big" @click.stop>
    <div style="text-align: center; margin-bottom: 20px;">
      <strong>Editer le contrat {{ originalContract?.ref }}</strong>
    </div>

    <LoaderView v-if="schema == null" />

    <div v-else>
      <div class="flow-spaced-container">
        <FormField
          :schema="schema.signature_date"
          :error="validationErrors?.start_date">
          <Datepicker
            v-model="signatureDate"
            :format="dateToHumanized"
            style="max-width: 400px;"
            :enable-time-picker="false"
            :clearable="false"
            locale="fr"
            cancelText=""
            selectText="Valider"
            :teleport-center="isMobile" />
        </FormField>

        <FormField
          :schema="schema.iteration_duration"
          :error="validationErrors?.iteration_duration">
          <div class="input-group">
            <input
              v-model="iterationDuration"
              type="number"
              min="0"
              class="form-control"
              style="width: auto;">
            <div class="input-group-append">
              mois
            </div>
          </div>
        </FormField>
      </div>

      <FormField
        :schema="schema.nb_interventions"
        :error="validationErrors?.nb_interventions">
        <template #default>
          <input
            v-model="nbInterventions"
            type="number"
            class="form-control"
            style="max-width: 100px;">
        </template>
      </FormField>

      <div class="flow-spaced-container">
        <FormField
          :schema="schema.price"
          :error="validationErrors?.price">
          <div class="input-group">
            <input
              v-model="priceInEuro"
              type="number"
              min="0"
              class="form-control"
              style="width: auto;">
            <div class="input-group-append">
              € HTVA
            </div>
          </div>
        </FormField>

        <FormField
          :schema="schema.price_model"
          :error="validationErrors?.price_model">
          <select
            v-model="priceModel"
            class="form-select">
            <option value="per_iteration">
              Pour tout le contrat
            </option>
            <option value="per_intervention">
              Par intervention
            </option>
            <option value="per_month">
              Par mois
            </option>
          </select>
        </FormField>
      </div>

      <small class="text-red">
        Attention, ces modifications s'appliqueront aux futures périodes du contrat. Elles ne s'appliquent pas à la période en cours ni aux périodes passées.<br>
        Pour modifier une période en cours ou passée, veuillez l'éditer individuellement.
      </small>
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="hide">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="!canSubmit || submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "EditContractModal",
  components: {
    Modal,
    LoaderView,
    FormField,
    Datepicker,
  },
  emits: ["change"],
  data() {
    return {
      schema: null,
      originalContract: null,
      validationErrors: null,
      submitting: false,

      priceInEuro: null,
      priceModel: null,
      signatureDate: null,
      iterationDuration: null,
      nbInterventions: null,
    };
  },
  computed: {
    canSubmit() {
      return this.priceInEuro > 0
        && this.priceModel != null
        && this.signatureDate != null
        && this.nbInterventions > 0
        && this.iterationDuration > 0;
    },
    isMobile() {
      return window.innerWidth < 500;
    },
  },
  methods: {
    clear()  {
      this.originalContract = null;
      this.validationErrors = null;
      this.submitting = false;
      this.priceInEuro = null;
      this.priceModel = null;
      this.signatureDate = null;
      this.iterationDuration = null;
      this.nbInterventions = null;
    },
    show(contract) {
      this.clear();

      if (this.schema == null) {
        this.fetchSchema();
      }
      
      this.originalContract = contract;
      this.priceInEuro = contract.price / 100;
      this.priceModel = contract.price_model;
      this.signatureDate = new Date(contract.signature_date);
      this.iterationDuration = contract.iteration_duration;
      this.nbInterventions = contract.nb_interventions;

      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    fetchSchema() {
      this.$services.contract.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    dateToHumanized(date) {
      const options = { weekday: "short", month: "short", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    },
    submit() {
      this.submitting = true;
      this.validationErrors = null;

      this.$services.contract.patch(
        this.originalContract.id,
        {
          signature_date: this.signatureDate.toISOString().split("T")[0],
          price: this.priceInEuro * 100,
          price_model: this.priceModel,
          iteration_duration: this.iterationDuration,
          nb_interventions: this.nbInterventions,
        },
      )
        .then((response) => {
          this.$emit("change", response.data);
          this.hide();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>