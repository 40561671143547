<template>
  <div class="card">
    <div class="card-header">
      <i class="bi bi-briefcase title" style="margin-right: 10px;" />
      <span v-if="modelValue.rdv_date" class="title">
        Intervention du {{ $filters.dateStringToHumanizedDate(modelValue.rdv_date) }} à {{ modelValue.location.city }}
      </span>
      <span v-else class="title">
        Intervention à planifier <span v-if="modelValue.location">à {{ modelValue.location.city }}</span>
      </span>
    </div>

    <div v-if="modelValue.id" class="card-body">
      <div class="info-grid-layout">
        <div>
          Référence
        </div>
        <div>
          {{ modelValue.ref_number }}
        </div>
        <div>Adresse</div>
        <div>
          {{ modelValue.location.street_number }} {{ modelValue.location.street }}<br>
          {{ modelValue.location.zipcode }} {{ modelValue.location.city }} {{ modelValue.location.country_code }}
        </div>
      </div>
      <div class="flow-spaced-container">
        <router-link :to="{ name: 'intervention', params: { id: modelValue.id } }" class="btn-link">
          Voir l'intervention
        </router-link>
        <button
          class="btn-link">
          Voir le Qr Code
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      Intervention pas encore créée
    </div>

    <hr v-if="showInvoice">

    <InvoiceCompactCardHeader
      v-if="showInvoice && modelValue.invoice"
      :invoice="modelValue.invoice" />

    <InvoiceCompactCardBody
      v-if="showInvoice && modelValue.invoice"
      :invoice="modelValue.invoice"
      style="flex-grow: 0;" />

    <hr v-if="showQuote">

    <div v-if="showQuote" class="card-header">
      <InvoiceIcon
        status="quote"
        width="20px"
        height="20px" /> 
      <span v-if="modelValue.quote" class="title">
        Devis<span v-if="modelValue.quote"> de {{ modelValue.quote.total_incl_tax }}€</span>
      </span>
      <i v-else>Aucun devis</i>
    </div>

    <div v-if="showQuote && modelValue.quote" class="card-body">
      <div class="info-grid-layout">
        <div>Référence</div>
        <div>{{ modelValue.quote.ref }}</div>
        <div>
          Montant
        </div>
        <div>{{ modelValue.quote.total_incl_tax }}€ TTC / {{ modelValue.quote.total_excl_tax }}€ HT</div>
        <div>Date</div>
        <div>{{ $filters.dateStringToHumanizedDate(modelValue.quote.created_at) }}</div>
        <div>Auteur</div>
        <div>{{ $filters.fullname(modelValue.quote.author) }}</div>
      </div>
      <div class="flow-spaced-container">
        <span
          v-if="modelValue.quote.send_date"
          class="highlighted green tooltip"
          style="margin-right: 5px;">
          Envoyé au client
          <div class="tooltip-text">{{ $filters.dateStringToHumanizedDate(modelValue.quote.send_date) }}</div>
        </span>
        <span
          v-else
          class="highlighted red"
          style="margin-right: 5px;">
          Non envoyé au client
        </span>
      </div>
      <div class="flow-spaced-container">
        <router-link :to="{ name: 'quote', params: { id: modelValue.quote.id } }" class="btn-link">
          Voir le devis
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import InvoiceCompactCardHeader from "@/components/cards/invoice/InvoiceCompactCardHeader.vue";
import InvoiceCompactCardBody from "@/components/cards/invoice/InvoiceCompactCardBody.vue";

export default {
  name: "InterventionCompactCard",
  components: {
    InvoiceIcon,
    InvoiceCompactCardBody,
    InvoiceCompactCardHeader,
  },
  props: {
    modelValue: { // The intervention
      type: Object,
      required: true,
    },
    showInvoice: {
      type: Boolean,
      default: true,
    },
    showQuote: {
      type: Boolean,
      default: true,
    },
  },
};

</script>