<template>
  <div
    class="history-row"
    @click="$router.push({ name: 'intervention', params: { id: object.id } })">
    <p><strong>Intervention {{ object.ref_number }}</strong></p>
    <p>
      Créée le: <strong>{{ $filters.dateStringToHumanizedDate(object.created_at) }}</strong><br>
      Rdv fixé le: <strong>{{ $filters.dateStringToHumanizedDate(object.rdv_date) }}</strong>
    </p>
    <p>
      Client:
      <router-link v-if="object.customer" class="btn-link" :to="{ name: 'customer', params: { 'id': object.customer.id } }" @click.stop>
        {{ $filters.printCustomer(object.customer) }}
      </router-link>
    </p>
    <p>
      Contacts additionnels: <strong>TODO</strong>
    </p>
    <p>
      Adresse:<br>
      <strong>
        {{ object.location.street_number }} {{ object.location.street }}<br>
        {{ object.location.zipcode }} {{ object.location.city }} {{ object.location.country_code }}
      </strong>
    </p>
    <InvoiceCompactRow v-if="object.invoice" :object="object.invoice" />
    <button class="btn-link" :disabled="fetchingQrCode" @click.stop="showQrCode">
      Voir le QR code
      <Loader v-if="fetchingQrCode" />
    </button>
  </div>
  <ImageModal ref="qrCodeModal" @click.stop />
</template>

<script>

import InvoiceCompactRow from "@/components/rows/invoice/InvoiceCompactRow.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "InterventionCompactRow",
  components: {
    InvoiceCompactRow,
    ImageModal,
    Loader,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingQrCode: false,
    };
  },
  methods: {
    showQrCode() {
      this.fetchingQrCode = true;

      this.$services.intervention.fetchQrCode(this.object.id)
        .then((response) => {
          const qrCodeUrl = "data:image/png;base64,"+ response.data;     
          this.$refs.qrCodeModal.show(qrCodeUrl);
        }).finally(() => {
          this.fetchingQrCode = false;
        });
    },
  },
};
</script>



<style lang="scss" scoped>


</style>