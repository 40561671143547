<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Réapprovisionnement</span>
      <button v-if="editMode" class="btn btn-link" @click="cancel">
        (annuler)
      </button>
      <button v-else class="btn btn-link" @click="edit">
        (editer)
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <FormField :schema="schema.provider" :error="validationErrors?.provider">
        <template #default>
          <input v-model="editedProduct.provider" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.provider_product_name" :error="validationErrors?.provider_product_name">
        <template #default>
          <input v-model="editedProduct.provider_product_name" type="text" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.reorder_threshold" :error="validationErrors?.reorder_threshold">
        <template #default>
          <input v-model="editedProduct.reorder_threshold" type="number" class="form-control">
        </template>
      </FormField>

      <FormField :schema="schema.reorder_unit_count" :error="validationErrors?.reorder_unit_count">
        <template #default>
          <input v-model="editedProduct.reorder_unit_count" type="number" class="form-control">
        </template>
      </FormField>

      <div class="form-group" style="max-width: 200px;">
        <label class="form-label">
          Coût par unité
        </label>

        <div class="input-group">
          <input
            v-model="editedProduct._purchase_price_per_unit"
            type="number"
            class="form-control">

          <div class="input-group-append">
            € HTVA
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <p>Fournisseur : <strong>{{ modelValue.provider }}</strong></p>
      <p>Nom du produit chez le fournisseur : <strong>{{ modelValue.provider_product_name }}</strong></p>
      <p>Seuil de réaprovisionnement : <strong>{{ modelValue.reorder_threshold }}</strong></p>
      <p>Nombre d'unité à réapprovisionner : <strong>{{ modelValue.reorder_unit_count }}</strong></p>
      <p>
        Coût :

        <span v-if="modelValue._purchase_price_per_unit">
          <strong>{{ modelValue._purchase_price_per_unit }}€</strong> HTVA
        </span>
        <i v-else>Non renseigné</i>
      </p>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import { notifyError } from "@/notifications";

export default {
  name: "ProductPurchaseInformationCard",
  components: {
    FormField,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editMode: false,
      editedProduct: null,
      submitting: false,
      validationErrors: null,
    };
  },
  methods: {
    edit() {
      this.editedProduct = {
        provider: this.modelValue.provider,
        provider_product_name: this.modelValue.provider_product_name,
        reorder_threshold: this.modelValue.reorder_threshold,
        reorder_unit_count: this.modelValue.reorder_unit_count,
        _purchase_price_per_unit: this.modelValue._purchase_price_per_unit,
      };
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedProduct = null;
    },
    submit() {
      this.submitting = true;

      this.$services.product.patch(this.modelValue.id, this.editedProduct)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            {
              ...this.modelValue,
              provider: response.data.provider,
              provider_product_name: response.data.provider_product_name,
              reorder_threshold: response.data.reorder_threshold,
              reorder_unit_count: response.data.reorder_unit_count,
              _purchase_price_per_unit: response.data._purchase_price_per_unit,
            },
          );
          this.cancel();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            notifyError("Erreur");
            throw error;
          }
        });
    },
  },
};

</script>