<template>
  <div class="card">
    <!-- Overlay transparent -->
    <div v-if="refreshing" class="overlay">
      <!-- Loader -->
      <Loader width="50%" height="50%" />
    </div>

    <div class="card-header">
      <span class="title">Paiement</span>
    </div>

    <div class="card-body">
      <p class="text-muted">
        Tous les montants sont en TTC
      </p>

      <div class="flow-spaced-container" style="margin-bottom: 20px;">
        <div
          class="payment-card">
          <p><strong>Paiements locales</strong></p>
          <p
            v-if="modelValue.payments.length == 0">
            <i>Aucun paiement</i>
          </p>
          <InvoicePaymentCard
            v-for="(payment, index) in modelValue.payments"
            :key="index"
            :modelValue="payment"
            @update:modelValue="refresh"
            @delete="refresh" />
          <div
            v-if="newPayment != null"
            style="background: white; padding: 5px; margin: 5px; border-radius: 5px;">
            <FormField
              title="Type de paiement">
              <template #default>
                <select
                  v-model="newPayment.type"
                  class="form-select">
                  <option
                    v-for="(choice, index) in [['cash', 'liquide'], ['wire', 'virement'], ['bancontact', 'bancontact']]"
                    :key="index"
                    :disabled="choice[0] == 'wire' && !meIsAdmin && !meIsManager"
                    :value="choice[0]">
                    {{ choice[1] }}
                  </option>
                </select>
              </template>
            </FormField>
            <FormField
              title="Montant">
              <template #default>
                <input v-model="newPayment.amount" type="number" class="form-control">
              </template>
            </FormField>
            <FormField
              v-if="newPayment.type == 'cash'"
              title="Collecteur">
              <SelectObject
                type="user"
                :selectedValue="newPayment.receiver ? $filters.fullname(newPayment.receiver) : null"
                @select="onNewPaymentReceiverSelected" />
            </FormField>
            <div class="flow-spaced-container">
              <button :disabled="submittingNewPayment" class="btn btn-secondary" @click="newPayment = null">
                Annuler
              </button>
              <button
                :disabled="newPayment.amount == 0 || newPayment.type == null || submittingNewPayment || (newPayment.type == 'cash' && newPayment.receiver == null)"
                :class="{ 'loading': submittingNewPayment }"
                class="btn btn-primary"
                @click="submitNewPayment">
                Ajouter
              </button>
            </div>
          </div>
          <button v-else class="tool-btn" style="margin: 5px auto;" @click="addNewPayment">
            <i class="bi bi-plus-circle" style="font-size: 2rem;" />
          </button>
          <p>Total payé : {{ amountPaid / 100 }}€</p>
          <p v-if="modelValue.credit_note">
            Note de crédit : {{ modelValue.credit_note.total_incl_tax }}€
          </p>
          <p v-if="amountToPay >= 0">
            Reste à payer : {{ amountToPay / 100 }}€
          </p>
          <p v-else>
            Trop plein payé : {{ -amountToPay / 100 }}€
          </p>
        </div>
        <div
          class="payment-card">
          <p><strong>Paiements sur Odoo</strong></p>
          <div style="margin: auto;">
            <p>Statut : {{ modelValue.odoo_payment_state != null ? modelValue.odoo_payment_state : 'unknown' }}</p>
            <p>Reste à payer : {{ modelValue.odoo_amount_residual != null ? modelValue.odoo_amount_residual : 'unknown' }}</p>
          </div>
        </div>
        <div
          class="payment-card">
          <p><strong>Ce qui est affiché sur la facture</strong></p>
          <p>Payé : {{ (modelValue.paid / 100).toFixed(2) }}€</p>
          <p>
            <span
              v-if="modelValue.to_pay >= 0"
              class="highlighted"
              :class="{ 'green': modelValue.to_pay <= 0, 'yellow': modelValue.to_pay > 0 }">
              Reste à payer : {{ (modelValue.to_pay / 100).toFixed(2) }}€
            </span>
            <span
              v-else
              class="highlighted red">
              Trop plein payer : {{ (-modelValue.to_pay / 100).toFixed(2) }}€
            </span>
          </p>
        </div>
      </div>
      <div class="info-grid-layout" style="width: max-content;">
        <div>Responsable qui a donné son accord pour ne pas collecté le paiement lors de l'intervention</div>
        <div v-if="modelValue.manager_who_agreed_to_not_collect_payment">
          {{ $filters.fullname(modelValue.manager_who_agreed_to_not_collect_payment) }}
        </div>
        <div v-else>
          <i>Aucun</i>
        </div>
        <div>Raison pour ne pas avoir collecté le paiement lors de l'intervention</div>
        <div v-if="modelValue.reason_for_not_collecting_payment">
          {{ modelValue.reason_for_not_collecting_payment }}
        </div>
        <div v-else>
          <i>Aucune</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import { useMeStore } from "@/stores/me";
import InvoicePaymentCard from "@/components/cards/invoiceCards/InvoicePaymentCard.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "InvoicePaymentsCard",
  components: {
    FormField,
    SelectObject,
    InvoicePaymentCard,
    Loader,
  },
  props: {
    modelValue: { // The invoice
      type: Object,
      required: true,
      validator: function (value) {
        for (const field of [
          "is_locked",
          "reason_for_not_collecting_payment",
          "odoo_payment_state",
          "paid",
          "odoo_amount_residual",
          "credit_note",
          "to_pay",
        ]) {
          if (!Object.prototype.hasOwnProperty.call(value, field)) {
            console.error(`Missing field '${field}' in prop modelValue of component InvoicePaymentsCard`);
            return false;
          }
        }
        return true;
      },
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      refreshing: false,
      newPayment: null,
      submittingNewPayment: false,
    };
  },
  computed: {
    amountPaid() {
      return this.modelValue.payments.reduce((accumulateur, objet) => {
        return accumulateur + objet.amount;
      }, 0);
    },
    amountToPay() {
      let amountToPay = this.modelValue.total_incl_tax * 100 - this.amountPaid;

      if (this.modelValue.credit_note) {
        amountToPay = amountToPay - this.modelValue.credit_note.total_incl_tax * 100;
      }

      return amountToPay;
    },
    meIsAdmin() {
      const meStore = useMeStore();
      return meStore.meIsAdmin;
    },
    meIsManager() {
      const meStore = useMeStore();
      return meStore.meIsManager;
    },
  },
  methods: {
    addNewPayment() {
      this.newPayment = {
        amount: this.amountToPay / 100,
        type: null,
      };
    },
    onNewPaymentReceiverSelected(user) {
      this.newPayment.receiver = user;
    },
    submitNewPayment() {
      this.submittingNewPayment = true;

      const body = {
        amount: Math.round(this.newPayment.amount * 100),
        invoice_id: this.modelValue.id,
      };

      if (this.newPayment.receiver) {
        body.receiver_id = this.newPayment.receiver.id;
      }

      this.$services.payment.create(this.newPayment.type, body)
        .then(() => {
          this.refresh();
          this.newPayment = null;
        })
        .finally(() => {
          this.submittingNewPayment = false;
        });
    },
    refresh() {
      this.refreshing = true;
      this.$services.invoice.retrieve(this.modelValue.id)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            {
              ...this.modelValue,
              payments: [
                ...response.data.payments,
              ],
              paid: response.data.paid,
              to_pay: response.data.to_pay,
            },
          );
        })
        .finally(() => {
          this.refreshing = false;
        });
    },    
  },
  
};

</script>


<style lang="scss" scoped>

  .card {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Overlay transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Assurez-vous que le loader est au-dessus du contenu de la carte */
  }

  .payment-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    min-width: 100px;
    max-width: 200px;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 10px;
    }

    &.no-border {
      border: none;
    }

    &.clickable {
      cursor: pointer;
      color: #ccc;

      &:hover {
        border-color: #aaa;
        color: #aaa;
      }
    }
  }

</style>