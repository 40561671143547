<template>
  <div class="flow-spaced-container" style="align-items: center;">
    <Datepicker
      v-model="date"
      :format="dateToHumanized"
      :enable-time-picker="false"
      :clearable="false"
      locale="fr"
      cancelText=""
      selectText="Valider"
      :teleport-center="isMobile"
      :disabled="disabled"
      @update:modelValue="onDateChange" />

    <Datepicker
      v-model="time"
      :clearable="false"
      time-picker
      :start-time="{ 'hours': 8, 'minutes': 0 }"
      minutes-increment="15"
      minutes-grid-increment="15"
      locale="fr"
      cancelText=""
      selectText="Valider"
      :teleport-center="isMobile"
      :disabled="disabled"
      @update:modelValue="onTimeChange" />

    <button
      v-if="date !== null || time !== null"
      class="tool-btn tooltip"
      @click="() => { date = null; time = null; onDateChange(); }">
      <i class="bi bi-x-circle" style="font-size: 1.3rem;" />
      <div class="tooltip-text">
        Effacer la date
      </div>
    </button>
  </div>
</template>

<script>

// https://vue3datepicker.com/

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "RdvDatePicker",
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    const date = this.modelValue ? new Date(this.modelValue.substring(0, 19)) : null;

    return {
      date: date,
      time: date ? {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      } : null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 500;
    },
  },
  watch: {
    modelValue(newVal) {
      this.date = new Date(newVal.substring(0, 19));
      this.time = {
        hours: this.date.getHours(),
        minutes: this.date.getMinutes(),
      };
    },
  },
  methods: {
    onDateChange() {
      if (this.date === null || this.time == null) return;
      this.date.setHours(this.time.hours);
      this.date.setMinutes(this.time.minutes);
      this.update();
    },
    onTimeChange() {
      if (this.date === null) return;
      this.date.setHours(this.time.hours);
      this.date.setMinutes(this.time.minutes);
      this.update();
    },
    dateToHumanized(date) {
      const options = { weekday: "short", month: "short", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    },
    update() {
      const dateISOString = this.$filters.dateToISOString(this.date);
      this.$emit("update:modelValue", dateISOString);
      this.$emit("change", dateISOString);
    },
  },
};
</script>

<style>

</style>