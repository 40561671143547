<template>
  <div class="button-container-extended horizontal-spaced-container">
    <slot />
  </div>

  <button
    class="button-collapsed"
    @click.stop="$refs.mobileMenuDropdown.show()">
    <MoreVerticalIcon />
  </button>
  
  <MobileMenuDropdown ref="mobileMenuDropdown">
    <slot />
  </MobileMenuDropdown>
</template>

<script>

import MoreVerticalIcon from "@/assets/iconsVue/MoreVerticalIcon.vue";
import MobileMenuDropdown from "@/components/MobileMenuDropdown.vue";

export default {
  name: "Toolbar",
  components: {
    MoreVerticalIcon,
    MobileMenuDropdown,
  },
};

</script>

<style lang="scss" scoped>

.button-container-extended {

  @media(max-width: $mobile-layout-horizontal-breakpoint) {
    display: none;
  }
}

.button-collapsed {
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;

  @media(min-width: $mobile-layout-horizontal-breakpoint) {
    display: none;
  }
}

</style>