<template>
  <select v-model="selectedTechnician" class="form-select">
    <option
      v-if="allOption"
      :value="null">
      Tous
    </option>
    <option
      v-if="meOption && myId"
      :value="myId">
      Moi
    </option>
    <option
      v-for="(technicianChoice, index) in technicians"
      :key="index"
      :value="technicianChoice.id">
      {{ technicianChoice.first_name }} {{ technicianChoice.last_name }}
    </option>
  </select>
</template>

<script>

import { useTechniciansStore } from "@/stores/technicians";
import { useMeStore } from "@/stores/me";

export default {
  name: "TechnicianSelect",
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: null,
    },
    allOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    meOption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      techniciansStore: useTechniciansStore(),
      meStore: useMeStore(),
    };
  },
  computed: {
    myId() {
      return this.meStore.me.id;
    },
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    selectedTechnician: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};

</script>

