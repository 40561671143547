<template>
  <div
    class="history-row"
    @click="$router.push({ name: 'credit-note', params: { id: object.id } })">
    <p><strong>Note de crédit de {{ object.total_incl_tax }}€ TTC</strong> {{ object.amount }}€ HT</p>
    <p>Ref: {{ object.ref }}</p>
    <p>
      Créée le: <strong>{{ $filters.dateStringToHumanizedDate(object.created_at) }}</strong>
    </p>
  </div>
</template>

<script>

export default {
  name: "CreditNoteCompactRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>



<style lang="scss" scoped>


</style>