<template>
  <ObjectContactCard
    title="Coordonnées à afficher sur la facture"
    :object="modelValue"
    editable
    :enabledFields="enabledFields"
    :onSave="onSave" />
</template>

<script>

import ObjectContactCard from "@/components/cards/contactCards/ObjectContactCard.vue";

export default {
  name: "InvoiceContactCard",
  components: {
    ObjectContactCard,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    invoiceId: {
      type: Number,
      required: true,
    },
    locked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    enabledFields() {
      if (this.locked) {
        return [
          "email",
          "phone_number",
        ];
      } else {
        return null;
      }
    },
  },
  methods: {
    onSave(newContact) {
      return new Promise((resolve, reject) => {
        this.$services.invoice.patch(
          this.invoiceId,
          {
            contact: newContact,
          },
        ).then((response) => {
          this.$emit(
            "update:modelValue",
            response.data.contact,
          );
          resolve();
        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            error.response.data = error.response.data.contact;
          }
          reject(error);
        });      
      });
    },
  },
};

</script>