<template>
  <CreateContractModal
    ref="createContractModal"
    @created="onCreated" />
</template>

<script>

import CreateContractModal from "@/components/modals/CreateContractModal.vue";


export default {
  name: "ContractObjectCreationView",
  components: {
    CreateContractModal,
  },
  mounted() {
    this.$refs.createContractModal.show();
  },
  methods: {
    onCreated(contract) {
      this.$router.push({ name: "contract", params: { id: contract.id } });
    },
  },
};

</script>

<style lang="scss" scoped>
  

</style>