<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <HammerIcon
        v-if="product.type == 'equipment'"
        width="30px"
        height="30px" />
      <GridIcon
        v-else-if="product.type == 'bundle'"
        width="30px"
        height="30px" />
      <ClipboardIcon
        v-else-if="product.type == 'service'"
        width="30px"
        height="30px" />
      <i v-else class="bi bi-box" style="font-size: 2rem;" />
    </template>

    <template #title>
      <span class="big">{{ product.sku }}</span>
    </template>
    
    <td>
      <span class="big">{{ product.name }}</span>
    </td>

    <td>
      <p
        v-if="product.stock_unit_count_humanized != null"
        :class="{ 'highlighted red': product.stock_unit_count != null && product.stock_unit_count <= 0 }">
        {{ product.stock_unit_count_humanized }} en stock
      </p>
      <p v-if="product.value_in_stock_humanized != null">
        Valeur de {{ product.value_in_stock_humanized }}
      </p>
    </td>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <ProductToolButtons v-model="product" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import ProductToolButtons from "@/components/toolbuttons/ProductToolButtons.vue";
import { GBaseRow } from "g-list";
import HammerIcon from "@/assets/iconsVue/HammerIcon.vue";
import GridIcon from "@/assets/iconsVue/GridIcon.vue";
import ClipboardIcon from "@/assets/iconsVue/ClipboardIcon.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "ProductGenericRow",
  components: {
    GBaseRow,
    ProductToolButtons,
    HammerIcon,
    GridIcon,
    ClipboardIcon,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    product: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("From ProductGenericRow set product with unit_count = " + value.unit_count);
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "product", params: { id: this.product.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>