<template>
  <div class="card">
    <div class="cat-list" style="margin: 5px;">
      <router-link
        v-for="(storage, index) in storages"
        :key="index"
        class="cat-list-item"
        active-class="active"
        :to="{ name: 'storage', params: { id: storage.id} }">
        {{ storage.name }}
      </router-link>
    </div>

    <StorageContentView />
  </div>
</template>

<script>

import StorageContentView from "@/views/storages/StorageContentView.vue";
import { useStoragesStore } from "@/stores/storages";

export default {
  name: "StorageContentLayout",
  components: {
    StorageContentView,
  },
  data() {
    return {
      storagesStore: useStoragesStore(),
    };
  },
  computed: {
    storages() {
      const storages = this.storagesStore.getStorages;

      if (storages) {
        return storages;
      } else {
        return [];
      }
    },
  },
};
</script>
