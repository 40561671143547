<template>
  <select
    v-model="selectedOriginCode"
    class="form-select">
    <option
      v-for="(origin, index) in origins"
      :key="index"
      :value="origin.code">
      {{ origin.code }}
    </option>
  </select>
</template>

<script>

import { useOriginsStore } from "@/stores/origins";

export default {
  name: "OriginSelect",
  props: {
    modelValue: { // TThe Code or the origin
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    selectedOriginCode: {
      get() {
        return this.modelValue; 
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    origins() {
      return useOriginsStore().getOrigins;
    },
  },
};
</script>
