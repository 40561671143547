<template>
  <div>
    <NotificationMessage
      v-for="(notification, index) in notifications"
      :key="notification.id"
      :notification="notification"
      :index="index" />
  </div>
</template>

<script>

import NotificationMessage from "./NotificationMessage.vue";
import { useNotificationsStore } from "@/stores/notifications";

export default {
  name: "NotificationList",
  components: {
    NotificationMessage,
  },
  data() {
    return {
      notificationsStore: useNotificationsStore(),
    };
  },
  computed: {
    notifications() {
      return this.notificationsStore.getNotifications;
    },
  },
};

</script>

<style scoped lang="scss">

</style>