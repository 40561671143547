<template>
  <select
    v-model="selectedActivityId"
    class="form-select">
    <option
      v-for="(activity, index) in activities"
      :key="index"
      :value="activity.id">
      {{ activity.name }}
    </option>
  </select>
</template>

<script>

import { useActivitiesStore } from "@/stores/activities";

export default {
  name: "ActivitySelect",
  props: {
    modelValue: { // The id or the activity
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    selectedActivityId: {
      get() {
        return this.modelValue; 
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    activities() {
      return useActivitiesStore().getActivities;
    },
  },
};
</script>
