<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Ref, nom, email, téléphone..."
            style="width: 100%;" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Filtrer par
          </div>
          <select
            v-model="filterBy"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option value="unbilled">
              Pas encore facturé
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Origine
          </div>
          <select
            v-model="origin"
            class="form-select">
            <option :value="null">
              Tout
            </option>
            <option v-for="(originOptions, index) in origins" :key="index" :value="originOptions.code">
              {{ originOptions.code }}
            </option>
          </select>
        </div>
      
        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option :value="null">
              Date de création (plus récent d'abord)
            </option>
            <option value="created_at">
              Date de création (plus ancien d'abord)
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Filtrer par technicien
          </div>
          <select v-model="technicianId" class="form-select">
            <option
              :value="null">
              Tous
            </option>
            <option
              v-for="(technicianChoice, index) in technicians"
              :key="index"
              :value="technicianChoice.id">
              {{ technicianChoice.first_name }} {{ technicianChoice.last_name }}
            </option>
          </select>
        </div>
      
        <div class="filter-item">
          <ResetFiltersButton :store="interventionListStore" />
        </div>
      </div>

      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-intervention' })">
        <i class="bi bi-briefcase" />
        <div class="tooltip-text left">
          Nouvelle intervention
        </div>
      </button>
    </div>

    <div class="cat-list" style="margin: 5px;">
      <router-link :to="{ name: 'intervention-list.todo' }" class="cat-list-item" active-class="active">
        A traiter
        <span v-if="interventionListStore.getTotal('todo')">({{ interventionListStore.getTotal("todo") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.open' }" class="cat-list-item" active-class="active">
        En attente
        <span v-if="interventionListStore.getTotal('open')">({{ interventionListStore.getTotal("open") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.action-required' }" class="cat-list-item" active-class="active">
        Action requise
        <span v-if="interventionListStore.getTotal('action_required')">({{ interventionListStore.getTotal("action_required") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.to-plan' }" class="cat-list-item" active-class="active">
        A plannifier
        <span v-if="interventionListStore.getTotal('to_plan')">({{ interventionListStore.getTotal("to_plan") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.planned' }" class="cat-list-item" active-class="active">
        Planifiés
        <span v-if="interventionListStore.getTotal('planned')">({{ interventionListStore.getTotal("planned") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.to-close' }" class="cat-list-item" active-class="active">
        A cloturer
        <span v-if="interventionListStore.getTotal('to_close')">({{ interventionListStore.getTotal("to_close") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.closed' }" class="cat-list-item" active-class="active">
        Cloturées
        <span v-if="interventionListStore.getTotal('close')">({{ interventionListStore.getTotal("close") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.canceled' }" class="cat-list-item" active-class="active">
        Annulées
        <span v-if="interventionListStore.getTotal('cancel')">({{ interventionListStore.getTotal("cancel") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.mine' }" class="cat-list-item" active-class="active">
        Toutes les miennes
        <span v-if="interventionListStore.getTotal('mine')">({{ interventionListStore.getTotal("mine") }})</span>
      </router-link>

      <router-link :to="{ name: 'intervention-list.all' }" class="cat-list-item" active-class="active">
        Toutes
        <span v-if="interventionListStore.getTotal('all')">({{ interventionListStore.getTotal("all") }})</span>
      </router-link>
    </div>

    <router-view style="min-height: 100%;" />
  </div>

  <div class="tasks" :class="{ 'visible': tasksVisible }">
    <button
      class="tasks-toggle-button"
      @click="toggleTasks">
      <i class="bi bi-list-check" />
    </button>
    <InterventionTasksPlanningCard style="width: 100%; height: min-content;" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";
import { useInterventionListStore } from "@/stores/interventionList";
import { useOriginsStore } from "@/stores/origins";
import { useTechniciansStore } from "@/stores/technicians";
import InterventionTasksPlanningCard from "@/components/cards/InterventionTasksPlanningCard.vue";

export default {
  name: "InterventionListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    InterventionTasksPlanningCard,
    DateRangeInput,
  },
  data() {
    return {
      interventionListStore: useInterventionListStore(),
      originsStore: useOriginsStore(),
      techniciansStore: useTechniciansStore(),
      tasksVisible: false,
    };
  },
  computed: {
    query: {
      get() {
        return this.interventionListStore.getFilter("q");
      },
      set(val) {
        this.interventionListStore.addFilter("q", val);
        this.interventionListStore.refresh();
        this.$router.push({ name: "intervention-list-all" });
      },
    },
    createdDateRange: {
      get() {
        return [
          this.interventionListStore.getFilter("created_at_after"),
          this.interventionListStore.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.interventionListStore.addFilter("created_at_after", val[0]);
        this.interventionListStore.addFilter("created_at_before", val[1]);
        this.interventionListStore.refresh();
      },
    },
    status: {
      get() {
        return this.interventionListStore.getFilter("status");
      },
      set(val) {
        this.interventionListStore.addFilter("status", val);
        this.interventionListStore.refresh();
      },
    },
    filterBy: {
      get() {
        return this.interventionListStore.getFilter("filter_by");
      },
      set(val) {
        this.interventionListStore.addFilter("filter_by", val);
        this.interventionListStore.refresh();
      },
    },
    origin: {
      get() {
        return this.interventionListStore.getFilter("origin");
      },
      set(val) {
        this.interventionListStore.addFilter("origin", val);
        this.interventionListStore.refresh();
      },
    },
    sortBy: {
      get() {
        return this.interventionListStore.getFilter("sort_by");
      },
      set(val) {
        this.interventionListStore.addFilter("sort_by", val);
        this.interventionListStore.refresh();
      },
    },
    technicianId: {
      get() {
        return this.interventionListStore.getFilter("technician_id");
      },
      set(val) {
        this.interventionListStore.addFilter("technician_id", val);
        this.interventionListStore.refresh();
      },
    },
    origins() {
      return this.originsStore.getOrigins;
    },
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
  },
  methods: {
    toggleTasks() {
      this.tasksVisible = !this.tasksVisible;
    },
  },
};
</script>

<style lang="scss" scoped>

  .tasks {
    margin-top: 100px;
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    display: flex;

    transition: right 0.3s ease-in-out;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    .tasks-toggle-button {
      margin-top: 50px;
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 5px 0 0 5px;
      position: absolute;
      top: 0;
      right: 100%;
      
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &.visible {
      right: 0px;
    }
  }

</style>
