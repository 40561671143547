<template>
  <div class="search-container" title="Rechercher (shift+r)">
    <SearchInput 
      ref="searchInput"
      v-model="query"
      @focus="show = true; inputHasFocus = true;"
      @blur="onBlur" />
    <div
      :class="{'visible': show }"
      class="result-dropdown">
      <LoaderView v-if="loading" />
      <div
        v-else-if="hasResults"
        class="result-list">
        <!-- Clients -->

        <div v-if="results.customers" style="width: 100%; margin-bottom: 5px;">
          <p><strong>Clients</strong></p>
          <table
            v-if="results.customers.results.length > 0"
            style="width: 100%;">
            <CustomerResultRow
              v-for="(item, index) in results.customers.results"
              :key="index"
              :object="item"
              @click="onCustomerSelect(item)" />
          </table>
          <p v-if="results.customers.nb_results <= 0">
            <i>Aucun client trouvé</i>
          </p>

          <div style="text-align: right; width: 100%;">
            <button
              v-if="results.customers.nb_results - results.customers.results.length > 0"
              class="btn-link"
              @click="seeMoreCustomers">
              Voir plus de clients ({{ results.customers.nb_results - results.customers.results.length }}) >>
            </button>
          </div>
        </div>

        <!-- Interventions -->

        <div v-if="results.interventions" style="width: 100%; margin-bottom: 5px;">
          <p><strong>Interventions</strong></p>
          <table
            v-if="results.interventions.results.length > 0"
            style=" width: 100%;">
            <InterventionResultRow
              v-for="(item, index) in results.interventions.results"
              :key="index"
              :object="item"
              @click="onInterventionSelect(item)" />
          </table>
          <p v-if="results.interventions.nb_results <= 0">
            <i>Aucun client trouvé</i>
          </p>
          <div style="text-align: right; width: 100%;">
            <button
              v-if="results.interventions.nb_results - results.interventions.results.length > 0"
              class="btn-link"
              @click="seeMoreInterventions">
              Voir plus d'interventions ({{ results.interventions.nb_results - results.interventions.results.length }}) >>
            </button>
          </div>
        </div>

        <!-- Quotes -->

        <div v-if="results.quotes" style="width: 100%; margin-bottom: 5px; ">
          <p><strong>Devis</strong></p>
          <table
            v-if="results.quotes.results.length > 0"
            style="width: 100%;">
            <QuoteResultRow
              v-for="(item, index) in results.quotes.results"
              :key="index"
              :object="item"
              @click="onQuoteSelect(item)" />
          </table>
          <p v-if="results.quotes.nb_results <= 0">
            <i>Aucun devis trouvé</i>
          </p>
          <div style="text-align: right; width: 100%;">
            <button
              v-if="results.quotes.nb_results - results.quotes.results.length > 0"
              class="btn-link"
              @click="seeMoreQuotes">
              Voir plus de devis ({{ results.quotes.nb_results - results.quotes.results.length }}) >>
            </button>
          </div>
        </div>

        <!-- Invoices -->
        <div v-if="results.invoices" style="width: 100%; margin-bottom: 5px; ">
          <p><strong>Factures</strong></p>
          <table
            v-if="results.invoices.results.length > 0"
            style="width: 100%;">
            <InvoiceResultRow
              v-for="(item, index) in results.invoices.results"
              :key="index"
              :object="item"
              @click="onInvoiceSelect(item)" />
          </table>
          <p v-if="results.invoices.nb_results <= 0">
            <i>Aucune facture trouvé</i>
          </p>
          <div style="text-align: right; width: 100%;">
            <button
              v-if="results.invoices.nb_results - results.invoices.results.length > 0"
              class="btn-link"
              @click="seeMoreInvoices">
              Voir plus de factures ({{ results.invoices.nb_results - results.invoices.results.length }}) >>
            </button>
          </div>
        </div>

        <!-- Credit notes -->

        <div v-if="results.creditNotes" style="width: 100%; margin-bottom: 5px; ">
          <p><strong>Notes de crédit</strong></p>
          <table
            v-if="results.creditNotes.results.length > 0"
            style="width: 100%;">
            <CreditNoteResultRow
              v-for="(item, index) in results.creditNotes.results"
              :key="index"
              :object="item"
              @click="onCreditNoteSelect(item)" />
          </table>
          <p v-if="results.creditNotes.nb_results <= 0">
            <i>Aucune note de crédit trouvé</i>
          </p>
          <div style="text-align: right; width: 100%;">
            <button
              v-if="results.creditNotes.nb_results - results.creditNotes.results.length > 0"
              class="btn-link"
              @click="seeMoreCreditNotes">
              Voir plus de notes de crédit ({{ results.creditNotes.nb_results - results.creditNotes.results.length }}) >>
            </button>
          </div>
        </div>
      </div>

      <!-- History -->

      <div
        v-else
        class="result-list">
        <div v-for="(historicalQuery, index) in history" :key="index" class="history-row" @click="search(historicalQuery)">
          <HistoryIcon width="20px" height="20px" style="margin-right: 10px;" @click="search(historicalQuery)" />
          <span @click="onHistorySelect(historicalQuery)">{{ historicalQuery }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SearchInput from "@/components/inputs/SearchInput.vue";
import LoaderView from "@/components/LoaderView.vue";
import HistoryIcon from "@/assets/iconsVue/HistoryIcon.vue";
import CustomerResultRow from "@/components/rows/customer/CustomerResultRow.vue";
import InterventionResultRow from "@/components/rows/intervention/InterventionResultRow.vue";
import QuoteResultRow from "@/components/rows/quote/QuoteResultRow.vue";
import InvoiceResultRow from "@/components/rows/invoice/InvoiceResultRow.vue";
import CreditNoteResultRow from "@/components/rows/creditNote/CreditNoteResultRow.vue";
import { useCustomerListStore } from "@/stores/customerList";
import { useInterventionListStore } from "@/stores/interventionList";
import { useQuoteListStore } from "@/stores/quoteList";
import { useInvoiceListStore } from "@/stores/invoiceList";
import { useCreditNoteListStore } from "@/stores/creditNoteList";

export default {
  name: "GeneralSearchBar",
  components: {
    SearchInput,
    LoaderView,
    HistoryIcon,
    CustomerResultRow,
    InterventionResultRow,
    QuoteResultRow,
    InvoiceResultRow,
    CreditNoteResultRow,
  },
  data() {
    return {
      text: null,
      loading: false,
      error: false,
      results: {},
      show: false,
      inputHasFocus: false,
      history: [],
      recompute: false,
      query: null,
      customerListStore: useCustomerListStore(),
      interventionListStore: useInterventionListStore(),
      quoteListStore: useQuoteListStore(),
      invoiceListStore: useInvoiceListStore(),
      creditNoteListStore: useCreditNoteListStore(),
    };
  },
  computed: {
    hasResults() {
      this.recompute;

      for(const key in this.results) {
        if (this.results[key]) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    query(newVal) {
      this.search(newVal);
    },
  },
  mounted() {
    this.reset();
  },
  methods: {

    // Reset

    reset() {
      this.$refs.searchInput.clear();
      this.loading = false;
      this.error = false;
      this.results = {};
    },

    // Search

    search(query) {
      this.text = query;

      if (this.text == null || this.text.length <= 0) {
        this.reset();
        return;
      }

      this.loading = true;
      this.show = true;
      Promise.all([
        this.searchForCustomers(this.text),
        this.searchForInterventions(this.text),
        this.searchForQuotes(this.text),
        this.searchForInvoices(this.text),
        this.searchForCreditNotes(this.text),
      ]).then(() => {
        this.recompute = !this.recompute;
        this.error = false;
        this.loading = false;
      }).catch((err) => {
        this.error = true;
        this.loading = false;
        throw err;
      });
    },

    // On Focus lost
    onBlur() {
      this.inputHasFocus = false;
      setTimeout(
        () => {
          if (this.inputHasFocus) {
            return;
          }
          this.show = false;
        }, 100,
      );
    },

    saveQueryInHistory() {
      if (this.text == null || this.text.length == 0) {
        return;
      }

      if (this.history.indexOf(this.text) != -1) {
        return;
      }

      this.history.unshift(this.text);

      if (this.history.length > 5) {
        this.history.pop();
      }
    },

    // Search and set results
    
    searchForCustomers(query) {
      this.$services.customer.search(query, 3)
        .then((response) => {
          this.setResults("customers", response.data);
        });
    },
    searchForInterventions(query) {
      this.$services.intervention.search(query, 3)
        .then((response) => {
          this.setResults("interventions", response.data);
        });
    },
    searchForQuotes(query) {
      this.$services.quote.search(query, 3)
        .then((response) => {
          this.setResults("quotes", response.data);
        });
    },
    searchForInvoices(query) {
      this.$services.invoice.search(query, 3)
        .then((response) => {
          this.setResults("invoices", response.data);
        });
    },
    searchForCreditNotes(query) {
      this.$services.creditNote.search(query, 3)
        .then((response) => {
          this.setResults("creditNotes", response.data);
        });
    },
    setResults(list, results) {
      this.results[list] = results;
    },

    // On something select

    onCustomerSelect(customer) {
      this.saveQueryInHistory();
      this.$router.push({ name: "customer", params: { id: customer.id } });
      this.reset();
    },
    onInterventionSelect(intervention) {
      this.saveQueryInHistory();
      this.$router.push({ name: "intervention", params: { id: intervention.id } });
      this.reset();
    },
    onQuoteSelect(quote) {
      this.saveQueryInHistory();
      this.$router.push({ name: "quote", params: { id: quote.id } });
      this.reset();
    },
    onInvoiceSelect(invoice) {
      this.saveQueryInHistory();
      this.$router.push({ name: "invoice", params: { id: invoice.id } });
      this.reset();
    },
    onCreditNoteSelect(creditNote) {
      this.saveQueryInHistory();
      this.$router.push({ name: "credit-note", params: { id: creditNote.id } });
      this.reset();
    },
    onHistorySelect(value) {
      this.$refs.searchInput.focus();
      this.query = value;
      //this.search(value);
    },

    // See more
    
    seeMoreCustomers() {
      this.customerListStore.addFilter("q", this.text);
      this.customerListStore.refresh();
      this.$router.push({ name: "customer-list" });
    },
    seeMoreInterventions() {
      this.interventionListStore.addFilter("q", this.text);
      this.interventionListStore.refresh();
      this.$router.push({ name: "intervention-list" });
    },
    seeMoreQuotes() {
      this.quoteListStore.addFilter("q", this.text);
      this.quoteListStore.refresh();
      this.$router.push({ name: "quote-list" });
    },
    seeMoreInvoices() {
      this.invoiceListStore.addFilter("q", this.text);
      this.invoiceListStore.refresh();
      this.$router.push({ name: "invoice-list" });
    },
    seeMoreCreditNotes() {
      this.creditNoteListStore.addFilter("q", this.text);
      this.creditNoteListStore.refresh();
      this.$router.push({ name: "credit-note-list" });
    },
    
  },
};
</script>

<style lang="scss" scoped>

  .search-container {
    position: relative;

    .result-dropdown {

      // Size
      min-height: 200px;
      width: 190%;

      // Position
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 5;
      margin-top: 15px;

      // Layout
      display: none;
      padding: 15px;
    
      // Style
      background: rgba(white, 0.97);
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
      border-radius: 10px;

      &.visible {
        display: block;
      }

      .btn-link {
        margin: 5px 0;
        text-align: left;
        color: #b25441;
      }

      .result-list {
        table {
          tr {
            cursor: pointer;

            &:hover {
              background: rgba(230, 230, 230, 0.5);
            }

            td {
              padding: 10px 10px;
              border-bottom: solid 1px rgb(230, 230, 230);
              width: auto;

              &.min-size {
                width: 1%;
                white-space: nowrap;
              }

              .row-icon {
                width: 30px;

                &.small {
                  width: 20px;
                }
              }

              .tool-btn {
                background: transparent;
                border: none;
                padding: 5px;
                cursor: pointer;
                outline: none;

                &:hover {

                  svg {
                    color: black;
                  }
                }

                svg {
                  width: 30px;
                  height: 30px;
                  color: rgb(130, 130, 130);
                }
              }
            }

            &:last-of-type {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

</style>
