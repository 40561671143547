<template>
  <tr class="my-list-row selectable">
    <td>
      <span class="big">{{ object.name }}</span>
    </td>
  </tr>
</template>

<script>

export default {
  name: "StorageResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
