<template>
  <div class="flow-spaced-container">
    <div
      class="payment-card featured">
      <strong>Montant payé : {{ amountPaid / 100 }}€</strong><br>
      <strong v-if="amountToPay >= 0">Reste à payer : {{ amountToPay / 100 }}€</strong>
      <strong v-else>Trop plein payé : {{ -amountToPay / 100 }}€</strong>
    </div>
    <div
      v-for="(payment, index) in payments"
      :key="index"
      class="payment-card">
      <button
        class="tool-btn"
        @click="onRemovePayment(index)">
        <i class="bi bi-trash" />
      </button>
      <strong>{{ payment.amount / 100 }}€</strong><br>
      {{ translatePaymentType(payment.type) }}<br>
    </div>
    <div
      v-if="payments.length == 0"
      class="payment-card no-border">
      <i>Aucun paiement</i>
    </div>
    <div
      v-if="newPayment != null"
      class="payment-card">
      <FormField
        title="Type de paiement">
        <template #default>
          <select
            v-model="newPayment.type"
            class="form-select">
            <option
              v-for="(choice, index) in [['cash', 'liquide'], ['wire', 'virement'], ['bancontact', 'bancontact']]"
              :key="index"
              :disabled="choice[0] == 'wire' && !meIsAdmin && !meIsManager"
              :value="choice[0]">
              {{ choice[1] }}
            </option>
          </select>
        </template>
      </FormField>
      <FormField
        title="Montant">
        <template #default>
          <input v-model="newPayment.amount" type="number" class="form-control">
        </template>
      </FormField>
      <FormField
        v-if="newPayment.type == 'cash'"
        title="Collecteur">
        <SelectObject
          type="user"
          :selectedValue="newPayment.receiver ? $filters.fullname(newPayment.receiver) : null"
          @select="onNewPaymentReceiverSelected" />
      </FormField>
      <div class="flow-spaced-container">
        <button class="btn btn-secondary" @click="newPayment = null">
          Annuler
        </button>
        <button
          :disabled="newPayment.amount == 0 || newPayment.type == null || (newPayment.type == 'cash' && newPayment.receiver == null)"
          class="btn btn-primary"
          @click="submitNewPayment">
          Ajouter
        </button>
      </div>
    </div>
    <div v-else class="payment-card clickable" @click="onAddNewPayment">
      <i class="bi bi-plus-circle" style="font-size: 2rem;" />
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import FormField from "@/components/forms/FormField.vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "PaymentsForm",
  components: {
    SelectObject,
    FormField,
  },
  props: {
    modelValue: { // The payments
      type: Array,
      required: true,
    },
    totalWithTaxes: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      payments: this.modelValue,
      newPayment: null,
    };
  },
  computed: {
    // Pricing calculation
    
    amountPaid() { // In cents
      return this.payments.reduce((accumulateur, objet) => {
        return accumulateur + objet.amount;
      }, 0);
    },
    amountToPay() { // In cents
      return this.totalWithTaxes - this.amountPaid;
    },
    meIsAdmin() {
      const meStore = useMeStore();
      return meStore.meIsAdmin;
    },
    meIsManager() {
      const meStore = useMeStore();
      return meStore.meIsManager;
    },
  },
  watch: {
    payments: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
    modelValue: {
      handler(newVal) {
        this.payments = newVal;
      },
      deep: true,
    },
  },
  methods: {
    translatePaymentType(type) {
      switch (type) {
      case "cash":
        return "en espèces";
      case "wire":
        return "par virement";
      case "bancontact":
        return "par bancontact";
      case "payment_on_another_plateform":
        return "en ligne";
      default:
        return type;
      }

    },

    onAddNewPayment() {
      this.newPayment = {
        amount: this.amountToPay / 100,
        type: null,
      };
    },

    onNewPaymentReceiverSelected(user) {
      this.newPayment.receiver = user;
    },

    submitNewPayment() {
      const payment = {
        type: this.newPayment.type,
        amount: this.newPayment.amount * 100,
      };

      if (this.newPayment.type == "cash") {
        payment.receiver_id = this.newPayment.receiver.id;
      }


      this.payments.push(payment);
      this.newPayment = null;
    },

    
    onRemovePayment(index) {
      this.payments.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>

  .payment-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    min-width: 100px;
    max-width: 200px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.no-border {
      border: none;
    }

    &.clickable {
      cursor: pointer;
      color: #ccc;

      &:hover {
        border-color: #aaa;
        color: #aaa;
      }
    }

    &.featured {
      background-color: $primary;
    }
  }

</style>