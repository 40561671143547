import { api } from "@/api";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";

class MeApiService {

  fetch() {
    return api.get("/me/");
  }

  fetchFull() {
    return api.get("/me/?full=true");
  }

  schema() {
    return api.get("/me/schema/");
  }

  changePassword(oldPassword: string, newPassword: string) {
    return api.post(
      "/me/change_password/",
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
    );
  }

  patch(data: any) {
    return new Promise((resolve, reject) => {
      api.patch("/me/", data)
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Profil mis à jour");
          resolve(response);
        }).catch((error: Error) => {
          if (error.response && error.response.status === 400) {
            notifyError("Des champs n'ont pas été remplis correctements");
          } else if (error.response && error.response.status === 404) {
            notifyError("Vous essayez de modifier un profil qui n'existe pas ou plus.", "Essayez de rafraichir la page");
          } else {
            notifyError("Impossible de mettre à jour votre profil");
          }
          reject(error);
        });
    });
  }

  fetchTurnover(year: string, month: string) {
    return api.get(
      `/me/turnover/?year=${year}&month=${month}`,
    );
  }
}

export default MeApiService;