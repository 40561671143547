import { api } from "@/api";
import { AxiosResponse } from "axios"; // Import AxiosResponse type
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";


class InterventionTaskApiService extends ModelApiService {
  constructor() {
    super("/intervention-tasks/", "tâche");
  }

  toggleStatus(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/toggle_status/",
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess(`Tâche marquée comme ${response.data["status"]}`);
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de la mise à jour de la tâche");
          reject(error);
        });
    });
  }

  toggleCancel(id: number) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/toggle_cancel/",
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess(`Tâche marquée comme ${response.data["status"]}`);
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de la mise à jour de la tâche");
          reject(error);
        });
    });
  }

  comment(id: number, comment: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/comment/",
        {
          comment: comment,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commentaire enregistré");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'enregistrement du commentaire");
          reject(error);
        });
    });
  }
}

export default InterventionTaskApiService;