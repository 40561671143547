<template>
  <div class="card">
    <div class="card-body">
      <ul v-if="events && events.length > 0" role="list" class="space-y-6">
        <li v-for="(event, eventIndex) in events" :key="eventIndex" class="relative flex gap-x-4">
          <div v-if="eventIndex != events.length - 1" class="absolute left-0 flex w-6 justify-center" style="top: 20px; height: 100%;">
            <div class="w-px bg-gray-500 text-gray-500" />
          </div>
          <div v-if="event.author">
            <UserCircleIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
          </div>
          <div v-else class="relative flex h-6 w-6 flex-none items-center justify-center">
            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
          </div>
          <div class="flex-auto">
            <div class="flex justify-between gap-x-4">
              <p class="py-0.5 text-xs leading-5 text-gray-500">
                <span v-if="event.author" class="font-medium text-gray-900">{{ $filters.fullname(event.author) }}</span><span v-else class="font-medium text-gray-900">Le système</span> a modifié
              </p>
              <time :datetime="event.created_at" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ $filters.dateStringToHumanizedDateTime(event.created_at) }}</time>
            </div>
            <p class="text-sm leading-6 text-gray-500">
              {{ event.description }}
            </p>
          </div>
        </li>
      </ul>

      <!-- New comment form -->
      <div class="mt-6 flex gap-x-3">
        <UserCircleIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
        <div class="relative flex-auto">
          <div class="overflow-hidden rounded-lg ring-1 ring-inset ring-gray-300 p-2">
            <textarea
              id="comment"
              v-model="customEventDescription"
              rows="2"
              name="comment"
              class="block w-full resize-none border-0 bg-transparent text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              style="outline: none;"
              placeholder="Ajouter un commentaire..." />
            <div class="flex " style="justify-content: flex-end;">
              <button class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="submit">
                Commenter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { UserCircleIcon } from "@heroicons/vue/24/solid";

export default {
  name: "EventListCard",
  components: {
    UserCircleIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Evènements",
    },
    events: {
      type: Array,
      required: true,
    },
  },
  emits: ["submit"],
  data() {
    return {
      customEventDescription: null,
    };
  },
  methods: {
    reset() {
      this.customEventDescription = null;
    },
    submit() {
      this.$emit("submit", this.customEventDescription);
    },
  },
};
</script>

<style lang="scss" scoped>


</style>