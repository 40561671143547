<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Note de crédit</span>
    </div>

    <div class="card-body">
      <CreditNoteCompactRow
        :object="modelValue" />
    </div>
  </div>
</template>

<script>

import CreditNoteCompactRow from "@/components/rows/creditNote/CreditNoteCompactRow.vue";

export default {
  name: "InvoiceCreditNoteCard",
  components : {
    CreditNoteCompactRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
};

</script>