<template>
  <button class="tool-btn tooltip" @click.stop="editStorage">
    <i class="bi bi-pencil-square" />
    <div class="tooltip-text">
      Editer
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="addProductUnits">
    <i class="bi bi-box-arrow-in-down" />
    <div class="tooltip-text">
      Ajouter des produits
    </div>
  </button>

  <EditStorageModal ref="editStorageModal" :storage="modelValue" />
</template>

<script>

import EditStorageModal from "@/components/modals/EditStorageModal.vue";

export default {
  name: "StorageToolButtons",
  components: {
    EditStorageModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addProductUnits() {
      this.$router.push({ name: "scan-product", query: { dst_storage: this.modelValue.id, action: "addCart" } });
    },
    editStorage() {
      this.$refs.editStorageModal.show();
    },
  },
};

</script>

<style>

</style>