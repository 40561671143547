<template>
  <Modal ref="modal" width="medium">
    <template #default>
      <div v-if="selectedTechnician" class="form-group">
        {{ $filters.fullname(selectedTechnician) }}
      </div>
      <div class="form-group">
        <SelectObject
          type="technician"
          placeholder="Sélectionnez un technicien"
          @select="onTechnicianSelected" />
      </div>
    </template>
    <template #buttons>
      <button
        class="my-button mr-2"
        @click="hide">
        Annuler
      </button>
      <button
        class="my-button"
        style="margin-left: 5px;"
        @click="submit">
        Assigner
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "SelectInterventionTechnicianModal",
  components: {
    Modal,
    SelectObject,
  },
  props: {
    interventionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      selectedTechnician: null,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onTechnicianSelected(technician) {
      this.selectedTechnician = technician;
    },
    submit() {
      this.submitting = true;

      this.$services.intervention.patch(
        this.interventionId,
        {
          technician_id: this.selectedTechnician.id,
        },
      ).then((response) => {
        this.$store.commit("interventionList/updateElement", response.data);
        this.hide();
      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>