<template>
  <ObjectViewLayout
    title="Nouvelle note de crédit"
    :loading="initializing"
    :error="errorWhileInitializing"
    withBackButton
    hideHeaderOnMobile
    @back="$router.push({ name: 'credit-note-list' })">
    <template #title-icon-left>
      <InvoiceIcon status="creditNote" />
    </template>

    <template #default>
      <div class="card">
        <div class="card-body">
          <LoaderView v-if="schema == null" />

          <div v-else>
            <div
              v-if="mode == 'selectInvoice'"
              class="form-group"
              style="max-width: 300px;">
              <label class="form-label">Sélectionnez une facture existante</label>
              <SelectObject
                type="invoice"
                :selectedValue="selectedInvoice ? selectedInvoice.ref : null"
                :invalid="selectedInvoice?.credit_note"
                @select="onInvoiceSelect"
                @unselect="onInvoiceSelect(null)" />
              <small v-if="validationErrors?.invoice" class="form-text text-red">
                {{ validationErrors.invoice }}
              </small>
              <small v-if="selectedInvoice?.credit_note" class="form-text text-red">
                Cette facture a déjà une note de crédit
              </small>
            </div>

            <div
              v-if="mode != 'manual'"
              class="form-group">
              <button class="btn-link" @click="mode = 'manual'">
                Rentrer les informations manuellement
              </button>
            </div>

            <div
              v-if="mode == 'manual'">
              <FormField
                :schema="schema.origin"
                :error="validationErrors?.origin">
                <template #default>
                  <OriginSelect v-model="object.origin" />
                </template>
              </FormField>

              <div class="form-group" style="max-width: 300px;">
                <label class="form-label">Référence de la facture</label>
                <input
                  v-model="object.invoice_ref"
                  type="text"
                  class="form-control">
                <small v-if="validationErrors?.invoice_ref" class="form-text text-red">
                  {{ validationErrors.invoice_ref }}
                </small>
              </div>

              <ContactForm
                v-model="object.contact"
                :validationErrors="validationErrors?.contact" />

              <AddressForm
                v-model="object.address"
                :validationErrors="validationErrors?.address" />
            </div>
            
            <FormField
              :schema="schema.amount"
              :error="validationErrors ? validationErrors.amount : null"
              style="max-width: 200px;">
              <template #default>
                <input v-model="object.amount" type="number" class="form-control" min="0">
              </template>
            </FormField>

            <FormField
              :schema="schema.public_remarks"
              :error="validationErrors?.public_remarks">
              <template #default>
                <input v-model="object.public_remarks" type="text" class="form-control">
              </template>
            </FormField>
          </div>
        </div>

        <div class="card-footer justify-right">
          <button
            class="btn-primary"
            :disabled="!canSubmit || submitting"
            :class="{ 'loading': submitting }"
            @click="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import ContactForm from "@/components/forms/ContactForm.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import OriginSelect from "@/components/selects/OriginSelect.vue";

export default {
  name: "CreditNoteObjectCreationView",
  components: {
    ObjectViewLayout,
    InvoiceIcon,
    SelectObject,
    FormField,
    LoaderView,
    ContactForm,
    AddressForm,
    OriginSelect,
  },
  data() {

    return {
      schema: null,
      initializing: false,
      errorWhileInitializing: false,

      mode: "selectInvoice",

      object: {
        invoice_ref: null,
        contact: {
          title: "mr",
          language: "fr",
        },
        address: {
          country_code: "be",
        },
      },
      selectedInvoice: null,

      submitting: false,
      validationErrors: null,
    };
  },
  computed: {
    canSubmit() {
      if (this.selectedInvoice == null) {
        return this.object.invoice_ref != null && this.object.invoice_ref.length > 0 && this.object.amount > 0;
      } else {
        return this.selectedInvoice.credit_note == null && this.object.amount > 0;
      }
    },
  },
  async mounted() {
    this.initializing = true;
    this.fetchSchema();

    var invoiceId = this.$route.query.invoice;

    if (invoiceId) {
      const responseInvoice = await this.$services.invoice.retrieve(invoiceId);
      this.onInvoiceSelect(responseInvoice.data);
    }

    this.initializing = false;

  },
  methods: {
    fetchSchema() {
      return this.$services.creditNote.schema()
        .then((response) => {
          this.schema = response.data;
        })
        .catch((error) => {
          this.errorWhileInitializing = error;
        });
    },
    nextStep() {
      this.step = this.step + 1;
    },
    onInvoiceSelect(invoice) {
      this.selectedInvoice = invoice;
      this.object.amount = invoice.total_excl_tax;
    },
    onStepSelect(index) {
      this.step = index;
    },
    submit() {
      this.submitting = true;
      this.validationErrors = null;

      var body;

      if (this.mode == "selectInvoice") {
        body = {
          invoice_id: this.selectedInvoice.id,
          amount: this.object.amount,
          public_remarks: this.object.public_remarks,
        };
      } else {
        body = {
          ...this.object,
        };
      }

      this.$services.creditNote.create(body)
        .then((response) => {
          this.$router.push({ name: "credit-note", params: { id: response.data.id } });

        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>
  .card {
    flex-grow: 1;
  }

  .progress-bar {
    margin-bottom: 20px;

    @media(max-width: $mobile-layout-horizontal-breakpoint) {
      margin-bottom: 10px;
    }
  }

</style>