<template>
  <div style="margin: 10px;" class="vertical-spaced-container">
    <div style="display: flex; flex-wrap: wrap-reverse;">
      <div class="flow-spaced-container" style="flex-grow: 1; align-items: flex-end;">
        <div class="filter-item" style="max-width: 300px; width: 100%;">
          <SearchInput
            v-model="query"
            updateOnChange
            updateOnInputAfterDelay
            placeholder="Nom, prénom, email, téléphone..."
            style="width: 100%;" />
        </div>
      
        <div class="filter-item">
          <div class="filter-item-title">
            Créé
          </div>
          <DateRangeInput v-model="createdDateRange" />
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Trier par
          </div>
          <select
            v-model="sortBy"
            class="form-select">
            <option value="created_at">
              Date de création (descendant)
            </option>
            <option :value="null">
              Date de création (ascendant)
            </option>
          </select>
        </div>
      
        <div class="filter-item">
          <ResetFiltersButton :store="store" />
        </div>
      </div>

      <button
        class="tool-btn tooltip"
        @click="$router.push({ name: 'create-customer-call' })">
        <i class="bi bi-telephone-plus" />
        <div class="tooltip-text left">
          Nouvel appel client
        </div>
      </button>
    </div>

    <PaginatedStoreList
      :rowComponent="rowComponent"
      :columnsNames="[null, 'Coordonnées de l\'appelant', 'Société', 'Adresse', 'Secrétaire', 'Status']"
      :store="store" />
  </div>
</template>

<script>

import SearchInput  from "@/components/inputs/SearchInput.vue";
import ResetFiltersButton  from "@/components/buttons/ResetFiltersButton.vue";
import CustomerCallRow from "@/components/rows/customerCall/CustomerCallRow.vue";
import { shallowRef } from "vue";
import PaginatedStoreList from "@/components/listV2/PaginatedStoreList.vue";
import { useCustomerCallListStore } from "@/stores/customerCallList";
import DateRangeInput from "@/components/baseComponents/DateRangeInput.vue";

export default {
  name: "CustomerCallListLayout",
  components: {
    SearchInput,
    ResetFiltersButton,
    PaginatedStoreList,
    DateRangeInput,
  },
  data() {
    return {
      rowComponent: shallowRef(CustomerCallRow),
      store: useCustomerCallListStore(),
    };
  },
  computed: {
    createdDateRange: {
      get() {
        return [
          this.store.getFilter("created_at_after"),
          this.store.getFilter("created_at_before"),  
        ];
      },
      set(val) {
        this.store.addFilter("created_at_after", val[0]);
        this.store.addFilter("created_at_before", val[1]);
        this.store.refresh();
      },
    },
    query: {
      get() {
        return this.store.getFilter("q");
      },
      set(val) {
        this.store.addFilter("q",  val);
        this.store.refresh();
      },
    },
    sortBy: {
      get() {
        return this.store.getFilter("sort_by");
      },
      set(val) {
        this.store.addFilter("sort_by", val);
        this.store.refresh();
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
