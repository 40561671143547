<template>
  <button class="tool-btn tooltip" @click.stop="createIntervention">
    <i class="bi bi-briefcase" />
    <div class="tooltip-text">
      Nouvelle intervention
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createQuote">
    <InvoiceIcon status="quote" />
    <div class="tooltip-text">
      Nouveau un devis
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createInvoice">
    <InvoiceIcon status="invoice" />
    <div class="tooltip-text">
      Nouvelle facture
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createContract">
    <InvoiceIcon status="contract" />
    <div class="tooltip-text">
      Nouveau contrat
    </div>
  </button>
  
  <button
    class="tool-btn tooltip"
    :disabled="modelValue.main_email == null"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un message par email
      </div>
      <div
        v-if="modelValue.main_email == null"
        class="tooltip-text-red">
        Aucune adresse email renseignée
      </div>
    </div>
    <SendEmailToCustomerModal v-if="modelValue" ref="sendEmailModal" :customer="modelValue" />
  </button>

  <CreateContractModal
    ref="createContractModal"
    @created="onContractCreated" />
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import CreateContractModal from "@/components/modals/CreateContractModal.vue";

export default {
  name: "CustomerToolButtons",
  components: {
    SendEmailToCustomerModal,
    InvoiceIcon,
    CreateContractModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    createIntervention() {
      this.$router.push({ name: "create-intervention", query: { customer: this.modelValue.id } });
    },
    createQuote() {
      this.$router.push({ name: "create-quote", query: { customer: this.modelValue.id } });
    },
    createInvoice() {
      this.$router.push({ name: "create-invoice", query: { customer: this.modelValue.id } });
    },
    createContract() {
      this.$refs.createContractModal.show(this.modelValue);
    },
    onContractCreated(contract) {

      this.$services.invoice.patch(
        this.modelValue.id,
        {
          contract_id: contract.id,
        })
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
        });
    },
  },
};

</script>

<style>

</style>