<template>
  <ObjectViewLayout
    :title="title"
    :loading="initializing"
    withBackButton
    hideHeaderOnMobile
    @back="$router.push({ name: 'invoice', params: { 'id': $route.params.id } })">
    <template #default>
      <div class="card">
        <div class="card-body">
          <LoaderView v-if="schemaIntervention == null" />
          <div v-else>
            <FormField :schema="schemaIntervention.origin" :errors="validationErrors?.origin" style="max-width: 200px;">
              <template #default>
                <OriginSelect v-model="newIntervention.origin_code" />
              </template>
            </FormField>
        
            <FormField :schema="schemaIntervention.rdv_date" :errors="validationErrors?.rdv_date">
              <template #default>
                <RdvDatePicker
                  v-model="newIntervention.rdv_date" />
              </template>
            </FormField>

            <div class="flow-spaced-container">
              <FormField
                title="Le technicien peut arriver à partir de"
                :schema="schemaIntervention.rdv_min_start_time"
                :errors="validationErrors?.rdv_min_start_time">
                <template #default>
                  <TimeInput
                    v-model="newIntervention.rdv_min_start_time" />
                </template>
              </FormField>

              <FormField
                title="Le technicien peut arriver jusqu'à"
                :schema="schemaIntervention.rdv_max_start_time"
                :errors="validationErrors?.rdv_max_start_time">
                <template #default>
                  <TimeInput
                    v-model="newIntervention.rdv_max_start_time" />
                </template>
              </FormField>
            </div>

            <FormField :schema="schemaIntervention.is_emergency" :errors="validationErrors?.is_emergency" hideTitle>
              <template #default>
                <div class="form-check">
                  <input
                    v-model="newIntervention.is_emergency"
                    class="form-check-input"
                    type="checkbox">
                  <label class="form-check-label">
                    {{ schemaIntervention.is_emergency.verbose_name }}
                  </label>
                </div>
              </template>
            </FormField>
        
            <FormField
              :schema="schemaIntervention.activities"
              :errors="validationErrors?.activities">
              <template #default>
                <MultiActivityInput
                  v-model="newIntervention.activity_ids" />
              </template>
            </FormField>

            <ContactForm
              v-if="editInterventionContact"
              v-model="newIntervention.contact"
              :validationErrors="validationErrors?.contact" />

            <div v-else class="form-group">
              <label class="form-label">Contact pour cette intervention</label><br>
              Nom: <strong>{{ $filters.capitalize(newIntervention.contact.title) }} {{ $filters.capitalize(newIntervention.contact.first_name) }} {{ $filters.capitalize(newIntervention.contact.last_name) }}</strong><br>
              Entreprise: <strong>{{ newIntervention.contact.company_name }} {{ newIntervention.contact.company_vat }}</strong><br>
              Numéro de téléphone: <strong>{{ newIntervention.contact.phone_number }}</strong><br>
              Email: <strong>{{ newIntervention.contact.email }}</strong><br>
              Langue: <strong>{{ newIntervention.contact.language }}</strong><br>
              <button class="btn-link" @click="editInterventionContact = true">
                Entrer des informations de contact différentes des informations du client pour cette intervention
              </button>
            </div>

            <AddressForm
              v-if="editInterventionAddress"
              v-model="newIntervention.address"
              :validationErrors="validationErrors?.address" />

            <div v-else class="form-group">
              <label class="form-label">Adresse de cette intervention</label><br>
              <strong>{{ newIntervention.address.street_number }} {{ newIntervention.address.street }} {{ newIntervention.address.bp }}</strong><br>
              <strong>{{ newIntervention.address.zipcode }} {{ newIntervention.address.city }} {{ newIntervention.address.country_code }}</strong><br>
              <button class="btn-link" @click="editInterventionAddress = true">
                Entrer une adresse différentes de l'adresse du client pour cette intervention
              </button>
            </div>
        
            <FormField
              :schema="schemaIntervention.remarks"
              :errors="validationErrors?.remarks">
              <template #default>
                <textarea v-model="newIntervention.remarks" class="form-control" />
              </template>
            </FormField>
          </div>
        </div>

        <div class="card-footer justify-right">
          <button
            class="btn btn-primary"
            :disabled="!canSubmit || submitting"
            :class="{ 'loading': submitting }"
            @click="submit">
            Créer l'intervention
          </button>
        </div>
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import ContactForm from "@/components/forms/ContactForm.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";
import OriginSelect from "@/components/selects/OriginSelect.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";

export default {
  name: "AddNewInterventionToInvoiceView",
  components: {
    ObjectViewLayout,
    LoaderView,
    FormField,
    ContactForm,
    AddressForm,
    MultiActivityInput,
    OriginSelect,
    RdvDatePicker,
    TimeInput,
  },
  props: {
    invoiceId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      
      // States
      initializing: false,
      submitting: false,

      // Schemas
      schemaIntervention: null,

      // Invoice
      invoice: null,

      // Intervention to create
      newIntervention: {
        contact: {
          title: "mr",
          language: "fr",
        },
        address: {
          country_code: "be",
        },
        activity_ids: [],
      },

      // ValidationErrors
      validationErrors: null,

      editInterventionContact: false,
      editInterventionAddress: false,

    };
  },
  computed: {
    title() {
      return `Ajouter une intervention à la facture ${this.invoice ? this.invoice.ref : null}`;
    },
    canSubmit() {
      return this.newIntervention.origin_code != null && this.newIntervention.activity_ids != null && this.newIntervention.activity_ids.length > 0;
    },
  },
  async mounted() {
    throw new Error("Method to update.");
    this.initializing = true;

    try {

      // Fetch intervention schema

      this.fetchSchema();

      // Load invoice

      const responseInvoice = await this.$services.invoice.retrieve(this.invoiceId);
      const invoice = responseInvoice.data;
      this.invoice = invoice;
      this.newIntervention.invoice_id = invoice.id;
      this.newIntervention.origin_code = invoice.journal.origin.code;

      // Load customer info

      const customer = invoice.customer;
      this.newIntervention.customer_id = customer.id;
      this.newIntervention.contact = JSON.parse(JSON.stringify(customer.contact));
      this.newIntervention.address = JSON.parse(JSON.stringify(customer.address));

      // Check if invoice already has an intervention

      if (invoice.interventions && invoice.interventions.length > 0) {

        // Load activities from the first intervention

        const intervention = invoice.interventions[0];
        this.newIntervention.activity_ids = intervention.activities.map((activity) => activity.id);
      }

    } finally {
      this.initializing = false;
    }

  },
  methods: {
    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schemaIntervention = response.data;
        });
    },
    submit() {
      this.submitting = true;
      
      var data = {
        ...this.newIntervention,
        customer_id: this.invoice.customer.id,
        invoice_id: this.invoice.id,
      };

      this.$services.intervention.create(data)
        .then((response) => {
          this.$router.push({
            name: "intervention",
            params: {
              id: response.data.id,
            },
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },

  },
};
</script>