<template>
  <Modal ref="modal">
    <LoaderView v-if="schema == null || customer == null" />
    <div v-else>
      <div class="row">
        <div class="col-sm">
          <FormField :schema="schema.billing_person_first_name">
            <input
              v-model="customer.billing_person_first_name"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm">
          <FormField :schema="schema.billing_person_last_name">
            <input
              v-model="customer.billing_person_last_name"
              type="text"
              class="form-control">
          </FormField>
        </div>
      </div>

      <div class="row">
        <div class="col-sm">
          <FormField :schema="schema.billing_company_name">
            <input
              v-model="customer.billing_company_name"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm">
          <FormField :schema="schema.billing_company_vat">
            <input
              v-model="customer.billing_company_vat"
              type="text"
              class="form-control">
          </FormField>
        </div>
      </div>

      <div class="form-group" style="display: flex;">
        <VueGoogleAutocomplete
          id="billing-address-search"
          :country="countryCodes"
          type="text"
          placeholder="Recherchez une adresse"
          class="form-control"
          @placechanged="onAddressSuggestion" />

        <button
          class="btn-secondary"
          style="min-width: 130px; margin-left: 10px;"
          @click="showCustomForm = !showCustomForm">
          Saisie manuelle
        </button>

        <i class="bi bi-check-lg" :class="{ 'valid': billingAddressIsValid }" style="margin-left: 10px;" />
      </div>

      <div v-if="showCustomForm" class="row">
        <div class="col-sm-4">
          <FormField :schema="schema.billing_street_number">
            <input
              v-model="customer.billing_street_number"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm">
          <FormField :schema="schema.billing_street">
            <input
              v-model="customer.billing_street"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm-4">
          <FormField :schema="schema.billing_bp">
            <input
              v-model="customer.billing_bp"
              type="text"
              class="form-control">
          </FormField>
        </div>
      </div>

      <div v-if="showCustomForm" class="row">
        <div class="col-sm">
          <FormField :schema="schema.billing_zipcode">
            <input
              v-model="customer.billing_zipcode"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm">
          <FormField
            :schema="schema.billing_city">
            <input
              v-model="customer.billing_city"
              type="text"
              class="form-control">
          </FormField>
        </div>
        <div class="col-sm">
          <FormField
            :schema="schema.billing_country_code">
            <CountrySelect
              v-model="customer.billing_country_code"
              class="form-select" />
          </FormField>
        </div>
      </div>
    </div>

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading || !canSubmit"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import CountrySelect from "@/components/inputs/CountrySelect.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { favoriteCountries } from "@/countries";

export default {
  name: "EditCustomerBillingInfoModal",
  components: {
    Modal,
    FormField,
    LoaderView,
    CountrySelect,
    VueGoogleAutocomplete,
  },
  emits: ["change"],
  data() {
    return {
      customer: null,
      loading: false,
      schema: null,
      showCustomForm: true,
    };
  },
  computed: {
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
    canSubmit() {
      return this.customer && this.customer.id && this.billingAddressIsValid && this.customer.billing_person_last_name;
    },
    billingAddressIsValid() {
      return this.customer && this.customer.billing_street && this.customer.billing_zipcode && this.customer.billing_city && this.customer.billing_country_code;
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.customer.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    onAddressSuggestion(addressData) {
      this.customer.billing_street_number = addressData.street_number;
      this.customer.billing_street = addressData.route;
      this.customer.billing_zipcode = addressData.postal_code;
      this.customer.billing_city = addressData.locality;
      this.customer.billing_country_code = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }

      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
    show(customer) {
      this.customer = {
        id: customer.id,
        billing_person_title: customer.billing_person_title,
        billing_person_first_name: customer.billing_person_first_name,
        billing_person_last_name: customer.billing_person_last_name,
        billing_company_name: customer.billing_company_name,
        billing_company_vat: customer.billing_company_vat,
        billing_street_number: customer.billing_street_number,
        billing_street: customer.billing_street,
        billing_bp: customer.billing_bp,
        billing_zipcode: customer.billing_zipcode,
        billing_city: customer.billing_city,
        billing_country_code: customer.billing_country_code,
      };
      this.loading = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.customer = null;
      this.loading = false;
    },
    submit() {
      this.loading = true;

      const body = {
        ...this.customer,
      };

      delete body.id;
      
      this.$services.customer.patch(
        this.customer.id,
        body,
      )
        .then((response) => {
          this.$emit(
            "change",
            {
              billing_person_title: response.data.billing_person_title,
              billing_person_first_name: response.data.billing_person_first_name,
              billing_person_last_name: response.data.billing_person_last_name,
              billing_company_name: response.data.billing_company_name,
              billing_company_vat: response.data.billing_company_vat,
              billing_street_number: response.data.billing_street_number,
              billing_street: response.data.billing_street,
              billing_bp: response.data.billing_bp,
              billing_zipcode: response.data.billing_zipcode,
              billing_city: response.data.billing_city,
              billing_country_code: response.data.billing_country_code,
            },
          );
          this.hide();
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>