<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <div style="display: flex;">
        <BriefcaseIcon
          :cancelled="intervention.status_type == 'cancel'"
          :validated="intervention.status_type == 'close'"
          width="30px"
          height="30px" />
        <i v-if="intervention.is_emergency" class="bi bi-exclamation-lg" style="font-size: 1.5rem;" />
      </div>
    </template>

    <template #title>
      <span class="text-bigger">{{ $filters.printCustomer(intervention.customer) }}</span>
      <br>
      <span class="text-smaller">{{ intervention.ref_number }}</span>
    </template>

    <template #default>
      <td>
        <span v-if="intervention.created_at">
          {{ $filters.dateStringToHumanizedDate(intervention.created_at) }}
        </span>
        <i v-else>Pas encore créée</i>
      </td>

      <td>
        {{ intervention.customer.billing_company_name }}
      </td>

      <td>
        <span
          v-for="(activity, index) in intervention.activities"
          :key="index">
          {{ activity.name }}, 
        </span>
      </td>

      <td class="text-smaller">
        <div v-if="intervention.location">
          {{ intervention.location.street_number }} {{ intervention.location.street }}<br>
          {{ intervention.location.zipcode }} {{ intervention.location.city }} {{ intervention.location.country_code }}
        </div>

        <i v-else>Lieu indéterminé</i>
      </td>

      <td>
        <span v-if="intervention.rdv_date">{{ $filters.dateStringToHumanizedDate(intervention.rdv_date) }}</span>
        <span v-if="intervention.optimized_rdv_date">
          Date de rdv idéale le {{ $filters.dateStringToHumanizedDate(intervention.optimized_rdv_date) }}
        </span>
      </td>

      <td>
        <div v-if="intervention.technician || (intervention.other_technicians && intervention.other_technicians.length > 0)">
          <span v-if="intervention.technician">{{ $filters.fullname(intervention.technician) }}</span>
          <span
            v-for="(technician, index) in intervention.other_technicians"
            :key="index">
            <br>{{ $filters.fullname(technician) }}
          </span>
        </div>
        
        <button v-else class="btn-link" @click.stop="selectTechnician">
          Assigner
        </button>
      </td>

      <td>
        <router-link
          v-if="intervention.invoice"
          class="highlighted green"
          :to="{ name: 'invoice', params: { 'id': intervention.invoice.id } }"
          @click.stop>
          <span v-if="intervention.invoice.send_date">Facture envoyée au client le {{ intervention.invoice.send_date }}</span>
          <span v-else>Facture créée</span>
        </router-link>
        
        <router-link
          v-else-if="intervention.quote"
          class="highlighted green"
          :to="{ name: 'quote', params: { 'id': intervention.quote.id } }"
          @click.stop>
          <span v-if="intervention.quote.send_date">Devis envoyée au client le {{ intervention.quote.send_date }}</span>
          <span v-else>Devis créée</span>
        </router-link>
        <div v-else>
          <router-link class="btn-link" style="white-space: nowrap" :to="{ name: 'create-quote', query: { intervention: intervention.id } }" @click.stop>
            Créer un devis
          </router-link><br>
          <router-link class="btn-link" style="white-space: nowrap" :to="{ name: 'create-invoice', query: { intervention: intervention.id } }" @click.stop>
            Créer une facture
          </router-link>
        </div>
      </td>
      
      <td>
        <div class="flow-spaced-container">
          <Task
            v-for="(task, index) in intervention.tasks"
            :key="index"
            :task="task"
            @click="$refs.editTaskModal.show(task)" />

          <button
            class="tool-btn tooltip"
            @click.stop="$refs.addTaskModal.show()">
            <i class="bi bi-pause-circle" />
            <div class="tooltip-text">
              Mettre en attente l'intervention
            </div>
          </button>
        </div>
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <InterventionToolButtons v-model="intervention" @showQrCode="showImage" />
      </Toolbar>
    </template>
  </GBaseRow>

  <ImageModal ref="imageModal" @click.stop />
  <SelectInterventionTechnicianModal ref="selectInterventionTechnicianModal" :interventionId="intervention.id" />

  <AddTaskToInterventionModal
    ref="addTaskModal"
    v-model="intervention"
    @click.stop />

  <TaskModal
    ref="editTaskModal"
    @change="onTaskChanged" />
</template>

<script>

import { GBaseRow } from "g-list";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import InterventionToolButtons from "@/components/toolbuttons/InterventionToolButtons.vue";
import SelectInterventionTechnicianModal from "@/components/modals/SelectInterventionTechnicianModal.vue";
import Task from "@/components/others/Task.vue";
import Toolbar from "@/components/Toolbar.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import AddTaskToInterventionModal from "@/components/modals/AddTaskToInterventionModal.vue";
import TaskModal from "@/components/modals/TaskModal.vue";

export default {
  name: "InterventionRow",
  components: {
    BriefcaseIcon,
    InterventionToolButtons,
    GBaseRow,
    SelectInterventionTechnicianModal,
    Task,
    Toolbar,
    ImageModal,
    AddTaskToInterventionModal,
    TaskModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    intervention: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "intervention", params: { "id": this.intervention.id } });
    },
    selectTechnician() {
      this.$refs.selectInterventionTechnicianModal.show();
    },
    showImage(image) {
      this.$refs.imageModal.show(image);
    },
    onTaskChanged(task) {
      const index = this.intervention.tasks.findIndex((t) => t.id === task.id);
      this.intervention.tasks[index] = task;
    },
  },
};
</script>

<style lang="scss" scoped>


</style>