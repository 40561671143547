<template>
  <button
    class="tool-btn tooltip"
    :disabled="modelValue.customer == null"
    @click="gotoCustomer">
    <UserIcon />
    <div class="tooltip-text">
      Client
    </div>
  </button>
</template>

<script>

import UserIcon from "@/assets/iconsVue/UserIcon.vue";

export default {
  name: "CustomerCallToolButtons",
  components: {
    UserIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { "id": this.modelValue.customer.id } });
    },
  },
};

</script>
