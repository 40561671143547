<template>
  <table class="product-table">
    <thead>
      <tr>
        <th class="expand">
          Intitulé
        </th>
        <th class="shrink">
          Quantité
        </th>
        <th class="shrink">
          Prix
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr style="text-align: center;">
        <td
          v-if="!internalProductLines || internalProductLines.length == 0"
          colspan="4">
          <i>Aucun produit. Commencez par ajouter quelques produits.</i>
        </td>
      </tr>
      <tr v-for="(line, index) in internalProductLines" :key="index">
        <td><input v-model="line.product.name" type="text" class="form-control" style="width: 100%;"></td>
        <td><input v-model="line.product_quantity" type="number" class="form-control" style="width: 70px;"></td>
        <td><input v-model="line.product.sale_price_per_unit" type="number" class="form-control" style="width: 100px;"></td>
        <td>
          <button style="cursor: pointer;" @click="removeItem(index)">
            <TrashIcon />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <SelectObject type="product" @select="addItem" />
  <table class="price-table">
    <tr style="text-align: right">
      <td><strong>Total</strong></td>
      <td><strong>{{ totalWithTaxes / 100 }} €</strong></td>
    </tr>
  </table>
</template>
  
<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import TrashIcon from "@/assets/iconsVue/TrashIcon.vue";

export default {
  name: "CustomerCallProductForm",
  components: {
    SelectObject,
    TrashIcon,
  },
  props: {
    products: {
      type: Array,
      required: false,
      default: () => { return []; },
    },
    origin: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:products"],
  data() {
    return {
      internalProductLines: [...this.products],
    };
  },
  computed: {
    totalWithoutTaxes() { // In cents
      if (!this.internalProductLines) {
        return 0;
      } else {
        var total = 0;
        for (const line of this.internalProductLines) {
          const priceInCents = line.product.sale_price_per_unit * 100;
          const invoiceItemPriceWithoutTaxesInCents = priceInCents * line.product_quantity;
          total = total + invoiceItemPriceWithoutTaxesInCents;
        }
        return total;
      }
    },
    taxesAmount() { // In cents
      if (!this.internalProductLines) {
        return 0;
      } else {
        const taxesAmount = this.origin.tax_rate * this.totalWithoutTaxes / 100;

        return Math.round(taxesAmount);
      }
    },
    totalWithTaxes() { // In cents
      return this.totalWithoutTaxes + this.taxesAmount;
    },
  },
  methods: {
    refreshProducts() {
      this.$emit(
        "update:products",
        this.internalProductLines,
      );
    },
    addItem(product) {
      if (!this.internalProductLines) {
        this.internalProductLines = [];
      }

      this.internalProductLines.push({
        product: product,
        product_quantity: 1,
      });

      this.refreshProducts();
    },
    removeItem(index) {
      this.internalProductLines.splice(index, 1);
      this.refreshProducts();
    },
  },
};
</script>
<style lang="scss" scoped>

  .product-table,
  .price-table {

    td,
    th {
      padding: 5px;
      align-items: center;
    }
  }

  .product-table {
    width: 100%;
  }

  .price-table {
    margin-left: auto;
  }

</style>