<template>
  <div class="object-view-layout">
    <p v-if="errorStr">
      {{ errorStr }}
    </p>

    <p v-else-if="error">
      Une erreur est survenue lors du chargement de la page
    </p>

    <LoaderView v-else-if="loading" />

    <div v-if="!loading && !error" class="object-view-header" :class="{ 'desktop-only': hideHeaderOnMobile }">
      <button
        v-if="withBackButton"
        class="tool-btn"
        @click="$emit('back')">
        <i class="bi bi-caret-left" />
      </button>

      <div class="title-container">
        <div class="left-icon-container">
          <slot name="title-icon-left" />
        </div>
        <div class="object-view-title">
          {{ title }}
        </div>
        <div class="right-icon-container">
          <slot name="title-icon-right" />
        </div>
        <div>
          <slot name="title-right" />
        </div>
      </div>

      <Toolbar v-if="$slots.rightButton">
        <template #default>
          <slot name="rightButton" />
        </template>
      </Toolbar>
    </div>

    <div v-if="!loading && !error" class="object-content">
      <div v-if="$slots.leftBar" style="padding: 5px; flex-basis: 0;" :style="{ 'flex-grow': leftBarWidth }">
        <slot name="leftBar" />
      </div>

      <div style="flex-grow: 3; padding: 5px; display: flex; flex-direction: column; flex-basis: 0;">
        <slot />
      </div>

      <div v-if="$slots.rightBar" style="padding: 5px; flex-basis: 0;" :style="{ 'flex-grow': rightBarWidth }">
        <slot name="rightBar" />
      </div>
    </div>
  </div>
</template>

<script>

import LoaderView from "@/components/LoaderView.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "ObjectViewLayout",
  components: {
    LoaderView,
    Toolbar,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorStr: {
      type: String,
      required: false,
      default: null,
    },
    withBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideHeaderOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    leftBarWidth: {
      type: [String, Number],
      required: false,
      default: 1,
    },
    rightBarWidth: {
      type: [String, Number],
      required: false,
      default: 1,
    },
  },
  emits: ["back"],
};

</script>

<style lang="scss" scoped>

  .object-view-layout {
    padding: 10px;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .object-view-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .title-container {
        margin: 0 auto;
        display: flex;
        align-items: center;

        .left-icon-container {
          margin-right: 5px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: 500;

          :deep(svg) {
            width: 100%;
            height: 100%;
          }
        }

        .object-view-title {          
          margin: 0;
          font-size: 1.5rem;
          font-weight: 500;
        }


        .right-icon-container {
          margin-left: 5px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: 500;

          :deep(svg) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .object-content {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      margin: -5px;
      flex-grow: 1;

      @media(max-width: $mobile-layout-horizontal-breakpoint) {
        flex-direction: column;
      }
    }
  }

</style>
