import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess } from "@/notifications";
import { AxiosResponse } from "axios";

class ContractApiService extends ModelApiService {
  constructor() {
    super("/contracts/", "contrat");
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

}

export default ContractApiService;