<template>
  <div class="top-bar">
    <button
      :class="{'turn': extendMenu, 'turnBack': !extendMenu}"
      style="color: black; font-size: 1.5rem;"
      title="Ouvrir / fermer le menu (shift+e)"
      class="toggle-btn"
      @click="extendMenu = !extendMenu">
      <i class="bi bi-layout-sidebar" />
    </button>
    <div class="spaced-container">
      <div style="display: flex;">
        <FloatingBar class="floating-bar" style="margin: 0;" />
        <button
          v-if="meIsSecretary"
          style="margin-left: 5px; white-space: nowrap;"
          class="btn-primary"
          @click="$router.push({ 'name': 'create-customer-call' })">
          <i class="bi bi-telephone-plus" />
          Encoder un appel client
        </button>
      </div>
      <GeneralSearchBar class="search" />
      <LoggedIn class="loggin" />
    </div>
  </div>
</template>

<script>

import GeneralSearchBar from "@/components/inputs/GeneralSearchBar.vue";
import LoggedIn from "@/components/login/LoggedIn.vue";
import FloatingBar from "@/components/layout/FloatingBar.vue";
import { useMeStore } from "@/stores/me";

export default {
  name: "TopBar",
  components: {
    GeneralSearchBar,
    LoggedIn,
    FloatingBar,
  },
  emits: ["toggle"],
  data() {
    return {
      extendMenu: this.extendMenuProp,
      meStore: useMeStore(),
    };
  },
  computed: {
    meIsSecretary() {
      return this.meStore.meIsSecretary;
    },
  },
  watch: {
    extendMenu() {
      this.$emit("toggle");
    },
  },
};
</script>

<style lang="scss" scoped>

  .top-bar {
    
    // Style
    background: white;
    border-bottom: 1px solid gray;

    // Layout
    display: flex;

    padding-right: 10px;

    .toggle-btn {
      width: 50px;
    }

    .spaced-container {
      justify-content: space-between;
      display: flex;
      flex-grow: 1;
      align-items: center;

      * {
        margin-right: 10px;
        margin-left: 10px;
      }

      *:first-child {
        margin-left: 0px;
      }

      *:last-child {
        margin-right: 0px;
      }
      .search {
        width: 100%;
        max-width: 400px;
      }
    }
  }

</style>
