<template>
  <div class="card">
    <div class="card-header">
      <span class="title">Client</span>
    </div>

    <div class="card-body">
      <!-- Select existing customer-->

      <div v-if="selectExistingCustomer">
        <div class="form-group" style="max-width: 300px;">
          <SelectObject
            type="customer"
            :selectedValue="modelValue ? $filters.printCustomer(modelValue) : null"
            @select="onExistingCustomerSelect"
            @unselect="onExistingCustomerSelect(null)" />
        </div>
        <div>
          <button class="btn-link" @click="selectExistingCustomer = false">
            Créer un nouveau client
          </button>
        </div>
      </div>

      <!-- Create new customer-->

      <div v-else>
        <button class="btn-link" @click="selectExistingCustomer = true">
          Sélectionner un client existant
        </button>

        <NewCustomerForm
          @change="newCustomer = $event" />
      </div>
    </div>

    <div class="card-footer justify-right">
      <button
        :disabled="!canSubmit || submitting"
        :class="{ 'loading': submitting }"
        class="btn btn-primary"
        @click="submit">
        Suivant
      </button>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import NewCustomerForm from "@/components/forms/NewCustomerForm.vue";
import { notifyError } from "@/notifications";

export default {
  name: "CustomerCreationStepCard",
  components: {
    SelectObject,
    NewCustomerForm,
  },
  props: {
    modelValue: { // The customer
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "next"],
  data() {
    return {
      // States
      submitting: false,

      // Customer to create
      newCustomer: null,

      // Modes
      selectExistingCustomer: true,
    };
  },
  computed: {

    // Validation

    canSubmit() {
      if (this.selectExistingCustomer) {
        return this.modelValue != null;

      } else {
        if (this.newCustomer == null || this.newCustomer.contacts.length <= 0) {
          return false;

        } else {
          for (const contact of this.newCustomer.contacts) {
            if (contact.last_name == null || contact.language == null) {
              return false;
            }
          }
        }

        return this.newCustomer.billing_person_last_name != null && this.newCustomer.billing_street != null && this.newCustomer.billing_zipcode != null && this.newCustomer.billing_city != null && this.newCustomer.billing_country_code != null;
      }
    },
    
  },
  methods: {

    onExistingCustomerSelect(customer) {
      this.$emit("update:modelValue", customer);
      this.selectExistingCustomer = true;
    },

    async submit() {
      if (!this.selectExistingCustomer) {
        await this.createCustomer();
      }

      this.$emit("next");
    },

    createCustomer() {
      return new Promise((resolve, reject) => {
        this.submitting = true;
        this.$services.customer.create(this.newCustomer)
          .then((response) => {
            this.$emit("update:modelValue", response.data);
            resolve();
          })
          .catch((error) => {
            if (error && error.response && error.response.status === 409) {
              notifyError(error.response.data);
            }

            reject(error);
          })
          .finally(() => {
            this.submitting = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>