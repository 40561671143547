<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">Couts</span>
      <button v-if="editMode" class="btn btn-link" @click="cancel">
        (annuler)
      </button>
      <button v-else class="btn btn-link" @click="edit">
        (editer)
      </button>
    </div>

    <div v-if="editMode" class="card-body">
      <div class="form-group" style="max-width: 200px;">
        <label class="form-label">
          Coût par unité
        </label>

        <div class="input-group">
          <input
            v-model="editedProduct._purchase_price_per_unit"
            type="number"
            class="form-control">

          <div class="input-group-append">
            € HTVA
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: flex-end;">
        <button class="btn-primary" :class="{ 'loading': submitting }" :disabled="submitting" @click="submit">
          Enregistrer
        </button>
      </div>
    </div>

    <div v-else class="card-body">
      <p>
        Coût :

        <span v-if="modelValue._purchase_price_per_unit">
          <strong>{{ modelValue._purchase_price_per_unit }}€</strong> HTVA
        </span>
        <i v-else>Non renseigné</i>
      </p>
    </div>
  </div>
</template>

<script>

import { notifyError } from "@/notifications";

export default {
  name: "ProductPurchaseInformationCard",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editMode: false,
      editedProduct: null,
      submitting: false,
    };
  },
  methods: {
    edit() {
      this.editedProduct = {
        _purchase_price_per_unit: this.modelValue._purchase_price_per_unit,
      };
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.editedProduct = null;
    },
    submit() {
      this.submitting = true;

      this.$services.product.patch(this.modelValue.id, this.editedProduct)
        .then((response) => {
          this.$emit(
            "update:modelValue",
            {
              ...this.modelValue,
              _purchase_price_per_unit: response.data._purchase_price_per_unit,
            },
          );
          this.cancel();
        }).catch((error) => {
          
          notifyError("Erreur");
          throw error;
        });
    },
  },
};

</script>