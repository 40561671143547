<template>
  <select
    v-model="selectedStorageId"
    class="form-select">
    <option
      v-for="(storage, index) in storages"
      :key="index"
      :value="storage.id">
      {{ storage.name }}
    </option>
  </select>
</template>

<script>

import { useStoragesStore } from "@/stores/storages";

export default {
  name: "StorageSelect",
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      storagesStore: useStoragesStore(),
    };
  },
  computed: {
    selectedStorageId: {
      get() {
        return this.modelValue; 
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    storages() {
      const storages = this.storagesStore.getStorages;

      if (storages) {
        return storages;
      } else {
        return [];
      }
    },
  },
};
</script>
