import ModelApiService from "../utils/modelApiService";
import { api } from "@/api";


class SaleApiService extends ModelApiService {
  constructor() {
    super("/sales/", "vente");
  }

  fetchPdf(id: number) {
    return api.get(this.url + id + "/pdf/");
  }

}

export default SaleApiService;