import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";


class UserApiService extends ModelApiService {
  constructor() {
    super("/users/", "utilisateur");
  }

  
  setLeaves(id, fromDate, fromMorning, toDate, toMorning, typeCode, description) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/leaves/",
        {
          from_date: fromDate,
          from_morning: fromMorning,
          to_date: toDate,
          to_morning: toMorning,
          type: typeCode,
          description: description,
        },
      )
        .then((response) => {
          notifySuccess("Modifications enregistrées");
          resolve(response);
        }).catch((error) => {
          notifyError("Erreur");
          reject(error);
        });
    });
  }

  totalIntoWallet(id) {
    return new Promise((resolve, reject) => {
      api.get(
        this.url + id + "/total_into_wallet/",
      )
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
}

export default UserApiService;