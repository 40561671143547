<template>
  <div class="card" style="border: 1px solid #ccc; background: transparent;">
    <div class="card-body">
      <div class="info-grid-layout">
        <div>
          Créé le
        </div>
        <div>
          {{ $filters.dateStringToHumanizedDateTimeWithYear(object.created_at) }}
          <span v-if="object.author">
            par {{ $filters.fullname(object.author) }}
          </span>
        </div>
        <div v-if="object.updated_at">
          Dernière modification le
        </div>
        <div v-if="object.updated_at">
          {{ $filters.dateStringToHumanizedDateTimeWithYear(object.updated_at) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CreatedUpdatedAtCard",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};

</script>