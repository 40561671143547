<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <i class="bi bi-cart" />
    </template>

    <template #title>
      <span class="text-bigger">{{ $filters.capitalize($filters.dateStringToHumanizedDateTime(sale.created_at)) }}</span>
      <br>
      <span class="text-smaller">{{ sale.marketplace }}</span>
    </template>

    <template #default>
      <td>
        <div>{{ sale.invoice.total_incl_tax }} € TTC</div>
        <div v-if="sale.invoice.credit_note" class="highlighted red">
          NC {{ sale.invoice.credit_note.total_incl_tax }} € TTC
        </div>
      </td>
      <td>
        <span v-if="sale.customer.billing_first_name == 'Anonymous' && sale.customer.billing_last_name == 'Customer'">
          Client particulier
        </span>
        <router-link
          v-else
          class="btn-link"
          :to="{ name: 'customer', params: { 'id': sale.customer.id } }">
          {{ $filters.printCustomer(sale.customer) }}
        </router-link>
      </td>
      <td>
        <div v-if="sale.address" class="text-smaller">
          <span v-if="sale.contact">
            {{ $filters.fullname(sale.contact) }}
          </span>
          {{ sale.address.street }}
          <br>
          {{ sale.address.postal_code }} {{ sale.address.city }} {{ sale.address.country }}
        </div>
      </td>
      <td>
        {{ $filters.fullname(sale.author) }}
      </td>
      <td>
        {{ sale.source_storage.name }}
      </td>

      <td>
        <div
          v-for="(line, index) in sale.invoice.items"
          :key="index"
          class="text-smaller">
          {{ line.product_quantity }} x {{ line.product_description }}
        </div>
      </td>

      <td>
        <div class="flow-spaced-container">
          <div v-if="sale.my_parcel_status" class="highlighted green">
            {{ sale.my_parcel_status }} via My Parcel
          </div>
          <div v-if="sale.sendcloud_status == 'cancelled'" class="highlighted red">
            Annulé via Sendcloud
          </div>
          <div v-else-if="sale.sendcloud_status" class="highlighted green">
            {{ sale.sendcloud_status }} via Sendcloud
          </div>
          <div v-if="sale.woo_status == 'cancelled'" class="highlighted red">
            Annulé sur Woo
          </div>
          <div v-else-if="sale.woo_status != null && sale.woo_status != 'processing'" class="highlighted gray">
            {{ sale.woo_status }} sur Woo
          </div>
          <div v-else-if="sale.address && !sale.my_parcel_status && !sale.sendcloud_status" class="highlighted yellow">
            En attente d'expédition
          </div>

          <div v-if="sale.invoice.refund" class="highlighted yellow">
            <span v-if="sale.invoice.refund.refunded_at">
              {{ sale.invoice.refund.amount }}€ remboursé le {{ sale.invoice.refund.refunded_at }}
            </span>
            <span v-else>
              {{ sale.invoice.refund.amount }}€ en attente de remboursement
            </span>
          </div>

          <div v-if="isReturning" class="highlighted yellow">
            En retour
          </div>

          <div v-if="isReturned" class="highlighted yellow">
            Retour colis réceptionné
          </div>
        </div>
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <SaleToolButtons v-model="sale" />
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";
import SaleToolButtons from "@/components/toolbuttons/SaleToolButtons.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "SaleRow",
  components: {
    GBaseRow,
    SaleToolButtons,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    sale: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isReturning() {
      if (this.isReturned) {
        return false;
      }
      
      return this.sale.invoice.items.some((item) => item.quantity_returned > 0);
    },
    isReturned() {
      return this.sale.return_reception_date != null;
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "sale", params: { "id": this.sale.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>