<template>
  <Modal ref="modal">
    <MultiActivityInput v-model="activityIds" />

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import MultiActivityInput from "@/components/inputs/MultiActivityInput.vue";

export default {
  name: "EditInterventionActivitiesModal",
  components: {
    Modal,
    MultiActivityInput,
  },

  emits: ["change"],
  data() {
    return {
      interventionId: null,
      activityIds: [],
      loading: false,
    };
  },
  methods: {
    show(intervention) {
      this.interventionId = intervention.id;
      this.activityIds = intervention.activities.map((activity) => activity.id);
      this.loading = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.interventionId = null;
      this.activityIds = null;
      this.loading = false;
    },
    submit() {
      this.loading = true;
      
      this.$services.intervention.patch(
        this.interventionId,
        {
          activity_ids: this.activityIds,
        },
      )
        .then((response) => {
          this.$emit(
            "change",
            {
              activities: response.data.activities,
              activity_ids: response.data.activity_ids,
            },
          );
          this.hide();
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>