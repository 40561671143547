<template>
  <TimelineCalendar />
</template>

<script>

import TimelineCalendar from "@/components/calendar/TimelineCalendar.vue";

export default {
  name: "InterventionsCalendarView",
  components: {
    TimelineCalendar,
  },
};
</script>

<style scoped lang="scss">

</style>
