<template>
  <GoogleMap
    api-key="AIzaSyBzJgIzsZqpBaFRUOdmCghxhKe7S4Pxkx0"
    style="width: 100%; height: 500px"
    :center="center"
    :zoom="15">
    <Marker
      v-for="(marker, index) in markers"
      :key="index"
      :options="{ position: {lat: marker.lat, lng: marker.lng } }" />
  </GoogleMap>
</template>

<script>

import { GoogleMap, Marker } from "vue3-google-map";

export default {
  components: { GoogleMap, Marker },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    center() {
      if (this.markers.length) {
        return {
          lat: this.markers[0].lat,
          lng: this.markers[0].lng,
        };
      } else {
        return {
          lat: 0,
          lng: 0,
        };
      }
    },
  },

  /*
  <GoogleMap
    api-key="AIzaSyBzJgIzsZqpBaFRUOdmCghxhKe7S4Pxkx0"
    style="width: 100%; height: 500px"
    :center="center"
    :zoom="15">
    <Marker v-if="center" :options="{ position: center }" />
  </GoogleMap>
  */
};
</script>