<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 5px;">{{ title }}</span>
      <button v-if="editable" class="small-tool-btn" @click="$refs.editModal.show(modelValue)">
        <i class="bi bi-pencil-square" />
      </button>
    </div>

    <div class="card-body">
      {{ modelValue.street_number }} {{ modelValue.street }} {{ modelValue.bp }}<br>
      {{ modelValue.zipcode }} {{ modelValue.city }} {{ modelValue.country_code }}
    </div>
    
    <EditLocationModal
      ref="editModal"
      @change="onLocationChanged" />
  </div>
</template>

<script>

import EditLocationModal from "@/components/modals/EditLocationModal.vue";

export default {
  name: "LocationCard",
  components: {
    EditLocationModal,
  },
  props: {
    modelValue: { // The location
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Adresse",
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onLocationChanged(newLocation) {
      this.$emit("update:modelValue", newLocation);
    },
  },
};

</script>