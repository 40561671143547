<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <InvoiceIcon
        status="invoice"
        width="30px"
        height="30px" />
    </template>

    <template #title>
      <span class="text-bigger">{{ invoice.ref }}</span><br>
      <span>{{ $filters.dateStringToHumanizedDate(invoice.created_at, "short") }}</span>
    </template>

    <template #default>
      <td>
        <span class="mobile-only">Client : </span>
        <router-link v-if="invoice.customer" class="btn-link" :to="{ name: 'customer', params: { 'id': invoice.customer.id } }" @click.stop>
          {{ $filters.printCustomer(invoice.customer) }}
        </router-link>
        <span v-else>
          {{ $filters.fullname(invoice.contact) }}
        </span>
      </td>

      <td class="text-smaller">
        {{ invoice.address.street_number }} {{ invoice.address.street }}<br>
        {{ invoice.address.zipcode }} {{ invoice.address.city }} {{ invoice.address.country_code }}
      </td>

      <td>
        <div
          v-for="(intervention, interventionIndex) in invoice.interventions"
          :key="interventionIndex">
          <router-link
            :to="{ name: 'intervention', params: { id: intervention.id }}"
            style="margin-right: 3px;"
            class="btn-link">
            <span v-if="intervention.rdv_date">
              {{ $filters.dateStringToHumanizedDate(intervention.rdv_date) }}
            </span>
            <span v-else>
              {{ intervention.location.street_number }} {{ intervention.location.street }} {{ intervention.location.city }}
            </span>
          </router-link>
          <br>
          <span v-if="intervention.rdv_date != null" class="text-smaller">
            {{ intervention.location.street_number }} {{ intervention.location.street }} {{ intervention.location.city }}
          </span>
          <span v-else class="text-red">Pas de date de rdv</span>
          <span v-if="intervention.nb_assigned_technicians == 0" class="text-red"><br>Aucun tech assigné</span>
        </div>
      </td>

      <td>
        <span class="mobile-only">Auteur : </span>
        {{ $filters.fullname(invoice.author) }}
      </td>

      <td style="white-space: nowrap;" class="text-smaller">
        <div v-if="invoice.send_date != null">
          Envoyé : {{ $filters.dateStringToHumanizedDate(invoice.send_date, "short") }}
        </div>

        <button
          v-if="invoice.send_date == null"
          class="btn-link tooltip"
          :class="{ 'loading': sendingInvoiceToCustomer }"
          :disabled="sendingInvoiceToCustomer || (!invoice.contact?.email && !invoice.customer?.contact?.email)"
          @click.stop="sendInvoiceToCustomerByEmail">
          Envoyer la facture au client
          <div
            v-if="invoice.contact?.email == null && invoice.customer?.contact?.email == null"
            class="tooltip-text">
            <span class="text-red">
              Aucun email associé au client
            </span>
          </div>
        </button>

        <div v-if="invoice.first_payment_request_send_date">
          1er rappel : {{ $filters.dateStringToHumanizedDate(invoice.first_payment_request_send_date, "short") }}
        </div>

        <div v-if="invoice.second_payment_request_send_date">
          2nd rappel : {{ $filters.dateStringToHumanizedDate(invoice.second_payment_request_send_date, "short") }}
        </div>

        <div v-if="invoice.third_payment_request_send_date">
          3ème rappel : {{ $filters.dateStringToHumanizedDate(invoice.third_payment_request_send_date, "short") }}
        </div>

        <div v-if="invoice.transfered_to_collection_company_date">
          Transféré à la société de recouvrement : {{ $filters.dateStringToHumanizedDate(invoice.transfered_to_collection_company_date, "short") }}
        </div>

        <div v-if="invoice.next_payment_request_send_date">
          Prochain rappel : {{ $filters.dateStringToHumanizedDate(invoice.next_payment_request_send_date, "short") }}
          <span v-if="isNextPaymentRequestPassed" class="text-red">
            Dépassée
          </span>
        </div>

        <button
          v-if="invoice.third_payment_request_send_date && invoice.transfered_to_collection_company_date == null"
          class="btn-link tooltip"
          :disabled="transferingToCollectionCompany"
          :class="{ 'loading': transferingToCollectionCompany }"
          @click.stop="sendToCollectionCompany">
          Transférer à la société de recouvrement
        </button>

        <button
          v-if="invoice.third_payment_request_send_date == null && invoice.transfered_to_collection_company_date == null"
          class="btn-link tooltip"
          :disabled="invoice.send_date == null || sendingPaymentRequestToCustomer"
          :class="{ 'loading': sendingPaymentRequestToCustomer }"
          @click.stop="sendPaymentRequestToCustomer">
          Envoyer un rappel maintenant
          <div v-if="invoice.send_date == null" class="tooltip-text-red">
            Envoyez d'abord la facture au client par email
          </div>
        </button>
      </td>

      <td>
        <p class="mobile-only">
          Montant :
        </p>

        <p style="white-space: nowrap; font-weight: bold;">
          {{ invoice.total_incl_tax }}€(TTC)
        </p>

        <p style="white-space: nowrap;">
          {{ invoice.total_excl_tax }}€(HT)
        </p>

        <p v-if="invoice.credit_note" style="white-space: nowrap;">
          Note de crédit de {{ invoice.credit_note.total_incl_tax }}€(TTC)
        </p>

        <div
          v-if="invoice.profit !== undefined"
          style="white-space: nowrap; display: flex;">
          <div style="margin-right: 5px;">
            Marge :
          </div>

          <div v-if="invoice.profit == null" class="tooltip">
            Indéterminée <i class="bi bi-question-circle" />
            <div class="tooltip-text-red">
              Les couts d'achats de tous les produits n'ont pas été encodés
            </div>
          </div>
          
          <div v-else>
            {{ invoice.profit }}€ ({{ profitPercent }}%)
          </div>
        </div>
      </td>

      <td>
        <div
          v-if="invoice.paid > 0"
          class="highlighted"
          :class="{ 'yellow': invoice.to_pay > 0, 'green': invoice.to_pay == 0, 'red': invoice.to_pay < 0 }">
          <span
            v-if="invoice.to_pay < 0">
            Trop payé
          </span>
          <span
            v-else-if="invoice.to_pay == 0">
            Payé
          </span>
          <span
            v-else>
            Part. payé
          </span>
          <span
            v-for="(payment, index) in invoice.payments"
            :key="index">
            {{ translatePaymentType(payment.type) }}
          </span>
        </div>
        <div v-else class="highlighted gray">
          Impayé
        </div>
        <div v-if="invoice.to_pay > 0" class="text-red">
          Reste à payer : {{ (invoice.to_pay / 100).toFixed(2) }}€
        </div>
      </td>
      
      <td>
        <div v-if="invoice.odoo_error_message" class="highlighted red tooltip">
          Erreur
          <span class="tooltip-text">
            {{ invoice.odoo_error_message }}
          </span>
        </div>
        <div v-else-if="invoice.odoo_id != null && invoice.odoo_amount_residual == null" class="highlighted gray">
          Impayé
        </div>
        <div v-else-if="invoice.odoo_amount_residual > 0" class="highlighted yellow">
          Part. payé
        </div>
        <div v-else-if="invoice.odoo_amount_residual == 0" class="highlighted green">
          Payé
        </div>
        <div v-else-if="invoice.odoo_amount_residual < 0" class="highlighted red">
          Trop payé
        </div>
        <div v-if="invoice.odoo_amount_residual > 0" class="text-red">
          Reste à payer : {{ invoice.odoo_amount_residual }}€
        </div>
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <InvoiceToolButtons v-model="invoice" />
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import InvoiceToolButtons from "@/components/toolbuttons/InvoiceToolButtons.vue";
import { GBaseRow } from "g-list";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "InvoiceRow",
  components: {
    InvoiceIcon,
    InvoiceToolButtons,
    GBaseRow,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      sendingInvoiceToCustomer: false,
      sendingPaymentRequestToCustomer: false,
      transferingToCollectionCompany: false,
    };
  },
  computed: {
    invoice: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    isNextPaymentRequestPassed() {
      return new Date(this.invoice.next_payment_request_send_date) < new Date();
    },
    profitPercent() {
      if (this.invoice.profit == null) {
        return null;
      }

      return Math.round(this.invoice.profit * 100 / this.invoice.total_excl_tax);
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "invoice", params: { "id": this.invoice.id } });
    },
    onSelect(val) {
      this.select = val;
    },
    sendInvoiceToCustomerByEmail() {
      this.sendingInvoiceToCustomer = true;
      this.$services.invoice.sendToCustomerEmail(this.invoice.id)
        .finally(() => {
          this.sendingInvoiceToCustomer = false;
        });
    },
    sendToCollectionCompany() {
      this.sendingPaymentRequestToCustomer = true;
      this.$services.invoice.sendToCollectionCompany(this.invoice.id)
        .finally(() => {
          this.sendingPaymentRequestToCustomer = false;
        });
    },
    transfertToCollectionCompany() {
      this.transferingToCollectionCompany = true;
      this.$services.invoice.transfertToCollectionCompany(this.invoice.id)
        .finally(() => {
          this.transferingToCollectionCompany = false;
        });
    },
    translatePaymentType(type) {
      switch (type) {
      case "cash":
        return "en espèces";
      case "wire":
        return "par vire.";
      case "bancontact":
        return "par banc.";
      case "payment_on_another_plateform":
        return "en ligne";
      default:
        return type;
      }

    },
    sendPaymentRequestToCustomer() {
      this.sendingPaymentRequestToCustomer = true;
      this.$services.invoice.sendPaymentRequestToCustomer(this.invoice.id)
        .finally(() => {
          this.sendingPaymentRequestToCustomer = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>