<template>
  <Datepicker
    v-model="date"
    range
    :preset-ranges="presetRanges"
    :enable-time-picker="false"
    clearable
    locale="fr"
    cancelText="Annuler"
    selectText="Valider"
    :teleport-center="isMobile">
    <template #yearly="{ label, range, presetDateRange }">
      <span @click="presetDateRange(range)">{{ label }}</span>
    </template>
  </Datepicker>
</template>

<script setup lang="ts">

// https://vue3datepicker.com/

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, computed } from "vue";
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from "date-fns";

// Props

const props = defineProps({
  modelValue: {
    type: Array as unknown as () => [string | null, string | null],
    required: false,
    default: () => [null, null],
  },
});

// Emits

const emits = defineEmits(["update:modelValue"]);

// Data

const presetRanges = ref([
  { label: "Aujourd'hui", range: [new Date(), new Date()] },
  { label: "Ce mois-ci", range: [startOfMonth(new Date()), endOfMonth(new Date())] },
  {
    label: "Le mois dernier",
    range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
  },
  { label: "Cette année", range: [startOfYear(new Date()), endOfYear(new Date())] },
]);

// Computed

const isMobile = computed(() => window.innerWidth < 768);

const date = computed({
  get() {
    return [
      props.modelValue[0] ? new Date(props.modelValue[0]) : null,
      props.modelValue[1] ? new Date(props.modelValue[1]) : null,
    ];
  },
  set(value) {
    if (value == null) {
      emits("update:modelValue", [null, null]);
    } else {
      emits("update:modelValue", [value[0] ? getISODateWithoutTime(value[0]) : null, value[1] ? getISODateWithoutTime(value[1]) : null]);
    }
  },
});

// Methods

function getISODateWithoutTime(date: Date): string {
  const isoString = date.toISOString();
  return isoString.split("T")[0];
}


</script>