<template>
  <ObjectViewLayout
    :title="object ? object.ref : null"
    :loading="object == null || schema == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'quote-list' })">
    <template #title-icon-left>
      <InvoiceIcon
        status="quote" />
    </template>

    <template #rightButton>
      <QuoteToolButtons v-model="object" />
    </template>

    <template #default>
      <div class="vertical-spaced-container">
        <QuoteInfoCard v-model="object" />

        <InvoiceAndQuoteProductsCard
          v-model="object"
          type="quote" />

        <div class="flow-spaced-container fill">
          <QuoteContactCard
            v-model="object.contact"
            :quoteId="object.id"
            editable
            style="margin-top: 5px; margin-bottom: 5px;" />

          <QuoteAddressCard
            v-model="object.address"
            :quoteId="object.id"
            editable
            style="margin-top: 5px; margin-bottom: 5px;" />
        </div>

        <FormSection
          title="Remarques">
          <template #default>
            <p v-if="object.remarks">
              Remarque interne: <strong v-if="object.remarks">{{ object.remarks }}</strong><span v-else><i>Aucune</i></span>
            </p>
            <p>Remarque à afficher sur la facture: <strong v-if="object.public_remarks">{{ object.public_remarks }}</strong><span v-else><i>Aucune</i></span></p>
          </template>

          <template #edition>
            <FormField :schema="schema.public_remarks" :error="validationErrors ? validationErrors.public_remarks : null" class="form-group">
              <input v-model="object.public_remarks" type="text" class="form-control">
            </FormField>

            <button
              :class="{ 'loading': savingRemarks }"
              class="btn-secondary"
              @click="saveRemarksForm">
              Enregistrer les modifications
            </button>
          </template>
        </FormSection>

        <PdfCard :pdfData="quotePdf" />
      </div>
    </template>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div class="card">
          <div class="card-body">
            <div class="info-grid-layout">
              <div>Montant</div>
              <div>
                <strong>{{ object.total_incl_tax }}€ TTC</strong><br>
                {{ object.total_excl_tax }}€ HT
              </div>
              <div>
                Créé le
              </div>
              <div>
                {{ $filters.dateStringToHumanizedDateTime(object.created_at) }}
              </div>
              <div>
                Mise à jour le
              </div>
              <div>
                {{ $filters.dateStringToHumanizedDateTime(object.updated_at) }}
              </div>
              <div>
                Ref
              </div>
              <div>
                {{ object.ref }}
              </div>
              <div>
                Envoyé au client
              </div>
              <div>
                <span v-if="object.send_date" class="highlighted green">{{ $filters.dateStringToHumanizedDateTime(object.send_date) }}</span>
                <i v-else class="bi bi-x-circle-fill text-red" />
              </div>
            </div>
          </div>
        </div>

        <QuoteCustomerCard
          v-model="object" />


        <div class="card">
          <div class="card-header">
            <span class="title">Interventions</span>
            <button
              v-if="!editingInterventionList"
              class="btn-link"
              style="margin-left: 5px;"
              @click="editInterventionList">
              Editer
            </button>
          </div>

          <div
            v-if="editingInterventionList"
            class="card-body">
            <InterventionListForm
              v-model="editedInterventionList" />
            <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
              <button
                class="btn-outline-secondary"
                style="margin-right: 10px;"
                :disabled="savingInterventionList"
                @click="cancelEditInterventionList">
                Annuler
              </button>
              <button
                :class="{ 'loading': savingInterventionList }"
                class="btn-primary"
                :disabled="savingInterventionList"
                @click="saveInterventionList">
                Enregistrer
              </button>
            </div>
          </div>

          <div v-else class="card-body">
            <i v-if="object.interventions == null || object.interventions.length == 0">Aucune</i>
            <InterventionCompactRow
              v-for="(intervention, index) in object.interventions"
              :key="index"
              :object="intervention" />
          </div>
        </div>

        <EventListCard
          ref="eventListCard"
          style="margin: 10px 0;"
          :events="object.events"
          @submit="onCustomEvent" />

        <HistoryCard
          objectName="le devis"
          :history="object.history"
          :postComment="postComment" />
      </div>
    </template>    
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormSection from "@/components/layout/FormSection.vue";
import QuoteToolButtons from "@/components/toolbuttons/QuoteToolButtons.vue";
import FormField from "@/components/forms/FormField.vue";
import EventListCard from "@/components/EventListCard.vue";
import InterventionListForm from "@/components/forms/InterventionListForm.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import QuoteCustomerCard from "@/components/cards/customerCards/QuoteCustomerCard.vue";
import QuoteAddressCard from "@/components/cards/addressCards/QuoteAddressCard.vue";
import QuoteContactCard from "@/components/cards/contactCards/QuoteContactCard.vue";
import QuoteInfoCard from "@/components/cards/quoteCards/QuoteInfoCard.vue";
import InvoiceAndQuoteProductsCard from "@/components/cards/invoiceAndQuoteCards/InvoiceAndQuoteProductsCard.vue";
import InterventionCompactRow from "@/components/rows/intervention/InterventionCompactRow.vue";
import PdfCard from "@/components/cards/PdfCard.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";

export default {
  name: "QuoteObjectView",
  components: {
    ObjectViewLayout,
    FormSection,
    QuoteToolButtons,
    FormField,
    InvoiceIcon,
    EventListCard,
    InterventionListForm,
    QuoteCustomerCard,
    QuoteAddressCard,
    QuoteContactCard,
    QuoteInfoCard,
    InvoiceAndQuoteProductsCard,
    InterventionCompactRow,
    PdfCard,
    HistoryCard,
  },
  data() {
    return {
      object: null,
      schema: null,
      editedInterventionList: null,
      errorFetching: false,
      editingInterventionList: false,
      savingQuote: false,
      savingRemarks: false,
      savingInterventionList: false,
      validationErrors: null,
      quotePdf: null,
    };
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchQuotePdf(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.quote.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
    fetchSchema() {
      return this.$services.quote.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchQuotePdf() {
      return this.$services.quote.fetchPdf(this.$route.params.id)
        .then((response) => {
          this.quotePdf = response.data;
        });
    },
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.object.customer.id }});
    },
    gotoIntervention(intervention) {
      this.$router.push({ name: "intervention", params: { id: intervention.id }});
    },
    editInterventionList() {
      this.editedInterventionList = this.object.interventions;
      this.editingInterventionList = true;
    },
    cancelEditInterventionList() {
      this.editedInterventionList = null;
      this.editingInterventionList = false;
    },
    saveInterventionList() {
      this.savingInterventionList = true;
      this.validationErrors = null;

      const interventionIdsList = [];
      this.editedInterventionList.forEach((intervention) => { interventionIdsList.push(intervention.id); });

      this.$services.quote.patch(
        this.object.id,
        {
          intervention_ids: interventionIdsList,
        },
      )
        .then((response) => {
          this.object.interventions = response.data.interventions;
          this.editingInterventionList = false;

        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }

        })
        .finally(() => {
          this.savingInterventionList = false;
        });
    },
    saveRemarksForm() {
      this.savingRemarks = true;
      this.validationErrors = null;

      const data = {
        remarks: this.object.remarks,
        public_remarks: this.object.public_remarks,
      };

      this.$services.quote.patch(this.object.id, data)
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationError = error.response.data;
          } else {
            throw error;
          }

        }).finally(() => {
          this.savingRemarks = false;
        });
    },
    saveAddressForm(addressData) {
      return new Promise((resolve, reject) => {
        this.$services.quote.patch(
          this.object.id,
          {
            address: addressData,
          },
        ).then((response) => {
          this.object.address = response.data.address;
          resolve(response);

        }).catch((error) => {
          if (error.response && error.response.status === 400) {
            reject(error.response.data.address);
          } else {
            throw error;
          }
        });
      });
    },
    onCustomEvent(description) {
      this.$services.quote.createEvent(this.object.id, description)
        .then((response) => {
          this.object.events = response.data.events;
          this.$refs.eventListCard.reset();
        });
    },
    postComment(comment) {
      return this.$services.intervention.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },
  },
};
</script>


<style scoped lang="scss">


</style>