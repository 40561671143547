<template>
  <ObjectViewLayout
    title="Nouvelle vente"
    withBackButton
    @back="$router.push({ name: 'sale-list' })">
    <template #title-icon-left>
      <i class="bi bi-cart" style="font-size: 2rem;" />
    </template>
  
    <template #default>
      <div class="vertical-spaced-container">
        <div
          class="card">
          <div v-if="saleSchema == null" class="card-body">
            <LoaderView />
          </div>

          <div v-else class="card-body">
            <div
              v-if="newCustomer">
              <div class="form-group">
                Nom: <strong>{{ $filters.capitalize(newCustomer.contact.title) }} {{ $filters.capitalize(newCustomer.contact.first_name) }} {{ $filters.capitalize(newCustomer.contact.last_name) }}</strong><br>
                Entreprise: <strong>{{ newCustomer.contact.company_name }} {{ newCustomer.contact.company_vat }}</strong><br>
                Numéro de téléphone: <strong>{{ newCustomer.contact.phone_number }}</strong><br>
                Email: <strong>{{ newCustomer.contact.email }}</strong><br>
                Langue: <strong>{{ newCustomer.contact.language }}</strong>
                Adresse: <strong>{{ newCustomer.address.street_number }} {{ newCustomer.address.street }} {{ newCustomer.address.bp }} {{ newCustomer.address.zipcode }} {{ newCustomer.address.city }} {{ newCustomer.address.country_code }}</strong>
              </div>

              <div class="form-group">
                <button class="btn-link" @click="newCustomer = null">
                  Effacer
                </button>
              </div>
            </div>

            <div v-else>
              <div class="form-group">
                <label class="form-label">Sélectionnez un client</label>
                <SelectObject
                  style="max-width: 300px;"
                  type="customer"
                  :disabled="anonymousCustomer"
                  :selectedValue="selectedCustomer ? $filters.fullname(selectedCustomer.contact) : null"
                  @select="onCustomerSelect"
                  @unselect="onCustomerSelect(null)" />
                <small v-if="validationErrors?.customer" class="form-text text-red">
                  {{ validationErrors.customer }}
                </small>
              </div>

              <div class="form-group">
                <button class="btn-link" @click="$refs['create-customer-modal'].show()">
                  Créer un nouveau client
                </button>
              </div>
            </div>

            <!-- Anonymous customer -->
                
            <div class="form-group">
              <div class="form-check">
                <input
                  v-model="anonymousCustomer"
                  class="form-check-input"
                  type="checkbox">
                <label class="form-check-label">
                  Client particulier
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card">
          <div v-if="saleSchema == null" class="card-body">
            <LoaderView />
          </div>

          <div v-else class="card-body">
            <FormField
              :schema="saleSchema.source_storage"
              :error="validationErrors?.source_storage_id">
              <template #default>
                <StorageSelect v-model="object.source_storage_id" style="width: auto;" />
              </template>
            </FormField>
          </div>
        </div>

        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <label class="switch">
            <input v-model="toDeliver" type="checkbox">
            <span class="slider round" />
          </label>
          <label style="margin-left: 5px">A expédier</label>
        </div>

        <div v-if="toDeliver" class="row" style="margin-bottom: 10px;">
          <div class="col">
            <div
              class="card">
              <div class="card-header">
                <span class="title">Informations de contact pour la livraison</span>
              </div>

              <div class="card-body">
                <ContactForm
                  v-model="contact"
                  :validationErrors="validationErrors?.contact" />
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card">
              <div class="card-header">
                <span class="title">Adresse de livraison</span>
              </div>

              <div class="card-body">
                <AddressForm
                  v-model="address"
                  :validationErrors="validationErrors?.address" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="card">
          <div class="card-header">
            <span class="title">Produits</span>
          </div>
          
          <div class="card-body">
            <div class="form-group">
              <InvoiceProductsForm
                v-if="origin"
                ref="invoice-products-form"
                v-model:products="object.items"
                v-model:discountPercent="object.discount_percent"
                v-model:pricesIncludesTax="object.prices_includes_tax"
                :taxRate="origin.tax_rate" />
            </div>
          </div>
        </div>

        <div
          class="card">
          <div class="card-header">
            <span class="title">Paiement</span>
          </div>

          <div v-if="invoiceSchema == null" class="card-body">
            <LoaderView />
          </div>

          <div v-else class="card-body">
            <PaymentsForm
              v-model="object.payments"
              class="form-group"
              :totalWithTaxes="totalWithTaxes" />

            <FormField
              :schema="invoiceSchema.manager_who_agreed_to_not_collect_payment"
              :required="amountPaid <= 0"
              :error="validationErrors?.manager_who_agreed_to_not_collect_payment_id"
              style="max-width: 300px;">
              <template #default>
                <SelectObject
                  type="manager"
                  :disabled="amountPaid > 0"
                  :selectedValue="selectedManagerWhoAdreedToNotCollectPayment ? $filters.fullname(selectedManagerWhoAdreedToNotCollectPayment) : null"
                  @select="onManagerWhoAgreedToNotCollectPaymentSelect"
                  @unselect="onManagerWhoAgreedToNotCollectPaymentSelect(null)" />
              </template>
            </FormField>

            <FormField
              :schema="invoiceSchema.reason_for_not_collecting_payment"
              :required="amountPaid <= 0"
              :error="validationErrors?.reason_for_not_collecting_payment">
              <template #default>
                <textarea
                  v-model="object.reason_for_not_collecting_payment"
                  class="form-control"
                  :disabled="amountPaid > 0" />
              </template>
            </FormField>
          </div>
        </div>
      </div>
    </template>

    <template #rightBar>
      <div class="card">
        <div class="card-body">
          <button
            class="btn-primary"
            :disabled="submitting || !canSubmit"
            :class="{ 'loading': submitting }"
            @click="submit">
            Terminer
          </button>
        </div>
      </div>
      <div class="card" style="margin-top: 10px;">
        <div class="card-body">
          <textarea
            v-model="object.comments"
            class="form-control"
            style="height: 100px; width: 100%;"
            placeholder="Commentaires" />
        </div>
      </div>
    </template>
  </ObjectViewLayout>

  <CreateCustomerModal ref="create-customer-modal" @submit="onNewCustomerSubmitted" />
</template>
  
<script>
  
import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import InvoiceProductsForm from "@/components/forms/InvoiceProductsForm.vue";
import SelectObject from "@/components/inputs/SelectObject.vue";
import StorageSelect from "@/components/selects/StorageSelect.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import ContactForm from "@/components/forms/ContactForm.vue";
import CreateCustomerModal from "@/components/modals/CreateCustomerModal.vue";
import { useMeStore } from "@/stores/me";
import { useOriginsStore } from "@/stores/origins";
import PaymentsForm from "@/components/cards/creationTunnel/utils/PaymentsForm.vue";

export default {
  name: "SaleV2ObjectCreationView",
  components: {
    ObjectViewLayout,
    LoaderView,
    FormField,
    InvoiceProductsForm,
    SelectObject,
    StorageSelect,
    AddressForm,
    ContactForm,
    CreateCustomerModal,
    PaymentsForm,
  },
  data() {
    return {
      saleSchema: null,
      invoiceSchema: null,
      object: {
        items: [],
        source_storage_id: useMeStore().getMe.default_storage_id,
        payments: [],
        discount_amount: 0,
        discount_percent: 0,
        prices_includes_tax: false,
      },
      contact: {
        title: "mr",
        language: "fr",
      },
      address: {
        country_code: "be",
      },
      toDeliver: false,
      anonymousCustomer: false,
      validationErrors: null,
      submitting: false,
      selectedCustomer: null,
      newCustomer: null,
      selectedManagerWhoAdreedToNotCollectPayment: null,
    };
  },
  computed: {
    origin() {
      return useOriginsStore().getOriginFromCode("EDN-BE-FR");
    },
    
    canSubmit() {
      if (!this.object.items) {
        return false;
      }

      if (!this.anonymousCustomer && this.selectedCustomer == null && this.newCustomer == null) {
        return false;
      }

      if (this.object.source_storage_id == null) {
        return false;
      }

      if (this.amountPaid <= 0) {
        if (this.selectedManagerWhoAdreedToNotCollectPayment == null || this.object.reason_for_not_collecting_payment == null) {
          return false;
        }
      }

      return true;

    },

    // Pricing calculation
    
    totalWithoutTaxes() { // In cents
      if (!this.object.items) {
        return 0;
      } else {
        var total = 0;
        for (const item of this.object.items) {
          total = total + this.totalLineBeforeTaxes(item);
        }
        return total;
      }
    },
    totalWithTaxes() { // In cents
      if (!this.object.items) {
        return 0;
      } else {
        var total = 0;
        for (const item of this.object.items) {
          total = total + this.totalLineAfterTaxes(item);
        }
        return total - this.object.discount_amount * 100;
      }
    },
    amountPaid() { // In cents
      return this.object.payments.reduce((accumulateur, objet) => {
        return accumulateur + objet.amount;
      }, 0);
    },
  },
  mounted() {
    this.fetchSaleSchema();
    this.fetchInvoiceSchema();  
  },
  methods: {
    fetchSaleSchema() {
      return this.$services.saleV2.schema()
        .then((response) => {
          this.saleSchema = response.data;
        });
    },
    fetchInvoiceSchema() {
      return this.$services.invoice.schema()
        .then((response) => {
          this.invoiceSchema = response.data;
        });
    },
    // Pricing calculation

    totalLineBeforeTaxes(item) {
      const totalLineBeforeTaxesBeforeDiscount = item.product_quantity * item.product_price * 100;
      const discount = totalLineBeforeTaxesBeforeDiscount * this.object.discount_percent / 100;
      return Math.round(totalLineBeforeTaxesBeforeDiscount - discount);
    },
    lineTaxes(item) {
      const taxes = this.totalLineBeforeTaxes(item) * this.origin.tax_rate / 100;
      return Math.round(taxes);
    },
    totalLineAfterTaxes(item) {
      return this.totalLineBeforeTaxes(item) + this.lineTaxes(item);
    },

    // Events

    onManagerWhoAgreedToNotCollectPaymentSelect(user) {
      this.selectedManagerWhoAdreedToNotCollectPayment = user;
    },

    onCustomerSelect(customer) {

      if (this.customer.contacts == null || this.customer.contacts.length < 1) {
        throw new Error("Invalid . On SaleObjectCreation > onCustomerSelect: customer has no contacts or zero contacts.");
      }

      this.selectedCustomer = customer;

      const customerContact = customer.contacts[0];

      this.contact = {
        title: customerContact.title,
        first_name: customerContact.first_name,
        last_name: customerContact.last_name,
        company_name: customer.billing_company_name,
        company_vat: customer.billing_company_vat,
        phone_number: customerContact.phone_number,
        email: customerContact.email,
        language: customerContact.language,
      };

      this.address = {
        street_number: customer.billing_street_number,
        street: customer.billing_street,
        bp: customer.billing_bp,
        zipcode: customer.billing_zipcode,
        city: customer.billing_city,
        country_code: customer.billing_country_code,
      };
    },
    onNewCustomerSubmitted(customer) {
      if (this.customer.contacts == null || this.customer.contacts.length < 1) {
        throw new Error("Invalid . On SaleObjectCreation > onCustomerSelect: customer has no contacts or zero contacts.");
      }

      this.newCustomer = customer;
      
      const customerContact = customer.contacts[0];

      this.contact = {
        title: customerContact.title,
        first_name: customerContact.first_name,
        last_name: customerContact.last_name,
        company_name: customer.billing_company_name,
        company_vat: customer.billing_company_vat,
        phone_number: customerContact.phone_number,
        email: customerContact.email,
        language: customerContact.language,
      };

      this.address = {
        street_number: customer.billing_street_number,
        street: customer.billing_street,
        bp: customer.billing_bp,
        zipcode: customer.billing_zipcode,
        city: customer.billing_city,
        country_code: customer.billing_country_code,
      };
    },
    submit() {
      this.submitting = true;
      this.validationErrors = null;

      const body = {
        ...this.object,
      };

      if (this.anonymousCustomer) {
        // Do nothing
      } else if (this.newCustomer) {
        body.customer = this.newCustomer;
      } else if (this.selectedCustomer) {
        body.customer_id = this.selectedCustomer.id;
      } else {
        throw new Error("Invalid state");
      }

      if (this.toDeliver) {
        body.contact = this.contact;
        body.address = this.address;
      }

      if (this.selectedManagerWhoAdreedToNotCollectPayment) {
        body.manager_who_agreed_to_not_collect_payment_id = this.selectedManagerWhoAdreedToNotCollectPayment.id;
      }

      this.$services.saleV2.create(body)
        .then((response) => {
          this.$router.push({ name: "sale", params: { id: response.data.id } });

        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>

  .btn-select {
    background: #fff;
    border: 1px solid #e6e6e6;
    padding: 8px;

    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.selected {
      background: lightsteelblue;
    }

    &:not(.selected) {
      cursor: pointer;
    }

    &:hover:not(.selected) {
      background: #e6e6e6;
    }
  }

</style>
