<template>
  <div
    class="history-row"
    @click="$router.push({ name: 'invoice', params: { id: object.id } })">
    <div class="info-grid-layout">
      <div>Ref</div>
      <div>{{ object.ref }}</div>
      <div>Créé le</div>
      <div>{{ $filters.dateStringToHumanizedDate(object.created_at) }}</div>
      <div>Auteur</div>
      <div>
        <span v-if="object.author">{{ $filters.fullname(object.author) }}</span>
        <span v-else><i>Aucun</i></span>
      </div>
      <div v-if="object.credit_note">
        Note de crédit
      </div>
      <div v-if="object.credit_note">
        <router-link
          :to="{ name: 'credit-note', params: { id: object.credit_note.id } }">
          {{ object.credit_note.ref }}
        </router-link><br>
        <strong>{{ object.credit_note.total_incl_tax }}€ TTC</strong> {{ object.credit_note.amount }}€ HT<br>
        Créée le {{ $filters.dateStringToHumanizedDate(object.credit_note.created_at) }}
      </div>
    </div>
    <div class="flow-spaced-container">
      <div
        v-if="object.send_date"
        class="highlighted green tooltip"
        style="margin-right: 5px;">
        Envoyé au client
        <div class="tooltip-text">
          {{ $filters.dateStringToHumanizedDate(object.send_date) }}
        </div>
      </div>

      <div
        v-else
        class="highlighted red"
        style="margin-right: 5px;">
        Non envoyé au client
      </div>

      <div
        v-if="object.odoo_created_at"
        style="margin-right: 5px;"
        class="highlighted green">
        Verrouillée
      </div>

      <div
        v-else
        style="margin-right: 5px;"
        class="highlighted red">
        Non verrouillée
      </div>

      <div
        v-if="object.to_pay <= 0"
        class="highlighted green">
        Payé
      </div>

      <div
        v-else
        class="highlighted red">
        {{ object.to_pay / 100 }}€ à payer
      </div>

      <div v-if="object.refund" class="highlighted yellow">
        <span v-if="object.refund.refunded_at">
          {{ object.refund.amount }}€ remboursé le {{ object.refund.refunded_at }}
        </span>
        <span v-else>
          {{ object.refund.amount }}€ en attente de remboursement
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "InvoiceCompactRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
