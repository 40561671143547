<template>
  <GBaseRow>
    <template #icon>
      <i class="bi bi-cash-stack" style="font-size: 2rem;" />
    </template>

    <template #title>
      <span class="text-bigger">{{ adjustment.amount / 100 }}€</span>
      <br>
      <span class="text-smaller">{{ $filters.dateStringToHumanizedDate(adjustment.created_at, "short") }}</span>
    </template>

    <td>
      <i v-if="adjustment.odoo_id" class="bi bi-check-circle-fill text-green tooltip">
        <div class="tooltip-text">
          Push le {{ $filters.dateStringToHumanizedDate(adjustment.odoo_created_at, "short") }} dans Odoo
        </div>
      </i>
      <i v-else-if="adjustment.odoo_error_message" class="bi bi-x-circle-fill text-red tooltip">
        <div class="tooltip-text">
          <p>{{ adjustment.odoo_error_message }}</p>
          <p>{{ adjustment.push_to_odoo_attempt_left }} tentatives restantes</p>
        </div>
      </i>
    </td>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";

export default {
  name: "RegisterAdjustmentRow",
  components: {
    GBaseRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    adjustment: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>


</style>