<template>
  <ObjectViewLayout
    :title="title"
    :loading="!object"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'product-list' })">
    <template #rightButton>
      <ProductToolButtons v-model="object" />
    </template>

    <template #default>
      <div class="cat-list" style="margin: 0px 10px 1.25rem 10px;">
        <router-link :to="{ name: 'product-view', params: { id: $route.params.id } }" class="cat-list-item" active-class="active">
          Informations produit
        </router-link>
        <router-link :to="{ name: 'product-stock', params: { id: $route.params.id } }" class="cat-list-item" active-class="active">
          Stock
        </router-link>
      </div>
      <router-view />
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import ProductToolButtons from "@/components/toolbuttons/ProductToolButtons.vue";

export default {
  name: "ProductObjectLayout",
  components: {
    ObjectViewLayout,
    ProductToolButtons,
  },
  data() {
    return {
      object: null,
      errorFetching: false,
    };
  },
  computed: {
    title() {
      if (!this.object) {
        return null;
      }

      return `${this.object.sku ? this.object.sku : "No SKU"} : ${this.object.name}`;
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      this.$services.product.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>