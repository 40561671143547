<template>
  <button class="tool-btn tooltip" @click.stop="updateBatch">
    <i class="bi bi-pencil-square" />
    <div class="tooltip-text">
      Editer le lot
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="seeStorage">
    <i class="bi bi-boxes" />
    <div class="tooltip-text">
      Voir l'espace de stockage
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="seeProduct">
    <i class="bi bi-box" />
    <div class="tooltip-text">
      Voir la fiche produit
    </div>
  </button>

  <UpdateBatchModal
    ref="updateBatchModal"
    v-model="internalValue" />
</template>

<script>

import UpdateBatchModal from "@/components/modals/UpdateBatchModal.vue";

export default {
  name: "BatchToolButtons",
  components: {
    UpdateBatchModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      deletingProduct: false,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("Emit from BatchToolButtons");
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    seeProduct() {
      this.$router.push({ name: "product", params: { "id": this.modelValue.product.id } });
    },
    seeStorage() {
      this.$router.push({ name: "storage", params: { "id": this.modelValue.storage.id } });
    },
    updateBatch() {
      this.$refs.updateBatchModal.show();
    },
  },
};

</script>


<style lang="scss" scoped>


</style>