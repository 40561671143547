import { api } from "@/api";

class JobApiService {

  list() {
    return api.get("/jobs/");
  }

  run(jobName: string) {
    return api.post(
      `/jobs/${jobName}/run/`,
    );
  }

  
}

export default JobApiService;