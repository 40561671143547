<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="loader-svg">
    <ellipse
      cx="12"
      cy="12"
      rx="10"
      ry="10"
      class="loader-ellipse" />
  </svg>
</template>

<script>

export default {
  name: "Loader",
  props: {
    width: {
      type: String,
      default: "16px",
    },
    height: {
      type: String,
      default: "16px",
    },
  },
};
</script>

<style lang="scss" scoped>

  .loader-svg {
    position: relative;
    display: flex;
    animation: ShowAnimation 0.25s normal ease,
      RotationAnimation 0.7s linear infinite;
    transition-property: opacity, transform;
    transition-timing-function: ease;
    transform-origin: 50% 50%;

    .loader-ellipse {
      fill: transparent;
      stroke: #b25441;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-dasharray: 60;
      stroke-dashoffset: 20;
    }
  }

  @keyframes ShowAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes RotationAnimation {
    100% {
      transform: rotate(360deg);
    }
  }
  
</style>
