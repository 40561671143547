<template>
  <Modal ref="modal" noBackground>
    <div
      v-for="intervention of interventions"
      :key="intervention.id">
      <time :datetime="intervention.created_at" style="background: white; padding: 5px 10px; border-radius: 15px;">
        Créé le {{ $filters.dateStringToHumanizedDateTime(intervention.created_at, "short") }}
      </time>
      <InterventionCompactCard
        style="margin: 10px 0px 20px 0px"
        :modelValue="intervention" />
    </div>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import InterventionCompactCard from "@/components/cards/interventionCards/InterventionCompactCard.vue";

export default {
  name: "CustomerInterventionsModal",
  components: {
    Modal,
    InterventionCompactCard,
  },
  props: {
    interventions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
  },
};

</script>

<style lang="scss" scoped>


</style>