<template>
  <div
    class="job">
    {{ job }}
    <SimpleButton
      :loading="loading"
      disableWhenLoading
      icon="bi bi-terminal"
      tooltipText="Lancer"
      @click="run" />
  </div>
</template>

<script>


import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";

export default {
  name: "Job",
  components: {
    SimpleButton,
  },
  props: {
    job: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    run() {
      this.loading = true;
      this.$services.job.run(this.job).then(() => {
        this.loading = false;
      });
    },
  },
};

</script>


<style scoped lang="scss">

  .job {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }



</style>


