import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess } from "@/notifications";
import { AxiosResponse } from "axios";

class InterventionApiService extends ModelApiService {
  constructor() {
    super("/interventions/", "intervention");
  }

  cancel(id: number, remarks: string | null = null) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/cancel/", { remarks: remarks })
        .then((response) => {
          notifySuccess(`${this.verboseName} annulée`);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  close(id: number, rdvDate = null) {

    const body = rdvDate ? {rdv_date: rdvDate} : null;

    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/close/",
        body,
      ).then((response) => {
        notifySuccess(`${this.verboseName} cloturée`);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  fetchQrCode(id: number) {
    return api.get(this.url + id + "/qrcode/");
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  addComment(id: number, comment: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/comment/",
        {
          comment: comment,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commentaire ajouté");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }

  addTask(id: number, action: string, deadline: string, assignedAt: any = null, comment = null) {
    
    const body: any = {
      action: action,
      deadline: deadline,
    };

    if (assignedAt !== null) {
      body["assigned_id"] = assignedAt.id;
    }

    if (comment !== null) {
      body["comment"] = comment;
    }

    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/tasks/",
        body,
      ).then((response) => {
        notifySuccess("Tache créé");
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  syncWithGoogleCalendar(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/sync_with_google_calendar/")
        .then((response) => {
          notifySuccess("Synchronisation terminée");
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  fetchReportPdf(id: number) {
    return api.get(this.url + id + "/report_pdf/");
  }

  sendReportToCustomerByEmail(id: number) {
    return new Promise((resolve, reject) => {
      api.post(this.url + id + "/send_report_to_customer_by_email/")
        .then((response) => {
          notifySuccess("Rapport envoyé");
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  tripDurationFromAddress(id: number, latitude: number, longitude: number) {
    return api.get(this.url + id + `/trip_duration_from_address/?latitude=${latitude}&longitude=${longitude}`);
  }

  tripDurationToAddress(id: number, latitude: number, longitude: number) {
    return api.get(this.url + id + `/trip_duration_to_address/?latitude=${latitude}&longitude=${longitude}`);
  }
}

export default InterventionApiService;