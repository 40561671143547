<template>
  <div class="flex-auto">
    <div class="flex justify-between gap-x-4">
      <p v-if="historicalRecord.event" class="py-0.5 text-xs leading-5 text-gray-500">
        <span class="font-medium text-gray-900">{{ historicalRecord.event }}</span><span v-if="historicalRecord.author"> par {{ $filters.fullname(historicalRecord.author) }}</span>
      </p>
      <p v-else class="py-0.5 text-xs leading-5 text-gray-500">
        <span v-if="historicalRecord.author" class="font-medium text-gray-900">{{ $filters.fullname(historicalRecord.author) }}</span><span v-else class="font-medium text-gray-900">Le système</span> a {{ historicalRecord.state ? "modifié" : historicalRecord.comment ? "commenté" : "fait un truc avec" }} {{ objectName }}
      </p>
      <time :datetime="historicalRecord.date" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ $filters.dateStringToHumanizedDateTime(historicalRecord.date, "short") }}</time>
    </div>

    <p v-if="historicalRecord.comment" class="text-sm leading-6 text-gray-500">
      {{ historicalRecord.comment }}
    </p>

    <div v-if="historicalRecord.is_first">
      <p
        class="text-sm leading-6 text-gray-500">
        Premier enregistrement
      </p>
    </div>

    <div v-else-if="historicalRecord.changes">
      <div
        v-for="(changes, objectChanged) in historicalRecord.changes"
        :key="objectChanged">
        <p
          v-for="(change, fieldChanged) in changes"
          :key="fieldChanged"
          class="text-sm leading-6 text-gray-500">
          {{ fieldChanged }} : {{ change }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HistoricalRecord",
  props: {
    historicalRecord: {
      type: Object,
      required: true,
    },
    previousHisoricalRecord: {
      type: Object,
      required: false,
      default: null,
    },
    objectName: {
      type: String,
      required: false,
      default: "l'objet",
    },
  },
};
</script>

<style lang="scss" scoped>


</style>