<script setup lang="ts">

import { computed } from "vue";
import Loader from "@/components/Loader.vue";

// Props

const props = defineProps({
  page: {type: Number, required: false, default: undefined },
  limit: {type: Number, required: false, default: undefined },
  total: {type: Number, required: false, default: undefined },
  loading: {type: Boolean, required: false, default: false },
  allowExport: {type: Boolean, required: false, default: false },
  exporting: {type: Boolean, required: false, default: false },
});

// Emits

const emit = defineEmits(["first", "previous", "next", "export"]);

// Computed

const canGoToPreviousPage = computed(() => {
  if (props.page === undefined || props.limit === undefined) {
    return false;
  }
  return props.page > 1 && !props.loading;
});

const canGoToNextPage = computed(() => {
  if (props.page === undefined || props.limit === undefined || props.total === undefined) {
    return false;
  }
  return props.page * props.limit < props.total && !props.loading;
});

// Methods

const firstPage = () => {
  emit("first");
};

const previousPage = () => {
  emit("previous");
};

const nextPage = () => {
  emit("next");
};

const doExport = () => {
  emit("export");
};

</script>

<template>
  <div class="flow-spaced-container" style="align-items: center;">
    <div style="flex: 1; display: flex; align-items: center;">
      <span v-if="page != undefined && limit != undefined && total != undefined">
        <span v-if="loading">Chargement de</span><span v-else>Affichage de</span> {{ (page - 1) * limit + 1 }} à {{ Math.min(page * limit, total) }} sur {{ total }} résultats
      </span>
      <span v-else-if="loading">
        Chargement
      </span>
      <span v-if="loading">
        ...
      </span>
      <Loader v-if="loading" style="margin-left: 5px;" />
    </div>
    <div class="flow-spaced-container" style="align-items: center;">
      <button class="btn-secondary tooltip" :disabled="!canGoToPreviousPage" @click="firstPage">
        <i class="bi bi-chevron-double-left" />
        <div class="tooltip-text">
          Première page
        </div>
      </button>
      <button class="btn-secondary tooltip" :disabled="!canGoToPreviousPage" @click="previousPage">
        <i class="bi bi-chevron-left" />
        <div class="tooltip-text">
          Page précédente
        </div>
      </button>
      <div>
        Page {{ page }}
      </div>
      <button class="btn-secondary tooltip" :disabled="!canGoToNextPage" @click="nextPage">
        <i class="bi bi-chevron-right" />
        <div class="tooltip-text">
          Page suivante
        </div>
      </button>
      <button
        v-if="allowExport"
        class="tool-btn tooltip"
        :disabled="exporting || total == undefined || total <= 0"
        @click="doExport">
        <Loader v-if="exporting" />
        <i v-else class="bi bi-box-arrow-up-right" />
        <div class="tooltip-text left">
          Exporter les {{ total }} résultats
        </div>
      </button>
    </div>
  </div>
</template>