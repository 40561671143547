<template>
  <div class="card">
    <div class="card-body">
      <LoaderView v-if="schema == null" />

      <div v-else>
        <p class="title form-group">
          Produits
        </p>
        <FormField v-if="intervention == null" title="Origine" style="max-width: 200px;">
          <template #default>
            <OriginSelect v-model="selectedOriginCode" />
          </template>
        </FormField>

        <div class="form-group">
          <InvoiceProductsForm
            v-model:products="invoice.items"
            v-model:discountPercent="invoice.discount_percent"
            v-model:pricesIncludesTax="invoice.prices_includes_tax"
            :taxRate="origin ? origin.tax_rate : null" />
        </div>

        <p class="title form-group">
          Coordonnées du client sur <span v-if="mode == 'invoice'">la facture</span><span v-else>le devis</span>
        </p>

        <ContactForm
          v-if="editInvoiceContact"
          v-model="invoice.contact"
          :validationErrors="validationErrors?.contact" />

        <div v-else>
          <div class="info-grid-layout form-group" style="width: max-content;">
            <div>Nom</div>
            <div>
              <strong>{{ $filters.capitalize(invoice.contact.title) }} {{ $filters.capitalize(invoice.contact.first_name) }} {{ $filters.capitalize(invoice.contact.last_name) }}</strong>
            </div>
            <div>Entreprise</div>
            <div>
              <strong>{{ invoice.contact.company_name }} {{ invoice.contact.company_vat }}</strong>
            </div>

            <div>Numéro de téléphone</div>
            <div>
              <strong>{{ invoice.contact.phone_number }}</strong>
            </div>
            <div>Email</div>
            <div>
              <strong>{{ invoice.contact.email }}</strong>
            </div>
            <div>Langue</div><div><strong>{{ invoice.contact.language }}</strong></div>
          </div>
          <div class="form-group">
            <button class="btn-link" @click="editInvoiceContact = true">
              Editer les coordonnées du client sur <span v-if="mode == 'invoice'">la facture</span><span v-else>le devis</span>
            </button>
          </div>
        </div>

        <p class="title form-group">
          Adresse de facturation
        </p>

        <AddressForm
          v-if="editInvoiceAddress"
          v-model="invoice.address"
          :validationErrors="validationErrors?.address" />

        <div v-else>
          <div class="form-group">
            {{ invoice.address.street_number }} {{ invoice.address.street }} {{ invoice.address.bp }}<br>
            {{ invoice.address.zipcode }} {{ invoice.address.city }} {{ invoice.address.country_code }}
          </div>
          <div class="form-group">
            <button class="btn-link" @click="editInvoiceAddress = true">
              Editer l'adresse de facturation
            </button>
          </div>
        </div>

        <p v-if="mode == 'invoice'" class="title form-group">
          Paiement
        </p>

        <PaymentsForm
          v-if="mode == 'invoice'"
          v-model="invoice.payments"
          class="form-group"
          :totalWithTaxes="totalInclTax" />

        <FormField
          v-if="mode == 'invoice'"
          :schema="schema.manager_who_agreed_to_not_collect_payment"
          :required="amountPaid <= 0"
          :error="validationErrors?.manager_who_agreed_to_not_collect_payment_id"
          style="max-width: 300px;">
          <template #default>
            <SelectObject
              type="manager"
              :disabled="amountPaid > 0"
              :selectedValue="selectedManagerWhoAdreedToNotCollectPayment ? $filters.fullname(selectedManagerWhoAdreedToNotCollectPayment) : null"
              @select="onManagerWhoAgreedToNotCollectPaymentSelect"
              @unselect="onManagerWhoAgreedToNotCollectPaymentSelect(null)" />
          </template>
        </FormField>

        <FormField
          v-if="mode == 'invoice'"
          :schema="schema.reason_for_not_collecting_payment"
          :required="amountPaid <= 0"
          :error="validationErrors?.reason_for_not_collecting_payment">
          <template #default>
            <textarea
              v-model="invoice.reason_for_not_collecting_payment"
              class="form-control"
              :disabled="amountPaid > 0" />
          </template>
        </FormField>

        <FormField
          :schema="schema.public_remarks"
          :error="validationErrors?.public_remarks"
          class="form-group">
          <template #default>
            <textarea v-model="invoice.public_remarks" class="form-control" />
          </template>
        </FormField>
      </div>
    </div>

    <div class="card-footer justify-right">
      <button
        class="btn btn-primary"
        :disabled="!invoiceIsValid || submitting"
        :class="{ 'loading': submitting }"
        @click="submit">
        Terminer
      </button>
    </div>
  </div>
</template>

<script>

import SelectObject from "@/components/inputs/SelectObject.vue";
import LoaderView from "@/components/LoaderView.vue";
import FormField from "@/components/forms/FormField.vue";
import ContactForm from "@/components/forms/ContactForm.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import InvoiceProductsForm from "@/components/forms/InvoiceProductsForm.vue";
import { useOriginsStore } from "@/stores/origins";
import { useActivitiesStore } from "@/stores/activities";
import OriginSelect from "@/components/selects/OriginSelect.vue";
import PaymentsForm from "@/components/cards/creationTunnel/utils/PaymentsForm.vue";

export default {
  name: "InvoiceCreationStepCard",
  components: {
    LoaderView,
    SelectObject,
    FormField,
    ContactForm,
    AddressForm,
    InvoiceProductsForm,
    OriginSelect,
    PaymentsForm,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: (value) => {
        return ["invoice", "quote"].includes(value);
      },
    },
    intervention: {
      type: Object,
      required: false,
      default: null,
      validator: (value) => {
        if (value === null) {
          return true;
        }

        for (const field of [
          "id",
          "origin",
          "activities",
        ]) {
          if (!Object.prototype.hasOwnProperty.call(value, field)) {
            console.warn(`Missing field '${field}' in prop intervention of component InvoiceCreationStepCard`);
            return false;
          }
        }

        for (const field of [
          "tax_rate",
          "code",
        ]) {
          if (!Object.prototype.hasOwnProperty.call(value.origin, field)) {
            console.warn(`Missing field '${field}' in prop intervention.origin of component InvoiceCreationStepCard`);
            return false;
          }
        }

        if (value.activities.length <= 0) {
          console.warn("Prop intervention.activities of component InvoiceCreationStepCard must contain at least one activity");
          return false;
        }

        
        return true;
      },
    },
    customer: {
      type: Object,
      required: true,
      validator: (value) => {
        if (!value.billing_person_last_name) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain a billing_person_last_name field");
        }

        if (!value.billing_street) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain a billing_street field");
        }

        if (!value.billing_zipcode) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain a billing_zipcode field");
        }

        if (!value.billing_city) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain a billing_city field");
        }

        if (!value.billing_country_code) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain a billing_country_code field");
        }

        if (!value.contacts || value.contacts.length <= 0) {
          throw new Error("Prop customer of component InvoiceCreationStepCard must contain at least one contact");
        }

        if (!value.locations || value.locations.length <= 0) {
          throw new Error("Prop customer of component InterventionCreationStepCard must contain at least one location");
        }

        return true;
      },
    },
    invoiceOrQuoteToCopy: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {

      // States
      submitting: false,

      // Schemas
      schema: null,
      selectedOriginCode: null,

      // Invoice to create
      invoice: {
        contact: {
          title: "mr",
          language: "fr",
        },
        address: {
          country_code: "be",
        },
        discount_percent: 0,
        discount_amount: 0,
        payments: [],
        prices_includes_tax: false,
      },
      selectedManagerWhoAdreedToNotCollectPayment: null,

      // Selected existing intervention
      selectedExistingIntervention: null,

      // ValidationErrors
      validationErrors: null,

      editInvoiceContact: false,
      editInvoiceAddress: false,

    };
  },
  computed: {
    // Validation
    
    invoiceIsValid() {
      return this.invoiceContactIsValid && this.invoiceAddressIsValid && this.invoicePaymentInfoIsValid;
    },

    invoiceContactIsValid() {
      return this.invoice.contact.last_name != null && this.invoice.contact.language != null;
    },

    invoiceAddressIsValid() {
      return this.invoice.address.street != null && this.invoice.address.zipcode != null && this.invoice.address.city != null && this.invoice.address.country_code != null;
    },

    invoicePaymentInfoIsValid() {
      if (this.mode != "invoice") {
        return true;
      } else if (this.amountPaid <= 0) {
        return this.selectedManagerWhoAdreedToNotCollectPayment != null && this.invoice.reason_for_not_collecting_payment != null;
      } else {
        return true;
      }
    },

    // Pricing calculation
    
    totalWithoutTaxes() { // In cents
      if (!this.invoice.items) {
        return 0;
      } else {
        var total = 0;
        for (const item of this.invoice.items) {
          total = total + this.productPriceExclTaxWithDiscount(item);
        }
        return total;
      }
    },
    totalInclTax() { // In cents
      if (!this.invoice.items) {
        return 0;
      } else {
        var total = 0;
        for (const item of this.invoice.items) {
          total = total + this.totalLineInclTaxWithDiscount(item);
        }
        const res = total - this.invoice.discount_amount * 100;
        console.log(`total with taxes = ${res}`);
        return res;
      }
    },
    origin() {
      if (this.intervention) {
        return this.intervention.origin;
      } else if (this.selectedOriginCode) {
        return useOriginsStore().getOriginFromCode(this.selectedOriginCode);
      } else {
        return null;
      }
    },
    amountPaid() { // In cents
      return this.invoice.payments.reduce((accumulateur, objet) => {
        return accumulateur + objet.amount;
      }, 0);
    },
  },
  async mounted() {

    this.validateProps();

    // Load contact and address from customer

    this.invoice.contact = {
      first_name: this.customer.billing_person_first_name,
      last_name: this.customer.billing_person_last_name,
      company_name: this.customer.billing_company_name,
      company_vat: this.customer.billing_company_vat,
      language: this.customer.contacts[0].language,
    };

    if (this.customer.contacts && this.customer.contacts.length > 0) {
      this.invoice.contact.phone_number = this.customer.contacts[0].phone_number;
      this.invoice.contact.email = this.customer.contacts[0].email;
    }



    this.invoice.address = {
      street: this.customer.billing_street,
      street_number: this.customer.billing_street_number,
      zipcode: this.customer.billing_zipcode,
      city: this.customer.billing_city,
      country_code: this.customer.billing_country_code,
    };

    if (this.invoiceOrQuoteToCopy) {

      // Load products and origin from invoice to copy

      this.selectedOriginCode = this.invoiceOrQuoteToCopy.journal.origin.code;

      for (const productLine of this.invoiceOrQuoteToCopy.items) {
        this.onAddProductLine(productLine);
      }

    } else if (this.intervention != null) {

      // Load products from intervention activities

      const activities = this.intervention.activities;
        
      for (const activity of activities) {
        const fullActivity = useActivitiesStore().getActivityById(activity.id);
        if (fullActivity == null) {
          console.warn("Activity %s not found", activity.id);
        } else {
          for (const defaultProductLine of fullActivity.default_product_lines) {
            if (defaultProductLine.origin.code == this.intervention.origin.code) {
              const defaultQuantity = defaultProductLine.product_quantity;
              if (this.invoice.items) {
                const index = this.invoice.items.findIndex(item => item.product_id == defaultProductLine.product.id);
                if (index != -1) {
                  this.invoice.items[index].product_quantity = defaultQuantity;
                } else {
                  this.onAddProduct(defaultProductLine.product, defaultQuantity);
                }
              } else {
                this.onAddProduct(defaultProductLine.product, defaultQuantity);
              }
            }
          }
        }
      }
    }

    this.fetchSchema();
  },
  methods: {
    validateProps() {
      if (this.importProductsFromInvoiceId != null && this.importProductsFromQuoteId != null) {
        throw new Error("You can't import products from an invoice and a quote at the same time");
      }
    },
    fetchSchema() {
      return this.$services.invoice.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },

    // Pricing calculation

    productPriceExclTaxWithDiscount(productLine) {
      const priceExclTax = productLine.product_price * 100;
      const discountPercent = productLine.discount_percent != null ? productLine.discount_percent : this.invoice.discount_percent;
      const priceExclTaxAfterDiscount = priceExclTax - (priceExclTax * discountPercent / 100);
      return Math.round(priceExclTaxAfterDiscount);
    },
    totalLineExclTaxWithDiscount(productLine) {
      return this.productPriceExclTaxWithDiscount(productLine) * productLine.product_quantity;
    },
    totalLineTax(productLine) {
      const totalLineExclTaxWithDiscount = this.totalLineExclTaxWithDiscount(productLine);
      const totalLineTax = totalLineExclTaxWithDiscount * this.origin.tax_rate / 100;
      return Math.round(totalLineTax);
    },
    totalLineInclTaxWithDiscount(productLine) {
      if (this.invoice.prices_includes_tax) {
        return this.totalLineExclTaxWithDiscount(productLine);
      } else {
        return this.totalLineExclTaxWithDiscount(productLine) + this.totalLineTax(productLine);
      }
    },

    // Events

    onManagerWhoAgreedToNotCollectPaymentSelect(user) {
      this.selectedManagerWhoAdreedToNotCollectPayment = user;
    },

    onAddProduct(product, quantity = 1) {
      if (!this.invoice.items) {
        this.invoice.items = [];
      }

      this.invoice.items.push({
        product_id: product.id,
        product_description: product.public_name ? product.public_name : product.name,
        product_quantity: quantity,
        product_price: product.sale_price_per_unit,
      });
    },

    onAddProductLine(productLine) {
      if (!this.invoice.items) {
        this.invoice.items = [];
      }

      this.invoice.items.push({
        product_id: productLine.product_id ? productLine.product_id : productLine.product ? productLine.product.id : null,
        product_description: productLine.product_description,
        product_quantity: productLine.product_quantity,
        product_price: productLine.product_price,
      });
    },

    submit() {
      this.submitting = true;
      this.validationErrors = null;

      var data = {
        ...this.invoice,
        origin: this.origin.code,
        customer_id: this.customer.id,
      };
      
      if (this.mode == "invoice") {
        data.manager_who_agreed_to_not_collect_payment_id = this.selectedManagerWhoAdreedToNotCollectPayment?.id;
      }

      if (this.intervention != null && this.intervention.id != null) {
        data.intervention_id = this.intervention.id;
      }

      if (this.mode == "invoice") {

        this.$services.invoice.create(data)
          .then((response) => {
            this.$router.push({ name: "invoice", params: { id: response.data.id } });

          }).catch((error) => {
            if (error.response && error.response.status === 400) {
              this.validationErrors = error.response.data;
            } else {
              throw error;
            }
          }).finally(() => {
            this.submitting = false;
          });
      } else {
        this.$services.quote.create(data)
          .then((response) => {
            this.$router.push({ name: "quote", params: { id: response.data.id } });

          }).catch((error) => {
            if (error.response && error.response.status === 400) {
              this.validationErrors = error.response.data;
            } else {
              throw error;
            }
          }).finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  

</style>