<template>
  <ObjectViewLayout
    title="Vente"
    :loading="object == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'sale-list' })">
    <template #title-icon-left>
      <i class="bi bi-cart" style="font-size: 2rem;" />
    </template>

    <template #title-icon-right>
      <div v-if="isReturned" class="highlighted yellow">
        Retourné
      </div>
    </template>

    <template #rightButton>
      <SaleToolButtons v-model="object" />
    </template>
  
    <div class="vertical-spaced-container">
      <SaleCustomerCard
        v-model="object" />

      <div>
        <div class="flow-spaced-container fill">
          <SaleContactCard
            v-model="object.contact"
            editable
            :saleId="object.id" />

          <SaleAddressCard
            v-model="object.address"
            editable
            :saleId="object.id" />
        </div>
      </div>
    
      <div>
        <div class="flow-spaced-container fill">
          <InvoiceContactCard
            v-model="object.invoice.contact"
            :invoiceId="object.invoice.id"
            :locked="!object.invoice.is_locked" />

          <InvoiceAddressCard
            v-model="object.invoice.address"
            :invoiceId="object.invoice.id"
            :editable="!object.invoice.is_locked" />
        </div>
      </div>

      <InvoiceAndQuoteProductsCard
        v-model="object.invoice"
        type="invoice" />
        
      <InvoicePaymentsCard v-model="object.invoice" />

      <PdfCard :pdfData="invoicePdf" />
    </div>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <CreatedUpdatedAtCard :object="object" />

        <div class="card">
          <div class="card-body">
            <p>Marketplace: <strong><span v-if="object.marketplace_order_id">Commande {{ object.marketplace_order_id }} sur </span>{{ object.marketplace }}</strong></p>
            <p v-if="object.channable_id != null">
              ID Channable: <a :href="`https://app.channable.com/companies/71328/projects/186704/orders/${object.channable_id}`" tagret="_blank" class="btn-link">{{ object.channable_id }}</a>
            </p>
            <p v-if="object.woo_id != null">
              ID Woo: <a :href="`https://www.exterminationdenuisibles.be/wp-admin/post.php?post=${object.woo_id}&action=edit`" target="_blank" class="btn-link">{{ object.woo_id }}</a>
            </p>
            <p>Stockage source: <strong>{{ object.source_storage.name }}</strong></p>
            <p>Commentaires: <strong>{{ object.comments }}</strong></p>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">My Parcel</span>
          </div>
          <div class="card-body">
            <p>ID colis: <strong>{{ object.my_parcel_shipment_id }}</strong></p>
            <p>Status: <strong>{{ object.my_parcel_status }}</strong></p>
            <p>Code bar: <strong>{{ object.my_parcel_barcode }}</strong></p>
            <p>Etiquette: <strong>{{ object.my_parcel_shipment_label_url }}</strong></p>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Sendcloud</span>
          </div>
          <div class="card-body">
            <p>ID colis: <strong>{{ object.sendcloud_shipment_id }}</strong></p>
            <p>Status: <strong>{{ object.sendcloud_status }}</strong></p>
            <p>Numéro de tracking: <strong>{{ object.sendcloud_tracking_number }}</strong></p>
          </div>
        </div>

        <SaleInvoiceCard
          v-model="object.invoice"
          :saleId="object.id" />
      </div>
    </template>
  </ObjectViewLayout>
</template>
  
<script>
  
import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import SaleCustomerCard from "@/components/cards/customerCards/SaleCustomerCard.vue";
import SaleContactCard from "@/components/cards/contactCards/SaleContactCard.vue";
import SaleAddressCard from "@/components/cards/addressCards/SaleAddressCard.vue";
import SaleInvoiceCard from "@/components/cards/invoiceCards/SaleInvoiceCard.vue";
import SaleToolButtons from "@/components/toolbuttons/SaleToolButtons.vue";
import InvoiceAddressCard from "@/components/cards/addressCards/InvoiceAddressCard.vue";
import InvoiceContactCard from "@/components/cards/contactCards/InvoiceContactCard.vue";
import InvoicePaymentsCard from "@/components/cards/invoiceCards/InvoicePaymentsCard.vue";
import PdfCard from "@/components/cards/PdfCard.vue";
import InvoiceAndQuoteProductsCard from "@/components/cards/invoiceAndQuoteCards/InvoiceAndQuoteProductsCard.vue";
import CreatedUpdatedAtCard from "@/components/cards/generic/CreatedUpdatedAtCard.vue";

export default {
  name: "SaleV2ObjectView",
  components: {
    ObjectViewLayout,
    SaleCustomerCard,
    SaleContactCard,
    SaleAddressCard,
    SaleInvoiceCard,
    SaleToolButtons,
    InvoiceAddressCard,
    InvoiceContactCard,
    InvoicePaymentsCard,
    PdfCard,
    InvoiceAndQuoteProductsCard,
    CreatedUpdatedAtCard,
  },
  data() {
    return {
      object: null,
      errorFetching: false,
      schema: null,
      validationErrors: null,
      invoicePdf: null,
      savingProductsForm: false,
    };
  },
  computed: {
    isReturned() {
      if (!this.object) {
        return false;
      }
      return this.object.invoice.items.some((item) => item.quantity_returned > 0);
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorFetching = true;
      throw err;
    });
  },
  methods: {
    fetchObject() {
      this.$services.saleV2.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
          this.fetchInvoicePdf();
        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    fetchSchema() {
      return this.$services.sale.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchInvoicePdf() {
      return this.$services.invoice.fetchPdf(this.object.invoice.id)
        .then((response) => {
          this.invoicePdf = response.data;
        });
    },
    saveProductsForm() {
      this.savingProductsForm = true;
      this.validationErrors = null;

      const data = {
        items: this.object.invoice.items,
        discount_percent: this.object.invoice.discount_percent,
      };

      this.$services.invoice.patch(this.object.invoice.id, data)
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.validationErrors = error.response.data;
          } else {
            throw error;
          }

        }).finally(() => {
          this.savingProductsForm = false;
        });
    },
  },
};
  
</script>
  
<style lang="scss" scoped>

</style>