<template>
  <div style="margin: 10px;">
    <div class="row" style="margin-bottom: 20px;">
      <div
        v-for="(availableTechnician, index) in technicians"
        :key="index"
        class="col-sm-4">
        <div class="card clickable" style="margin-bottom: 10px;" :class="{ 'selected': technician && technician.id == availableTechnician.id}" @click="selectTechnician(availableTechnician)">
          <div class="card-header">
            <div class="text-muted" style="display: flex; align-items: center;">
              <i class="bi bi-wallet" style="font-size: 16px; margin-right: 5px;" />
              Porte-monnaie de {{ $filters.fullname(availableTechnician) }}
            </div>
          </div>
          <div class="card-body">
            <span v-if="totalIntoTechniciansWallet[availableTechnician.id] != null">
              {{ totalIntoTechniciansWallet[availableTechnician.id] / 100 }}€
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="technician" class="card" style="margin-bottom: 20px;">
      <div class="card-header">
        <div class="text-muted" style="display: flex; align-items: center;">
          <i class="bi bi-box-arrow-in-right" style="font-size: 16px; margin-right: 5px;" />
          Paiements en liquide reçus par {{ $filters.fullname(technician) }}
        </div>
      </div>
      <div class="card-body">
        <LocalList
          ref="cashPaymentList"
          :rowComponent="rowComponent"
          :load="loadCashPaymentsInTechnicianWallet"
          :columnsNames="['Montant', 'Facture', 'Technicien', 'Statut']" />
      </div>
    </div>
  </div>
</template>

<script>

import LocalList from "@/components/listV2/LocalList.vue";
import CashPaymentRow from "@/components/rows/cashTransaction/CashPaymentRow.vue";
import { shallowRef } from "vue";
import { useTechniciansStore } from "@/stores/technicians";

export default {
  name: "TechnicianWalletView",
  components: {
    LocalList,
  },
  data() {
    return {
      rowComponent: shallowRef(CashPaymentRow),
      technician: null,
      totalIntoTechniciansWallet: {},
      techniciansStore: useTechniciansStore(),
    };
  },
  computed: {
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
  },
  watch: {
    technicians() {
      this.refreshAmountsIntoTechniciansWallets();
    },
  },
  mounted() {
    this.refreshAmountsIntoTechniciansWallets();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    loadCashPaymentsInTechnicianWallet(page) {
      return this.$services.payment.listCashPaymentsInTechnicianWallet(this.technician.id);
    },
    refreshAmountsIntoTechniciansWallets() {
      if (this.technicians == null) {
        return;
      }

      this.totalIntoTechniciansWallet = {};

      for (const technician of this.technicians) {
        this.$services.user.totalIntoWallet(technician.id)
          .then((total) => {
            this.totalIntoTechniciansWallet[technician.id] = total;
          });
      }
    },
    selectTechnician(technician) {
      this.technician = null;
      
      setTimeout(() => {
        this.technician = technician;
      }, 100);
    },
  },
};

</script>


<style lang="scss" scoped>

  .card {
    &.selected {
      border: 2px solid #0d6efd;
    }
  }

</style>
  