<template>
  <div :class="{'extended': extendMenu}" class="navigation" @click="onClick">
    <div class="custom-menu" :class="{'collapsed': !extendMenu}">
      <router-link :to="{ name: 'dashboard' }" class="custom-menu-item" active-class="active">
        <i class="bi bi-house" />
        <span>Accueil</span>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsSecretary"
        :to="{ name: 'customer-call-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <i class="bi bi-telephone" />
        <span>Appels clients</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'customer-call-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des appels clients</span>
          </router-link>
          <router-link :to="{ name: 'create-customer-call' }" class="custom-menu-item">
            <i class="bi bi-telephone-plus" />
            <span>Enregistrer un appel client</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'customer-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <i class="bi bi-people" />
        <span>Clients</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'customer-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des clients</span>
          </router-link>
          <router-link :to="{ name: 'create-customer' }" class="custom-menu-item">
            <i class="bi bi-person-fill-add" />
            <span>Créer un client</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'intervention-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <i class="bi bi-briefcase" />
        <span>Interventions</span>
        <div v-if="nbTodoInterventions > 0" class="notification">
          {{ nbTodoInterventions }}
        </div>
        <div class="custom-menu">
          <router-link :to="{ name: 'intervention-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des interventions</span>
            <div v-if="nbTodoInterventions > 0" class="notification tooltip">
              {{ nbTodoInterventions }}
              <div class="tooltip-text">
                {{ nbTodoInterventions }} interventions à traiter
              </div>
            </div>
          </router-link>

          <router-link :to="{ name: 'create-intervention' }" class="custom-menu-item">
            <BriefcasePlusIcon />
            <span>Créer une intervention</span>
          </router-link>

          <router-link :to="{ name: 'intervention-calendar' }" class="custom-menu-item">
            <i class="bi bi-calendar4-week" />
            <span>Calendrier</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary || meIsShopManager"
        :to="{ name: 'sale-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <i class="bi bi-cart" />
        <span>Ventes</span>
        <div v-if="nbToShipSales > 0" class="notification">
          {{ nbToShipSales }}
        </div>
        <div class="custom-menu">
          <router-link :to="{ name: 'sale-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des ventes</span>
            <div v-if="nbToShipSales > 0" class="notification tooltip">
              {{ nbToShipSales }}
              <div class="tooltip-text">
                {{ nbToShipSales }} ventes à expédier
              </div>
            </div>
          </router-link>

          <router-link :to="{ name: 'create-sale' }" class="custom-menu-item">
            <i class="bi bi-cart-plus" />
            <span>Créer une vente</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'invoice-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <InvoiceIcon status="invoice" />
        <span>Factures</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'invoice-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des factures</span>
          </router-link>

          <router-link :to="{ name: 'create-invoice' }" class="custom-menu-item">
            <InvoiceIcon status="invoice" />
            <span>Créer une facture</span>
          </router-link>

          <router-link :to="{ name: 'payment-request-list' }" class="custom-menu-item">
            <i class="bi bi-envelope-exclamation" />
            <span>Rappels de paiements</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'quote-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <InvoiceIcon status="quote" />
        <span>Devis</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'quote-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des devis</span>
          </router-link>

          <router-link :to="{ name: 'create-quote' }" class="custom-menu-item">
            <InvoiceIcon status="quote" />
            <span>Créer un devis</span>
          </router-link>
        </div>
      </router-link>

      <router-link 
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'credit-note-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <InvoiceIcon status="creditNote" />
        <span>Notes de crédit</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'credit-note-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des notes de crédit</span>
          </router-link>

          <router-link :to="{ name: 'create-credit-note' }" class="custom-menu-item">
            <InvoiceIcon status="creditNote" />
            <span>Créer une note de crédit</span>
          </router-link>
        </div>
      </router-link>

      <router-link 
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'contract-list' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <InvoiceIcon status="contract" />
        <span>Contrats</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'contract-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste des contrats</span>
          </router-link>

          <router-link :to="{ name: 'create-contract' }" class="custom-menu-item">
            <InvoiceIcon status="contract" />
            <span>Créer un contrat</span>
          </router-link>
        </div>
      </router-link>

      <router-link 
        v-if="meIsAdmin || meIsManager || meIsTechnician || meIsSecretary"
        :to="{ name: 'stock' }"
        class="custom-menu-item with-menu"
        active-class="active">
        <i class="bi bi-boxes" />
        <span>Stock</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'product-list' }" class="custom-menu-item">
            <i class="bi bi-list-ul" />
            <span>Liste de produits</span>
          </router-link>

          <router-link :to="{ name: 'storage-list' }" class="custom-menu-item">
            <i class="bi bi-boxes" />
            <span>Espaces de stockage</span>
          </router-link>

          <router-link v-if="meIsAdmin || meIsManager" :to="{ name: 'create-product' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-box" />
            <span>Nouveau produit</span>
          </router-link>

          <router-link :to="{ name: 'scan-product' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-upc-scan" />
            <span>Scanner</span>
          </router-link>
        </div>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician"
        :to="{ name: 'staff-calendar' }"
        class="custom-menu-item"
        active-class="active">
        <i class="bi bi-calendar" />
        <span>Calendrier équipes</span>
      </router-link>

      <router-link v-if="meIsAdmin" :to="{ name: 'register' }" class="custom-menu-item with-menu" active-class="active">
        <i class="bi bi-cash-stack" />
        <span>Paiements en liquide</span>
        <div class="custom-menu">
          <router-link :to="{ name: 'register' }" class="custom-menu-item">
            <RegisterIcon />
            <span>Caisse</span>
          </router-link>

          <router-link :to="{ name: 'technician-wallet' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-wallet" />
            <span>Porte-monnaie technicien</span>
          </router-link>
        </div>
      </router-link>
    </div>

    <!-- Bottom icons extended -->

    <div class="custom-menu extended-bottom-menu" :class="{'collapsed': !extendMenu}">
      <router-link :to="{ name: 'stats' }" class="custom-menu-item" active-class="active">
        <i class="bi bi-graph-down" />
        <span>Statistiques</span>
      </router-link>

      <router-link v-if="meIsAdmin" :to="{ name: 'jobs' }" class="custom-menu-item" active-class="active">
        <i class="bi bi-terminal" />
        <span>Jobs</span>
      </router-link>

      <router-link
        v-if="meIsAdmin || meIsManager || meIsTechnician"
        :to="{ name: 'rewards' }"
        class="custom-menu-item"
        active-class="active">
        <i class="bi bi-piggy-bank" />
        <span>Bonus</span>
      </router-link>

      <router-link :to="{ name: 'my-provider-profil' }" class="custom-menu-item" active-class="active">
        <i class="bi bi-person-circle" />
        <span>Mon profil</span>
      </router-link>
    </div>

    <!-- Bottom icons collapsed -->

    <div class="custom-menu collapsed-bottom-menu" :class="{'collapsed': !extendMenu}">
      <div class="custom-menu-item with-menu">
        <i class="bi bi-three-dots-vertical" />
        <span>Plus...</span>
        <div class="custom-menu align-bottom">
          <router-link :to="{ name: 'stats' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-graph-down" />
            <span>Statistiques</span>
          </router-link>

          <router-link v-if="meIsAdmin" :to="{ name: 'jobs' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-terminal" />
            <span>Jobs</span>
          </router-link>

          <router-link
            v-if="meIsAdmin || meIsManager || meIsTechnician"
            :to="{ name: 'rewards' }"
            class="custom-menu-item"
            active-class="active">
            <i class="bi bi-piggy-bank" />
            <span>Bonus</span>
          </router-link>

          <router-link :to="{ name: 'my-provider-profil' }" class="custom-menu-item" active-class="active">
            <i class="bi bi-person-circle" />
            <span>Mon profil</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BriefcasePlusIcon from "@/assets/iconsVue/BriefcasePlusIcon.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import RegisterIcon from "@/assets/iconsVue/RegisterIcon.vue";
import { useMeStore } from "@/stores/me";
import { useInterventionListStore } from "@/stores/interventionList";
import { useSaleListStore } from "@/stores/saleList";

export default {
  name: "NavigationBar",
  components: {
    BriefcasePlusIcon,
    InvoiceIcon,
    RegisterIcon,
  },
  data() {
    return {
      extendMenu: false,
      meStore: useMeStore(),
      interventionListStore: useInterventionListStore(),
      saleListStore: useSaleListStore(),
    };
  },
  computed: {
    meIsAdmin() {
      return this.meStore.meIsAdmin;
    },
    meIsManager() {
      return this.meStore.meIsManager;
    },
    meIsTechnician() {
      return this.meStore.meIsTechnician;
    },
    meIsSecretary() {
      return this.meStore.meIsSecretary;
    },
    meIsShopManager() {
      return this.meStore.meIsShopManager;
    },
    nbTodoInterventions() {
      return this.interventionListStore.getNbInterventionTodo();
    },
    nbToShipSales() {
      return this.saleListStore.getNbSaleToShip();
    },
  },
  methods: {
    toggle() {
      this.extendMenu = !this.extendMenu;
    },
    onClick() {
      if (window.innerWidth < 700) {
        this.extendMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  $height-breakpoint: 800px;

  .navigation {

    // Color
    background: $primary;
    color: rgb(230, 230, 230);

    // Size on desktop
    @media(min-width: $mobile-layout-horizontal-breakpoint) {
      width: 50px;
      max-width: 50px;
      min-width: 50px;

      @media(max-height: $height-breakpoint) {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
      }

      &.extended {
        width: 190px;
        max-width: 190px;
        min-width: 190px;

        @media(max-height: $height-breakpoint) {
          width: 185px;
          max-width: 185px;
          min-width: 185px;
        }
      }
    }

    // Size on mobile
    @media(max-width: $mobile-layout-horizontal-breakpoint) {
      width: 0px;
      overflow: hidden;

      &.extended {
        @media(max-width: $mobile-layout-horizontal-breakpoint) {
          width: 100%;
        }
      }
    }

    // Animation
    transition: 0.5s ease-in-out;

    // Layout
    display: flex;
    flex-direction: column;

    .extended-bottom-menu {
      margin-top: auto;

      @media(max-height: 800px) {
        @media(min-width: $mobile-layout-horizontal-breakpoint) {
          display: none;
        }
      }
    }

    .collapsed-bottom-menu {
      margin-top: auto;
      
      
      @media(min-height: 800px) {
        display: none;
      }

      @media(max-width: $mobile-layout-horizontal-breakpoint) {
        display: none;
      }
    }    
  }

</style>
