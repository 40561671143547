<template>
  <tr class="my-list-row selectable">
    <td>
      <span v-if="object.contact.email == 'anonymous@customer.com'" class="text-bigger mr-2">{{ object.total_incl_tax }}€ TTC pour un client particulier</span>
      <span v-else class="text-bigger mr-2">{{ object.total_incl_tax }}€ TTC pour {{ $filters.fullname(object.contact) }}</span>
      <span class="text-smaller">{{ $filters.dateStringToHumanizedDate(object.created_at) }}</span>
    </td>
  </tr>
</template>

<script>

export default {
  name: "InvoiceResultRow",
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>
