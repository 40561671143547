<template>
  <div
    class="month-selector">
    <button
      class="month-selector-btn"
      @click="previous">
      <i class="bi bi-arrow-left" />
    </button>
    <div style="min-width: 200px; text-align: center;">
      {{ $filters.capitalize(text) }}
    </div>
    <button
      class="month-selector-btn"
      @click="next">
      <i class="bi bi-arrow-right" />
    </button>
  </div>
</template>
  
<script>

export default {
  name: "CalendarDateSelector",
  props: {
    modelValue: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
    },
    mode: {
      type: String,
      required: false,
      default: "day",
      validator: (value) => {
        return ["day", "week", "month"].includes(value);
      },
      
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalDate: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    text() {
      if (this.mode == "day") {
        return new Date(this.internalDate).toLocaleDateString("fr-Fr", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else if (this.mode == "week") {
        const date = new Date(this.internalDate);
        const day = date.getDay();
        date.setDate(date.getDate() - day + 1);
        const start = date.toLocaleDateString("fr-Fr", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        date.setDate(date.getDate() + 6);
        const end = date.toLocaleDateString("fr-Fr", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return `${start} - ${end}`;
      } else if (this.mode == "month") {
        return new Date(this.internalDate).toLocaleDateString("fr-Fr", {
          year: "numeric",
          month: "long",
        });
      } else {
        return "Error";
      }
    },
  },
  watch: {
    mode(newVal) {
      if (newVal == "week") {
        const date = new Date(this.internalDate);
        const day = date.getDay();
        date.setDate(date.getDate() - day + 1);
        this.internalDate = date.toISOString().substr(0, 10);
      } else if (newVal == "month") {
        const date = new Date(this.internalDate);
        date.setDate(1);
        this.internalDate = date.toISOString().substr(0, 10);
      }
    },
  },
  methods: {
    previous() {
      if (this.mode == "day") {
        const date = new Date(this.internalDate);
        date.setDate(date.getDate() - 1);
        this.internalDate = date.toISOString().substr(0, 10);
      } else if (this.mode == "week") {
        const date = new Date(this.internalDate);
        date.setDate(date.getDate() - 7);
        this.internalDate = date.toISOString().substr(0, 10);
      } else if (this.mode == "month") {
        const date = new Date(this.internalDate);
        date.setMonth(date.getMonth() - 1);
        this.internalDate = date.toISOString().substr(0, 10);
      }
    },
    next() {
      if (this.mode == "day") {
        const date = new Date(this.internalDate);
        date.setDate(date.getDate() + 1);
        this.internalDate = date.toISOString().substr(0, 10);
      } else if (this.mode == "week") {
        const date = new Date(this.internalDate);
        date.setDate(date.getDate() + 7);
        this.internalDate = date.toISOString().substr(0, 10);
      } else if (this.mode == "month") {
        const date = new Date(this.internalDate);
        date.setMonth(date.getMonth() + 1);
        this.internalDate = date.toISOString().substr(0, 10);
      }
    },
  },
  
};
  
</script>
  
  <style lang="scss" scoped>
  
    .month-selector {
      display: flex;
      align-items: center;

      .month-selector-btn {
        background: transparent;
        border: none;
        padding: 5px;
        outline: none;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;

        svg {
          width: 30px;
          height: 30px;
        }

        i {
          font-size: 2rem;
        }

        color: rgb(100, 100, 100);

        svg, i {
          color: rgb(100, 100, 100);
        }

        &:hover {
          color: black;
          
          svg, i {
            color: black;
          }
        }
      }
    }
  
  </style>