<template>
  <div class="level-selector">
    <div class="level-arrow" @click="selectLevel('previous')">
      <i class="bi bi-caret-left" />
    </div>
    <div class="selected-level">
      {{ selectedLevelToDisplay }}
    </div>
    <div class="level-arrow" @click="selectLevel('next')">
      <i class="bi bi-caret-right" />
    </div>
  </div>
</template>

<script>

export default {
  name: "InfestationLevelSelector",
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      levels: ["none", "low", "medium", "high"],
      levelsToDisplay: ["Aucune", "Faible", "Modérée", "Forte"],
      internalValue: this.modelValue != null ? this.modelValue : this.value != null ? this.value : "none",
    };
  },
  computed: {
    selectedLevelToDisplay() {
      const currentIndex = this.levels.indexOf(this.internalValue);
      return this.levelsToDisplay[currentIndex];
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit("update:modelValue", newVal);
      this.$emit("change", newVal);
    },
  },
  methods: {
    selectLevel(direction) {
      const currentIndex = this.levels.indexOf(this.internalValue);
      let newIndex;

      if (direction === "previous") {
        newIndex = (currentIndex - 1 + this.levels.length) % this.levels.length;
      } else if (direction === "next") {
        newIndex = (currentIndex + 1) % this.levels.length;
      }

      this.internalValue = this.levels[newIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
  .level-selector {
    display: flex;
    align-items: center;
  }

  .level-arrow {
    cursor: pointer;
    font-size: 20px;
  }

  .selected-level {
    width: 50px;
    text-align: center;
  }

</style>
