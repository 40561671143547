import { buildPaginatedMultiListStore } from "./utils/storeBuilders";

export const useProductListStore = buildPaginatedMultiListStore(
  "productList",
  "/products/",
  "Aucun produit trouvé",
  false,
  (filters: object, subList: string) => {
    if (subList == "grid") {
      filters.limit = 100;
    } else {
      filters.type = subList;
    }
    return filters;
  },
);
