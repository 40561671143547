<template>
  <GBaseRow
    clickable
    @click="click">
    <template #icon>
      <InvoiceIcon
        status="creditNote"
        width="30px"
        height="30px" />
    </template>

    <template #title>
      <span class="big">{{ creditNote.ref }}</span>
    </template>

    <template #default>
      <td>
        <span>{{ $filters.dateStringToHumanizedDate(creditNote.created_at) }}</span>
      </td>

      <td>
        <router-link v-if="creditNote.invoice" class="btn-link" :to="{ name: 'invoice', params: { 'id': creditNote.invoice.id } }" @click.stop>
          {{ creditNote.invoice.ref }}
        </router-link>
        <span v-else-if="creditNote.invoice_ref">
          {{ creditNote.invoice_ref }}
        </span>
      </td>

      <td>
        {{ $filters.fullname(creditNote.author) }}
      </td>

      <td>
        <router-link v-if="creditNote.invoice?.customer" class="btn-link" :to="{ name: 'customer', params: { 'id': creditNote.invoice.customer.id } }" @click.stop>
          {{ $filters.printCustomer(creditNote.invoice.customer) }}
        </router-link>
        <span v-else-if="creditNote.contact">
          {{ $filters.fullname(creditNote.contact) }}
        </span>
      </td>

      <td class="small">
        <span v-if="creditNote.address">
          {{ creditNote.address.street_number }} {{ creditNote.address.street }}<br>
          {{ creditNote.address.zipcode }} {{ creditNote.address.city }} {{ creditNote.address.country_code }}
        </span>
      </td>

      <td>
        {{ creditNote.amount }}€ HT
      </td>
      
      <td>
        <CheckIcon v-if="creditNote.send_date != null" class="text-green" />
      </td>

      <td>
        <div v-if="creditNote.odoo_error_message" class="highlighted red tooltip">
          Erreur
          <span class="tooltip-text">
            {{ creditNote.odoo_error_message }}
          </span>
        </div>
        <div v-else-if="creditNote.odoo_id != null" class="highlighted green">
          Ok
        </div>
      </td>
    </template>

    <template #toolButtons>
      <Toolbar>
        <template #default>
          <CreditNoteToolButtons v-model="creditNote" />
        </template>
      </Toolbar>
    </template>
  </GBaseRow>
</template>

<script>

import CheckIcon from "@/assets/iconsVue/CheckIcon.vue";
import CreditNoteToolButtons from "@/components/toolbuttons/CreditNoteToolButtons.vue";
import {GBaseRow} from "g-list";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "CreditNoteRow",
  components: {
    InvoiceIcon,
    CheckIcon,
    CreditNoteToolButtons,
    GBaseRow,
    Toolbar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    creditNote: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "credit-note", params: { "id": this.creditNote.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>