<template>
  <button
    class="tool-btn tooltip"
    @click.stop="exportPDF">
    <Loader v-if="fetchingPDF" />
    <i v-else class="bi bi-filetype-pdf" />
    <div class="tooltip-text">
      Télécharger
    </div>
  </button>
</template>

<script>

import Loader from "@/components/Loader.vue";

export default {
  name: "CreditNoteToolButtons",
  components: {
    Loader,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchingPDF: false,
    };
  },
  methods: {
    exportPDF() {
      this.fetchingPDF = true;

      this.$services.creditNote.fetchPdf(this.modelValue.id)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          var filename = this.modelValue.ref + ".pdf";

          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          
        }).finally(() => {
          this.fetchingPDF = false;
        });
    },    
  },
};

</script>

<style>

</style>