import { api } from "@/api";
import ModelApiService from "../utils/modelApiService";
import { notifySuccess, notifyError } from "@/notifications";
import { AxiosResponse } from "axios";

class CustomerApiService extends ModelApiService {
  constructor() {
    super("/customers/", "client");
  }

  sendMessage(id: number, subject: string, message: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/send_email/",
        {subject: subject, message: message},
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Email envoyé");
          resolve(response);
        }).catch((error: Error) => {
          notifyError("Erreur lors de l'envoi de l'email");
          reject(error);
        });
    });
  }

  createEvent(id: number, description: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/events/",
        {
          description: description,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Evènement créé");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
  
  addComment(id: number, comment: string) {
    return new Promise((resolve, reject) => {
      api.post(
        this.url + id + "/comment/",
        {
          comment: comment,
        },
      )
        .then((response: AxiosResponse<any>) => {
          notifySuccess("Commentaire ajouté");
          resolve(response);
        }).catch((error: Error) => {
          reject(error);
        });
    });
  }
}

export default CustomerApiService;