<template>
  <Modal ref="modal">
    <div class="form-group">
      <label class="form-label">Pourquoi mettre en attente l'intervention ? </label>
      <div v-if="snoozeReasonChoices.length > 0" class="flow-spaced-container" style="align-items: center; justify-content: center;">
        <button
          v-for="reason of snoozeReasonChoices"
          :key="reason.id"
          :class="{ 'active': selectedSnoozeReasonChoice != null && selectedSnoozeReasonChoice.id == reason.id }"
          class="btn-secondary"
          @click="selectSnoozeReasonChoice(reason)">
          {{ reason.description }}
        </button>
      </div>
      <div v-else class="flow-spaced-container text-muted" style="align-items: center; justify-content: center;">
        <i>Rendez-vous sur le dashboard administrateur pour configurer des choix de raisons de mise en attente</i>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Tache à efféctuer plus tard</label>
      <div class="flow-spaced-container" style="align-items: center; justify-content: center;">
        <button
          :class="{ 'active': action == 'call' }"
          class="tool-btn tooltip"
          @click="action = 'call'">
          <i class="bi bi-telephone" />
          <div class="tooltip-text">
            Appeler le client
          </div>
        </button>

        <button
          :class="{ 'active': action == 'sms' }"
          class="tool-btn tooltip"
          @click="action = 'sms'">
          <i class="bi bi-chat-dots" />
          <div class="tooltip-text">
            Envoyer un SMS
          </div>
        </button>

        <button
          :class="{ 'active': action == 'email' }"
          class="tool-btn tooltip"
          @click="action = 'email'">
          <i class="bi bi-envelope-at" />
          <div class="tooltip-text">
            Envoyer un email au client
          </div>
        </button>

        <button
          :class="{ 'active': action == 'rdv' }"
          class="tool-btn tooltip"
          @click="action = 'rdv'">
          <i class="bi bi-calendar" />
          <div class="tooltip-text">
            Fixer un rendez-vous
          </div>
        </button>

        <button
          :class="{ 'active': action == 'close' }"
          class="tool-btn tooltip"
          @click="action = 'close'">
          <i class="bi bi-check-circle" />
          <div class="tooltip-text">
            Cloturer l'intervention
          </div>
        </button>

        <button
          :class="{ 'active': action == 'cancel' }"
          class="tool-btn tooltip"
          @click="action = 'cancel'">
          <i class="bi bi-x-circle" />
          <div class="tooltip-text">
            Annuler l'intervention
          </div>
        </button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Echéance</label>

      <div class="flow-spaced-container" style="margin-bottom: 10px;">
        <button
          class="btn-secondary"
          :class="{ 'active': deadline == tomorrowDateISO }"
          @click="delayToTomorrow">
          Demain
        </button>

        <button
          class="btn-secondary"
          :class="{ 'active': deadline == afterTomorrowDateISO }"
          @click="delayToAfterTomorrow">
          Après-demain
        </button>
        <button
          class="btn-secondary"
          :class="{ 'active': deadline == nextMondayISO }"
          @click="delayToNextWeek">
          Lundi prochain
        </button>
        <button
          class="btn-secondary"
          :class="{ 'active': deadline == firstDayOfNextMonthISO }"
          @click="delayToNextMonth">
          Début du mois prochain
        </button>
        <button
          class="btn-secondary"
          :class="{ 'active': deadline == in30DaysISO }"
          @click="delay30Days">
          Dans 30 jours
        </button>
      </div>

      <Datepicker
        v-model="deadline"
        :format="dateToHumanized"
        :enable-time-picker="false"
        :clearable="false"
        locale="fr"
        cancelText=""
        selectText="Valider"
        :teleport-center="isMobile" />
    </div>

    <div class="form-group">
      <label class="form-label">Assigné à</label>

      <div v-if="modelValue.technician" class="form-check" style="margin-bottom: 0.5rem;">
        <input
          :checked="assignedAt == null"
          class="form-check-input"
          type="radio"
          disabled
          @change="assignedAt = null">

        <label class="form-check-label">
          Le technicien de l'intervention : {{ $filters.fullname(modelValue.technician) }}
        </label>
      </div>

      <div class="form-check">
        <input
          disabled
          :checked="assignedAt != null"
          class="form-check-input"
          type="radio">

        <SelectObject
          type="user"
          :selectedValue="assignedAt ? $filters.fullname(assignedAt) : null"
          @select="onNewAssignedAt"
          @unselect="assignedAt = null" />
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Commentaire</label>
      <textarea
        v-model="comment"
        maxlength="255"
        class="form-control" />
    </div>
      
    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        style="margin-left: 5px;"
        :disabled="submitting || !canSubmit"
        :class="{ 'loading': submitting }"
        @click="submit">
        Reporter
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useInterventionSnoozeReasonChoicesStore } from "@/stores/interventionSnoozeReasonChoices";
import SelectObject from "@/components/inputs/SelectObject.vue";

export default {
  name: "AddTaskToInterventionModal",
  components: {
    Modal,
    Datepicker,
    SelectObject,
  },
  props: {
    modelValue: { // The intervention
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      action: null,
      deadline: null,
      assignedAt: null,
      comment: null,
      submitting: false,
      interventionSnoozeReasonChoicesStore: useInterventionSnoozeReasonChoicesStore(),
      selectedSnoozeReasonChoice: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 500;
    },
    canSubmit() {
      return this.action != null && this.deadline != null;
    },
    tomorrowDateISO() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      // Get the date in ISO 8601 format
      const tomorrowISO = tomorrow.toISOString().split("T")[0];

      return tomorrowISO;
    },
    afterTomorrowDateISO() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 2);

      // Get the date in ISO 8601 format
      const tomorrowISO = tomorrow.toISOString().split("T")[0];

      return tomorrowISO;
    },
    in30DaysISO() {
      const today = new Date();
      const in30Days = new Date(today);
      in30Days.setDate(today.getDate() + 30);

      // Get the date in ISO 8601 format
      const in30DaysISO = in30Days.toISOString().split("T")[0];

      return in30DaysISO;
    },
    nextMondayISO() {
      const aujourdHui = new Date();
  
      // Obtenir le jour de la semaine (0 = dimanche, 1 = lundi, ..., 6 = samedi)
      const jourSemaine = aujourdHui.getDay();
  
      // Calculer le nombre de jours restants jusqu'au prochain lundi
      const joursRestants = 8 - jourSemaine; // 8 jours pour inclure le lundi de la semaine prochaine
  
      // Créer la date du prochain lundi
      const prochainLundi = new Date(aujourdHui);
      prochainLundi.setDate(aujourdHui.getDate() + joursRestants);

      // Obtient la date au format ISO 8601
      const prochainLundiISO = prochainLundi.toISOString().split("T")[0];

      return prochainLundiISO;
    },
    firstDayOfNextMonthISO() {
      const today = new Date();
  
      // Obtenir le mois actuel
      const currentMonth = today.getMonth();

      // Passer au mois suivant
      const nextMonth = new Date(today);
      nextMonth.setMonth(currentMonth + 1);

      // Définir le jour au 1er jour du mois
      nextMonth.setDate(1);

      // Obtenir la date au format ISO 8601
      const firstDayOfNextMonthISO = nextMonth.toISOString().split("T")[0] + "T00:00:00";

      return firstDayOfNextMonthISO;
    },
    snoozeReasonChoices() {
      return this.interventionSnoozeReasonChoicesStore.get;
    },
  },
  methods: {
    clear() {
      this.action = null;
      this.deadline = null;
      this.comment = null;
      this.assignedAt = null;
      this.submitting = false;
    },
    show(action = null) {
      this.clear();
      this.action = action;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.submitting = true;
      this.$services.intervention.addTask(this.modelValue.id, this.action, this.deadline, this.assignedAt, this.comment)
        .then((response) => {
          this.hide();
          this.submitting = false;
          this.$emit(
            "update:modelValue",
            {
              ...this.modelValue,
              tasks: response.data.tasks,
            },
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    dateToHumanized(date) {
      return date.toLocaleDateString("fr-FR", { weekday: "short", month: "short", day: "numeric" });
    },
    delayToTomorrow() {
      this.deadline = this.tomorrowDateISO;
    },
    delayToAfterTomorrow() {
      this.deadline = this.afterTomorrowDateISO;
    },
    delayToNextWeek() {
      this.deadline = this.nextMondayISO;
    },
    delayToNextMonth() {
      this.deadline = this.firstDayOfNextMonthISO;
    },
    delay30Days() {
      this.deadline = this.in30DaysISO;
    },
    delay(nbOfDays) {
      const today = new Date();
      const deadline = new Date(today);
      deadline.setDate(today.getDate() + nbOfDays);
      this.deadline = deadline.toISOString().split("T")[0];
    },
    selectSnoozeReasonChoice(reason) {
      this.selectedSnoozeReasonChoice = reason;
      this.action = reason.action;
      this.delay(reason.delay);
    },
    onNewAssignedAt(user) {
      this.assignedAt = user;
    },
  },
};

</script>

<style lang="scss" scoped>

  .tool-btn {
    &.active {

      i {
        color: $primary;
        font-size: 3rem;
      }
    }
  }


</style>