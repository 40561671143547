import { api, encode } from "@/api";

export default class StatsApiService {

  turnover(frequency: string, technicianId: number | string | null = null, groupBy: string | null = null) : Promise<object> {
    const args = {
      frequency: frequency,
      technician_id: technicianId ? technicianId : null,
      group_by: groupBy,
    };
    
    return api.get(`/stats/turnover/?${encode(args)}`);
  }

}
