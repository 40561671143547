<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round">
    <rect 
      x="2"
      y="7"
      width="20"
      height="14"
      rx="2"
      ry="2" />
    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    <g v-if="cancelled">
      <ellipse
        ry="5.21398"
        rx="5.21398"
        cy="16"
        cx="17"
        stroke="#000"
        fill="#ff0000" />
      <line
        fill="none"
        y2="18.08801"
        x2="18.87161"
        y1="13.98915"
        x1="14.95359" />
      <line
        transform="rotate(-90.5073, 16.9729, 16.0386)"
        fill="none"
        y2="18.08801"
        x2="18.93189"
        y1="13.98915"
        x1="15.01387" />
    </g>
    <g v-if="validated">
      <ellipse
        ry="5.21398"
        rx="5.21398"
        cy="16"
        cx="17"
        stroke="#000"
        fill="#00ff00" />
      <line
        fill="none"
        y2="18.20856"
        x2="16.64135"
        y1="15.1947"
        x1="14.59192" />
      <line
        transform="rotate(-87.1468, 18.962, 16.0687)"
        fill="none"
        y2="17.97507"
        x2="20.9751"
        y1="14.16236"
        x1="16.94896" />
    </g>
  </svg>
</template>

<script>

export default {
  name: "BriefcaseIcon",
  props: {
    cancelled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};

</script>

<style>

</style>