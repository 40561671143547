<template>
  <div
    class="month-selector">
    <button
      class="tool-btn"
      @click="previous">
      <ArrowLeftIcon />
    </button>
    <span>{{ $filters.capitalize(monthName) }}</span>
    <button
      class="tool-btn"
      @click="next">
      <ArrowRightIcon />
    </button>
  </div>
</template>
  
<script>

import ArrowLeftIcon from "@/assets/iconsVue/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/assets/iconsVue/ArrowRightIcon.vue";

export default {
  name: "MonthSelector",
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  emits: ["change"],
  data() {
    return {
      date: new Date(),
    };
  },
  computed: {
    monthName() {
      return this.date.toLocaleString("fr", { month: "long" });
    },
  },
  watch: {
    date(newVal) {
      this.$emit("change", {year: newVal.getFullYear(), month: newVal.getMonth() + 1});
    },
  },
  methods: {
    previous() {
      const date = new Date(this.date);
      date.setMonth(this.date.getMonth() - 1);
      this.date = date;
    },
    next() {
      const date = new Date(this.date);
      date.setMonth(this.date.getMonth() + 1);
      this.date = date;
    },
  },
  
};
  
</script>
  
  <style lang="scss" scoped>
  
    .month-selector {
      display: flex;
      align-items: center;
    }
  
  </style>