<template>
  <button
    class="tool-btn tooltip"
    :disabled="modelValue.main_email == null"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un message par email
      </div>
      <div
        v-if="modelValue.main_email == null"
        class="tooltip-text-red">
        Aucune adresse email renseignée
      </div>
    </div>
    <SendEmailToCustomerModal v-if="modelValue.customer" ref="sendEmailModal" :customer="modelValue.customer" />
  </button>
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";

export default {
  name: "ContractToolButtons",
  components: {
    SendEmailToCustomerModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    clone() {
      this.$router.push({ name: "create-contract", query: { contract: this.modelValue.id } });      
    },
  },
};

</script>

<style>

</style>