<template>
  <div class="main-wrapper">
    <TopBar class="top-bar" @toggle="$refs.navigation.toggle()" />

    <NavigationBar
      ref="navigation"
      class="navigation" />

    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>
  
<script>
  
import NavigationBar from "@/components/layout/NavigationBar.vue";
import TopBar from "@/components/layout/TopBar.vue";
  
export default {
  name: "NavigationLayout",
  components: {
    NavigationBar,
    TopBar,
  },
};
</script>
  
<style lang="scss" scoped>

$top-bar-height: 50px;
  
.main-wrapper {

  // Position
  position: relative;

  // Size
  height: 100vh;

  // Layout
  display: flex;
  padding-top: $top-bar-height; // Leave space for the top bar
  align-items: stretch;
  
  .top-bar {

    // Position : Top bar is fixed to top
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    // Size
    height: $top-bar-height;
    max-height: $top-bar-height; // Fix height of top bar so every other components can adjust their height
    width: 100%;
  }

  .navigation {

    // Set fixed but only on mobiles
    @media(max-width: $mobile-layout-horizontal-breakpoint) {
      position: fixed;
      height: calc(100% - $top-bar-height);
      z-index: 4;
    }
  }
  
  .content-wrapper {
    transition: 0.5s;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    position: relative;

    @media(max-width: $mobile-layout-horizontal-breakpoint) {
      padding-bottom: 60px; // Add padding on bottom on mobile, because some mobile have their search bar on the bottom and it can hide the content
    }

  }
}

</style>
  