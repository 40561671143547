<template>
  <div style="flex: 1; display: flex; flex-direction: column;">
    <PlanningDate :date="date" showMonth @click="onDateSelected" />
    
    <div style="flex: 1; display: flex; border: 1px solid #e5e5e5;">
      <UserDayPlanning
        v-for="(user, index) in users"
        :key="index"
        :style="{ 'flex-grow': selectedUserId == null || selectedUserId == user.id ? '1' : '0'}"
        :user="user"
        :events="getEventsForUser(user)"
        :date="date"
        showUser
        showTimeScale />
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed } from "vue";
import UserDayPlanning from "@/components/calendar/UserDayPlanning.vue";
import PlanningDate from "@/components/calendar/PlanningDate.vue";
import { useTechniciansStore } from "@/stores/technicians";
import { useRouter } from "vue-router";

// Props

// eslint-disable-next-line no-undef
const props = defineProps({
  date: { type: String, required: true },
  events: { type: Array<any>, required: false, default: [] },
});

// Emits

// eslint-disable-next-line no-undef
const emits = defineEmits(["dateSelected"]);

// Data

const techniciansStore = useTechniciansStore();
const router = useRouter();


// Computed

const users = computed(
  function get() {
    return techniciansStore.getTechnicians;
  },
);

const selectedUserId = computed(
  function get() {
    const id = router.currentRoute.value.query.user;

    if (id == null) {
      return null;
    } else {
      return parseInt(id);
    }
  },
);

// Methods

function getEventsForUser(user: any) {
  return props.events.filter((event) => event.user.id == user.id);
}

function onDateSelected() {
  emits("dateSelected");
}


</script>

<style lang="scss" scoped>

</style>