<template>
  <div class="card">
    <div class="card-header">
      <span class="title" style="margin-right: 10px;">
        Evolution du chiffre d'affaire
      </span>

      <button
        class="tool-btn"
        @click="showInfo">
        <i class="bi bi-info-circle" />
      </button>
    </div>

    <div class="card-body">
      <div class="flow-spaced-container">
        <div class="filter-item">
          <div class="filter-item-title">
            Fréquence
          </div>

          <select
            v-model="frequency"
            class="form-select"
            style="width: auto;">
            <option value="monthly">
              Mensuel
            </option>
            <option value="weekly">
              Hebdomadaire
            </option>
            <option value="daily">
              Journalier
            </option>
          </select>
        </div>

        <div class="filter-item">
          <div class="filter-item-title">
            Technicien
          </div>

          <TechnicianSelect
            v-model="technicianId"
            allOption />
        </div>
      </div>

      <div class="cat-list">
        <span
          class="cat-list-item"
          :class="{ 'active': selectedData == 'total' }"
          style="cursor: pointer"
          @click="(selectedData = 'total')">
          Tout confondu
        </span>

        <span
          class="cat-list-item"
          :class="{ 'active': selectedData == 'per_origin' }"
          style="cursor: pointer"
          @click="(selectedData = 'per_origin')">
          Par origine
        </span>

        <span
          class="cat-list-item"
          :class="{ 'active': selectedData == 'per_activity' }"
          style="cursor: pointer"
          @click="(selectedData = 'per_activity')">
          Par activité
        </span>
      </div>

      <div class="cat-list">
        <span
          class="cat-list-item"
          :class="{ 'active': selectedChart == 'bar' }"
          style="cursor: pointer"
          @click="(selectedChart = 'bar')">
          Bar
        </span>
        <span
          class="cat-list-item"
          :class="{ 'active': selectedChart == 'line' }"
          style="cursor: pointer"
          @click="(selectedChart = 'line')">
          Ligne
        </span>
      </div>

      <div v-if="selectedData == 'total' && selectedChart == 'line'" style="height: 500px;">
        <Line
          v-if="chartDataTotalForLine"
          :data="chartDataTotalForLine"
          :options="lineChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>

      <div v-if="selectedData == 'total' && selectedChart == 'bar'" style="height: 500px;">
        <Bar
          v-if="chartDataTotalForBar"
          :data="chartDataTotalForBar"
          :options="barChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>

      <div v-if="selectedData == 'per_origin' && selectedChart == 'line'" style="height: 500px;">
        <Line
          v-if="chartDataPerOriginForLine"
          :data="chartDataPerOriginForLine"
          :options="lineChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>

      <div v-if="selectedData == 'per_origin' && selectedChart == 'bar'" style="height: 500px;">
        <Bar
          v-if="chartDataPerOriginForBar"
          :data="chartDataPerOriginForBar"
          :options="barChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>

      <div v-if="selectedData == 'per_activity' && selectedChart == 'line'" style="height: 500px;">
        <Line
          v-if="chartDataPerActivityForLine"
          :data="chartDataPerActivityForLine"
          :options="lineChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>

      <div v-if="selectedData == 'per_activity' && selectedChart == 'bar'" style="height: 500px;">
        <Bar
          v-if="chartDataPerActivityForBar"
          :data="chartDataPerActivityForBar"
          :options="barChartOptions" />

        <LoaderView v-else style="height: 100%;" />
      </div>
    </div>
  </div>

  <InfoModal ref="infoModal" />
</template>

<script>

import InfoModal from "@/components/modals/InfoModal.vue";
import LoaderView from "@/components/LoaderView.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "vue-chartjs";
import { Line } from "vue-chartjs";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PointElement,
  LineElement,
);

export default {
  name: "TurnoverCharts",
  components: {
    Bar,
    Line,
    TechnicianSelect,
    InfoModal,
    LoaderView,

  },
  data() {
    return {
      selectedChart: "line",
      selectedData: "total",
      data: null,
      dataPerOrigin: null,
      dataPerActivity: null,
      loadingData: false,
      colorIndex: 0,
      frequency: "monthly",
      technicianId: null,
    };
  },
  computed: {
    info() {
      return "<p>Ces graphiques représentent l'évolution du Chiffre d'affaire.</p><p>Pour une période donnée, le chiffre d'affaire est calculé en faisant la somme du montant HT - montant note de crédit de toutes les factures créées pendant cette période.</p><p>Cela ne représente donc pas le chiffre d'affaire perçu, car une facture peut être payée bien plus tard que la date de création de la facture. C'est plutot le montant collectable ou collecté.</p><p>Pour le graphique par activité, notez qu'une facture peut avor plusieurs activitées et donc apparaitre dans plusieurs catégories. C'est donc normal que la somme des courbes de chaque activité ne corresponde pas à la courbe toute activité confondu.</p><p>Tip : Cliquez sur les libellé des courbes pour les masquer / afficher.</p>";
    },
    lineChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true, // Afficher l'axe des ordonnées
            title: {
              display: true,
              text: "CA (€)",
            },
            beginAtZero: true,
          },
        },
      };
    },
    barChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true, // Empiler les barres horizontalement
          },
          y: {
            stacked: true, // Empiler les barres verticalement
            beginAtZero: true,
            title: {
              display: true,
              text: "CA (€)",
            },
          },
        },
      };
    },
    chartDataTotalForLine() {
      if (!this.data) {
        return null;
      }

      const datasets = [];
      const color = this.getColor();

      datasets.push({
        label: "Cette année",
        backgroundColor: color,
        data: this.data.datasets.now,
        borderColor: color, // Définir la couleur de ligne ici
        fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
      });

      datasets.push({
        label: "L'année dernière",
        backgroundColor: color,
        data: this.data.datasets.last_year,
        borderColor: color, // Définir la couleur de ligne ici
        fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
        borderDash: [5, 5], // Define dash pattern here
      });

      return {
        labels: this.data.labels,
        datasets: datasets,
      };
    },
    chartDataTotalForBar() {
      if (!this.data) {
        return null;
      }

      const datasets = [];
      const color = this.getColor();

      datasets.push({
        label: "Cette année",
        backgroundColor: color,
        data: this.data.datasets.now,
        borderColor: color, // Définir la couleur de ligne ici
        fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
      });

      return {
        labels: this.data.labels,
        datasets: datasets,
      };
    },
    chartDataPerOriginForLine() {
      if (!this.dataPerOrigin) {
        return null;
      }

      const datasets = [];

      for (const [origineCode, origineData] of Object.entries(this.dataPerOrigin.datasets)) {
        const color = this.getColor();

        datasets.push({
          label: origineCode,
          backgroundColor: color,
          data: origineData.now,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
        });

        datasets.push({
          label: `${origineCode} l'an dernier`,
          backgroundColor: color,
          data: origineData.last_year,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
          borderDash: [5, 5], // Define dash pattern here
        });
      }
      
      return {
        labels: this.dataPerOrigin.labels,
        datasets: datasets,
      };
    },
    chartDataPerOriginForBar() {
      if (!this.dataPerOrigin) {
        return null;
      }

      const datasets = [];

      for (const [origineCode, origineData] of Object.entries(this.dataPerOrigin.datasets)) {
        const color = this.getColor();

        datasets.push({
          label: origineCode,
          backgroundColor: color,
          data: origineData.now,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
        });
      }
      
      return {
        labels: this.dataPerOrigin.labels,
        datasets: datasets,
      };
    },
    chartDataPerActivityForLine() {
      if (!this.dataPerActivity) {
        return null;
      }

      const datasets = [];

      for (const [activityName, activityData] of Object.entries(this.dataPerActivity.datasets)) {
        const color = this.getColor();
        datasets.push({
          label: activityName,
          backgroundColor: color,
          data: activityData.now,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
        });

        datasets.push({
          label: `${activityName} l'an dernier`,
          backgroundColor: color,
          data: activityData.last_year,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
          borderDash: [5, 5], // Define dash pattern here
        });
      }
        
      return {
        labels: this.dataPerActivity.labels,
        datasets: datasets,
      };
    },
    chartDataPerActivityForBar() {
      if (!this.dataPerActivity) {
        return null;
      }

      const datasets = [];

      for (const [activityName, activityData] of Object.entries(this.dataPerActivity.datasets)) {
        const color = this.getColor();
        datasets.push({
          label: activityName,
          backgroundColor: color,
          data: activityData.now,
          borderColor: color, // Définir la couleur de ligne ici
          fill: false, // Assurez-vous que fill est défini sur false pour obtenir des lignes colorées
        });
      }
      
      return {
        labels: this.dataPerActivity.labels,
        datasets: datasets,
      };
    },
  },
  watch: {
    frequency() {
      this.fetchData();
    },
    technicianId() {
      this.fetchData();
    },
    selectedData(newVal) {
      if (newVal == "per_origin") {
        if (!this.dataPerOrigin) {
          this.fetchData("origin");
        }
      } else if (newVal == "per_activity") {
        if (!this.dataPerActivity) {
          this.fetchData("activity");
        }
      } else (
        this.fetchData()
      );
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(groupBy = null) {
      this.loadingData = true;
      return this.$services.stats.turnover(
        this.frequency,
        this.technicianId,
        groupBy,
      ).then((response) => {
        if (groupBy == "origin") {
          this.dataPerOrigin = response.data;
        } else if (groupBy == "activity") {
          this.dataPerActivity = response.data;
        } else {
          this.data = response.data;
        }
      })
        .finally(() => {
          this.loadingData = false;
        });
    },
    getColor() {
      const colors = [
        "blue",
        "red",
        "green",
        "yellow",
        "orange",
        "purple",
        "pink",
        "brown",
        "grey",
        "black",
        "aqua",
        "aquamarine",
        "beige",
        "bisque",
        "blueviolet",
        "burlywood",
        "cadetblue",
        "chartreuse",
        "chocolate",
        "coral",
        "cornflowerblue",
        "crimson",
        "cyan",
        "darkblue",
        "darkcyan",
        "darkgoldenrod",
        "darkgray",
        "darkgreen",
        "darkkhaki",
        "darkmagenta",
        "darkolivegreen",
        "darkorange",
        "darkorchid",
        "darksalmon",
        "darkseagreen",
        "darkslateblue",
        "darkslategray",
        "darkturquoise",
        "darkviolet",
        "deeppink",
        "deepskyblue",
        "dimgray",
        "dodgerblue",
        "firebrick",
        "floralwhite",
        "forestgreen",
        "fuchsia",
        "gainsboro",
        "ghostwhite",
        "gold",
      ];

      
      const color = colors[this.colorIndex];

      this.colorIndex = this.colorIndex + 1;

      if (this.colorIndex >= colors.length) {
        this.colorIndex = 0;
      }

      return color;
    },
    showInfo() {
      this.$refs.infoModal.showHtml(this.info);
    },
  },
};

</script>