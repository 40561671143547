<template>
  <Modal ref="modal" width="small">
    <template #default>
      <strong>Mettre à jour le lot de {{ modelValue.product.name }} dans {{ modelValue.storage.name }}</strong>
      <div class="form-inline-group">
        <label class="form-label">
          Nombre d'unités
        </label>
        <input
          v-model="internalUnitCount"
          type="number"
          class="form-control">
      </div>
    </template>

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="submitting"
        @click="hide">
        Annuler
      </button>
      
      <button
        class="btn-primary"
        :class="{ 'loading': submitting }"
        :disabled="submitting"
        style="margin-left: 5px;"
        @click="submit">
        Valider
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "UpdateBatchModal",
  components: {
    Modal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      submitting: false,
      internalUnitCount: this.modelValue.unit_count,
    };
  },
  methods: {
    show() {
      this.internalUnitCount = this.modelValue.unit_count,
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.submitting = true;

      this.$services.batch.patch(
        this.modelValue.id,
        { unit_count: this.internalUnitCount },

      ).then((response) => {
        this.$emit("update:modelValue", {
          ...this.modelValue,
          unit_count: response.data.unit_count,
          quantity_humanized: response.data.quantity_humanized,
        });

        this.hide();

      }).finally(() => {
        this.submitting = false;
      });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>