<template>
  <Modal ref="modal">
    <div style="margin-bottom: 20px">
      <strong>Ajouter des factures à cette période de contrat</strong>
    </div>

    <div
      v-for="invoice of availableInvoices"
      :key="invoice.id"
      style="margin-bottom: 5px;">
      <div
        class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :disabled="invoicesIdsAlreadyAdded.includes(invoice.id)"
          :checked="invoicesIdsToAdd.includes(invoice.id) || invoicesIdsAlreadyAdded.includes(invoice.id)"
          @change="onSelect(invoice, $event.target.checked)">
        <label class="form-check-label">
          Facture {{ invoice.ref }}
        </label>
      </div>
      <div
        v-for="intervention of invoice.interventions"
        :key="intervention.id"
        style="margin-left: 17px;">
        Intervention {{ intervention.ref_number }}
      </div>
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <button
          class="btn-secondary"
          :disabled="submitting"
          @click="hide">
          Annuler
        </button>

        <button
          class="btn-primary"
          :class="{'loading': submitting}"
          :disabled="submitting"
          @click="submit">
          Enregistrer
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "AddExistingInvoicesToContractModal",
  components: {
    Modal,
  },
  props: {
    modelValue: { // The iteration object
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      availableInvoices: [],
      invoicesIdsToAdd: [],
      invoicesIdsAlreadyAdded: this.modelValue.invoices.map((invoice) => invoice.id),
      submitting: false,
    };
  },
  watch: {
    modelValue() {
      this.invoicesIdsAlreadyAdded = this.modelValue.invoices.map((invoice) => invoice.id);
    },
  },
  mounted() {
    this.$services.customer.retrieve(this.modelValue.contract.customer.id)
      .then((response) => {
        this.availableInvoices = response.data.invoices;
      });
  },
  methods: {
    clear() {
      this.invoicesIdsToAdd = [];
      this.submitting = false;
    },
    show() {
      this.clear();
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
      this.clear();
    },
    onSelect(invoice, checked) {
      if (checked) {
        this.invoicesIdsToAdd.push(invoice.id);
      } else {
        this.invoicesIdsToAdd = this.invoicesIdsToAdd.filter((id) => {
          return id !== invoice.id;
        });
      }
    },
    submit() {
      this.submitting = true;

      const promises = this.invoicesIdsToAdd.map((invoiceId) => {
        return this.$services.invoice.patch(invoiceId, {contract_id: this.modelValue.id});
      });

      Promise.all(promises)
        .then(() => {
          this.$services.contractIteration.retrieve(this.modelValue.id)
            .then((response) => {
              this.$emit("update:modelValue", response.data);
            });
          this.hide();
        })
        .finally(() => {
          this.submitting = false;
        });
      
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>