<template>
  <GBaseRow
    :clickable="modelValue.id != null"
    @click="click">
    <template #icon>
      <div style="display: flex;">
        <i class="bi bi-briefcase" />
        <i v-if="intervention.is_emergency" class="bi bi-exclamation-lg" style="font-size: 1.5rem;" />
      </div>
    </template>

    <template #title>
      <span v-if="intervention.ref_number" class="text-smaller">{{ intervention.ref_number }}</span>
    </template>

    <template #default>
      <td>
        <router-link
          class="btn-link"
          style="margin-right: 5px;"
          :to="{ name: 'customer', params: { 'id': intervention.customer.id } }"
          @click.stop>
          {{ $filters.printCustomer(intervention.customer) }}
        </router-link>

        <router-link
          class="btn-link"
          target="_blank"
          :to="{ name: 'customer', params: { 'id': intervention.customer.id } }"
          @click.stop>
          <i class="bi bi-box-arrow-up-right" />
        </router-link>
      </td>

      <td>
        <router-link
          v-if="intervention.contract"
          class="btn-link"
          style="margin-right: 5px;"
          :to="{ name: 'contract', params: { 'id': intervention.contract.contract.id } }"
          @click.stop>
          Voir le contrat
        </router-link>

        <router-link
          v-if="intervention.contract"
          class="btn-link"
          target="_blank"
          :to="{ name: 'contract', params: { 'id': intervention.contract.contract.id } }"
          @click.stop>
          <i class="bi bi-box-arrow-up-right" />
        </router-link>
        <br>
        <i class="bi bi-briefcase" /> {{ intervention.rank }} / {{ intervention.contract.nb_interventions }}
      </td>

      <td>
        {{ $filters.dateStringToHumanizedDate(intervention.optimized_rdv_date) }}
      </td>

      <td>
        <span v-if="intervention.created_at">
          {{ $filters.dateStringToHumanizedDate(intervention.created_at) }}
        </span>
      </td>

      <td>
        <span
          v-for="(activity, index) in intervention.activities"
          :key="index">
          {{ activity.name }}, 
        </span>
      </td>

      <td class="text-smaller">
        <div v-if="intervention.location">
          {{ intervention.location.street_number }} {{ intervention.location.street }}<br>
          {{ intervention.location.zipcode }} {{ intervention.location.city }} {{ intervention.location.country_code }}
        </div>
      </td>

      <td>
        <div v-if="intervention.technician || (intervention.other_technicians && intervention.other_technicians.length > 0)">
          <span v-if="intervention.technician">{{ $filters.fullname(intervention.technician) }}</span>
          <span
            v-for="(technician, index) in intervention.other_technicians"
            :key="index">
            <br>{{ $filters.fullname(technician) }}
          </span>
        </div>
      </td>

      <td>
        <router-link
          v-if="intervention.invoice"
          class="highlighted green"
          :to="{ name: 'invoice', params: { 'id': intervention.invoice.id } }"
          @click.stop>
          <span v-if="intervention.invoice.send_date">Facture envoyée au client le {{ intervention.invoice.send_date }}</span>
          <span v-else>Facture créée</span>
        </router-link>
        
        <router-link
          v-else-if="intervention.quote"
          class="highlighted green"
          :to="{ name: 'quote', params: { 'id': intervention.quote.id } }"
          @click.stop>
          <span v-if="intervention.quote.send_date">Devis envoyée au client le {{ intervention.quote.send_date }}</span>
          <span v-else>Devis créée</span>
        </router-link>

        <div v-else>
          <router-link class="btn-link" style="white-space: nowrap" :to="{ name: 'create-quote', query: { intervention: intervention.id } }" @click.stop>
            Créer un devis
          </router-link><br>
          <router-link class="btn-link" style="white-space: nowrap" :to="{ name: 'create-invoice', query: { intervention: intervention.id } }" @click.stop>
            Créer une facture
          </router-link>
        </div>
      </td>
    </template>
  </GBaseRow>
</template>

<script>

import { GBaseRow } from "g-list";

export default {
  name: "InterventionToPlanRow",
  components: {
    GBaseRow,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    intervention: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    click() {
      this.$router.push({ name: "intervention", params: { "id": this.intervention.id } });
    },
  },
};
</script>

<style lang="scss" scoped>


</style>