<template>
  <Modal ref="image-modal" width="big">
    <template #default>
      <div style="width: 100%; height: 100%; position: relative; display: flex; justify-content: center; align-items: center;">
        <img v-if="imageHref" :src="imageHref">
      </div>
    </template>
    <template #buttons>
      <button
        class="btn-primary"
        @click="hide()">
        Ok
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";

export default {
  name: "ImageModal",
  components: {
    Modal,
  },
  data() {
    return {
      imageHref: null,
    };
  },
  methods: {
    show(imageHref) {
      this.imageHref = imageHref;
      this.$refs["image-modal"].show();
    },
    hide() {
      this.$refs["image-modal"].hide();
      this.imageHref = null;
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>