<template>
  <ObjectViewLayout
    :title="title"
    :loading="object == null"
    :errorStr="errorStr"
    withBackButton
    @back="$router.push({ name: 'intervention-list' })">
    <template #title-icon-left>
      <BriefcaseIcon />
    </template>

    <template #rightButton>
      <InterventionToolButtons v-model="object" @showQrCode="showImage" />
    </template>

    <div class="grid">
      <div class="card">
        <div class="card-header">
          <span class="title">Information sur l'intervention</span>
        </div>
        <div class="card-body">
          <div class="info-grid-layout">
            <div>Status</div>
            <div>{{ object.status_type }}</div>
            <div>Origine</div>
            <div>{{ object.origin.code }} ({{ object.origin.tax_rate }}% de taxes)</div>
            <div>Type de nuisibles</div>
            <div v-if="objectActivitiesHumanized">
              {{ objectActivitiesHumanized }}
              <button
                class="btn-link"
                @click="$refs.editActivitiesModal.show(object)">
                Modifier
              </button>
            </div>
            <div v-else>
              <i>Aucun</i>
              <button
                class="btn-link"
                @click="$refs.editActivitiesModal.show(object)">
                Modifier
              </button>
            </div>
            <div>Demande du client</div>
            <div v-if="object.request">
              {{ object.request }}
            </div>
            <div v-else>
              <i>Aucun</i>
            </div>
            <div>Urgence</div>
            <div v-if="object.is_emergency">
              Oui
            </div>
            <div v-else>
              Non
            </div>
          </div>
          <EditInterventionActivitiesModal ref="editActivitiesModal" @change="object = {...object, ...$event}" />
        </div>
      </div>

      <InterventionTechniciansCard
        :intervention="object"
        @updateTechnician="(technician) => object.technician = technician"
        @updateOtherTechnicians="(otherTechnicians) => object.other_technicians = otherTechnicians" />
    </div>

    <div class="grid">
      <InterventionCustomerCard
        v-model="object" />

      <PersonsCard v-model="object.contacts" />

      <LocationCard
        v-model="object.location"
        editable
        title="Adresse d'intervention" />
    </div>

    <div class="grid">
      <InterventionInvoiceCard
        v-model="object.invoice"
        :interventionId="object.id" />

      <InterventionQuoteCard
        v-model="object.quote"
        :interventionId="object.id" />
    </div>

    <RemarkForm
      v-if="object.remarks"
      title="Remarques internes"
      :remarks="object.remarks" />

    <template #rightBar>
      <div class="vertical-spaced-container">
        <CreatedUpdatedAtCard :object="object" />

        <div class="card">
          <div class="card-body">
            <p>Numéro d'intervention: <strong>{{ object.ref_number }}</strong></p>
            <p>Numéro de communication structurée: <strong>{{ object.structured_communication }}</strong></p>
            <p>Premier rendez-vous: <strong v-if="object.first_rdv">Oui</strong><strong v-else>Non</strong></p>
          
            <div class="form-group">
              <label class="form-label">Date de rendez-vous</label>
              <div class="tooltip">
                <RdvDatePicker
                  v-model="object.rdv_date"
                  :disabled="!object.technician"
                  @update:modelValue="onRdvDateSelected" />
                <span v-if="!object.technician" class="tooltip-text-red">
                  Vous devez assigner un technicien avant de choisir une date de rendez-vous
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-md form-group">
                <label class="form-label">Le technicien peut arriver à partir de</label>
                <div style="display: flex; align-items: center;">
                  <TimeInput
                    v-model="object.rdv_min_start_time"
                    @update:modelValue="onRdvMinStartTimeSelected" />
                </div>
              </div>

              <div class="col-md form-group">
                <label class="form-label">Le technicien peut arriver jusqu'à</label>
                <div style="display: flex; align-items: center;">
                  <TimeInput
                    v-model="object.rdv_max_start_time"
                    @update:modelValue="onRdvMaxStartTimeSelected" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="form-label">Durée prévue de l'intervention</label>
              <div class="input-group">
                <input
                  v-model="customDuration"
                  type="number"
                  min="0"
                  class="form-control">
                <div class="input-group-append">
                  min
                </div>
                <button
                  v-if="customDuration != null && customDuration != object.duration"
                  class="btn-primary"
                  style="margin-left: 5px;"
                  :class="{ 'loading': savingCustomDuration }"
                  :disabled="savingCustomDuration"
                  @click="saveCustomDuration">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>

        <InterventionCalendarEventsCard v-model="object" />

        <InterventionTasksCard
          v-model="object" />

        <CallListCard
          :customerId="object.customer.id"
          :calls="object.customer.calls" />

        <HistoryCard
          objectName="l'intervention"
          :history="object.history"
          :postComment="postComment" />
      </div>
    </template>
  </ObjectViewLayout>

  <ImageModal ref="imageModal" @click.stop />

  <ConfirmModal
    ref="confirmCloseCallTaskModal"
    message="Voulez-vous cloturer les taches 'Appeler' sur cette intervention ?"
    @confirm="closeCallTasks" />
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import InterventionToolButtons from "@/components/toolbuttons/InterventionToolButtons.vue";
import RemarkForm from "@/components/forms/RemarkForm.vue";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import InterventionInvoiceCard from "@/components/cards/InterventionInvoiceCard.vue";
import InterventionQuoteCard from "@/components/cards/InterventionQuoteCard.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import InterventionTechniciansCard from "@/components/cards/InterventionTechniciansCard.vue";
import PersonsCard from "@/components/cards/PersonsCard.vue";

import InterventionCustomerCard from "@/components/cards/customerCards/InterventionCustomerCard.vue";
import LocationCard from "@/components/cards/LocationCard.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";

import CallListCard from "@/components/CallListCard.vue";
import InterventionTasksCard from "@/components/cards/interventionCards/InterventionTasksCard.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import InterventionCalendarEventsCard from "@/components/cards/interventionCards/InterventionCalendarEventsCard.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CreatedUpdatedAtCard from "@/components/cards/generic/CreatedUpdatedAtCard.vue";

import EditInterventionActivitiesModal from "@/components/modals/EditInterventionActivitiesModal.vue";

export default {
  name: "InterventionObjectView",
  components: {
    ObjectViewLayout,
    InterventionToolButtons,
    RemarkForm,
    BriefcaseIcon,
    InterventionInvoiceCard,
    InterventionTechniciansCard,
    InterventionCustomerCard,
    InterventionQuoteCard,
    InterventionTasksCard,
    ConfirmModal,
    CallListCard,
    TimeInput,
    RdvDatePicker,
    InterventionCalendarEventsCard,
    ImageModal,
    CreatedUpdatedAtCard,
    LocationCard,
    PersonsCard,
    EditInterventionActivitiesModal,
    HistoryCard,
  },
  data() {
    return {
      object: null,
      schema: null,
      errorStr: null,
      customDuration: null,
      savingCustomDuration: false,
    };
  },
  computed: {
    objectActivitiesHumanized() {
      if (this.object.activities) {
        const activities = [];
        
        for (const activity of this.object.activities) {
          activities.push(activity.name);
        }
        return activities.join(", ");
      }
      return null;
    },
    title() {
      if (!this.object) {
        return "Chargement de l'intervention...";
      } else {
        return "Intervention " + this.object.ref_number;
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorStr = "Une erreur est survenue lors du chargement de l'intervention";
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.intervention.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
          this.customDuration = response.data.duration;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.errorStr = "L'intervention n'existe pas ou vous n'avez pas les droits pour la consulter. Contactez le Webmaster si vous pensez qu'il s'agit d'une erreur.";
          } else {
            throw error;
          }
        });
    },
    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.object.customer.id }});
    },

    // Rdv date and time

    onRdvDateSelected(date) {
      this.$services.intervention.patch(
        this.object.id,
        {
          rdv_date: date,
        },
      ).then((response) => {
        this.object.rdv_date = response.data.rdv_date ? response.data.rdv_date.substring(0, 19) : null;

        if (this.object.tasks.findIndex((task) => task.action === "call") !== -1) {
          this.$refs.confirmCloseCallTaskModal.show();
        }
      });
    },

    closeCallTasks() {
      this.object.tasks.forEach((task) => {
        if (task.action === "call" && task.status === "todo") {
          this.$services.task.toggleStatus(task.id)
            .then((response) => {
              task.status = response.data.status;
            });
        }
      });
      this.$refs.confirmCloseCallTaskModal.hide();
    },

    onRdvMinStartTimeSelected(time) {
      this.$services.intervention.patch(
        this.object.id,
        {
          rdv_min_start_time: time,
        },
      ).then((response) => {
        this.object.rdv_min_start_time = response.data.rdv_min_start_time;
      });
    },

    onRdvMaxStartTimeSelected(time) {
      this.$services.intervention.patch(
        this.object.id,
        {
          rdv_max_start_time: time,
        },
      ).then((response) => {
        this.object.rdv_max_start_time = response.data.rdv_max_start_time;
      });
    },

    saveCustomDuration() {
      this.savingCustomDuration = true;
      this.$services.intervention.patch(
        this.object.id,
        {
          custom_duration: this.customDuration,
        },
      ).then((response) => {
        this.customDuration = response.data.duration;
        this.object.duration = response.data.duration;
      }).finally(() => {
        this.savingCustomDuration = false;
      });
    },
    
    onCustomEvent(description) {
      this.$services.intervention.createEvent(this.object.id, description)
        .then((response) => {
          this.object.events = response.data.events;
          this.$refs.eventListCard.reset();
        });
    },
    postComment(comment) {
      return this.$services.intervention.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },

    showImage(image) {
      this.$refs.imageModal.show(image);
    },
    
  },
};
</script>


<style lang="scss" scoped>

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-flow: row;
  margin-bottom: 10px;
}

</style>