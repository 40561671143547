<template>
  <div class="card" style="border: 1px solid #ccc; background: transparent;">
    <div class="card-body">
      <div class="horizontal-spaced-container" style="margin-bottom: 10px;">
        <div style="flex-grow: 1;">
          <div class="flow-spaced-container">
            <div style="flex-grow: 1;">
              <div class="info-grid-layout">
                <div>
                  Dates
                </div>

                <div class="horizontal-spaced-container align-middle">
                  <div>Du {{ $filters.dateStringToHumanizedDate(modelValue.start_date) }} au {{ $filters.dateStringToHumanizedDate(modelValue.end_date) }}</div>
                </div>
          
                <div>
                  Résponsable
                </div>

                <div class="horizontal-spaced-container align-middle">
                  <div v-if="modelValue.responsible">
                    {{ $filters.fullname(modelValue.responsible) }}
                  </div>
                  <div v-else>
                    <i>Aucun</i>
                  </div>
                </div>

                <div>
                  Prix
                </div>
          
                <div class="horizontal-spaced-container align-middle">
                  <div>{{ modelValue.price / 100 }}€ HTVA</div>
                </div>

                <div>
                  Mode de facturation
                </div>

                <div class="horizontal-spaced-container align-middle">
                  <div>{{ modelValue.price_model }}</div>
                </div>
              </div>
            </div>

            <div>
              <ContractProgressBar :iteration="modelValue" />
            </div>
          </div>
        </div>

        <div>
          <button
            class="tool-btn"
            @click="$refs.iterationModal.edit(modelValue)">
            <i class="bi bi-pencil-square" />
          </button>
        </div>
      </div>
      
      <div class="horizontal-spaced-container">
        <InvoiceCompactCard
          v-for="invoice of modelValue.invoices.slice(0, 2)"
          :key="invoice.id"
          :modelValue="invoice">
          <template #header>
            <button
              class="small-tool-btn tooltip"
              @click="unlinkInvoice(invoice)">
              <i class="bi bi-x-circle" />
              <div class="tooltip-text">
                Délier la facture du contrat
              </div>
            </button>
          </template>
        </InvoiceCompactCard>

        <div class="vertical-spaced-container align-center">
          <button
            v-if="modelValue.invoices.length > 2"
            class="tool-btn tooltip"
            @click="$refs.contractIterationInvoicesModal.show()">
            ({{ modelValue.invoices.length - 2 }}) <i class="bi bi-arrow-bar-right" />
            <div class="tooltip-text">
              Voir {{ modelValue.invoices.length - 2 }} factures de plus
            </div> 
          </button>

          <button
            class="tool-btn tooltip"
            @click="$refs.addInvoicesToContractModal.show()">
            <i style="margin-right: 5px;" class="bi bi-plus-circle" /> Ajouter des factures
            <div class="tooltip-text">
              Ajouter des factures existantes à ce contrat
            </div>
          </button>
        </div>

        <CustomerInvoicesModal ref="contractIterationInvoicesModal" :invoices="modelValue.invoices" />

        <AddExistingInvoicesToContractModal
          ref="addInvoicesToContractModal"
          :modelValue="modelValue"
          @update:modelValue="onUpdateModelValue" />
      </div>
    </div>

    <EditContractIterationModal
      ref="iterationModal"
      @saved="onUpdateModelValue" />
  </div>
</template>

<script>

import CustomerInvoicesModal from "@/components/modals/CustomerInvoicesModal.vue";
import InvoiceCompactCard from "@/components/cards/invoiceCards/InvoiceCompactCard.vue";
import AddExistingInvoicesToContractModal from "@/components/modals/AddExistingInvoicesToContractModal.vue";
import EditContractIterationModal from "@/components/modals/EditContractIterationModal.vue";
import ContractProgressBar from "@/components/others/ContractProgressBar.vue";

export default {
  name: "ContractIterationCard",
  components: {
    CustomerInvoicesModal,
    InvoiceCompactCard,
    AddExistingInvoicesToContractModal,
    EditContractIterationModal,
    ContractProgressBar,
  },
  props: {
    modelValue: { // The iteration object
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onUpdateModelValue(value) {
      this.$emit("update:modelValue", value);
    },
    unlinkInvoice(invoice) {
      this.$services.invoice.patch(invoice.id, {contract_id: null})
        .then(() => {
          this.onUpdateModelValue(
            Object.assign({}, this.modelValue, {
              invoices: this.modelValue.invoices.filter(i => i.id !== invoice.id),
              interventions: this.modelValue.interventions.filter(i => i.invoice.id !== invoice.id),
            }),
          );
        });
    },
  },
};

</script>