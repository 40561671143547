<template>
  <div
    v-for="(element, index) in elements"
    :key="index"
    class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :checked="internalValue.includes(element.id)"
      @change="onElementSelected(element, $event.target.checked)">
    <label class="form-check-label">{{ element[fieldToDisplay] }}</label>
  </div>
</template>

<script>

export default {
  name: "ManyToManyForm",
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => {return [];},
    },
    elements: {
      type: Array,
      required: false,
      default: () => {return [];},
    },
    fieldToDisplay: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      internalValue: [...this.modelValue],
    };
  },
  methods: {
    onElementSelected(element, selected) {

      if (this.internalValue == null) {
        this.internalValue = [];
      }

      const index = this.internalValue.indexOf(element.id);

      if (!selected && index != -1) {
        this.internalValue.splice(index, 1);

      } else if (selected && index == -1) {
        this.internalValue.push(element.id);
      }

      this.$emit("update:modelValue", this.internalValue);
      this.$emit("change", this.internalValue);
    },
  },
};
</script>
